<template>
  <div class="avatar-upload">
    <div class="avatar-upload__image" :style="avatar"></div>
    <el-tooltip
      class="item"
      effect="dark"
      content="Coming Soon! Currently available when Google accounts are linked"
      placement="bottom"
    >
      <el-button class="avatar-upload__action" type="primary" size="mini" plain
        >Edit Avatar</el-button
      >
    </el-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      picture: null,
    };
  },
  computed: {
    avatar() {
      let url =
        this.$store.state.user && this.$store.state.user.picture
          ? this.picture
          : null;
      return `background-image: url('${url}')`;
    },
  },
  beforeCreate() {
    axios({
      url: '/api/user',
      method: 'GET',
      headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
    }).then((data) => {
      let userData = data.data;
      this.picture = userData.picture;
    });
  }
};
</script>

<style>
.avatar-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.avatar-upload__image {
  height: 150px;
  width: 150px;
  margin: 0 4rem 1rem 4rem;
  border-radius: 75px;
  background: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
