<template>
  <section
    v-if="adminAccess"
    class="content--wide"
    account-settings>

    <toggle-grid
      allow-hide
      caption="Your Permissions"
      heading="2"
      hide-list
      paging
      set-all
      request-detail="/api/feature/get/list"
      request-set="/api/feature/set"
      type="permissions_user"
    ></toggle-grid>

  </section>
</template>
<script>
import toggleGrid from './../../../components/ToggleGrid.vue';
export default {
  name: 'AccountSettings',
  components: {
    toggleGrid,
  },
  props: ['adminAccess'],
  data: function() {
    return {
      settings: null,
    }
  },
  mounted() {
    if (this.adminAccess) {
      this.settings = this.$el.querySelector('[account-settings="settings"]');
      this.registerMethods();
    }
  },
  methods: {
    /**
     * @description Register renderer functions
     */
    registerMethods() {
      window.renderToggle = (curVal) => {
        const ON = '<div role="switch" class="el-switch is-checked" aria-checked="true"><input type="checkbox" name="" true-value="true" class="el-switch__input"><!----><span class="el-switch__core" style="width: 40px; border-color: rgb(19, 206, 102); background-color: rgb(19, 206, 102);"></span><!----></div>';
        const OFF = '<div role="switch" class="el-switch"><input type="checkbox" name="" true-value="true" class="el-switch__input"><!----><span class="el-switch__core" style="width: 40px; border-color: rgb(255, 73, 73); background-color: rgb(255, 73, 73);"></span><!----></div>';
        if (curVal) return ON;
        else return OFF;
      }
    }
  },
}
</script>