<template>
  <div pricing="box" v-loading="loading">
    <h2 pricing="title">{{plan && plan.name ? plan.name : null}}</h2>
    <p pricing="tags">{{plan && plan.name ? planTags(plan.name) : null}}</p>
    <div pricing="body">
      <div pricing="lead">
        <div pricing="price-box">
          <strong pricing="price-value">${{planInterval.UnitPrice ? planInterval.UnitPrice : '-'}}</strong>
          <span pricing="price-type">{{interval === 'perpetual' ? '' : '/ year'}}</span>
        </div>
        <div pricing="toggle-box" :perpetual="isPerpetual" @click="$emit('toggle')">
          <span pricing="toggle-year">Yearly</span>
          <svg viewBox="0 0 24 24" type="toggle-off" style="width: 50px; height: 50px;">
            <path
              d="M17 7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h10c2.76 0 5-2.24 5-5s-2.24-5-5-5zM7 15c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"
              fill="var(--color-tertiary-1)"
            ></path>
          </svg>
          <svg viewBox="0 0 24 24" type="toggle-on" style="width: 50px; height: 50px;">
            <path
              d="M17 7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h10c2.76 0 5-2.24 5-5s-2.24-5-5-5zm0 8c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"
              fill="var(--color-tertiary-1)"
            ></path>
          </svg>
          <span pricing="toggle-perpetual">Perpetual</span>
        </div>
      </div>
      <div pricing="features">
        <ul v-if="planInterval">
          <li
            v-for="feature in planInterval.features"
            :key="feature"
          >{{feature}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    interval: String,
    isPerpetual: Boolean,
    plan: Object,
    planInterval: Object,
  },
  data() {
    return {
      loading: true,
    }
  },
  watch: {
    planInterval() {
      this.setLoading();
    },
  },
  mounted() {
    this.setLoading();
  },
  methods: {
    /**
     * @description Display tags for specific license
     * @param {String} plan - plan name
     */
    planTags(plan) {
      switch(plan) {
        // ZingChart
        case 'Website': return 'Blog, Simple Website';
        case 'Internal': return 'Internal Teams & Reporting';
        case 'Enterprise': return 'Multiple Internal Teams';
        case 'SaaS': return 'Salable Software & Applications';

        // ZingGrid
        case 'Single-Domain': return 'Website, Application';
        case 'Multi-Domain': return 'SaaS, Enterprise';
        case 'OEM': return 'Custom Solutions';
        
        default: return 'Website, Application';
      }
    },
    /**
     * @description Loading state is based on plan interval amount. If present, loading state is turned off.
     */
    setLoading() {
      if (this.planInterval.amount !== '-') this.loading = false;
    }
  }
}
</script>

<style src="../styles/subscription.css" scoped></style>
