<template>
  <el-dialog
    @open="getData"
    @close="triggerClose"
    domain-modal
    :visible="visible"
    width="1200px">

    <div slot="title">
      <p class="title">User info for {{email}}</p>
    </div>

    <div class="flex" v-loading="loading">
      <div>
        <section>
          <p class="zingnetCard--header">General</p>

          <p v-if="name">{{name}}</p>
          <p v-if="email || dataEmail">{{email || dataEmail}}</p>
          <p v-if="company || dataCompany">{{company || dataCompany}}</p>
          <p v-if="ip || dataIp">{{ip || dataIp}}</p>

          <p v-if="noData">No data on user</p>
        </section>

        <section v-if="quickbooks">
          <p class="zingnetCard--header">Quickbooks</p>
          <a :href="`https://c11.qbo.intuit.com/app/customerdetail?nameId=${quickbooks}`" target="_blank">Customer</a>
        </section>

        <section v-if="email">
          <p class="zingnetCard--header">TrackJS</p>
          <a :href="trackJsUrl" target="_blank">Errors by User</a>
        </section>
      </div>

      <div>
        <section>
          <p class="zingnetCard--header">Licensing</p>
          <template v-if="licenses && licenses.length > 0">
            <ul>
              <template v-for="license in licenses">
                <a :key="license.id" :href="`https://c11.qbo.intuit.com/app/invoice?txnId=${license.id}`" target="_blank"><li>{{license.name}}</li></a>
              </template>
            </ul>
          </template>
          <template v-else>
            <callout type="danger" icon="info"><p slot="content">This domain is not associated with a ZingChart or ZingGrid license</p></callout>
          </template>
        </section>

        <section>
          <p class="zingnetCard--header">Total Spendings</p>
          <p>${{totalSpending || 0}}</p>
        </section>

        <section>
          <p class="zingnetCard--header">Balance</p>
          <p>${{balance || 0}}</p>
        </section>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import axios from 'axios';
import Callout from './Callout.vue';

export default {
  components: {
    Callout,
  },
  props: {
    company: String,
    email: String,
    id: Number,
    ip: String,
    quickbooksId: Number,
    visible: Boolean,
  },
  data() {
    return {
      balance: 0,
      dataCompany: '',
      dataEmail: '',
      dataIp: '',
      name: '',
      licenses: [],
      loading: false,
      quickbooks: '',
      totalSpending: 0,
    };
  },
  computed: {
    noData() {
      return !this.name && !this.company && !this.dataCompany && !this.email && !this.dataEmail
        && !this.ip && !this.dataIp;
    },
  },
  methods: {
    getData() {
      this.loading = true;

      let query = '?';
      let addQuery = (q) => {
        if (this[q]) {
          if (query.length > 1) query += '&';
          query += `${q}=${this[q]}`;
        };
      };

      if (this.company) query += `company=${this.company}`;
      addQuery('email');
      addQuery('id');
      addQuery('quickbooksId');

      axios({
        url: `/api/admin/user${query}`,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
      })
      .then((response) => {
        let data = response.data;

        this.balance = this._addCommas(data.balance);
        this.dataCompany = data.company;
        this.dataEmail = data.email;
        this.dataIp = data.ip;
        this.name = data.name;
        if (data.licenses) {
          this.licenses = data.licenses.map((license) => {
            let interval = license.plan.interval === 'year' ? 'Annual' : 'Perpetual';
            let type = license.plan.type === 'ZC' ? 'ZingChart' : 'ZingGrid';
            return {
              id: license.id,
              name: [interval, type, license.plan.product].join(' '),
            };
          });
        };
        this.quickbooks = data.quickbooksId;
        this.totalSpending = this._addCommas(data.totalSpending);

        this.loading = false;
      });
    },
    trackJsUrl() {
      return this.email ? `https://my.trackjs.com/users/filtered?userid=${encodeURIComponent(this.email)}` : null;
    },
    triggerClose() {
      this.balance = 0,
      this.dataCompany = '';
      this.dataEmail = '';
      this.dataIp = '';
      this.name = '';
      this.licenses = [];
      this.quickbooks = '';
      this.totalSpending = 0;

      this.$emit('close');
    },

    _addCommas(num) {
      if (num) {
        let sNum = typeof num === 'string' ? num : num.toString();
        return sNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        return null;
      };
    },
  },
}
</script>

<style scoped>
  .flex {
    display: flex;
  }
  .flex > div {
    padding: var(--domain-modal-padding);
    width: 50%;
  }
  .flex > div + div {
    border-left: var(--zingnet-border);
  }
  section + section {
    margin-top: 1.875rem;
  }
</style>
