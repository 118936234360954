export default {
  data() {
    return {
      addLoading: false,
      cardDialogVisible: false,
      formCard: {
        cardNumber: '',
        cardExpiration: '',
        cardCvc: '',
        chargeOptional: '',
      },
    };
  },
  methods: {
    addCard() {
      this.addLoading = true;
      let cardExpiration = this.formCard.cardExpiration.split('/');
      let card = {
        number: this.formCard.cardNumber,
        exp_month: parseInt(cardExpiration[0]),
        exp_year: parseInt(cardExpiration[1]),
        cvc: this.formCard.cardCvc,
      }
      if (this.formCard.chargeOptional) card.name = this.formCard.chargeOptional;


      this.$api('card/add', {
        data: {card},
      }).then((response) => {
        if (response.data.cardType) {
          this.cardDialogVisible = false;
          this.hasCard = true;
        } else {
          this.$message({
            duration: 10000,
            message: `Could not add card. ${response.data}`,
            showClose: true,
            type: 'error',
          });
        }
        this.addLoading = false;
      }).catch((err) => {
        this.$message({
          duration: 10000,
          message: `Could not add card. ${err.response.data}`,
          showClose: true,
          type: 'error',
        });
        this.addLoading = false;
      });
    },
  }
};