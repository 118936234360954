<template>
  <div :class="`zingnetCard${gridBody ? ' gridBody' : ''}${span ? ' span' : ''}`">
    <div class="zingnetCard--header--wrapper">
      <h2 class=zingnetCard--header>
        <span class="header__left">
          <slot name="header"></slot>
        </span>
        <span class="header__right">
          <slot name="header__right"></slot>
        </span>
      </h2>
      <div class="header__subtitle">
        <slot name="subtitle"></slot>
      </div>
    </div>
    
    <div :class="`zingnetCard--body${flex ? ' cardFlex': ''}`">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'gridBody',   // Set true if body contains just ZingGrid to remove padding
    'flex',       // Set true to set display to row
    'span',       // Set true to make ZingnetCard span full row
  ],
}
</script>

<style>
  :root {
    --zingnet-background-color: #fff;
    --zingnet-border-radius: 0.25rem;
    --zingnet-border-color: #E0E5EF;
    --zingnet-border: 1px solid #E0E5EF;
    --zingnet-color-gray: #F6F9FC;
    --zingnet-head-cell-height: 3.125rem;
    --zingnet-input-color: #49586A;
    --zingnet-padding-v: 2.0625rem;
    --zingnet-padding-h: 1.9375rem;
    --zingnet-padding: var(--zingnet-padding-v) var(--zingnet-padding-h);

    --zingnet-chart-color: #e3e3e3;

    --zg-head-cell-icon-color_sorted: var(--color-secondary-blue-active);
    --zg-icon-init-fill: var(--color-primary-gray-visited);
    --zg-input-height: 35px;
    --zg-input-width: 50px;
    --zg-select-arrow-color: #49586A;
    --zg-cell-padding_layout_row: 1.3125rem 1.9375rem;
  }

  /* Links */
  .zingnetCard a,
  .zingnetCard .text--link {
    color: var(--color-secondary-blue);
    font-weight: 500;
    text-decoration: none;
  }
  .zingnetCard .text--link {
    margin-right: 5px;
  }
  .zingnetCard a:hover,
  .zingnetCard .text--link:hover {
    color: var(--color-tertiary-blue-focus);
    cursor: pointer;
  }

  /* Element UI */
  .zingnetCard .el-button:not(.el-button--text) {
    box-shadow: 0px 2px 4px 1px rgba(73, 79, 94, 0.07);
    font-size: 0.9375rem;
    margin-top: 1.25rem;
    padding: 0.625rem;
  }
  .zingnetCard .el-button:not(.el-button--text):not([disabled="disabled"]):hover {
    background-color: #fff;
    border-color: #40a6c3;
    color: var(--color-tertiary-blue-focus);
  }
  .zingnetCard .el-button[span] {
    width: 100%;
  }
  .zingnetCard .el-date-editor {
    border-radius: 10px;
    border-color: #E5E9F0;
    box-shadow: 0px 2px 4px 1px rgba(73, 79, 94, 0.07);
    height: 36px;
    line-height: 36px;
    padding: 0.05rem 0 0 1rem;
  }
  .zingnetCard .el-date-editor .el-range-separator {
    color: var(--color-primary-gray) !important;
    width: 7%;
  }
  .zingnetCard .input-wrapper {
    position: relative;
  }
  .zingnetCard .input-wrapper [icon="search2"] {
    top: 0;
  }
  .zingnetCard .el-input input {
    padding: 1.375rem 1.063rem;
  } 
  .zingnetCard .el-input-number .el-input-number__increase,
  .zingnetCard .el-input-number .el-input-number__decrease {
    line-height: 22px;
  }
  .zingnetCard .el-input-number .el-input-number__increase {
    border-radius: 0 10px 0 0;
  }
  .zingnetCard .el-input-number .el-input-number__decrease {
    border-radius: 0 0 10px 0;
  }
  .zingnetCard .el-textarea__inner {
    padding: 0.75rem 1.063rem;
  }
  .zingnetCard .el-input input,
  .zingnetCard .el-textarea__inner {
    color: #49586A !important;
    border: var(--border);
    border-radius: 10px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 0.9375rem !important;
  }
  .zingnetCard .el-input ::placeholder {
    color: #A5B3BF;
    font-size: 0.9375rem;
    font-style: italics;
    font-family: 'Nunito Sans', sans-serif;
  }
  .zingnetCard .el-date-input:focus,
  .zingnetCard .el-input input:focus,
  .zingnetCard .el-textarea__inner:focus {
    border-color: var(--color-tertiary-blue-focus);
  }
  .zingnetCard .el-input[type="teaxtarea"] {
    border-radius: 10px;
  }
  .zingnetCard .el-input input[search],
  .zingnetCard .filter_wrapper input {
    border-radius: var(--zingnet-border-radius);
    box-shadow: 0px 2px 4px 1px rgba(73, 79, 94, 0.07);
    padding: 1.25rem 0.875rem;
  }

  .el-form-item.flex .el-form-item__content {
    display: flex;
    height: 46px;
    width: 100%;
  }
  .el-form-item.flex .el-form-item__content .el-button {
    margin-left: 10px;
    margin-top: 0;
  }

  .zingnetCard .el-input-group--append .el-input__inner {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .zingnetCard .el-input-group--append .el-input-group__append {
    border: var(--border);
    border-left: 0;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .zingnetCard .el-form-item + .el-form-item {
    margin-top: 15px;
  }

  .zingnetCard .el-input-group--prepend .el-input-group__prepend {
    border: var(--border);
    border-right: 0;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }
  .zingnetCard .el-input-group--prepend input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    box-shadow: none !important;
  }
  .zingnetCard .el-input-group--prepend .el-select .el-input__inner:focus {
    border-color: transparent !important;
  }
  
  .zingnetCard .el-select input {
    border-color: #E5E9F0;
    box-shadow: 0px 2px 4px 1px rgba(73, 79, 94, 0.07);
    color: var(--color-primary-gray) !important;
    font-size: 0.9375rem !important;
    height: 36px;
    line-height: 36px;
    padding: 0 0 0 1rem;
    width: 140px;
  }
  .zingnetCard .el-select[large] input {
    width: 250px;
  }
  .zingnetCard .el-select .el-input__inner:focus {
    border-color:var(--color-tertiary-blue-focus) !important;
  }
  .zingnetCard .el-select-wrapper {
    position: relative;
    width: 140px;
  }
  .zingnetCard .el-select-icon {
    fill: #49586A;
    position: absolute;
    right: 0.75rem;
    top: 0;
  }
  .zingnetCard .zg-select-trigger {
    border: 0;
    width: 20px;
  }

  /* Flex */
  .zingnetCard .flex {
    display: flex;
  }
  .zingnetCard .flex__column {
    flex-direction: column;;
  }

  /* Row */
  .zingnetCardRow {
    width: 100%;
  }
  .zingnetCard--row {
    display: flex;
    position: relative;
    width: 100%;
  }
  .zingnetCard--row + .zingnetCard--row {
    border-top: var(--zingnet-border);
  }
  .zingnetCard--row.no-border {
    border-top: 0;
  }

  /* Column */
  .zingnetCard--column.column-45 {
    width: 45%;
  }
  .zingnetCard--column-50 {
    width: 50%;
  }
  .zingnetCard--column-55 {
    width: 55%;
  }
  .zingnetCard--column {
    flex: 1;
    padding: 1.4375rem var(--zingnet-padding-h);
  }
  .zingnetCard--column + .zingnetCard--column {
    border-left: var(--zingnet-border);
  }

  /* Header */
  .zingnetCard--header--wrapper {
    border-bottom: var(--zingnet-border);
    padding: var(--zingnet-padding);
  }
  .zingnetCard--header--wrapper p {
    color: var(--color-primary-gray);
    font-size: 0.9375rem;
    margin: 0.3125rem 0 0;
  }
  .zingnetCard--header {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
  }
  .zingnetCard--header.required::after {
    color: var(--color-fail);
    content: '*';
    margin-left: 5px;
  }
  .zingnetCard--header .header__right {
    display: flex;
  }
  .zingnetCard--header .header__right .el-button {
    margin-top: auto;
  }
  .zingnetCard [metadataby] .el-select input {
    width: 150px;
  }
  .zingnetCard--header [metadataby] [icon="close--circle"] {
    right: 0.7rem;
    top: 0.1rem;
  }
  .zingnetCard--header [metadataby] [icon="angle"] {
    right: 0.7rem;
    top: -0.05rem;
  }
  h2.zingnetCard--header {
    background-color: var(--zingnet-background-color);
    color: var(--color-secondary-blue-active);
    font-size: 1.25rem;
    margin: 0;
  }
  h3.zingnetCard--header,
  .zingnetCard--header {
    color: var(--color-tertiary-blue);
    font-size: 0.9375rem;
    font-weight: 600;
    margin: 0 0 1rem 0;
  }

  /* List */
  ol.zingnetCard--list {
    list-style-type: none;
    margin-top: 1rem;
    padding: 0;
  }
  .zingnetCard--list li {
    color: var(--color-secondary-blue);;
    counter-increment: item;
    font-size: 0.9375rem;
    font-weight: 500;
    padding-bottom: 0.25rem;
  }
  .zingnetCard--list li:before {
    color: var(--color-primary-gray);
    content: counter(item) ". ";
    font-size: 0.9375rem;
    font-weight: 400;
    margin-right: 0.25rem;
  }
  .zingnetCard--list--item:hover {
    color: var(--color-tertiary-blue-focus);
    cursor: pointer;
  }

  /* ZingGrid */
  .zingnetCard zing-grid[rounded-borders] {
    --zing-grid-border-radius: 10px;
  }
  .zingnetCard zing-grid[padded] {
    --zg-cell-padding_layout_row: 1.3125rem 1.9375rem;
  }
  .zingnetCard input:focus {
    border-color: var(--color-tertiary-blue-focus);
  }
  .zingnetCard zg-body:not([layout="card"]) zg-row:first-child {
    border-top: 0;
  }
  .zingnetCard zg-body zg-row:hover {
    background: var(--color-hover-light);
  }
  .zingnetCard zg-button {
    --zg-icon-color: var(--color-secondary-blue);
  }
  .zingnetCard zg-button[disabled] {
    --zg-icon-color: var(--color-secondary-gray);
  }
  .zingnetCard zg-button[action="reload"] {
    --zg-icon-color: #7F9099;
    margin-left: 5px;
  }
  .zingnetCard zg-button[action="reload"] zg-icon {
    height: 25px;
    width: 25px;
  }
  .zingnetCard zg-button:hover zg-icon svg {
    fill: var(--color-tertiary-blue-focus);
  }
  .zingnetCard zg-cell {
    color: var(--color-primary-gray);
  }
  .zingnetCard zg-cell zg-button zg-icon svg {
    fill: var(--color-primary-gray);
  }
  .zingnetCard zg-cell zg-button:hover zg-icon svg {
    fill: var(--color-primary-gray-visited);
  }
  .zingnetCard zg-filter {
    border-top: 0;
    height: 50px !important;
    padding-left: 0.875rem;
    overflow: visible;
  }
  .zingnetCard zg-filter,
  .zingnetCard zg-pager,
  .zingnetCard zg-head zg-row zg-head-cell {
    background: var(--zingnet-color-gray);
  }
  .zingnetCard zg-filter[sorted] {
    background: var(--zg-head-cell-background_sorted);
  }
  .zingnetCard zg-filter input,
  .zingnetCard zg-filter select {
    border-radius: var(--zingnet-border-radius);
    bottom: 8px;
    font-size: 0.9375rem;
    height: 2.25rem;
    min-width: 85%;
    padding-left: 10px;
    position: absolute;
    z-index: 10;
  }
  .zingnetCard zg-filter input[type="checkbox"] {
    margin-left: -1.6rem;
  }
  .zingnetCard zg-filter input[type="checkbox"]:before {
    border: 2px solid rgb(227, 227, 227);
    border-radius: 3px;
    content: "";
    height: 33px;
    left: 47px;
    position: absolute;
    width: 33px;
  }
  .zingnetCard zg-filter select {
    background: url('../../../assets/images/dropdown-arrow.png') no-repeat right #fff;
    background-position-x: calc(100% - 10px);
    border: 1px solid rgb(227, 227, 227);
    margin-left: 1rem;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
  }
  .zingnetCard zg-filter select:focus {
    border-color: var(--color-tertiary-blue-focus);
  }
  .zingnetCard zg-filter ::placeholder {
    color: #A5B3BF;
    font-size: 0.9375rem;
    padding-left: 10px;
  }
  .zingnetCard zg-head {
    border-bottom: var(--zingnet-border);
  }
  .zingnetCard zg-head zg-icon {
    bottom: 4px;
    height:  15px;
    position: relative;
    width: 15px;
  }
  .zingnetCard zg-head-cell {
    height: var(--zingnet-head-cell-height);
    padding: 0 1.875rem;
  }
  .zingnetCard zg-row {
    height: var(--zingnet-head-cell-height);
  }
  .zingnetCard zg-head zg-row zg-head-cell {
    color: var(--color-tertiary-blue);
    font-weight: 500;
  }
  .zingnetCard zg-icon {
    height: 18px;
    width: 18px;
  }
  .zingnetCard zg-input {
    color: #49586A;
    font-size: 0.9375rem;
  }
  .zingnetCard zg-pager {
    color: #49626A;
    font-size: 0.875rem;
  }
  .zingnetCard zg-pager zg-input,
  .zingnetCard zg-select {
    background: #fff;
    box-shadow: 0px 2px 4px 1px rgba(73, 79, 94, 0.07);
    color: var(--zingnet-input-color);
    font-size: 0.9375rem;
  }
  .zingnetCard zg-pager zg-input input {
    border-radius: var(--zingnet-border-radius);
  }
  .zingnetCard zg-select,
  .zingnetCard .zg-select-selected,
  .zingnetCard .zg-select-trigger {
    height: 35px;
  }
  .zingnetCard zing-grid {
    border: var(--zingnet-border);
    font-family: 'Nunito Sans', sans-serif;
    font-size: 0.9375rem;
    overflow: hidden;
  }
  .zingnetCard zing-grid [icon="open"] {
    fill: var(--color-primary-gray);
    margin: 0 0.3125rem;
  }
  .zingnetCard zing-grid [icon="open"]:hover {
    cursor: pointer;
    fill: var(--color-tertiary-blue-focus);
  }
  .zingnetCard zg-caption,
  .zingnetCard zg-watermark,
  .zingnetCard zing-grid[no-header] zg-head {
    display: none;
  }

  /* Renderers Misc */
  .zingnetCard zg-cell.zg-cell__na {
    color: var(--color-tertiary-gray);
  }
  .zingnetCard zing-grid .date {
    color: var(--color-tertiary-blue-visited);
    font-size: 0.6875rem;
    font-style: italic;
  }

  /* ZingGrid Pills */
  .zingnetCard zing-grid .pill--container {
    display: flex;
  }
  .zingnetCard zing-grid .pill {
    border-radius: 15px;
    color: #006180;
    font-size: 0.9375rem;
    padding: 0.125rem 0.8125rem;
  }
  .zingnetCard zing-grid .pill + .pill {
    margin-left: 0.75rem;
  }
  .zingnetCard zing-grid .pill__neg {
    background-color: #FFCDDA;
  }
  .zingnetCard zing-grid .pill__pos {
    background-color: #DAFFE0;
  }
  .zingnetCard zing-grid .pill__zinggrid {
    background-color: #D0F3FD;
  }
  .zingnetCard zing-grid .pill__zingchart {
    background-color: #D0E4FD;
  }

  /* ZingGrid [messages] Styles */
  .zingnetCard zing-grid[messages] {
    --zg-row-card-grid-gap: 1.25rem;

    border: 0;
  }
  .zingnetCard zing-grid[messages] zg-no-data {
    background: none;
    border: 0;
    padding: 0;
  }
  .zingnetCard zing-grid[messages] .no-data-custom {
    background-color: #F5F9FC;
    border: var(--zingnet-border);
    color: var(--color-primary-gray);
    margin: 0;
    padding: 1.25rem 1.625rem;
    font-size: 0.8125rem;
    border-radius: 10px;
  }
  .zingnetCard zing-grid[messages][layout="card"] zg-body {
    background: none;
    padding: 0;
  }
  .zingnetCard zing-grid[messages][layout="card"] [data-field-index],
  .zingnetCard zing-grid[messages][layout="card"] zg-cell,
  .zingnetCard zing-grid[messages][layout="card"] zg-row {
    padding: 0;
  }
  .zingnetCard zing-grid[messages][layout="card"] zg-cell label {
    display: none;
  }
  .zingnetCard zing-grid[message][layout="card"] zg-row {
    max-height: 331px;
  }
  .zingnetCard zing-grid[messages][layout="card"] .messages {
    max-width: 490px;
    width: 98%;
  }
  .zingnetCard zing-grid[messages][layout="row"] zg-cell {
    padding: 0 0 1.375rem 0;
  }
  .zingnetCard zing-grid[messages] zg-cell:hover,
  .zingnetCard zing-grid[messages] zg-row:hover {
    background: none;
  }
  .zingnetCard zing-grid[messages] zg-row {
    border: 0;
  }
  .zingnetCard zing-grid[messages] .messages {
    border: var(--zingnet-border);
    border-radius: 10px;
    max-width: 465px;
    overflow: hidden;
    width: 465px;
  }
  .zingnetCard zing-grid[messages] .messages--header {
    align-items: center;
    background-color: var(--zingnet-color-gray);
    border-bottom: var(--zingnet-border);
    display: flex;
    justify-content: space-between;
    min-height: 69px;
    padding: 0.75rem 1rem;
  }
  .zingnetCard zing-grid[messages] .messages--header--left > div {
    overflow: hidden;
    padding-right: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 165px;
  }
  .zingnetCard zing-grid[messages] .messages--header .el-button {
    margin-top: 0;
  }
  .zingnetCard zing-grid[messages] .messages--body {
    height: 260px;
    max-height: 260px;
    overflow: scroll;
    padding: 0.75rem 1rem;
    word-break: break-word;
  }
  .zingnetCard zing-grid[messages] .messages--body img {
    max-width: 100%;
  }

  /* Style Overwrites */
  .zingnetCard zing-grid[no-border] {
    border: 0 !important;
  }
  .zingnetCard.gridBody zing-grid {
    border: 0;
    border-top: var(--zingnet-border);
  }
  .zingnetCard .no-padding {
    padding: 0;
  }
</style>

<style scoped>
  .zingnetCard {
    background-color: var(--zingnet-background-color);
    box-shadow: 0px 0px 19px 8px rgba(73, 79, 94, 0.11);
    font-family: 'Nunito Sans', sans-serif;
    border-radius: var(--border-radius-medium);
    overflow: hidden;
    max-width: calc(var(--max-width) - 4rem);
  }
  .zingnetCard.gridBody .zingnetCard--body {
    padding: 0;
  }
  .zingnetCard.span {
    width: 100%;
  }
  .zingnetCard--body {
    background-color: var(--zingnet-background-color);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  .zingnetCard--body.cardFlex > div {
    display: flex;
    justify-content: center;
  }
  
  @media only screen and (min-width: 1050px) {
    .zingnetCard {
      width: 100%;
    }
  }
</style>