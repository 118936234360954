<template>
  <!-- WINDOW OPTIONS (MOBILE) -->
  <el-dropdown
    v-if="isMobile"
    @command="handleUpdate"
    size="mini"
    trigger="click">
    <el-button
      v-if="isMobile"
      type="primary"
      size="mini">
      <font-awesome-icon :icon="['fas', 'window-maximize']" size="sm"></font-awesome-icon><i class="el-icon-arrow-down el-icon--right"></i>
    </el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="option in showControlsOptions"
        :key="option"
        :command="option"
        :disabled="option === disabled"
        >{{option.toUpperCase()}}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
  <!-- WINDOW OPTIONS (DESKTOP) -->
  <el-checkbox-group
    v-else
    @change="handleUpdate"
    v-model="showControls"
    :min="1"
    size="mini">
    <el-checkbox-button  v-for="(toggle, index) in showControlsOptions" :label="toggle" :key="index" size="mini">
      {{toggle.toUpperCase()}}&nbsp;
    </el-checkbox-button>
  </el-checkbox-group>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$store.getters['ui/isMobile'];
    },
    stateHtmlTab() {
      let settings = this.$store.state.user['settings_editor'] ? JSON.parse(this.$store.state.user['settings_editor']) : {};
      if (settings.tabsHtml) {
        return settings.tabsHtml === 'true' ? 'html' : null;
      } else {
        return 'html';
      };
    },
    stateCssTab() {
      let settings = this.$store.state.user['settings_editor'] ? JSON.parse(this.$store.state.user['settings_editor']) : {};
      if (settings.tabsCss) {
        return settings.tabsCss === 'true' ? 'css' : null;
      } else {
        return 'css';
      };
    },
    stateJsTab() {
      let settings = this.$store.state.user['settings_editor'] ? JSON.parse(this.$store.state.user['settings_editor']) : {};
      if (settings.tabsJs) {
        return settings.tabsJs === 'true' ? 'js' : null;
      } else {
        return 'js';
      };
    },
  },
  data() {
    return {
      disabled: 'html',
      showControls: ['html', 'css', 'js'],
      showControlsOptions: ['html', 'css', 'js'],
    };
  },
  methods: {
    /**
     * @description Triggered on radio change event. Emits update event to parent element.
     * Disables current option.
     * Also updates the tab state.
     * @param {Array} value - label value of chosen radio(s)
     */
    handleUpdate(value) {
      // Disables current tab option
      this.disabled = value;

      // Emits event to parent
      this.$emit('update', value);

      // Update state
      let state = this.$store.state.user['settings_editor'] ? JSON.parse(this.$store.state.user['settings_editor']) : {};
      state.tabsHtml = value.includes('html').toString();
      state.tabsCss = value.includes('css').toString();;
      state.tabsJs = value.includes('js').toString();;
      this.$store.state.user['settings_editor'] = JSON.stringify(state);

      // Null check user and settings before updating
      if (this.$store.state.user.user_id && this.$store.state.user['settings_editor']) {
        // Update database
        this.$api('user/update', {
          'settings_editor': state,
        })
        .then((response) => {
          this.$store.dispatch('user/refresh', this);
        })
        .catch((result) => {
          this.$message({
            duration: 10000,
            message: 'Unable to update autoupdate settings',
            showClose: true,
            type: 'error',
          });
        })
      };
    },
  }
};
</script>


<style>
</style>