<template>
  <div id="app-shell">
    <template v-if="isLogout">
      <logout-view></logout-view>
    </template>
    <template v-if="socialSignup && !hasUser && name">
      <login-view :social="true" :name="name"></login-view>
    </template>
    <template v-else-if="isEmailVerify">
      <email-verify></email-verify>
    </template>
    <template v-else-if="isSubscribing">
      <subscribe-view></subscribe-view>
    </template>
    <template v-else-if="authenticated && hasUser">
      <app></app>
    </template>
    <template v-else-if="isDemosCreate && !hasUser && !authenticated">
      <demos-create-view></demos-create-view>
    </template>
    <template v-else-if="isForgot">
      <reset-password></reset-password>
    </template>
    <template v-else-if="isSiteLogin || (isLogin && !authenticated && !signupProcess)">
      <login-view :isAuthing="localStorageAuthState"></login-view>
    </template>
    <template v-else-if="isSignup">
      <signup-view :isAuthing="localStorageAuthState"></signup-view>
    </template>
    <template v-else-if="isUnsuccessful">
      <unsuccessful-view :isAuthing="localStorageAuthState"></unsuccessful-view>
    </template>
    <template v-else-if="isError">
      <page-warning></page-warning>
    </template>
    <template v-else>
      <!-- Empty div to prevent flashing above screens on redirect (caused by auth login/signup) -->
      <div></div>
    </template>


    <my-dialog
      title="New Version Available"
      type="warning"
      :show-close="false"
      :visible.sync="chunkWarningVisible">
        <div slot="content">
          <p>A new version of the app is available. Please reload page to continue.</p>
        </div>
        <div slot="options">
          <el-button @click="reloadPage" type="primary">Reload</el-button>
        </div>
    </my-dialog>
  </div>
</template>

<script>
  import axios from 'axios';
  import EmailVerify from './views/entry/EmailVerify.vue';
  import LoginView from './views/entry/Login.vue';
  import LogoutView from './views/entry/Logout.vue';
  import SignupView from './views/entry/Signup.vue';
  import SubscribeView from './views/entry/SubscribeView.vue';
  import ResetPassword from './views/entry/ResetPassword.vue';
  import UnsuccessfulView from './views/entry/Unsuccessful.vue';
  import DemosCreateView from './views/demos/Create.vue';
  import AppView from './views/App.vue';
  import jwtDecode from 'jwt-decode';
  import { mapState } from '@vuex';
  import AppFooter from './views/layouts/Footer.vue';
  import MixingLogs from './mixins/gaeLog.js';
  import MyDialog from './components/MyDialog.vue';
  import PageWarning from './views/PageWarning.vue';

  export default {
    components: {
      LoginView,
      LogoutView,
      'app': AppView,
      AppFooter,
      DemosCreateView,
      EmailVerify,
      MyDialog,
      SignupView,
      UnsuccessfulView,
      SubscribeView,
      ResetPassword,
      PageWarning,
    },
    mixins: [MixingLogs],
    
    data() {
      return {
        chunkWarningVisible: false,
        currentLocalStorageAuthState: false,
        name: '',
        signupProcess: false,
        socialSignup: false,
        signupReferrer: null,
      };
    },

    computed: {
      ...mapState({
        setAuthIsNewSignupLocalStorage: state => state.auth.setAuthIsNewSignupLocalStorage,
      }),
      isDemosCreate() {
        return this.$route.path.includes('/demos/create');
      },
      isEmailVerify() {
        return this.$route.path === '/verify';
      },
      isError() {
        let errorPages = ['/401', '/404', '/429'];
        let errors = errorPages.filter((err) => this.$route.path.includes(err));
        return errors.length > 0;
      },
      isForgot() {
        return this.$route.path === '/forgot';
      },
      isSiteLogin() {
        // Unable to update in 'watch', clear variable to display login screen
        this.signupProcess = false;
        return this.$route.path === '/login';
      },
      isLogin() {
        return this.$route.path === '/';
      },
      isLogout() {
        return this.$route.path === '/logout';
      },
      isSignup() {
        return this.$route.path === '/signup';
      },
      isSubscribing() {
        return this.$route.path === '/subscribe';
      },
      isUnsuccessful() {
        return this.$route.path === '/unsuccessful';
      },
      lock() { 
        return this.$store.state.auth.lock;
      },
      webAuth() {
        return this.$store.state.auth.webAuth;
      },
      // Determines is authenticated through auth0 is successful
      authenticated() {
        return !!this.$store.state.auth.idToken;
      },
      // After successful authentication, checks if user in database to complete login/signup process
      hasUser() {
        return !!this.$store.state.user['user_id'];
      },
      localStorageAuthState() {
        return this.currentLocalStorageAuthState;
      },
      socialAccount() {
        return (this.authenticated) ? this.$store.getters['auth/is_social'] : false;
      },
      message() {
        return this.$store.state.ui.message;
      },
    },

    watch: {
      // Prevent flash during signup by checking for localstorage item "setupDemos"
      signupProcess(newVal) {
        localStorage.setupDemos = (newVal === 'true') || newVal === true;
      },
      message(newValue, oldValue) {
        this.$message({
          duration: 10000,
          message: newValue,
          showClose: true,
          type: 'error',
        });
      },
    },

    created() {
      // Read localStorage variable 'GoogleAuthSignup' to display Social Signup screen
      // then remove variable to allow social login
      this.socialSignup = localStorage.getItem('GoogleAuthSignup') === 'true';
    },
    
    mounted() {
      // Read localStorage variable auth state to show/hide login page 
      // until auth returns from Auth0 lookup
      this.getLocalStorageAuthState();

      // Set a message modal from a previous screen.
      this.setStartupMessage();

      // We check for authentication.
      this.handleAuthFlow().then(() => {
        // Boot up app + check for previous auth.
        this.bootApp();
      });

      // Get referrer for signup
      this.getReferrer();

      // Read localStorage variable "setupDemos" to show/hide login page
      // after signup to prevent flashing login screen
      if (localStorage.setupDemos) {
        this.signupProcess = localStorage.setupDemos === 'true';
      }

      // Fetch tenant information
      //this.loadTenant();

      if (process.env.NODE_ENV === 'production') {
        // only init trackJS on production site because 
        // it makes it hard to track erros
        this.initTrackJS();
      }

      // initialize HelpScout
      this.initHelpScout();

      //init fullstory
      if (process.env.NODE_ENV !== 'dev') this.initFullstory();
      // init piwik
      // this.initPiwik();
      
      // Setup window dimension handler
      this.$store.commit('ui/updateDimensions');
      window.addEventListener('resize', () => {
        this.$store.commit('ui/updateDimensions');
      });

      // add function event listener for fullstory through global
      // window object
      window._fs_ready = this.fullStoryInstantiated;

      // add global destroy action
      window.addEventListener('beforeunload', this.appDestroyed);

      // add error handling for loading chunk fails
      window.addEventListener('unhandledrejection', (promiseRejectionEvent) => {
        if (/loading chunk \d* failed./i.test(promiseRejectionEvent.reason)) {
          this.chunkWarningVisible = true;
        };
      });
    },

    methods: {
      /**
       * @descriptiona function to clean up anything before navigating away from this app
       */ 
      appDestroyed() {
        Beacon('destroy');
      },

      /**
       * @description Read localStorage variable auth state on load to show/hide login page 
       * until auth returns from Auth0 lookup
       */
      getLocalStorageAuthState() {
        // Get localStorage var if it exists
        let isAuth = localStorage.getItem('isAuthenticating') == 'true';
        // Turn it off now that we stored the state
        localStorage.setItem('isAuthenticating', false);
        // Set variable passed to login view:
        // `<login-view :isAuthing="localStorageAuthState"></login-view> `
        this.currentLocalStorageAuthState = isAuth;
        // If the auth state was true, set to false to show the login view.
        // NOTE: The reason for this is that is you might click back to the app 
        // from the Auth0 page without authenticating OR you launch the Auth0 modal
        // and then hard-refresh the app. Each of these actions will leave the storage var
        // as TRUE, which would hide the login view (assumes you're authed by Auth0 at this point)
        // ---
        // The normal auth flow will return to the site with `id_token` localStorage var set, so
        // `this.currentLocalStorageAuthState` will be TRUE initially on return to the app,
        // where we'll briefly hide the login view from showing. At some point the Vue app will
        // fetch the session/auth from Auth0 and set the `id_token` var, which will prompt
        // this template file to show `<app></app>` instead of `<login-view></login-view>`,
        // so setting `this.currentLocalStorageAuthState` will do nothing visually b/c
        // that whole view is hidden - this process sets an attribute on an inner dom element
        // inside the login view, so when the full view is hidden, it doesn't matter if an internal
        // process toggles, etc.
        setTimeout(() => {
          if (isAuth) this.currentLocalStorageAuthState = false;
        }, 1000);
      },

      /**
       * @description Displays a message with app starts up. Used for hard logouts, authentication flows.
       * The message to display is stored in localStorage variable 'startup_status'
       */
      setStartupMessage() {
        const status = localStorage.getItem('startup_status');
        if (status) {
          const {message, type} = JSON.parse(status);
          localStorage.removeItem('startup_status');
          this.$message({
            duration: 10000,
            message: message,
            showClose: true,
          });
        }
      },

      /**
      * @description Start up app and reload authentication. Authentication information is stored in localStorage variables
      * 'id_token' and 'access_token'.
      */
      bootApp() {
        axios({
          url: '/api/user/serversession',
          method: 'GET',
        }).then((res) => {
          // If CSRF token does not exist, sign out to reload new
          if (!res.data || res.data === '') {
            this.forceLogout(true);
            return;
          }

          // Anti-CSRF token
          axios.defaults.headers.delete['X-CSRF-Token'] = res.data;
          axios.defaults.headers.post['X-CSRF-Token'] = res.data;
          axios.defaults.headers.patch['X-CSRF-Token'] = res.data;
          axios.defaults.headers.put['X-CSRF-Token'] = res.data;

          // Indicate that the page may be authing (flash issue)
          localStorage.setItem('isAuthenticating', true);
          
          // Start session cookie
          axios({
            url: '/api/user/serversession',
            method: 'POST',
            headers: { 'Authorization': `Bearer true` },
          }).then((response) => {
            // Auth token
            if (response.data.idToken) {
              this.$store.commit('auth/add_tokens', {
                idToken: response.data.idToken.split(' ')[1],
              });
            };

            if (this.$store.state.auth.parsedToken && this.$store.state.auth.parsedToken.sub.indexOf('auth0|') > -1) {
              this.$store.dispatch('refresh_state', { idToken: response.data.idToken });
            }

            // Handle redirect for non-public urls after refreshing when user already authenticated
            this.handleRedirects();
          });
        });
      },

      /**
       * @description Force user to log out.
       * @param {boolean} noRedirect - set true to not redirect
       */
      forceLogout(noRedirect) {
        ['id_token', 'access_token', 'user'].forEach(token => {
          localStorage.removeItem(token);
        });
        this.$store.commit('auth/logout');
        if (!noRedirect) window.location.href = window.location.origin;
      },

      /**
      * @description Gets referrer from query parameter or through `document.referrer` to pass to the signup process.
      * This inforamtion sets the default homepage/sections
      */
      getReferrer() {
        const authReferrer = localStorage.getItem('signupReferrer');
        if (authReferrer) {
          this.signupReferrer = authReferrer;
        } else {
          const referrerUrl = (this.$route.query.signup || document.referrer).toLowerCase();
          if (referrerUrl.indexOf('zingchart') >= 0) this.signupReferrer = 'zingchart';
          else if (referrerUrl.indexOf('zinggrid') >= 0) this.signupReferrer = 'zinggrid';
          if (this.signupReferrer) localStorage.setItem('signupReferrer', this.signupReferrer);
        }
      },

      /**
      * @description Check for authentication. 
      * If not authenticated:
      *   and not on public url: app redirects to login screen.
      *   and on public url: when user logins through lock, user is authenicated and redirected back to url they were on
      */
      handleAuthFlow() {
        return new Promise((resolve, reject) => {
          // If unauthenticated and on non-public url, redirect to login screen
          if (!this.authenticated && !this.isPublicURL()) {
            // Set redirectPath to redirect to page after login
            let forkID = this.$route.query.fork ? `?fork=${this.$route.query.fork}` : '';
            localStorage.setItem('redirectPath', `${this.$route.path}${forkID}`);

            // Redirect to login screen
            window.location.href = window.location.origin;
            resolve();
          };

          // Return if lock not initialized
          if (!this.lock) {
            resolve();
            return;
          };
          
          // User on public url and attempts to login => redirect back to url if authenticates
          if (this.isPublicURL()) {
            // Listen for the lock callback
            const authHash = window.location.hash;
            // User logging in through the lock
            this.lock.on('authenticated', authResult => {
              if (window.location.search.indexOf('linking') > -1 && authHash.length > 0) {
                this.handleAccountLinking(authResult, authHash);
                resolve();
              } else {
                this.handleNormalLogin(authResult, resolve);
              };
            });

            // when Auth0 lock throws an authentication error
            this.lock.on('authorization_error', error => {
              // firefox fires authenticated lock error when coming from
              // an already authenticated state
              if (!this.authenticated) {
                this.$message({
                  duration: 10000,
                  message: 'Could not authenticate',
                  showClose: true,
                  type: 'error',
                });
              }
            });

            // In the case where app refreshed
            if (authHash === '') {
              resolve();
            }
          } else {
            resolve();
          };
        });
      },

      /**
       * @description After login or signup, auth0 redirects to '/'. We check if localStorage variable 'redirectPath' or 'redirectSite' is set to
       * redirect to that specified location. Redirects only if not signing up through Google Auth, which requires redirecting
       * to login screen to prompt user to login to complete signup.
       * USE CASES:
       *    1. User signup/login from studio to save demo => redirect to demo
       *    2. User signup/login after redirecting from site's pricing page => redirect to account subscriptions
       *    3. User signup using google auth => redirect to google login page to complete google auth signup process
       */
      handleRedirects() {
        // Check the localStorage if the app should redirect to the page user previously on before login/signup
        const redirect = localStorage.getItem('redirectPath');
        const redirectSite = localStorage.getItem('redirectSite');
        // Redirect to redirectPath, if not completing Google Auth Signup
        if((redirect || redirectSite) && !this.$store.getters['auth/is_social']) {
          if (redirect) {
            // Do not remove if `redirectPath` used as flag
            if (!redirect.includes('/subscribe')) {
              localStorage.removeItem('redirectPath');
              localStorage.setItem('isAuthenticating', true);
            }
            if (this.$route.path !== redirect) this.$router.push(redirect);
            
            // Rehydrate state
            let state = localStorage.getItem('redirectData');
            if(state) {
              state = JSON.parse(state);
              const types = Object.keys(state);
              Object.keys(state).forEach(type => {
                this.$store.commit(type + '/add', {
                  ...state[type],
                });
              });
            }
          } else {
            // Redirect to external site
            localStorage.removeItem('redirectSite');
            // Prevent addblocker from 
            let newTab = window.open();
            newTab.location.href = redirectSite;
            // Close after allowing site get user info
            setTimeout(() => {
              window.close();
            }, 1000);
          }
        }
      },

      /**
       * @description Links two accounts together. After accounts successfully linked, user is logged out and prompted to relogin to
       * grab new account tokens.
       */
      handleAccountLinking(authResult, authHash) {
        //Parse the hash.
        const socialUser = authHash.slice(1).split('&').reduce((acc, next) => {
          const parts = next.split('=');
          acc[parts[0]] = parts[1];
          return acc;
        }, {});
        const userID = (JSON.parse(localStorage.getItem('user')))['id'];
        const socialID = jwtDecode(socialUser.id_token);
        this.$api('oauth/link', {
          data: {
            userid: userID,
            secondaryUserID: socialID.sub,
          }
        })
        .then((response) => {
          localStorage.setItem('startup_status', JSON.stringify({
            message: 'Account Linked! Please sign back in',
            type: 'success'
          }));
          // Hard logout. Forces the user to sign back in with the correct login.
          this.forceLogout();
        })
        .catch(error => {
          console.error(error);
          console.error('error posting to server');
        });
      },

      /**
       * @description After authenticating, Auth0 returns an object which is used to set localStorage variables 'id_token' and 'access_token',
       * which app uses to determine if user is logged in. This method handles both signup and login.
       * 
       * Signup:
       * An entry is created for the new user in the database. 
       * For non-Google-Auth signups, logs a 'zingsoft-signup' event
       * For Google-Auth signups, the app cleans up the localStorage and keeps redirect or signup variables
       * 
       * Login:
       * When user is logged in, load state through localStorage variables
       */
      handleNormalLogin(authResult, resolve) {
        // Authenticates user. Authentication may occur through Auth0 but may not guarentee successful login if occurs too quickly
        // or if user information is in different database. We look at the user store to check if login is successful.
        localStorage.setItem('id_token', true);

        axios({
          url: '/api/user/serversession',
          method: 'POST',
          headers: { 'Authorization': `Bearer ${authResult.idToken}` },
        }).then(() => {
          // Checks if it was a signup redirect from a non-Google-Auth signup
          if (localStorage.getItem('setupBeacon')) {
            // if !Beacon something went wrong loading Beacon in the browser...
            if (typeof Beacon !== 'undefined')  {
              this.$store.dispatch('user/addBeaconSession', { 'signup-type': `${this.signupReferrer || 'zingosoft'}-signup` });
              localStorage.removeItem('setupBeacon'); // clear that local storage signup item
            }
          };

          // If the user has just signed up, we need to create an entry in our local database
          if(authResult.idTokenPayload['https://app.zinggrid.com/signup'] && typeof authResult.idTokenPayload['https://app.zinggrid.com/signup_url'] !== 'undefined') {
            axios({
              url: `/api/user/signup${this.signupReferrer ? `?signup=${this.signupReferrer}` : ''}`,
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${authResult.idToken}`,
              }
            })
            .then(() => {
              if(authResult.idTokenPayload.sub.includes('auth0|')) {
                // Non-Google-Auth Signup: Fetch the user information from the serverside
                this.$store.dispatch('refresh_tokens');
                this.handleRedirects();
              } else {
                // Google-Auth Signup:
                // Gets name to display on screen that prompts user to login to complete Google Auth signup
                this.name = authResult.idTokenPayload.given_name || null;
                // Clean localStorage and rollover variables we care about
                let redirectPath = localStorage.getItem('redirectPath');
                let redirectSite = localStorage.getItem('redirectSite');
                let redirectData = localStorage.getItem('redirectData');
                let authSignup = localStorage.getItem('GoogleAuthSignup');
                let authReferrer = localStorage.getItem('signupReferrer');

                localStorage.clear();
                if (redirectPath) localStorage.setItem('redirectPath', redirectPath);
                if (redirectSite) localStorage.setItem('redirectSite', redirectSite);
                if (redirectData) localStorage.setItem('redirectData', redirectData);
                if (authSignup) localStorage.setItem('GoogleAuthSignup', authSignup);
                if (authReferrer) localStorage.setItem('signupReferrer', authReferrer);
              }
              localStorage.setItem('isAuthenticating', false);
              resolve();
            });
          } else {
            // Install the state from the authTokens when user logs in and check for redirects
            this.$store.commit('auth/add_tokens', {
              idToken: authResult.idToken,
            });
            resolve();
          }
        });
      },

      /**
     * @description Checks to see if the current URL is publically accessible or is behind authentication
     */
      isPublicURL() {
        const whitelist =  [
          '',
          '/',
          '/_ah/warmup',
          '/401',
          '/404',
          '/429',
          '/login',
          '/logout',
          '/signup',
          '/forgot',
          '/subscribe',
          '/verify',
          '/view',
          '/embed',
          '/preview',
          '/unsuccessful',
          '/demos/create',
          '/popup-handler',
          '/silent-callback',
        ];
        // For paths with slugs, check if contains whitelist url instead of complete match
        const matches = whitelist.filter(path => path === '/demos/create' || path === '/view' ? this.$route.path.includes(path) : this.$route.path === path);
        return matches.length > 0;
      },

      loadTenant() {
        this.$api('tenant/url')
        .then(response => {
          this.$store.commit('tenant/add', response.data);
        })
        .catch(error => {
          this.$message({
            duration: 10000,
            message: 'Cannot locate tenant',
            showClose: true,
            type: 'error',
          });
        });
      },

      /**
       * @description dispatch event to store to update all fullstory 
       * related items and associations in tracking
       */
      fullStoryInstantiated() {
        this.$store.dispatch('user/fullStoryInstantiated');
      },

      /**
      * @description Logs into HelpScout using email only if not on subscription page
      * @param { String } email - email to login with
      */
      initHelpScout() {
        try {
          if (window.location.pathname !== '/subscribe') {
            Beacon('init', VUE_APP_HELPSCOUT_BEACON_ID);
          };
        } catch(e) { console.error('Issue loading Beacon'); }
      },

      /**
       * @description Init `Piwik` 3rd-party tool
       */
      initPiwik() {
        (function() {
          var u="//tracking.zingchart.com/piwik/";
          _paq.push(['setTrackerUrl', u+'piwik.php']);
          _paq.push(['setSiteId', VUE_APP_PIWIK_ID]);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
        })();
      },

      /**
       * @description Init `Piwik` 3rd-party tool
       */
      initFullstory() {
        (function(m,n,e,t,l,o,g,y){
          if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
          g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
          o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
          y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
          g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
          g.anonymize=function(){g.identify(!!0)};
          g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
          g.log = function(a,b){g("log",[a,b])};
          g.consent=function(a){g("consent",!arguments.length||a)};
          g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
          g.clearUserCookie=function(){};
          g.setVars=function(n, p){g('setVars',[n,p]);};
          g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
          if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
          g._v="1.3.0";
        })(window,document,window['_fs_namespace'],'script','user');
      },

      /**
       * @description load the variable _trackjs for the defered trackjs script
       * to read once it loads
       */
      initTrackJS() {
        // if trackJS has not been loaded on the page already 
        // e.g) script from prerender page
        if (!window.trackJs) {
          // defer loading of trackJS script
          // Add execution script
          const SCRIPT_LIB = document.createElement('script');
          // set script defer property
          SCRIPT_LIB.defer = true;
          // put script in DOM
          SCRIPT_LIB.src = 'https://cdn.trackjs.com/releases/current/tracker.js';
          document.body.appendChild(SCRIPT_LIB);
        }
      },

      /**
       * @description Reloads page
       */
      reloadPage() {
        this.chunkWarningVisible = false
        window.location.reload();
      },
    },
  }
</script>

<style>
  #app-shell {
    height: 100%;
  }
</style>
