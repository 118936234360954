<template>
    <section class="form__collection" style="width:100%">
      <div class="form__col">
        <div class="form__row">
          <el-form class="form__item">
            <label class="form__label" for="newPass">New password</label>
            <el-input class="form__field" :type="isPassword" v-model="newPassword" placeholder="New password" for="new-password"></el-input>
          </el-form>
        </div>
        <div class="form__row" v-show="newPassword !== ''">
          <el-form class="form__item">
            <label class="form__label" for="confirmPass">Confirm new password</label>
            <el-input class="form__field" :type="isPassword" v-model="confirmPassword" placeholder="Confirm new password" for="new-password"></el-input>
          </el-form>
        </div>
        <div class="form__row">
          <el-checkbox v-model="showPassword">show password</el-checkbox>
        </div>
        <div class="form__row" v-if="resetPassword">
          <el-button :disabled="!validPassword" type="primary" @click="updatePassword">Reset Password</el-button>
        </div>
      </div>
      <div class="form__col" style="min-width: 175px;">
        <div v-if="success" class="status success">We've sent a verification email to the new e-mail address provided.</div>
        <div v-if="failure" class="status failure">An error occurred: {{message}}</div>
        <span v-if="showMismatch" style="color:#f44336;">Passwords do not match</span>
        <div v-show="newPassword !== ''">
          Password must:
          <ul class="password-validation">
            <li class="password" v-bind:class="validClass('atLeast8Chars')"><font-awesome-icon :icon="validIcon('atLeast8Chars')"/> Be a minimum of 8 characters</li>
            <li class="password" v-bind:class="validClass('atLeast1Lower')"><font-awesome-icon :icon="validIcon('atLeast1Lower')"/> Contain at least lower case letter</li>
            <li class="password" v-bind:class="validClass('atLeast1Upper')"><font-awesome-icon :icon="validIcon('atLeast1Upper')"/> Contain at least 1 upper case letter</li>
            <li class="password" v-bind:class="validClass('atLeast1Number')"><font-awesome-icon :icon="validIcon('atLeast1Number')"/> Contain at least 1 number</li>
            <li class="password" v-bind:class="validClass('notEmail')"><font-awesome-icon :icon="validIcon('notEmail')"/> Not match email</li>
          </ul>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  components: {

  },
  props: {
    'value': {},
    'resetPassword': {
      default: true,
    },
    'email': String,
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      success: false,
      failure: false,
      message: '',
      showPassword: false,
    }
  },
  computed: {
    isPassword() {
      return (this.showPassword) ? 'text' : 'password';
    },
    showMismatch() {
      if (this.newPassword === '' || this.confirmPassword === '') {
        return false;
      } else {
        return !this.match;
      }
    },
    match() {
      return this.newPassword === this.confirmPassword
    },
    atLeast8Chars() {
      return this.newPassword && this.newPassword.length >= 8;
    },
    atLeast1Upper() {
      return /[A-Z]/.test(this.newPassword);
    },
    atLeast1Lower() {
      return /[a-z]/.test(this.newPassword);
    },
    atLeast1Number() {
      return /[0-9]/.test(this.newPassword);
    },
    notEmail() {
      return this.newPassword && this.email && this.newPassword !== this.email && this.newPassword.toLowerCase() !== this.email.toLowerCase();
    },
    validPassword() {
      return this.match && this.atLeast8Chars && this.atLeast1Upper && this.atLeast1Lower && this.atLeast1Number && this.notEmail;
    },
  },
  watch: {
    validPassword(value) {
      if(value) {
        this.$emit('input', this.newPassword);
      } else {
        this.$emit('input', null);
      }
    }
  },
  methods: {
    updatePassword() {
      // Update password
      this.$emit('change', this.newPassword);
      // Add timestamp to invalidate sessions before password change
      this.$api('user/update', {session_expiration: new Date().getTime()});
      // Refresh token to updating auth init
      this.$store.dispatch('refresh_tokens');
    },
    validClass(target) {
      if (this.newPassword && this.newPassword.length === 0) {
        return '';
      } else {
        return (this[target]) ? 'password--valid' : 'password--invalid';
      }
    },
    validIcon(target) {
      if (this.newPassword && this.newPassword.length === 0) {
        return ['fas', 'check'];
      } else {
        return ['fas', (this[target])? 'check' : 'times'];
      }
    },
    update() {
      this.failure = this.success = false;
      if (this.match && this.newPassword !== '' && this.confirmPassword !== '') {
        this.$emit('update', this.newPassword);
      }
    },
  }
}
</script>
<style>
.password-validation {
  margin: 0;
  padding: 0;
}
.password-validation li {
  list-style-type: none;
}

.password {
  color: #a2a2a2;
}

.password--valid {
  position: relative;
  list-style-type: none;
  color: #4caf50;
}

.password--invalid {
  color: #f44336;
  list-style-type: none;
  position: relative;
}

.layout--row {
  display: flex;
}
</style>
