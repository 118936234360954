<template>
  <section class="content--wide" group-admin>
    <el-button @click="editVisible=true" class="header__btn" size="small" type="primary">Edit Group Info</el-button>
    
    <h2>Groups you admin for.</h2>

    <!-- General -->
    <p>Group Name: {{group_}}</p>
    <p>Address: </p>

    <!-- Member Permissions -->
    <zing-grid
      group-admin="members"
      layout=row
      page-size=10
      pager
      search>
      <zg-caption>
        Member Permissions
        <el-button @click="inviteVisible=true" group-admin="inviteBtn" size="small" type="primary">Add Member</el-button>
      </zg-caption>
      <zg-colgroup>
        <zg-column index="name" renderer="renderName"></zg-column>
        <zg-column 
          v-for="permission in permissions"
          :key="permission" 
          :index="`features.${permission}.enabled`"
          header-auto-format="formatHeaders"
          renderer="renderToggle"
          type="toggle"
          type-toggle-options='["false", "true"]'></zg-column>
      </zg-colgroup>
    </zing-grid>

    <!-- Edit form -->
    <el-dialog group-admin="editDialog" title="Edit Group Information" :visible.sync="editVisible">
      <el-form label-width="150px" :model="editForm" ref="editForm">

        <el-form-item 
          label="Group Name"
          prop="name"
          :rules="[
            { required: true, message: 'Please input group name', trigger: ['blur', 'change'] },
            { min: 3, max: 50, message: 'Length should be 3 to 50', trigger: ['blur', 'change'] },
          ]">
          <el-input v-model="group_"></el-input>
        </el-form-item>

        <el-form-item 
          label="Group Address"
          prop="address">
          <el-input v-model="editForm.address"></el-input>
        </el-form-item>

        <el-form-item button-align="right">
          <el-button @click="editVisible=false">Cancel</el-button>
          <el-button @click="submit" type="primary">Save</el-button>
        </el-form-item>

      </el-form>
    </el-dialog>

    <!-- Invite Dialog -->
    <el-dialog group-admin="inviteDialog" title="Send Member Invites" :visible.sync="inviteVisible">
      <el-form 
        label-width="150px" 
        :model="inviteForm" 
        ref="inviteForm">

        <el-form-item
          group-admin="inputRow"
          v-for="(member, index) in inviteForm.members"
          :label="'Member ' + parseInt(index+1)"
          :key="member.key"
          :prop="'members.' + index + '.email'"
          :rules="[
            { required: true, message: 'Please input email address', trigger: 'blur' },
            { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
          ]"
        >
          <el-input v-model="member.email"></el-input><el-button @click.prevent="removeMember(member)">Remove</el-button>
        </el-form-item>

        <el-form-item button-align="right">
          <el-button @click="addMember">Add Member</el-button>
          <el-button @click="validateAndSubmit('inviteForm')" type="primary">Invite</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </section>
</template>

<script>
import axios from 'axios';
export default {
  name: 'GroupAdmin',
  data: function() {
    return {
      group_: null,
      editVisible: false,
      editForm: {
        name: '',
        address: '',
      },
      grid: null,
      permissions: ['sample_group_admin', 'sample_group_billing', 'sample_group_demo_edit', 'sample_group_demo_view', 'grouping_create'],
      inviteForm: {
        members: [{
          key: 1,
          email: '',
        }]
      },
      inviteVisible: false,
    }
  },
  computed: {
    group() {
      return this.$store.state.user.group;
    },
  },
  watch: {
    // Handles updates from the store.
    group(value, old) {
      this.group_ = value;
      this.dirty = false;
    },
    group_() {
      // Handles an init call.
      if(this.group_ === '') {
        this.init = false;
      // Handles changes by the store mutating the event.
      } else if(!this.dirty)  {
        this.dirty = true;
      // If the field was modified by user entry.
      } else if(this.dirty) {
        // this.$emit('change', {company: this.group});
      }
    },
  },
  mounted() {
    // Save references
    this.grid = this.$el.querySelector('[group-admin="members"]');
    this.group_ = this.$store.state.user.group;

    this.registerMethods();
    this.initGrid();
  },
  methods: {
    /**
     * @description Adds input field for Create Group's member invite 
     */
    addMember() {
      this.inviteForm.members.push({
        key: Date.now(),
        value: '',
      });
    },
    /**
     * @description Initialize grid data
     */
    initGrid() {
      axios({
        url: '/api/grouping/list/users',
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.$store.state.auth.idToken}`,
        }
      })
      .then((response) => {
        this.grid.setData(response.data);
      }).catch((err) => {
        this.$message({
          message: `Error retreiving user permissions: ${err}`,
          type: 'error',
        });
      });
    },
    /**
     * @description Register renderer functions
     */
    registerMethods() {
      window.formatHeaders = (header) => {
        if (header.includes('grouping_create')) return 'Group Settings';
        let retVal = header;
        let groupEndIndex = header.indexOf('group_');
        retVal = header.slice(groupEndIndex+6);
        let underscoreIndex = retVal.indexOf('_');
        let enabledIndex = retVal.indexOf('.enabled');
        if (underscoreIndex !== -1) retVal = retVal[0].toUpperCase() + retVal.slice(1, underscoreIndex) + ' ' + retVal[underscoreIndex+1].toUpperCase() + retVal.slice(underscoreIndex+2, enabledIndex);
        else retVal = retVal[0].toUpperCase() + retVal.slice(1, enabledIndex);
        return retVal;
      }

      window.renderName = (name) => {
        if (name) return name;
        return 'N/A';
      }

      window.renderToggle = (curVal) => {
        const ON = '<div role="switch" class="el-switch is-checked" aria-checked="true"><input type="checkbox" name="" true-value="true" class="el-switch__input"><!----><span class="el-switch__core" style="width: 40px; border-color: rgb(19, 206, 102); background-color: rgb(19, 206, 102);"></span><!----></div>';
        const OFF = '<div role="switch" class="el-switch"><input type="checkbox" name="" true-value="true" class="el-switch__input"><!----><span class="el-switch__core" style="width: 40px; border-color: rgb(255, 73, 73); background-color: rgb(255, 73, 73);"></span><!----></div>';
        if (curVal === 'true') return ON;
        else return OFF;
      }
    },
    /**
     * @description Removes input field for Create Group's member invite
     * @param { String } item - input field to remove
     */
    removeMember(item) {
      var index = this.createForm.members.indexOf(item);
      if (index !== -1) {
        this.createForm.members.splice(index, 1);
      }
    },
    /**
     * @descrition Submits edits for group information
     */
    submit() {
      editForm = {
        name: '',
        address: '',
      };
    },
    /**
     * @description Validates form, then sends for submission if valid
     * @param { String } formName - ref value of form
     */
    validateAndSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // INVITE API CALL
        } else {
          return false;
        }
      });
    }
  },
}
</script>

<style>
  [group-admin] .header__btn { position: absolute; right: 0; top: -3.85rem; }
  [group-admin].content--wide { position: relative; }
  [group-admin="buttonContainer"] { display: flex; }
  [group-admin="inviteBtn"].el-button { float: right; margin-right: 0.75rem; }
  [group-admin="inputRow"] .el-form-item__content { display: flex; }
  [group-admin] .el-form .el-button { margin-left: 1rem; }
</style>