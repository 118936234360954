<template>
  <span
    :class="classname"
    :style="after ? 'margin-right: auto;' : ''">
    {{label ? ` ${label}` : label}}{{displayValue}}{{labelRight ? ` ${labelRight}` : labelRight}}
  </span>
</template>

<script>

export default {
  props: {
    count: Number,
    comparisonCur: Number,
    comparisonPrev: Number,
    label: String,
    labelRight: String,
    after: Boolean,
  },
  data() {
    return {
      classname: 'sidebar-item__count',
    };
  },
  computed: {
    displayValue() {
      if (typeof this.count !== 'undefined') {
        return this.addCommas(this.count);
      } else if (typeof this.comparisonCur !== 'undefined' && typeof this.comparisonPrev !== 'undefined') {
        // Calculate Percentage
        let percentage = 0;
        if (this.comparisonPrev === 0 && this.comparisonCur > 0) percentage = 1 
        else if (this.comparisonPrev > 0 && this.comparisonPrev > 0) percentage = (this.comparisonCur - this.comparisonPrev) / this.comparisonPrev;
        percentage = parseInt(percentage*100);
        let positve = percentage > 0 ? '+' : '';

        // Add class
        this.classname = `sidebar-item__count ${positve ? 'pos' : 'neg'}`;
        return `${positve}${percentage}%`;
      };
    },
  },
  methods: {
    addCommas(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
}

</script>

<style scoped>
  .sidebar-item__count {
    border-color: #F5F9FC;
    background-color: #F5F9FC;
    color: var(--color-primary-gray);
    font-size: 0.6875rem;
    font-weight: 500;
    line-height: 1.125rem;
    margin: 0 0.3125rem;
    padding: 3px 10px;
  }

  .sidebar-item__count.pos {
    border-color: #DAFFE0;
    background-color: #DAFFE0;
    color: #1E6F2B;
  }
  .sidebar-item__count.neg {
    border-color: #FFCDDA;
    background-color: #FFCDDA;
    color: var(--color-primary-danger);
  }
</style>

<style>
  .zingnetCard--header .sidebar-item__count {
    margin: 0 auto 0 2.25rem;
  }
</style>