<template>
  <default-layout :editorTheme="editorTheme">
    <el-alert
      class="editor__alert"
      v-show="showNotice"
      title="[Warning: Not Forking]"
      type="warning"
      center
      effect="dark"
      show-icon>
      <p class="el-alert__description">Admin, you are editing demo directly because you are coming from the ZingChart/ZingGrid site.
        <a :href="demoUrl">Click here to fork demo.</a></p>
    </el-alert>

    <!-- EDITOR CONTROL BAR -->
    <header class="editor__controls">
      <section class="editor__controls--left" v-show="!isMobile">
        <el-input v-model="demoTitle" placeholder="Untitled Demo" size="mini" maxlength="75"></el-input>
      </section>
      <!-- Run and settings -->
      <section class="editor__controls--right">
        <toggle-editorPane @update="updateControls">
        </toggle-editorPane>
        <!-- Editor Settings -->
        <editor-settings
          @update="updateEditorSettings"
          type="primary"
          size="mini"
          :uid="uid">
        </editor-settings>
        <!-- Save Dropdown-->
        <template v-if="authenticated">
          <el-dropdown split-button type="primary" @click="saveDemo(null)" @command="selectSaveOption" size="mini">
            <span class="editor__save__icon"></span>&nbsp;<span class="action-text">SAVE</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="personal">Personal</el-dropdown-item>
              <el-dropdown-item v-show="adminSaveOverride" command="updateOwner">Update Owner</el-dropdown-item>
              <el-dropdown-item disabled divided>Save to Group:</el-dropdown-item>
              <el-dropdown-item v-for="group in myGroups" :key="group.id" :command="group.id">{{group.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template v-else>
          <lock @beforeLogin="saveLocally" size="mini" type="primary"><font-awesome-icon :icon="['fas', 'save']" size="1x"/>&nbsp;<span class="action-text">Sign Up to Save</span></lock>
        </template>
        <!-- TOOLTIP -->
        <el-button-group>
          <el-tooltip class="item" effect="dark" content="cmd(ctrl) + p" placement="top-start" :open-delay="1000">
            <el-button v-if="!autoUpdate" @click="createPreview(null, null, true)" size="mini">
              <font-awesome-icon :icon="['fas', 'play']" size="1x"/>&nbsp;<span class="action-text">RUN</span>
            </el-button>
          </el-tooltip>
            <el-tooltip v-show="authenticated" class="item" effect="dark" :content="!uid || isForking ? 'Please save this demo before forking it' : 'Please wait until demo ready to be forked'" :disabled="uid && !isForking && forkReady ? true : false" placement="top-start" :open-delay="1000">
              <el-button @click="forkToSave()" :class="uid && !isForking && forkReady ? '' : 'is-disabled'" size="mini">
                <template v-if="!uid || isForking"><font-awesome-icon :icon="['fas', 'code-branch']" size="1x"/>&nbsp;<span class="action-text">FORK</span></template>
                <template v-else-if="!forkReady"><font-awesome-icon :icon="['fas', 'spinner']" spin size="1x"/>&nbsp;<span class="action-text">FORK</span></template>
                <template v-else><font-awesome-icon :icon="['fas', 'code-branch']" size="1x"/>&nbsp;<span class="action-text">FORK</span></template>
              </el-button>
            </el-tooltip>
          <el-button v-if="assets && assets.length !== 0" @click="openAssets" size="mini">
            <font-awesome-icon :icon="['fas', 'file-code']" size="1x"/>&nbsp;<span class="action-text">ASSETS</span>
          </el-button>
          <el-button v-show="authenticated" @click="settingsVisible = true"  size="mini">
            <font-awesome-icon :icon="['fas', 'cog']" size="1x"/>&nbsp;<span class="action-text">SETTINGS</span>
          </el-button>
        </el-button-group>
      </section>
    </header>


    <div class="flex content__wrap--sidebar">
      <!-- SIDEBAR -->
      <create-sidebar
        @resize="handleResize()"
        @show="toggleSidebar()"
        :uid="demo.uid"
        :show.sync='showSidebar'
      ></create-sidebar>


      <!-- CONTENT -->
      <div :class="contentClass">
        <section ref="editor" class="editor">
          <section :editorLayout="editorLayout">
            <section class="editor__top">
              <section class="editor__code-container">
                <div :style="htmlStyle" ref="html" class="editor__code editor__code--html">
                  <header class="editor__code-header">
                    <div>HTML</div>
                    <div v-if="!isMobile" class="editor__maximize-icon" @click="maximizeWindow('html')"><font-awesome-icon :icon="['fas', 'window-maximize']"/></div>
                  </header>
                  <section ref="codeHTMLWrap" class="editor__codewrap">
                    <studio-editor
                      ref="codeHTML"
                      @update="updateCode"
                      :autocomplete="autoComplete.indexOf('html') !== -1"
                      :code="htmlCode"
                      :font-family="editorFontFamily"
                      :font-size="editorFontSize"
                      language="html"
                      :theme="editorTheme">
                    </studio-editor>
                  </section>
                </div>
                <div @mousedown="setActiveResizer('html', $event)" class="editor__resizer editor__resizer--code" v-show="showHTML && (showCSS || showJS)">
                  <font-awesome-icon :icon="['fas', 'ellipsis-v']" size="1x"/>
                  <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="1x"/>
                </div>
                <div :style="cssStyle" ref="css" class="editor__code editor__code--css">
                  <header class="editor__code-header">
                    <div>CSS</div>
                    <div v-if="!isMobile" class="editor__maximize-icon" @click="maximizeWindow('css')"><font-awesome-icon :icon="['fas', 'window-maximize']"/></div>
                  </header>
                  <section ref="codeCSSWrap" class="editor__codewrap">
                    <studio-editor
                      ref="codeCSS"
                      @update="updateCode"
                      :autocomplete="autoComplete.indexOf('css') !== -1"
                      :code="cssCode"
                      :font-family="editorFontFamily"
                      :font-size="editorFontSize"
                      language="css"
                      :theme="editorTheme">
                    </studio-editor>
                  </section>
                </div>
                <div v-if="showCSS && showJS" @mousedown="setActiveResizer('css', $event)" class="editor__resizer editor__resizer--code">
                  <font-awesome-icon :icon="['fas', 'ellipsis-v']" size="1x"/>
                  <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="1x"/>
                </div>
                <div :style="jsStyle" ref="js" class="editor__code editor__code--js">
                  <header class="editor__code-header">
                    <div>JS</div>
                    <div v-if="!isMobile" class="editor__maximize-icon" @click="maximizeWindow('js')"><font-awesome-icon :icon="['fas', 'window-maximize']"/></div>
                  </header>
                  <section ref="codeJSWrap" class="editor__codewrap">
                    <studio-editor
                      ref="codeJS"
                      @update="updateCode"
                      :autocomplete="autoComplete.indexOf('js') !== -1"
                      :code="jsCode"
                      :font-family="editorFontFamily"
                      :font-size="editorFontSize"
                      language="javascript"
                      :theme="editorTheme">
                    </studio-editor>
                  </section>
                </div>
              </section>
            </section>
            <section @mousedown="setActiveResizer('preview', $event)" class="editor__resizer editor__resizer--preview">
              <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="1x"/>
              <font-awesome-icon :icon="['fas', 'ellipsis-v']" size="1x"/>
            </section>
            <!-- Use a mask to track mouse events on the current window and not on the preview window-->
            <div class="iframe-mask" v-if="currentlyResizing"></div>
            <section ref="previewWrap" v-loading="previewLoading" :style="previewStyle" class="editor__bottom">
              <div v-if="displayRunOnClick" @click="createPreview(null, null, true)" class="editor__bottom__runOnClick" :style="previewStyle">
                <div class="container">
                  <div class="clickToRun">
                    <font-awesome-icon :icon="['fas', 'play']" size="6x"/>
                    <p>Click to Run</p>
                  </div>
                </div>
              </div>
              <iframe v-else id="preview" ref="preview" class="preview" :style="previewStyle">
                <p>Your browser does not support iframes.</p>
              </iframe>
            </section>
          </section>
        </section>

        <!-- Settings -->
        <demo-settings
          @save="saveDemo"
          @toggle-visibility="settingsVisible = !settingsVisible"
          @update-demo="updateDemoData"
          :demo.sync="demo"
          :settings="['all']"
          :settingsVisible.sync="settingsVisible"
        ></demo-settings>

        <!-- Create a Demo -->
        <el-dialog
          title="Create a Demo"
          :visible.sync="createDemoVisible"
          class="template__dialog"
          >

          <section class="template__container">

            <el-tabs v-model="activeTemplateTab" @tab-click="handleClick">
              <el-tab-pane label="ZingChart Templates" name="zingchart">
                <section class="template__list">
                  <div class="template__item" v-for="(template, index) in zingchartTemplates" :key="index">
                    <p class="template__title">{{template.title}}</p>
                    <div class="template__image__wrapper">
                      <div 
                        class="template__image" 
                        :style="template.title != 'Blank Grid' ? getImageStyle(template.image) : null" 
                        v-html="template.title == 'Blank Grid' ? blankGrid : null">
                      </div>
                    </div>
                    <div class="template__description">{{template.description}}</div>
                    <el-button @click="setupTemplate(template)" class="template__button" size="mini" type="primary">Create</el-button>
                  </div>
                </section>
              </el-tab-pane>
              <el-tab-pane label="ZingGrid Templates" name="zinggrid">
                <section class="template__list">
                  <div class="template__item" v-for="(template, index) in zinggridTemplates" :key="index">
                    <p class="template__title">{{template.title}}</p>
                    <div class="template__image__wrapper">
                      <div 
                        class="template__image" 
                        :style="template.title != 'Blank Grid' ? getImageStyle(template.image) : null" 
                        v-html="template.title == 'Blank Grid' ? blankGrid : null">
                      </div>
                    </div>
                    <div class="template__description">{{template.description}}</div>
                    <el-button @click="setupTemplate(template)" class="template__button" size="mini" type="primary">Create</el-button>
                  </div>
                </section>
              </el-tab-pane>
              <el-tab-pane label="My Templates" name="myTemplates">
                <template v-if="myTemplates && myTemplates.length == 0">
                  <div style="margin: 1rem 2.3rem;">
                    <h3>You have not created any templates!</h3>
                    <p>You can convert a demo into a template in the demo's settings dialog.</p>
                  </div>
                </template>
                <template v-else>
                  <section class="template__list">
                    <div class="template__item" v-for="(template, index) in myTemplates" :key="index">
                      <p class="template__title">{{template.title}}</p>
                      <div class="template__image__wrapper">
                        <div class="template__image" :style="getImageStyle(template.image)"></div>
                      </div>
                      <div class="template__description">{{template.description}}</div>
                      <el-button @click="setupTemplate(template)" class="template__button" size="mini" type="primary">Create</el-button>
                    </div>
                  </section>
                </template>
              </el-tab-pane>
            </el-tabs>

          </section>

        </el-dialog>

        <!-- UPDATE ASSETS -->
        <el-dialog
          dialog="assets"
          short
          title="Assets"
          :visible.sync="assetsVisible">

          <el-form :model="assetsForm" label-width="120px">
            <!-- ZingGrid -->
            <el-form-item label="ZingGrid">
              <el-select v-model="assetsForm.zinggrid">
                <template v-for="asset in assets_zinggrid" >
                  <el-option v-if="asset.public != 0 || (asset.public == 0 && adminAssetsAccess)" :key="asset.name" :label="asset.name" :value="asset.id"></el-option>
                </template>
                <el-option label="None" value="None"></el-option>
              </el-select>
              <svg-icon class="el-input-icon" icon="angle"></svg-icon>
            </el-form-item>

            <!-- ZingChart -->
            <el-form-item label="ZingChart">
              <el-select @change="clearZingChartAsset" v-model="assetsForm.zingchart">
                <template v-for="asset in assets_zingchart" >
                  <el-option v-if="asset.public != 0 || (asset.public == 0 && adminAssetsAccess)" :key="asset.name" :label="asset.name" :value="asset.id"></el-option>
                </template>
                <el-option label="None" value="None"></el-option>
              </el-select>
              <svg-icon class="el-input-icon" icon="angle"></svg-icon>
            </el-form-item>

            <!-- ZingChart Version -->
            <el-form-item v-if="assetsForm.zingchart !== 'None' && assetsForm.zingchart !== '3'" label="Version">
              <el-select v-model="assetsForm.zingchartVersion">
                <template v-for="asset in assets_zingchart[assetsForm.zingchart].versions" >
                  <el-option v-if="asset.public != 0 || (asset.public == 0 && adminAssetsAccess)" :key="asset" :label="asset" :value="asset"></el-option>
                </template>
              </el-select>
              <svg-icon class="el-input-icon" icon="angle"></svg-icon>
            </el-form-item>

            <!-- ZingChrt Modules -->
            <el-form-item v-if="assetsForm.zingchart !== 'None' && assetsForm.zingchart !== '3'" label="Modules">
              <el-select v-model="assetsForm.zingchartModule">
                <template v-for="asset in assets_zingchart[assetsForm.zingchart].modules[assetsForm.zingchartVersion]" >
                  <el-option v-if="asset.public != 0 || (asset.public == 0 && adminAssetsAccess)" :key="asset" :label="asset" :value="asset"></el-option>
                </template>
                <el-option label="None" value="None"></el-option>
              </el-select>
              <svg-icon class="el-input-icon" icon="angle"></svg-icon>
            </el-form-item>

            <section class="settings__entry" style="display:flex; justify-content: flex-end; align-items: flex-end; flex:1;">
              <el-button @click="assetsVisible = false">Close</el-button>
              <el-button type="primary" @click="updateAsset">Update</el-button>
            </section>

          </el-form>
        </el-dialog>

        <!-- Signup to Access Premium Demo -->
        <el-dialog
          class="el-dialog__premium"
          short
          title="Signup to Access"
          :visible.sync="premiumVisible">

          <p>This is a premium demo!</p> 
          <p>Login or Signup to gain access to this demo!</p>
          <p><lock @beforeLogin="saveLocally" type="primary"><font-awesome-icon :icon="['fas', 'save']" size="1x"/>&nbsp;<span class="action-text">Login / Signup</span></lock></p>
        </el-dialog>

        <!-- Prompts Demo Fork -->
        <el-dialog
          short
          forkDemo
          title="Fork Demo to Save."
          :visible.sync="promptForkVisible">
          <p>This demo is not owned by you .</p>
          <p>Please fork demo to save your current changes.</p>
          <div class="dialog_container">
            <el-button @click="forkToSave($event)" type="primary">Fork Demo</el-button>
            <el-button v-show="adminSaveOverride" @click="saveDemo(null, null, true)" type="danger">Confirm Save</el-button>
          </div>
          <el-alert
            v-show="adminSaveOverride"
            type="warning"
            title="Note to admin!"
            :closable="false"
            show-icon>
            Saving demo does not update owner of demo.
          </el-alert>
        </el-dialog>

        <!-- Navigate without Saving Warning -->
        <my-dialog
          @close="closeDialog('saveWarningVisible')"
          title="Unsaved Changes"
          type="danger"
          :visible="saveWarningVisible">
            <div slot="content">
              <p>You will lose all unsaved progress if you leave this page.<br><b>Are you sure you want to proceed?</b></p>
            </div>
            <div slot="options">
              <el-button @click="leavePage" plain type="danger">Leave Page</el-button>
              <el-button @click="saveWarningVisible = false" type="primary">Stay on Page</el-button>
            </div>
        </my-dialog>

        <!-- Update Owner of Demo -->
        <el-dialog
          short
          title="Update Owner of Demo"
          :visible.sync="updateOwnerVisible">
          <el-form label-width="120px">
            <el-form-item label="Select User">
              <el-select
                v-model="newOwnerId"
                filterable
                placeholder="Enter user's email">
                <el-option
                  v-for="item in userOptions"
                  :key="item.id"
                  :label="userLabel(item)"
                  :value="item.id">
                </el-option>
              </el-select>
              <section style="display:flex; justify-content: flex-end; margin-top: 1.5rem; align-items: flex-end; flex:1;">
                <el-button @click="updateOwnerVisible = false">Close</el-button>
                <el-button @click="updateDemoOwner" type="primary" :disabled="!this.newOwnerId">Update</el-button>
              </section>
            </el-form-item>
          </el-form>
        </el-dialog>

      </div>
    </div>
  </default-layout>
</template>

<script>
  import axios from "axios";
  import CreateSidebar from './components/CreateSidebar.vue';
  import DefaultLayout from '../layouts/Default.vue';
  import defaultTemplate from './default.js';
  import DemoSettings from './DemoSettings.vue';
  import EditorSettings from '../../components/EditorSettings.vue';
  import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
  import Lock from "../../components/Lock.vue";
  import MixinEditorPanes from './../../mixins/editorPanes.js';
  import MixinGroup from './../../mixins/groups.js';
  import MixinPermissions from './../../mixins/permissions.js';
  import MyDialog from '../../components/MyDialog.vue';
  import routes from '../../routes/main.js';
  import StudioEditor from '../../components/StudioEditor.vue';
  import SvgIcon from '../../components/SvgIcon.vue';
  import ToggleEditorPane from '../../components/ToggleEditorPane.vue';

  export default {
    components: {
      CreateSidebar,
      DemoSettings,
      DefaultLayout,
      EditorSettings,
      FontAwesomeIcon,
      Lock,
      MyDialog,
      StudioEditor,
      SvgIcon,
      ToggleEditorPane,
    },
    mixins: [MixinEditorPanes, MixinGroup, MixinPermissions],
    data() {
      return {
        activeTemplateTab: (this.$route.query && this.$route.query.type && (this.$route.query.type.toLowerCase() === 'zingchart' || this.$route.query.type.toLowerCase() === 'zinggrid')) ? this.$route.query.type.toLowerCase() : this.$store.state.user['referrer'].toLowerCase(),
        assetsForm: {
          zinggrid: 'None',
          zingchart: 'None',
          zingchartVersion: 'Latest',
          zingchartModule: 'None',
        },
        assets: [],
        assets_zinggrid: {},
        assets_zingchart: {},
        blankGrid: '<svg style="margin:auto" width="161" height="52" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="#fff" d="M.5.5h160v51H.5z"/><path d="M1 1h159v10H1V1z" fill="#fff"/><path stroke="#fff" d="M0 41.5h161M0 31.5h161M0 20.5h161M0 10.5h161M81.5 0v51"/></svg>',
        currentlySaving: false, // disable save button for couple seconds after save
        saveButtonTimeout: null, // timeout for voiding rage clicking save button
        isForking: ('fork' in this.$route.query),
        forkReady: !('fork' in this.$route.query),
        isTemplate: false,
        editorSetting: 'bottom',
        editorFontFamily: 'monospace',
        editorFontSize: '13px',
        editorLayout: 'row-bottom',
        editorTheme: 'default',
        firstPreviewRun: false,
        previewLoading: false,
        zingchartTemplates: [],
        zinggridTemplates: [{
          created: '2018-07-26T23:41:55.000Z',
          css: 'body{background:#e6e6e6;}',
          description: 'Simple ZingGrid component example',
          html: ``,
          id_user: 'auth0|5b3ab422fbdcb762d016f44a',
          image: 'RJ68SIF1/_preview_1532623417768.png',
          is_template: 1,
          js: '',
          last_updated: '2018-07-26T23:43:37.000Z',
          public: 1,
          tags: [],
          title: 'Simple Grid',
          uid: 'RJ68SIF1', 
          desktop_height: 1000,
          desktop_grid: 'RJ68SIF1/_preview_1532623417768.txt',
          mobile_image: 'RJ68SIF1/_preview_1532623417768.png',
          mobile_height: 1200,
          mobile_grid:  'RJ68SIF1/_preview_1532623417768.txt',
        }],
        myTemplates: [],
        createDemoVisible: false,
        premiumVisible: false,
        demoTemplate: null,
        demoTitle: null,
        demoType: null,
        demoDescription: null,
        demoOwner: null,
        demoMetadata: null,
        newOwnerId: null,
        userOptions: [],
        files: [],
        createPath: '/demos/create/',

        // DEMO TYPE REGEX
        zcScriptRegex: /script\s+src.*(cdn\.zingchart\.com|\/api\/asset\/zingdata\/\d\.js).*\/script/i,
        zgScriptRegex: /script\s+src.*cdn\.zinggrid\.com.*\/script/i,

        // EDITOR SETTINGS
        autoUpdate: this.$store.state.user['settings_autoupdate'] && this.$store.state.user['settings_autoupdate'] === 'true' ? true : false,
        autoComplete: this.$store.state.user['settings_autocomplete'] ? this.$store.state.user['settings_autocomplete'].split(',') : [],
        docsTooltip: this.$store.state.user['settings_docs'] && this.$store.state.user['settings_docs'] === 'true' ? true : false,

        // TAGS
        tags: [],

        // UI Structures
        htmlCode: false,
        cssCode: false,
        jsCode: false,
        preview: null,
        promptForkVisible: false,
        uploadDialogVisible: false,
        shareDialogVisible: false,
        isPublic: false,
        templateListBottom: null,
        addScrollEvent: false,
        showSidebar: 'hidden',
        showNotice: false,

        // DB/USER/AUTH
        uploadHeaders: {
          Authorization: "Bearer " + this.$store.state.auth.idToken
        },
        existing: false,

        // COMPILE
        dirty: false,
        updateThreshold: 1000,
        iframeTarget: 'about:blank',
        uid: null,
        groupId: null,
        lastModified: new Date().getTime(),
        cache: null,
        cacheUid: null,

        // DIALOGS
        assetsVisible: false,
        forkVisible: false,
        saveWarningVisible: false,
        saveWarningNext: null,
        tagToAdd: '',
        selectedTemplate: null,
        prevSelectedDefaultTemplate: null,
        prevSelectedMyTemplate: null,
        settingsVisible: false,
        updateOwnerVisible: false,

        // Premium
        isPremium: false, // TODO used for testing premium demos
        premiumText: 'Signup to access!',
        originalHtml: null,
        originalCss: null,
        originalJs: null,

        // Run on click
        runOnClick: false,
        runOnClickQuery: this.$route.query.runonclick == 1,
        hideRunOnClick: false,
      };
    },
    asyncComputed: {
      adminAssetsAccess() {
        return this.checkPermission('admin_assets_access');
      },
      adminMetadataUpdate() {
        return this.checkPermission('admin_metadata_update');
      },
      adminSaveOverride() {
        return this.checkPermission('admin_save_override');
      },
      adminTagAccess() {
        return this.checkPermission('admin_tag_access');
      },
      adminTemplateCreate() {
        return this.checkPermission('admin_template_create');
      },
      premiumContentSet() {
        return this.checkPermission('premium_content_set');
      },
      premiumContentView() {
        return this.checkPermission('premium_content_view');
      },
  } ,
    computed: {
      cachedDemo() {
        return this.$store.state.demo.data;
      },
      demo() {
        return {
          autoComplete: this.autoComplete,
          autoUpdate: this.autoUpdate,
          demoTemplate: this.demoTemplate,
          description: this.demoDescription,
          docsTooltip: this.docsTooltip,
          files: this.files,
          isPremium: this.isPremium,
          isPublic: this.isPublic,
          isTemplate: this.isTemplate,
          runOnClick: this.runOnClick,
          tags: this.tags,
          title: this.demoTitle,
          uid: this.uid,
          id_user: this.demoOwner,
          type: this.demoType,
          metadata: this.demoMetadata,
        }
      },
      demoUrl() {
        return `${this.createPath}${this.uid}?fork`;
      },
      contentClass() {
        return `content${this.showSidebar === 'show' ? '--hasSidebar--wide' : ''} editor`;
      },
      isMobile() {
        return this.$store.getters['ui/isMobile'];
      },
      formattedTags() {
        return this.tags.map((tag) => tag.name);
      },
      // AUTH/USER
      authenticated() {
        return !!this.$store.state.auth.idToken;
      },
      userID() {
        // After getting user id, check if demo should be forked or edited
        return this.$store.state.user.user_id;
      },
      displayRunOnClick() {
        return !this.hideRunOnClick && (this.runOnClick || this.runOnClickQuery);
      },
    },
    watch: {
      jsCode() {
        this.dirty = true;
        this.lastModified = new Date().getTime();
      },
      htmlCode() {
        this.dirty = true;
        this.lastModified = new Date().getTime();
      },
      cssCode() {
        this.dirty = true;
        this.lastModified = new Date().getTime();
      },
    },
    // ROUTES
    beforeRouteLeave(to, from, next) {
      // Add attribute on enter
      document.body.removeAttribute('hide-footer');
      // Display save warning prompt before route change
      if (from.path.includes('/demos/create') && (this.dirty || (this.dirty && window.popStateDetected))) {
        this.saveWarningVisible = true;
        this.saveWarningNext = next;
        next(false);
      } else {
        window.popStateDetected = false;
        next();
      }
    },
    beforeRouteUpdate(to, from, next) {
      // Remove attribute on leave
      document.body.removeAttribute('hide-footer');
      // Display save warning prompt when params change
      if (to.path.includes(this.createPath) && from.path.includes(this.createPath) && this.dirty && !this.isForking) {
        this.saveWarningVisible = true;
        this.saveWarningNext = next;
        next(false);
      } else {
        next();
      }
    },
    // LIFECYCLE EVENTS
    // NOTE: beforeMount and mounted runs twice on page load!!! 
    //       In AppShell.vue, this component loads first in <demos-create-view> then again in <app>.
    //       When page loads, the user is not authenticated yet, so <demo-create-view> loads. After the user
    //       authenticates, the <app> loads with Create.vue. Therefore anything in beforeMount and mount may load
    //       twice, so use some flag to 
    beforeMount() {
      // Loads locally saved demo if applicable
      let forkID = this.$route.query.fork ? `-${this.$route.query.fork}` : '';
      this.cache = localStorage.getItem(`demo${forkID}`);
      // TODO: localStorage is async? find a way around this...
      let auth = this.authenticated;
      setTimeout(() => {
        if (this.cache) {
          const { html, js, css, metadata, uid } = JSON.parse(this.cache);
          this.htmlCode = html ? html : '';
          this.cssCode = css ? css : '';
          this.jsCode = js ? js : '';
          this.cacheUid = uid;
          this.demoMetadata = JSON.parse(metadata);
          this.createPreview();
          if (auth) {
            this.saveDemo();
            localStorage.removeItem(`demo${forkID}`);
          };
        }
      }, 1500);
    },
    mounted() {
      window.addEventListener('beforeunload', this.beforeUnload);
      window.addEventListener('keydown', this.hotKeys);

      if (this.$store.state.auth.idToken) this.setupAssets();

      // Hide footer
      document.body.setAttribute('hide-footer', '');

      // Trigger preview
      if (this.$route.params && this.$route.params.uid) {
        // Existing demo
        this.uid = this.$route.params.uid;
        this.loadDemo();
      } else if(this.cache) {
        // Demo from signup flow
        this.uid = null;
        this.existing = false;
        this.dirty = false;
      } else {
        // Brand new demos
        this.uid = null;
        this.existing = false;
        this.createDemoVisible = true;
        this.getTemplates('zingchart');
        this.getTemplates('zinggrid');
      }
      // Fetch files if authed
      if (this.$store.state.auth.idToken) {
        this.getFiles();
      }
      // Kick off auto-render fn
      this.trackChanges();

      // Adjust the preview size on first loadDemo
      this.previewSize.height = this.$refs && this.$refs.editor ? this.$refs.editor.getBoundingClientRect().height / 2 : 0;
      this.previewSize.width = this.$refs && this.$refs.editor ? this.$refs.editor.getBoundingClientRect().width * 0.6 : 0;
    },
    beforeDestroy() {
      window.clearTimeout(this.saveButtonTimeout);
    },
    destroyed() {
      window.removeEventListener('beforeunload', this.beforeUnload);
      window.removeEventListener('keydown', this.hotKeys);

      this.$store.dispatch('demo/consumeDemo');
    },
    methods: {
      getCustomThumbnail() {
        if (this.files) {
          for (let file in this.files) {
            if (file) {
              // Remove extension (if filename contains '.', get end half only)
              let filename = this.files[file].split('.').slice(0, -1).pop();
              if (filename && /demo(_|-)thumbnail$/.test(filename.toLowerCase())) {
                return this.files[file];
              }
            };
          }
        }
        return null;
      },
      updateCode(type, code) {
        // Map lanaguage to variable holding code
        this[`${type === 'javascript' ? 'js' : type}Code`] = code;

        // Load first preview after editor compiles code
        if (!this.firstPreviewRun && type === 'html' && this.jsCode === '') {
          this.firstPreviewRun = true;
          this.createPreview();
        } else if (!this.firstPreviewRun && type === 'javascript') {
          this.firstPreviewRun = true;
          this.createPreview();
        };
      },
      hotKeys(e) {
        if((navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
          switch(e.keyCode){
            case 80 : //P
              e.preventDefault();
              this.createPreview(null, null, true);
              break;
            case 83 : //S
              e.preventDefault();
              this.saveDemo();
              break;
          }
        }

        // Create Demo
        if (e.keyCode === 13 && this.createDemoVisible) this.setDemo();
      },
      beforeUnload(e) {
        // If we haven't been passed the event get the window.event
        if(this.dirty){
          e.preventDefault();
            e = e || window.event;
            var message = 'You didn\'t save to the demo!';
            // For IE6-8 and Firefox prior to version 4
            if (e){e.returnValue = message;}
            // For Chrome, Safari, IE8+ and Opera 12+
            return message;
        }
      },
      handleClose(tag) {
        //TODO: remove tag from server
        // this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      },
      handleClick(tab) {
        this.$store.state.user['referrer'] = tab.paneName;
      },
      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          if (this.$refs.saveTagInput) this.$refs.saveTagInput.$refs.input.focus();
          else document.querySelector('.el-select.input-new-tag input').focus();
        });
      },
      addTag(e) {
        let newTag = null;
        // Grab input of tag from select or input field
        if (this.adminTagAccess && typeof(e) === 'object') {
          if (e.target) newTag = e.target.value;
        } else {
          newTag = this.inputValue;
        }
        
        // Push tag
        if (newTag) {
          this.tags.push({
            id: null,
            name: newTag,
          });
        }
        this.inputVisible = false;
        this.inputValue = '';
      },
      removeTag(id, name, override) {
        if(id === null) {
          // Just sort via filters and remove the pending tag to save, otherwise ajax to remove from server
          this.tags = this.tags.filter((tag) => tag.name !== name);
        } else {
          this.$api('tag/delete', {
            slug: id,
            override
          })
          .then((result) => {
            this.fetchTags();
          })
          .catch((error) => {
            this.$message({
              duration: 10000,
              message: 'Could not delete tag',
              showClose: true,
              type: 'error',
            })
          });
        }
      },
      fetchTags() {
        this.$api('tag/read', {
          slug: this.uid,
        })
        .then((result) => {
          this.tags = result.data;
        })
        .catch((error) => {
          this.$message({
            duration: 10000,
            message: 'Could not retrieve tags',
            showClose: true,
            type: 'error',
          });
        });
      },
      download() {
        window.location = `${window.location.origin}/demos/download/${this.uid}`;
      },
      getImageStyle(url) {
        return {
          'background-image': `url("https://storage.googleapis.com/${VUE_APP_CLOUD_ASSETS_BUCKET}/${url}")`,
        }
      },
      setDemo() {
        this.$api('demo/retrieve', {
          slug: this.selectedTemplate.uid
        })
        .then((result) => {
          const {html, css, js} = this.selectedTemplate;
          this.jsCode = js ? js : '';
          this.htmlCode = html ? html : '';
          this.cssCode = css ? css : '';
          this.createDemoVisible = false;
          this.existing = false;
          this.$nextTick(() => {
            this.dirty = false;
          });
        })
        .catch((error) => {
          this.$message({
            message: 'Could not retrieve assets',
            type: 'error',
          });
        });
      },
      /**
       * @description Places template as first in list
       * @param { String } title - title of template to place first in list
       * @param { Array } list - array of templates
       */
      placeTemplateFirst(title, list) {
        if (list && list.length > 0) {
          // TODO: Setup database to support priority
          const index = list.findIndex(template => template.title === title);
          return ([list.splice(index, 1)[0], ...list]);
        }
      },
      /**
       * @description Get list of templates based on type specified
       * @param {String} type - type of template to grab
       */
      getTemplates(type) {
        const url = `/api/demo?start=0&limit=100&sort_by=title&sort_direction=ASC&filter=[{"by":"is_template","value":"1","type":"demo"}, {"by":"type","value":"${type}","type":"demo"}]`;
        axios({
          url: url + '&template_default',
          method: 'get',
          headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
          json: true,
        })
        .then((result) => {
          const temp = result.data.results;
          if (type === 'zinggrid') this.zinggridTemplates = this.placeTemplateFirst('A Simple Grid', temp);
          else this.zingchartTemplates = temp;
          this.selectedTemplate = temp.length > 0 ? temp[0] : null;
          setTimeout(() => { this.updateMoreTemplates() }, 0);
        })
        .catch((error) => {
          this.$message({
            duration: 10000,
            message: 'Could not load default templates',
            showClose: true,
            type: 'error',
          })
        });

        axios({
          url,
          method: 'get',
          headers: {
            'Authorization': `Bearer ${this.$store.state.auth.idToken}`,
          },
          json: true,
        })
        .then((result) => {
          this.myTemplates = result.data.results;
          this.myTemplates = this.placeTemplateFirst('A Simple Grid', this.myTemplates);
        })
        .catch((error) =>{
          this.$message({
            duration: 10000,
            message: 'Could not load your templates',
            showClose: true,
            type: 'error',
          })
        });
      },
      updateMoreTemplates() {
        setTimeout(() => {
          const templateItems = document.querySelectorAll(`#pane-${this.activeTemplateTab} .template__list .template__item`);
          const lastTemplateItem = templateItems[templateItems.length-1];
          const itemPos = lastTemplateItem ? lastTemplateItem.getBoundingClientRect() : null;
          const more = document.querySelector(`#pane-${this.activeTemplateTab} .template__item--more`);
          if (more) {
            if (itemPos && itemPos.bottom > this.templateListBottom) more.style.opacity = '1';
            else more.style.opacity = '0';
          }
        }, 0);
      },
      handleOptions(command) {
        switch(command) {
          case 'download':
            this.download();
          break;
          case 'save':
            this.saveDemo();
          break;
          case 'upload':
            if(this.uid) {
              this.uploadDialogVisible = true;
            } else {
              this.$message({
                duration: 10000,
                message: 'Cannot upload files without saving first',
                showClose: true,
                type: 'warning',
              })
            }
          break;
          case 'settings':
            this.settingsVisible = true;
          break;
          case 'share':
            this.shareDialogVisible = true;
          break;
        }
      },
      /**
       * @description Saves demo in localstorage to later load into studio 
       * @param {String} forkID - unique fork id associated to `demo-ID` in localstorage
       */
      saveLocally(forkID) {
        let path = this.$route.path.replace(this.$route.params.uid, '');
        if (forkID) path = `${path}?fork=${forkID}`;

        // Get metadata and only copy some
        let metadata = typeof this.demoMetadata === 'string' && this.demoMetadata.length > 0 ? JSON.parse(this.demoMetadata) : this.demoMetadata;
        metadata = this.metadataToCopy(metadata);

        localStorage.setItem('redirectPath', path);
        localStorage.setItem(
          forkID ? `demo-${forkID}` : 'demo',
          JSON.stringify({
            html: this.htmlCode,
            js: this.jsCode,
            css: this.cssCode,
            uid: this.uid,
            metadata: JSON.stringify(metadata || []),
          })
        );
      },
      getFiles() {
        if(this.uid) {
          this.$api(`file/list`, {
            slug: this.uid,
          })
          .then(response => {
            if (typeof response.data === 'object') {
              this.files = response.data.filter((filename) => !filename.includes('_preview'));
            }
          });
        }
      },
      setDemoData(data) {
        this.htmlCode = data.html || '';
        this.cssCode = data.css || '';
        this.jsCode = data.js || '';
        this.demoTemplate = data.template_type || '';
        this.demoTitle = data.title;
        this.demoDescription = data.description;
        this.demoOwner = data.id_user;
        this.tags = data.tags;
        this.groupId = data.id_grouping;
        this.isPremium = data.premium_template === 'true' ? true : false;
        this.labelDemo(this.groupId ? 'group' : 'personal');
        this.demoMetadata = data.metadata;
        this.runOnClick = Boolean(data.run_on_click);
        
        // Set demo data when not forking data
        let forkQuery = Object.keys(this.$route.query).includes('fork');
        if(!forkQuery) {
          this.isTemplate = (data.is_template === 1);
          this.isPublic = (data.public === 1);
          this.uid = data.uid;
          this.existing = true;
        }
        this.$nextTick(() => {
          this.dirty = false;

          this.adminEditSetup();
        });
      },
      /**
       * @description If referrer if from zingchart or zinggrid site and user is admin,
       * allow admin to directly edit demo instead of forking.
       */
      adminEditSetup() {
        let referrer = document.referrer.toLowerCase();
        let siteEdit = (referrer.includes('zingchart.com') || referrer.includes('zinggrid.com')) && this.userID === 2;
        let forkDemo = !this.existing;
        if (siteEdit && forkDemo) {
          // Disable forking
          this.existing = true;
          // Show notice to let admin know demo is not forking
          this.showNotice = true;
        }
      },
      loadDemo() {
        if (this.uid && (this.uid !== this.cacheUid)) {
          if (this.cachedDemo && this.uid === this.cachedDemo.uid) {
            this.setDemoData(this.cachedDemo);
            this.$store.dispatch('demo/consumeDemo');
          } else {
            this.$api(`demo/retrieve`, {
              slug: this.uid
            })
            .then(response => {
              this.setDemoData(response.data);
              this.$store.dispatch('demo/cacheDemo', response.data);
            })
            .catch(error => {
              switch(error && error.response && error.response.status) {
                case 401:
                  if (localStorage.getItem('startup_status')) location.reload();
                  else if (this.$route.path !== '/401') this.$router.push('/401');
                  break;
                default:
                  this.$router.replace('/404');
                  break;
              }
            });
          }
        }
      },
      metadataToCopy(metadata) {
        let toCopy = ['chartType', 'relatedDocs'];
        return metadata.filter((m) => {
          if (toCopy.indexOf(m.metadata) > -1) return m;
        });
      },
      trackChanges() {
        setInterval(() => {
          if (this.dirty && this.autoUpdate) {
            const now = new Date().getTime();
            // Check to see if enough time has passed to update since the last modification
            // if (now - this.updateThreshold > this.lastModified && this.$refs.preview) {
            if (now - this.updateThreshold > this.lastModified) {
              this.dirty = false;
              this.createPreview();
            }
          }
        }, 60);
      },
      /**
       * @description Updates preview of the code.
       * On first time load, an iframe is created and the src is set to a different domain
       * to prevent localstorage access.
       * After, if `destroy` is false, the contents of the iframe is cleared for writing
       * the updated demo.
       * Else is `destroy` is true, the iframe is removed and a new one is created because
       * unable to remove zingchart script when library assets updated.
       * @param {Event} e - native click event
       * @param {Boolean} destoy - to destroy and create new iframe instead of just
       * overwriting the contents
       * @param {Boolean} runOnClick - set true if preview is manually executed by user instead
       * of automatically from load or asset changes
       */
      createPreview(e, destroy, runOnClick) {
        let manuallyRunPreview = this.displayRunOnClick && runOnClick;
        // Determine to display "Run on Click" or demo preview
        if (!this.displayRunOnClick || manuallyRunPreview) {
          // Turn flag off to display preview
          this.hideRunOnClick = true;

          // Display demo preview 
          let html = this.htmlCode ? this.htmlCode : '';
          let parts;
          // Upate demo type
          this.updateDemoType();
          // For premium demos, check if user has access
          if (this.isPremium === 'true' || this.isPremium === true) this.setupPremiumPreview();

          // Inject CSS
          parts = html.split("</head>");
          // TODO: duplicate injection...?
          html = `${parts[0]}<style>${this.cssCode ? this.cssCode : ''}</style>`  // Add demo css
            + `</head>${parts[1]}`;

          // Inject JS (and ZC license)
          let jsCode = `${this.jsCode}\n
            ${this.demoType.includes('zingchart') ? `zingchart.MODULESDIR = "https://cdn.zingchart.com/modules/";ZC.LICENSE = ['569d52cefae586f634c54f86dc99e6a9', 'b55b025e438fa8a98e32482b5f768ff5'];` : ''}
            ${this.demoType.includes('zinggrid') ? 
              `ZingGrid.setLicense(['${VUE_APP_ZG_LICENSE}']);
              window.addEventListener('load', () => {
                window.addEventListener('message', () => {
                  (document.querySelectorAll('zing-grid')).forEach((grid) => {
                    if (typeof grid.updateSize === 'function') grid.updateSize();
                  });
                });
              });` 
              : ''}`;
          parts = html.split('</body>');
          html = `${parts[0]}<scr` + `ipt>${jsCode}</sc` + `ript>${parts[1]}`; // Add rest of demo (html/js)

          // Use existing iframe or create new if does not exist
          if (this.preview && !destroy) {
            // Refresh iframe
            this.preview.src = 'about:blank';
            this.preview.src = `${VUE_APP_PREVIEW_URL}/demos/preview`;
            this.previewLoading = true;

            // Send preview if ready
            setTimeout(() => {
              if (this.preview && this.preview.contentWindow) {
                this.preview.contentWindow.postMessage({
                  type: 'preview',
                  preview: html,
                }, this.preview.src);
                this.previewLoading = false;
              };
            }, 500);
          } else {
            // Remove iframe is exists
            if (this.preview) {
              this.preview.remove();
              this.preview = null;
            };
            // Clear placeholder preivew
            this.$refs.previewWrap.innerHTML = '';     

            // Create iframe
            this.preview = document.createElement('iframe');
            this.preview.id = 'preview';
            this.preview.src = `${VUE_APP_PREVIEW_URL}/demos/view/${this.uid || (this.selectedTemplate ? this.selectedTemplate.uid : null)}`;
            this.preview.setAttribute('class', 'preview');
            this.preview.frameBorder = '0';
            this.preview.allow = 'midi; clipboard-read; clipboard-write';
            this.preview.sandbox = 'allow-same-origin allow-downloads allow-forms allow-modals allow-pointer-lock allow-popups allow-presentation allow-scripts allow-top-navigation-by-user-activation';
            this.$refs.previewWrap.appendChild(this.preview);
          };
        };
      },
      setupPremiumPreview() {
        if (!this.premiumContentView) {
          this.originalHtml = this.htmlCode;
          this.originalCss = this.cssCode;
          this.originalJs = this.jsCode;
          this.htmlCode = `<!-- ${this.premiumText} -->`;
          this.cssCode = `/* ${this.premiumText} */`;
          this.jsCode = `// ${this.premiumText} `;
          this.premiumVisible = true;
        }
      },
      /**
       * @description Fork demo by opening "fork" url (?fork=forkID)
       * @param {Event} e - native click event
       * @param {String} [forkID] - unique fork id associated to `demo-ID` in localstorage
       */
      forkDemo(e, forkID) {
        // Checks if button is disabled (cannot user Element's 'disabled' attribute because cannot display tooltip properly)
        let target = null;
        let _forkID = forkID ? `=${forkID}` : '';
        if (e) {
          if (e.srcElement.tagName === 'BUTTON') target = e.srcElement;
          else target = e.srcElement.closest('.el-button'); 
          if (!target.classList.contains('is-disabled')) {
            window.open(`${this.createPath}${this.uid}?fork${_forkID}`, '_blank');
          }
          this.promptForkVisible = false;
          this.forkVisible = false;
        } else {
          // Fork demo programatically (does not have native event object)
          window.open(`${this.createPath}${this.uid}?fork${_forkID}`, '_blank')
        }
        // Remove pending on fork button
        this.forkReady = true;
        // Set flags to not fork when attempting to save after successfully forking
        this.existing = true;
        this.dirty = true;
      },
      forkId() {
        return crypto.randomUUID().replace(/\-/g, '').slice(0,8).toString('hex');
      },
      forkToSave(e) {
        if (this.forkReady) {
          // Set flags to fork only when not attempting to override save
          this.existing = false;
          this.dirty = false;
          // Create id for fork
          let forkId = this.forkId();
          // Save locally to fork
          this.saveLocally(forkId);
          this.forkDemo(e, forkId);
        };
      }, 
      /**
       * @description Saves the demo
       * @param {String} type - determines if demo is saved as personal or specified group. If none provided, saved as last specified
       * @param {Boolean} displayMessage - determines which success message to display on save
       * @param {Boolean} override - admin-only; override save limitation by allowing user to save demo not longing to the user
       * @param {String} newOwner - admin-only; override save limitation by allowing user to save demo to another user by providing user's id
       * @param {Function} cb - callback after demo saved
       */
      saveDemo(type, displayMessage = true, override, newOwner, cb) {
        // When saving a group demo as personal when not the demo owner, prompt user to fork demo instead
        if (this.groupId && type === 'personal' && this.demoOwner !== this.userID) {
          this.forkVisible = true;
          return;
        }
        // Sets label next to save button
        if (type) this.labelDemo(type);
        this.currentlySaving = true; // disable action
        this.isForking = false;
        this.forkReady = false;
        // Get demo type
        this.updateDemoType();
        // Determine type of grid
        const data = {
          html: this.originalHtml ? this.originalHtml : this.htmlCode ? this.htmlCode : '',
          js: this.originalJs ? this.originalJs : this.jsCode ? this.jsCode : '',
          css: this.originalCss ? this.originalCss : this.cssCode ? this.cssCode : '',
          id_user: this.userID,
          title: this.demoTitle,
          description: this.demoDescription,
          metadata: typeof this.demoMetadata === 'Object' ? JSON.stringify(this.demoMetadata) : this.demoMetadata,
          public: this.isPublic,
          is_template: this.isTemplate,
          template_type: this.demoTemplate,
          premium_template: this.isPremium,
          type: this.demoType,
          thumbnail_image_custom: this.getCustomThumbnail(),
          run_on_click: + this.runOnClick
        };
        this.demoOwner = this.userID;
        // Switch view back to original if premium turned off
        if (this.isPremium === false && this.originalHtml !== this.htmlCode) {
          this.htmlCode = this.originalHtml ? this.originalHtml : this.htmlCode ? this.htmlCode : '';
          this.cssCode = this.originalCss ? this.originalCss : this.cssCode ? this.cssCode : '';
          this.jsCode = this.originalJs ? this.originalJs : this.jsCode ? this.jsCode : '';
        } 
        if (this.adminTemplateCreate) data['default_template'] = this.isPublic ? 'true' : 'false';
        // Expect type to be group id (not event object triggered by saving from settings)
        if (type && typeof(type) !== 'object') data['id_grouping'] = type === 'personal' ? null : type;
        // Admin-only: Override save limitation
        if (override && this.adminSaveOverride) {
          data.override = true;
        }
        if (newOwner && this.adminSaveOverride) {
          data.changeUser = true;
          data.id_user = newOwner;
          this.demoOwner = newOwner;
        }
        if (this.existing) {
          // Update demo
          this.$api('demo/update', {
            slug: this.uid,
            data,
          })
          .then(response => {
            this.existing = true;
            let messageStatus = response.data.warningCount > 0 ? 'warning' : response.data.result ;
            let messageText = messageStatus === 'success' ? 'Demo updated!' : response.data.warning;
            this.$message({
              message: messageText,
              showClose: true,
              type: messageStatus,
            });

            this.saveTags(override && this.adminSaveOverride);
            this.settingsVisible = this.dirty = false;
            this.forkReady = true;
            if (this.promptForkVisible) this.promptForkVisible = false;

            // cb
            if (cb && typeof cb === 'function') cb(this);
          })
          .catch(error => {
            console.log(error)
            // 401 error prompts user to login to save demo
            if (error && error.response && error.response.status === 401) {
              this.$message({
                duration: 10000,
                showClose: true,
                message: 'Please log in or sign up to save demo.',
                type: 'error',
              });
              this.triggerLogin();
            } else if (error && error.response && error.response.status === 403) {
              // Prompt user to fork demo because demo does not belong to user and user cannot override save restrictions
              this.promptForkVisible = true;
            } else {
              // Unable to update
              this.$message({
                duration: 10000,
                message: 'Could not update the page',
                showClose: true,
                type: 'error',
              });
              // Fork to save demo if user does not own demo
              this.forkVisible = true;
            }
          });
        } else {
          // Fork over metadata
          this.$api('metadata/read', {
            uid: this.cachedDemo.uid,
            format: 'grid',
          }).then((result) => {
            // Update only if there was a cached demo
            if (this.cachedDemo && this.cachedDemo.uid) {
              data.metadata = this.metadataToCopy(result.data);
            };

            // Fork demo
            this.$api('demo/add', {
              data
            })
            .then((response) => {
              this.existing = true;
              if (displayMessage) {
                let messageStatus = response.data.warningCount > 0 ? 'warning' : response.data.result ;
                let messageText = messageStatus === 'success' ? 'Demo created!' : response.data.warning;
                this.$message({
                  message: messageText,
                  showClose: true,
                  type: messageStatus,
                });
              }
              // Reload the page with the new uid
              this.isForking = true;
              let path = `${this.createPath}${response.data.uid}`;
              if (this.$route.path !== path) this.$router.push({path});
              this.uid = response.data.uid;
              this.dirty = false;
              this.saveTags();
              this.settingsVisible = false;
              this.saveWarningVisible = false;
              this.isForking = false;
              this.forkReady = true;

              // Cb
              if (cb && typeof cb === 'function') cb(this);
            })
            .catch((error) => {
              this.triggerLogin();
            });
          });
        }
        // set a visual timeout to enable the save button because the server takes 10+ seconds to run the 
        // necessary code to save all assets through cloud function rendering
        const _this = this;
        window.clearTimeout(this.saveButtonTimeout);
        this.saveButtonTimeout = setTimeout(() => {
          _this.currentlySaving = false;
        }, 10000);
        this.createPreview();
      },
      // On save 401 error, prompt user to login or signup
      triggerLogin() {
        // Trigger lock and save locally
        let lockButton = document.querySelector('[lockbutton]');
        this.saveLocally();
        if (lockButton) lockButton.click();
      },
      saveTags(override) {
        //S ort through all of the tags. If any are null, then we need to save them.
        //TODO: Batch save tags
        let tagsToSave = this.tags.filter((tag) => tag.id === null);
        let tagsToRemove = [];
        // Attempt to save tags
        tagsToSave.forEach((tag, index) => {
          // Special case for "zc-gallery" tag. Requires `chartType` and `vanityUrl` metadata to be added.
          if (tag.name === 'zc-gallery') {
            let chartType = this.demoMetadata.find(m => m.metadata === 'chartType');
            let vanityUrl = this.demoMetadata.find(m => m.metadata === 'vanityUrl');
            if (!chartType || !vanityUrl) {
              // Remove tag later
              tagsToRemove.push(index);
              // Alert user of reason tag not added
              this.$message({
                duration: 0,
                message: 'Could not create "zc-gallery" tag. Must set "Chart Type" and "Vanity Url" metadatas.',
                showClose: true,
                type: 'error',
              })
              return;
            };
          };
          // Add tag
          this.$api('tag/add', {
            uid: this.uid,
            name: tag.name,
            override
          })
          .then((tagId) => {
            // Remove tag when successfully added
            this.tags.forEach((t) => {
              if (t.id === null && t.name === tag.name) {
                t.id = tagId.data;
              }
            })
          })
          .catch(() => {
            this.$message({
              duration: 10000,
              message: 'Could not create tag',
              showClose: true,
              type: 'error',
            })
          });
        });
        // Remove tags on client-side
        if (tagsToRemove.length > 0) {
          for (let i = tagsToRemove.length-1; i > -1; i--) {
            this.tags.splice(tagsToRemove[i], 1);
          };
        };
      },
      // UPLOAD DIALOG
      handleUploadClose(done) {
        done();
      },
      /**
       * @description Opens assets and sets correct dropdown value
       */
      openAssets() {
        this.updateDemoType();
        this.assetsVisible = true;
      },
      /** 
       * @description Setup assets by filtering into correct dropdown lists and
       * placing currently used as value in dropdown
       */
      setupAssets() {
        axios({
          url: '/api/asset',
          method: 'get',
          headers: {
            'Authorization': `Bearer ${this.$store.state.auth.idToken}`,
          },
          json: true,
        })
        .then((result) => {
          const resultAssets = result.data.main;
          resultAssets.forEach(asset => {
            this.assets.push(asset);
            if (asset.name.includes('ZingGrid')) this.assets_zinggrid[asset.id] = asset;
            else this.assets_zingchart[asset.id] = asset;
          });

          let addZingChartAssets = (n) => {
            let index = Object.keys(this.assets_zingchart)
              .filter((val) => {
                return this.assets_zingchart[val].name === `ZingChart${n ? ` ${n}` : ''}`;
            });
            let ref = this.assets_zingchart[index[0]];
            ref.modules = result.data[`zingchart${n}`].versions;
            ref.versions = Object.keys(result.data[`zingchart${n}`].versions);
          };
          addZingChartAssets(2);
          addZingChartAssets(3);
        });
      },
      /**
       * @description Update assets dropdown and demo type
       */
      updateDemoType() {
        let typeZinggrid = false;
        let typeZingchart = false;
        // Update assets dropdown values to select
        this.assets.forEach(asset => {
          if (this.htmlCode.includes(asset.path)) {
            if (asset.name.includes('ZingGrid')) {
              this.assetsForm.zinggrid = asset.id;
              typeZinggrid = true;
            }
            else if (asset.name.includes('ZingChart')) {
              this.assetsForm.zingchart = asset.id;
              typeZingchart = true;
            }
          }
        });
        // Upate asset dropdown to deselect
        if (!typeZinggrid) this.assetsForm.zinggrid = 'None';
        if (!typeZingchart) this.assetsForm.zingchart = 'None';

        // Check if variant of library scripted used (not listed in assets)
        if (this.zcScriptRegex.test(this.htmlCode)) typeZingchart = true;
        if (this.zgScriptRegex.test(this.htmlCode)) typeZinggrid = true;

        // Update demo type
        if (typeZingchart && !typeZinggrid) this.demoType = 'zingchart';
        else if (!typeZingchart && typeZinggrid) this.demoType = 'zinggrid';
        else if (!typeZingchart && !typeZinggrid) this.demoType = '';
        else this.demoType = 'zingchart,zinggrid';
      },
      /**
       * @description Updates demo with selected assets
       */
      updateAsset() {
        if (this.assetsForm.zinggrid !== '' && this.assetsForm.zinggrid !== 'ZingGrid' && this.assetsForm.zinggrid !== 'None') {
          this.fetchAsset('zinggrid', this.assetsForm.zinggrid);
        } else if (this.assetsForm.zinggrid === 'None') {
          this.removeAsset('zinggrid');
        }
        if (this.assetsForm.zingchart !== '' && this.assetsForm.zingchart !== 'None') {
          this.fetchAsset('zingchart', this.assetsForm.zingchart);
        } else if (this.assetsForm.zingchart === 'None') {
          this.removeAsset('zingchart');
        }

        this.assetsVisible = false;
      },
      /**
       * @description Fetches asset path
       * @param {Strint} assetType - type of asset (ex. zinggrid, zingchart, etc)
       * @param {String} assetId - id of asset, used to fetch asset path
       */
      fetchAsset(assetType, assetId) {
        let regexLib = null;
        // Get existing asset type to replace
        if (assetType === 'zinggrid') {
          regexLib = /\<script src=\"http.*\:\/\/.*.zinggrid.com\/.*zinggrid.*.min.js\"( |defer|crossorigin=\"anonymous\")*\>\<\/script\>/gi;
        } else {
          regexLib = /\<script src=\"http(s)*\:\/\/(cdn.zingchart.com\/zingchart|(app(-stage)*.zingsoft.com|localhost\:8080)\/api\/asset\/zingdata)(\/modules\/.*)*.*(.min)*.js(\?version\=.*)*\"( |defer|crossorigin=\"anonymous\")*\>\<\/script\>/gsi;
        }
        // Replace current demo asset
        let insertLib = null;
        // Include version (if specified)
        let assetRef = this[`assets_${assetType}`][assetId];
        let version = assetType === 'zingchart' && this.assetsForm.zingchartVersion !== 'Latest'
          ? `?version=${this.assetsForm.zingchartVersion}`: '';
        insertLib = `<script src="${assetRef.path}${version}">\<\/script>`;
        // Include module (if specified)
        if (assetType === 'zingchart' && this.assetsForm.zingchartModule !== 'None') {
          let type = assetRef.name.replace('ZingChart ', '');
          insertLib += `\n	  <script src="${window.location.origin}/api/asset/zingdata/${type}/modules/${this.assetsForm.zingchartModule}${version}">\<\/script>`;
        };
        if (regexLib.test(this.htmlCode)) {
          this.htmlCode = this.htmlCode.replace(regexLib, insertLib);
        } else {
          // Insert asset if none to replace
          let headEndTagIndex = this.htmlCode.indexOf('</head>');
          this.htmlCode = this.htmlCode.slice(0, headEndTagIndex) + `  ${insertLib}\n  ` + this.htmlCode.slice(headEndTagIndex);
        }

        this.$refs.codeHTML.updateEditorCode(this.htmlCode);
        this.$nextTick(_ => {this.createPreview(null, true)});
      },
      /**
       * @description Sets version and module to default values when ZingChart asset updated
       */
      clearZingChartAsset() {
        this.assetsForm.zingchartVersion = 'Latest';
        this.assetsForm.zingchartModule = 'None';
      },
      /**
       * @description Removes asset from demo
       * @param {Strint} assetType - type of asset (ex. zinggrid, zingchart, etc)
       */
      removeAsset(assetType) {
        let regexLib = null;
        if (assetType === 'zinggrid') regexLib = /[\s\n]*\<script src=\"http.*\:\/\/.*.zinggrid.com\/.*zinggrid.*.min.js\" defer\>\<\/script\>[\n]*/gi;
        else regexLib = /[\s\n]*\<script src=\"http(s)*\:\/\/(cdn.zingchart.com\/zingchart|(app(-stage)*.zingsoft.com|localhost\:8080)\/api\/asset\/zingdata)(\/modules\/.*)*.*(.min)*.js(\?version\=.*)*\"( |defer|crossorigin=\"anonymous\")*\>\<\/script\>[\n]*/gsi;

        if (regexLib.test(this.htmlCode)) {
          this.htmlCode = this.htmlCode.replace(regexLib, '\n');
          this.$refs.codeHTML.updateEditorCode(this.htmlCode);
          this.$nextTick(_ => {this.createPreview()});
        };
      },
      /**
       * @description Displays icon on save dropdown to indicate if demo is saved as personal or a group demo
       * @param { String } type - if 'personal', demo is saved as personal demo. Else, all other options indicate demo belongs to group
       */
      labelDemo(type) {
        let $saveIconWrapper = this.$el.querySelector('.editor__save__icon');
        let ICON_PERSONAL = '<svg aria-hidden="true" data-prefix="fas" data-icon="save" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-save fa-w-14 fa-1x"><path fill="currentColor" d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z" class=""></path></svg>';
        let ICON_GROUP = '<svg aria-hidden="true" data-prefix="fas" data-icon="users" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-users fa-w-20 fa-1x"><path fill="currentColor" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z" class=""></path></svg>';

        if ($saveIconWrapper) $saveIconWrapper.innerHTML = type === 'personal' ? ICON_PERSONAL : ICON_GROUP;
      },
      /**
       * @description Closes el-dialog
       * @param { string } visible - name of variable responsible for determining if el-dialog is open / closed (assigned to :model)
       */
      closeDialog(visible) {
        this[visible] = false;
      },
      /**
       * @description Navigate to route saved in 'saveWarningNext'. Must set 'saveWarningVisible' false to close
       * dialog when navigating to url with same path and reload demo.
       */ 
      leavePage() {
        this.saveWarningVisible = false;
        this.saveWarningNext();
        if (this.$route.params && this.$route.params.uid) {
          // Reset some variables before loading new demo
          this.dirty = false;
          this.uid = this.$route.params.uid;
          this.isForking = ('fork' in this.$route.query);
          this.forkReady = !('fork' in this.$route.query);
          this.loadDemo();
        }
      },
      /**
       * @description Update selectedTemplate template and setup demo
       * @param { Object } template - selected template
       */
      setupTemplate(template) {
        this.selectedTemplate = template;
        this.setDemo();
      },

      // Update Demo Owner
      /**
       * @description When selecting option to save demo to another user, fetch
       * list of users to display.
       */
      fetchUserOptions() {
        axios({
          url: `/api/user/list`,
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.$store.state.auth.idToken}`,
          },
        })
        .then((result) => {
          this.userOptions = result.data.result;
        });
      },
      /**
       * @description Triggered when save option selected. When selecting:
       * - 'updateOwner': display prompt to choose who to save demo to
       * - other: save demo under current user or selected group
       */
      selectSaveOption(option) {
        if (option === 'updateOwner') {
          this.updateOwnerVisible = true;
          
          if (this.userOptions.length === 0) this.fetchUserOptions();
        } else {
          this.saveDemo(option);
        }
      },
      updateDemoData(prop, val) {
        switch (prop) {
          case 'autoComplete': this.autoComplete = val; break;
          case 'autoUpdate': this.autoUpdate = val; break;
          case 'demoTemplate': this.demoTemplate = val; break;
          case 'description': this.demoDescription = val; break;
          case 'docsTooltip': this.docsTooltip = val; break;
          case 'files': this.files = val; break;
          case 'isPremium': this.isPremium = val; break;
          case 'isPublic': this.isPublic = val; break;
          case 'isTemplate': this.isTemplate = val; break;
          case 'metadata': this.demoMetadata = val; break;
          case 'runOnClick': this.runOnClick = val; break;
          case 'tags': this.tags = val; break;
          case 'title': this.demoTitle = val; break;
        }
      },
      /**
       * @description After selecting an owner, update demo owner specified by `this.newOwnerId`
       */
      updateDemoOwner() {
        if (this.adminSaveOverride) {
          this.updateOwnerVisible = false;
          this.saveDemo(null, null, true, this.newOwnerId);
          this.newOwnerId = null;
        }
      },
      /**
       * @description Creates label for user based on if the name and email
       * is available
       */
      userLabel(userInfo) {
        let label = `#${userInfo.id}`;
        if (userInfo.name && userInfo.email) label += `-  ${userInfo.name} (${userInfo.email})`;
        else if (userInfo.name || userInfo.email) label += ` - ${userInfo.name || userInfo.email}`;
        return label;
      },
      /**
       * @description On LayoutsDropdown selection, update `this.layout` to set active dropdown options then update editor layout
       * @param {String} option - option selected
       * @param {String} type - editor settings type being updated (ex. fontFamily, fontSize, layout, theme, )
       */
      updateEditorSettings(option, type) {
        // Update `editorSetting`
        this.editorSetting = option;
        switch(type) {
          case 'fontFamily':
            // Update editor fontFamily
            this.editorFontFamily = option;
            break;
          case 'fontSize':
            // Update editor fontSize
            this.editorFontSize = option;
            break;
          case 'layout': 
            // Update editor layout 
            let rowLayout = ['left', 'right'];
            let generalLayout = rowLayout.indexOf(this.editorSetting) > -1 ? 'col' : 'row';
            this.editorLayout = `${generalLayout}-${this.editorSetting}`;
            // Trigger resize on preview
            this.resizePreview();
            break;
          case 'theme':
            // Update editor theme
            this.editorTheme = this.editorSetting;
            break;
        }
      },
      toggleSidebar() {
        this.showSidebar = 'show';
      },
    },
  }
</script>
<style>
  :root {
    --code__header-height: 25px;
  }

  .action-text { text-transform: uppercase; }

  /* ASSETS */
  [dialog="assets"] .el-select {
    width: 100%;
  }
  [dialog="assets"] .el-input-icon {
    top: 0;
  }

  .content {
    width: 100%;
  }

  .dialog_container {
    display: flex;
    margin: 1.5rem 0;
  }
  
  .editor {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .editor__top {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    min-height: 300px;
    min-width: 0;
    overflow: hidden;
  }

  .editor__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    padding: 0.5rem 0;
    font-size: 1rem;
  }

  .editor__controls--left {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .editor__controls--left .el-input {
    font-size: 1rem;
    padding-right: 2rem;
  }

  .editor__controls--right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .editor__controls-toggle {
    display: flex;
    background: var(--color-tertiary-1);
    color: #FFF;
    border-radius: 10px;
    padding: 0.25rem 1rem;
    width: 90px;
    margin-right: 1rem;
  }

  .editor__controls ~ .flex {
    height: calc(100% - 40px);
  }

  .editor__code-container {
    display: flex;
    height: 100%;
    z-index: 200;
  }

  .editor__code {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  .editor__code-header {
    display: flex;
    justify-content: space-between;
    background: var(--color-primary-4);
    font-size: 0.8rem;
    color: #FFF;
    padding: 0.2rem 0.5rem;
    user-select: none;
    min-height: var(--code__header-height);
    max-height: var(--code__header-height);
  }

  .editor__maximize-icon {
    cursor: pointer;
  }

  .editor__bottom {
    background: #e6e6e6;
    display: flex;
    flex-direction: column;
  }
  .editor__bottom-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: var(--color-primary-4);
    width: 100%;
    padding: 1rem;

  }

  .editor__bottom__runOnClick .container {
    align-items: center;
    background:#e6e6e6;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .editor__bottom__runOnClick .clickToRun {
    align-items: center;
    display: flex;
    flex-direction: column;
   }

  .editor__bottom__runOnClick .clickToRun:hover {
    cursor: pointer;
  }

  .editor__bottom__runOnClick .clickToRun p {
    color: #063747;
    font-size: 1.5rem;
  }

  .editor__bottom__runOnClick .clickToRun:hover p {
    color: #606266;
  }

  .editor__bottom__runOnClick .clickToRun svg path {
    fill: #063747;
  }

  .editor__bottom__runOnClick .clickToRun:hover svg path {
    fill: #606266;
  }

  .editor__codewrap {
    height: calc(100% - 25px);
    font-size: 1rem;
  }

  .el-message--error {
    z-index: 10000000 !important;
  }

  [forkDemo] .el-button { 
    float: inherit;
    width: 100%;
  }

  [forkDemo] .el-dialog__body {
    padding: 0px 45px 30px;
  }

  /* Create Demo Dialog Overwrites */
  .template__dialog .el-dialog {
    padding-right: 0 !important;
  }

  .template__dialog .el-dialog__header {
    padding: 2rem 2.3rem 0 !important;
  }

  .template__dialog .el-tabs__header {
    margin: 0;
    padding: 0 0 0 2.3rem;
  }

  .template__dialog .el-tabs__active-bar {
    height: 4px;
  }

  .template__dialog .el-tabs__content {
    background: #F4F4F4;
  }

  .template__dialog .el-tabs__item {
    font-weight: 400;
    height: 38px;
  }

  .template__dialog .el-tabs__nav-wrap::after {
    background-color: transparent;
  }

  .template__dialog .el-tabs--top {
    font-size: 0.875rem;
  }

  .el-dialog__premium .el-dialog__body {
    padding: 0 45px 30px !important;
  }

  .el-dialog__premium .el-button {
    float: none !important;
    margin-top: 0.75rem;
  }

  .preview{
    height: 100%;
    flex: 1;
    width: 100%;
    border: 0;
  }

  [short] .el-dialog{
    width: 30rem !important;
  }

  .upload-dialog .el-dialog{
    width: 70% !important;
  }

  .upload-dialog__files {
    margin: 0;
    padding: 0;
    max-height: 300px;
    overflow-y: scroll;
  }
  .upload-dialog .el-dialog__header {
    padding: 1rem;
    border-bottom: 1px solid #EFEFEF;
  }

  .upload-dialog .el-dialog__title {
    font-size: 1.2rem;
    color: #303133;
    font-weight: 600;
  }

  .upload-dialog__heading {
    padding: 1rem 0;
    margin: 0;
  }

  .upload-dialog .el-dialog__body {
    padding: 0 1rem 1rem 1rem;
  }

  .upload-dialog__file {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 0.5em;
    border-bottom: 1px solid #EFEFEF;
  }

  .collapsed_bar {
    height: 100%;
    width: 20px;
  }


  .editor__alert {
    position: absolute;
    z-index: 1100;
  }


  .editor__title {
    width: 100%;
    max-width: 500px;
    font-size: 1.25rem;
    font-weight: 300;
    border: 0;
    background: none;
    margin-right: 1rem;
  }

  .editor__title:focus{
    border-bottom: 1px solid var(--color-tertiary-1);
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
    text-align: center;
  }

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}


/* TEMPLATE DIALOG */
.template__dialog {
  width: 100%;
}

.template__dialog .el-dialog {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 800px;
  height: 70%;
  max-height: 600px;
  flex: 1;
  overflow: hidden;
}

.template__dialog .el-dialog__body {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  overflow: hidden;
  height: 100%;
}

.template__container {
  display : flex;
  padding: 0.5rem 1rem 1rem;
  width: 100%;
  height: 100%;
  min-height: 0;
}

.el-tabs__content {
  display: flex;
  height: 100%;
}

.template__container .el-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.template__container .el-tab-pane {
  display: flex;
  flex: 1;
}

.settings_dialog .el-tab-pane {
  display: flex;
  flex-direction: column;
}

.template__container,
.template__dialog .el-dialog__body {
  padding: 0;
}

.template__button.el-button {
  border-radius: var(--border-radius);
  margin-bottom: 0.25rem;
  margin: auto 0 0.25rem 0;
  width: 4.5rem;
}

.template__description {
  color: #303133;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0.35rem 0;
}

.template__list {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  padding: 1.15rem;
  width: 100%;
}

.template__item {
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex: 1 0 25%;
  flex-direction: column;
  margin: 0.75rem;
  padding: 0.75rem 1.25rem;
  width: 40%;
}

.template__item:last-child {
  max-height: 15.5rem;
}

.template__item:first-child,
.template__item:nth-child(2) {
  flex: 1 0 40%;
}

.template__title {
  color: #303133;
  font-size: 0.75rem;
  font-weight: bolder;
  margin: 0 0 0.35rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 1px);
}

.template__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 100%;
  width: 100%;
}

.template__image__wrapper {
  background: #E6E6E6;
  border-radius: 8px;
  height: 5.25rem;
  padding: 0.75rem;
  width: 100%;
}

.template__item:first-child > .template__image__wrapper,
.template__item:nth-child(2) > .template__image__wrapper,
.template__item:last-child > .template__image__wrapper {
  height: 8.25rem;
}

.settings__dialog .el-dialog {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 80%;
  max-width: var(--max-width);
}

.settings__dialog .el-tab-pane {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 1.5rem;
  min-height: 400px;
  flex: 1;
}

.settings__dialog .el-input, .settings__dialog .el-textarea {
  max-width: 400px;
}

.settings__dialog .el-tab-pane .el-button{
  max-height: 45px;
}

.settings__entry {
  margin-bottom: 1.5rem;
}

/* TAGS */
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  vertical-align: bottom;
}
.input-new-tag:not(:first-child) {
  margin-left: 10px;
}
.input-new-tag input,
.input-new-tag .el-input__icon {
  line-height: 32px !important;
  height: 32px !important;
}

.demo-description textarea {
  min-height: 70px !important;
}

@media (min-width: 420px) {
  .editor__controls {
    padding: 0.8rem;
  }

  .editor__controls--right {
    justify-content: space-between;
  }

  .editor__controls--right .el-checkbox-group, .editor__controls--right .el-button {
    margin-left: 10px;
  }

  .editor__top {
    min-height: 0;
  }
}

@media (max-width: 360px) {
  .editor__controls--right .el-button-group .el-button--mini,
  .editor__controls--right .el-radio-button--mini .el-radio-button__inner,
  .editor__controls--right .el-dropdown .el-dropdown__icon {
    font-size: 9px;
  }
}

@media (max-width: 420px) {
  .editor__controls--right {
    justify-content: space-evenly;
    width: 100%;
  }

  /* El button mini override */
  .el-button, 
  .el-button-group .el-button--mini,
  .el-radio-button--mini .el-radio-button__inner {
    padding: 5px 10px !important;
  }

  .template__item,
  .template__item:first-child,
  .template__item:nth-child(2) {
    flex: 1 0 40%;
    width: 100%;
  }
}

@media (max-width: 770px) {
  .action-text {
    display: none;
  }
}

.iframe-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}
</style>
