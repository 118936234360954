import MixinCaching from './caching.js';

export default {
  mixins: [MixinCaching],
  data() {
    return {
      galleryMetadata: {
        chartType: {
          options: {},
          type: 'select',
          selectMultiple: true,
          addOption: false,
        }, 
        features: {
          options: {},
          type: 'select',
          selectMultiple: true,
          addOption: true,
        },
        isDashboard: {
          type: 'boolean',
        },
        isExample: {
          type: 'boolean',
        },
        isPopular: {
          type: 'boolean',
        },
        loadInIframe: {
          type: 'boolean',
        },
        relatedDocs: {
          options: {},
          type: 'select-url',
          selectMultiple: true,
          addOption: true,
        },
        theme: {
          options: {},
          type: 'select',
          selectMultiple: false,
          addOption: true,
        },
        useCase: {
          options: {},
          type: 'select',
          selectMultiple: true,
          addOption: true,
        },
        vanityUrl: {
          type: 'url',
          selectMultiple: false,
          addOption: true,
        }
      },
      galleryMetadataOptions: [
        {
          name: 'Chart Type',
          value: 'chartType',
        }, {
          name: 'Features',
          value: 'features',
        }, {
          name: 'Example',
          value: 'isExample',
        }, {
          name: 'Dashboard',
          value: 'isDashboard',
        }, {
          name: 'Popular',
          value: 'isPopular',
        }, {
          name: 'Load in Iframe',
          value: 'loadInIframe',
        }, {
          name: 'Related Docs',
          value: 'relatedDocs',
        }, {
          name: 'Theme',
          value: 'theme',
        }, {
          name: 'Business Use Case',
          value: 'useCase',
        }, {
          name: 'Gallery Vanity URL',
          value: 'vanityUrl',
        }
      ],
    };
  },
  mounted() {
    if (this.$store.state.user['user_id']) {
      this.fetchAllOptions();
    }
  },
  methods: {
    /**
     * @description Fetches options for all gallery metadata types
     */
    fetchAllOptions() {
      let options = this.galleryMetadataOptions.map((option) => option.value);
      this.fetchOption(options);
    },
    /**
     * @description Fetches options for specified gallery metadata type. Fetches from localstorage first,
     * before attempting to fetch from api endpoint
     * @description {String[]} type - gallery metadata type
     */
    fetchOption(type) {
      // Get options is cached
      if (typeof type !== 'object') type = [type];
      type.forEach((t) => {
        let data = this.cacheFetch(`metaoptions_${t}`);
        if (data) {
          data = JSON.parse(data);
          if (this.galleryMetadata[t].type === 'boolean' && data && data[''] && data[''[0]]) data = data[''][0];
          // Parse data and set options right away
          this.galleryMetadata[t].options = data;
        } 
      });

      // Fetch data to check if localstorage updated
      this.$api('metaoptions/list_and_group', {
        slug: 'type',
      })
      .then((result) => {
        if (result && result.data) {
          for (let type in result.data) {
            let options = {};
            result.data[type].forEach((item) => {
              // Option object
              let obj = {
                name: item.value,
                value: item.value,
                content: item.content || '',
                demo_type: item.demo_type,
                metadata_uid: item.id,
              }
              // Create array (if not exist) and push
              if (!options[item.demo_type]) options[item.demo_type] = [];
              options[item.demo_type].push(obj);
            });

            if (this.galleryMetadata[type]) {
              if (this.galleryMetadata[type].type === 'boolean') options = options[''][0];
              this.galleryMetadata[type].options = options;
              this.cacheSaveData(`metaoptions_${type}`, options);
            };
          };
        }
      });
    },
  },
};
