<template>
  <section class="demo-cards" :mode="mode">
    <!-- Header / Demo Controls -->
    <header class="demo-controls" >
      <div class="demo-controls__left">
        <!-- Filter -->
        <div class="demo-control__item">
          <tag-selector :filterBy.sync="tag" :group="group" :type="demoType"></tag-selector>
          <svg-icon class="el-input-icon" icon="angle"></svg-icon>
        </div>
        <!-- Sort -->
        <div class="demo-control__item">
          <el-select v-model="sortBy" placeholder="Select">
          <el-option
            v-for="item in sortOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <svg-icon class="el-input-icon" icon="angle"></svg-icon>
      </div>
      </div>
      <!-- Search -->
      <div class='demo-controls__right'>
        <div class="demo-control__item">
          <el-input search placeholder="Search By..." v-model="watchSearch" maxlength="75"></el-input>
          <svg-icon class="el-input-icon" icon="search"></svg-icon>
        </div>
      </div>
    </header>

    <section class="demos" :template="template ? template : false" v-show="authenticated && loaded">
      <template v-if="pagedDemos && pagedDemos.length > 0 && add">
        <template v-for="(demo, index) in pagedDemos">
          <demo-card v-show="index < 5" :demo="demo" :key="demo.id" @delete="refreshDemos" :mode="mode" :template="template"></demo-card>
        </template>
        <demo-card :demo-type="demoType" dummy="true" :template="template"></demo-card> <!-- TODO view if have group seat or is personal demo viewer -->
      </template>
      <template v-else-if="pagedDemos && pagedDemos.length > 0">
        <demo-card v-for="demo in pagedDemos" :demo="demo" :key="demo.id" @delete="refreshDemos" :mode="mode" :template="template"></demo-card>
        <demo-card :demo-type="demoType" v-if="pagedDemos && pagedDemos.length < 6 && group === false && template === false" dummy="true" :mode="mode" :template="template"></demo-card>
      </template>
      <template v-else-if="template && pagedDemos.length == 0">
        <div style="height: 400px;">
          <h3>You have not created any templates!</h3>
          <p>You can convert a demo in to a template in the demo's settings dialog.</p>
        </div>
      </template>
      <template v-else>
        <div class="demos no-demos" :template="template ? template : false">
          <demo-card @update-view="$emit('update-view', 'create', demoType || null)" :demo-type="demoType" dummy="true" :mode="mode" :template="template"></demo-card> <!-- TODO view if have group seat or is personal demo viewer -->
        </div>
      </template>
    </section>
    <section class="demo-pagination" v-show="pagination">
      <el-pagination
        layout="prev, pager, next"
        :total="totalDemos"
        :pageSize="pageSize"
        :current-page.sync="currentPage"
        @current-change="handleChange"
        @next-click="handleNext"
        @prev-click="handlePrev"
        >
      </el-pagination>
    </section>
  </section>
</template>

<script>
  import debounce from 'lodash.debounce';
  import DemoCard from './DemoCard.vue';
  import TagSelector from './TagSelector.vue';
  import axios from 'axios';
  import Autocomplete from 'vuejs-auto-complete';
  import MixinPermissions from '../mixins/permissions.js'
  import SvgIcon from './SvgIcon.vue';

  export default {
    components: {
      DemoCard,
      TagSelector,
      Autocomplete,
      SvgIcon,
    },
    mixins: [MixinPermissions],
    props: {
      add: {
        default: false,
      },
      demoType: String,
      pagination: {
        default: false,
      },
      search: {
        default: false,
      },
      template: {
        default: false,
      },
      group: {
        default: false,
      },
      mode: String,
    },
    data() {
      return {
        start: 0,
        tag: null,
        sortBy: 'last_updated',
        searchQuery: '',
        currentPage: 1,
        pageSize: 6,
        sortOptions: [
          {
            value: 'last_updated',
            label: 'Last Modified'
          },
          {
            value: 'title',
            label: 'Alphabetical'
          },
        ],
        demos: [],
        pagedDemos: [],
        demosCount: 0,
        newQuery: false,
        loaded: false,
        watchSearch: '',
      };
    },
    computed: {
      authenticated() {
        const auth = this.$store.state.user['user_id'];
        if (auth) this.fetchNext();
        return this.$store.state.user['user_id'];
      },
      totalDemos() {
        return this.demosCount;
      },
      sortDir() {
        return (this.sortBy === 'last_updated') ? 'DESC' : 'ASC';
      }
    },
    mounted() {
      this.newQuery = false;
    },
    watch: {
      searchQuery() {
        this.newQuery = true;
      },
      sortBy(value) {
        this.newQuery = true;
        this.fetchNext();
      },
      tag() {
        this.newQuery = true;
        this.fetchNext();
      },
      watchSearch: debounce(function(newVal){
        this.checkSearchStr(newVal)
      }, 100),
    },
    methods: {
      refreshDemos() {
        this.fetchNext();
        this.newQuery = false;
      },
      fetchNext() {
        if(this.newQuery) {
          this.demos = [];
          this.start = 0;
          this.newQuery = false;
          this.currentPage = 1;
          this.demosCount = 0;
        }
        let start = (this.currentPage - 1) * this.pageSize;
        let url = WP_API_URL + `/api/demo?start=${start}&limit=${this.pageSize}${this.group ? '&id_grouping='+this.group : ''}`;
        url += `&sort_by=${this.sortBy}&sort_direction=${this.sortDir}`;
        let filter = [];
        if(this.searchQuery) {
          filter.push({
            by: 'title',
            value: this.searchQuery,
            type: 'demo'
          });
        }
        if(this.tag) {
          filter.push({
            by: 'name',
            value: this.tag,
            type: 'tag'
          });
        }
        if(this.template) {
          filter.push({
            by: 'is_template',
            value: 1,
            type: 'demo',
          })
        }
        if (this.demoType) {
          filter.push({
            by: 'type',
            value: this.demoType,
            type: 'demo',
          })
        }
        if(filter && filter.length > 0) {
          url += `&filter=${JSON.stringify(filter)}`;
        }

        axios({
          url,
          json: true,
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth.idToken,
          },
        })
        .then((response) => {
          // TODO: PAGE SIZE IS UPDATED BEFORE FETCH NEXT. FIX THIS! POSSIBLY JUST PAGEDDEMOS AS A DATA VAL INSTEAD OF COMPUTED
          let demos = response.data.results.splice(0, this.pageSize);
          demos = demos.map(({title, image, last_updated, uid, tags, template_type, description}) => {
            let date = new Date(last_updated).toLocaleString('en-us', { month: "long", year: 'numeric', day: 'numeric' } );
            title = title || 'Untitled Demo';
            return {
              title,
              uid,
              image: `https://storage.googleapis.com/${VUE_APP_CLOUD_ASSETS_BUCKET}/${image}`,
              date,
              tags,
              template_type,
              description
            }
          });

          // this.demos = this.demos.concat(demos);
          this.demos[this.currentPage] = demos;
          this.setPagedDemos(this.currentPage);
          this.demosCount = response.data.size;
          
          // Setup new account with default demos
          if (localStorage.getItem('setupDemos') === 'true' && (this.demoType === 'zingchart' || this.demoType === 'zinggrid')) this.addDefaultDemos();
          else {
            this.loaded = true;
          }

          // Check if preview given, give height of 100% just in case images displays default behind
          let images = document.querySelectorAll('[demo-viewer] .demo__image__wrapper');
          images.forEach(image => {
            if (image.querySelector('.demo__image').getBoundingClientRect().height > 25) image.style.height = '100%';
          })
        })
        .catch((e) => {
          if (e.response && e.response.status) {
            // Session expires, log user out and save lastSeen
            if (e.response.data.message === 'jwt expired') {
              localStorage.setItem('startup_status', JSON.stringify({
                message: 'Session expired. Please log back in',
                type: 'success',
              }));
              localStorage.setItem('lastSeen', window.location.pathname);
              this.$store.commit('auth/logout');
            }
          }
          return Promise.reject(e);
        });
      },
      handlePrev(newPage) {
        this.currentPage--;
        this.setPagedDemos(newPage);
      },
      handleNext(newPage) {
        if(!this.demos[newPage]) {
          // this.start += this.pageSize;
          this.currentPage++;
          this.fetchNext();
        } else {
          this.setPagedDemos(newPage);
        }
      },
      handleChange(newPage) {
        if(!this.demos[newPage]) {
          this.currentPage = newPage;
          this.fetchNext();
        } else {
          this.setPagedDemos(newPage);
        }
      },
      setPagedDemos(currentPage) {
        this.pagedDemos = this.demos[currentPage];
      },
      /**
       * @description Retieve default demo of demo to add to new account
       * @param { String } demoUID - unique id of default demo
       */
      retrieveDemo(demoUID) {
        return new Promise( (resolve) => {
          axios({
            url: `/api/demo/${demoUID}`,
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + this.$store.state.auth.idToken },
          }).then((response) => {
            resolve({
              html: response.data.html,
              js: response.data.js,
              css: response.data.css,
              title: response.data.title,
              description: response.data.description,
              public: response.data.public,
              is_template: response.data.is_template,
              premium_template: response.data.premium_template,
              image: response.data.image,
              image_small: response.data.image_small,
              mobile_grid: response.data.mobile_grid,
              mobile_height: response.data.mobile_height,
              mobile_image: response.data.mobile_image,
              mobile_image_small: response.data.mobile_image_small,
              type: response.data.type,
            });
          }).catch((err) => {
            this.$message({
              duration: 10000,
              message: 'Could not retieve default demos',
              showClose: true,
              type: 'error',
            });
          });
        })
      },
      /**
       * @description Create default demo to add to new account
       * @param { Object } demoData - data to create new demos from
       */
      createDemo(demoData) {
        return new Promise( (resolve) => {
          axios({
            url: '/api/demo',
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + this.$store.state.auth.idToken },
            data: demoData,
          }).then((response) => {
            resolve(true);
          }).catch((err) => {
            this.$message({
            duration: 10000,
            message: 'Could not create default demos',
            showClose: true,
            type: 'error',
          });
        });
      });
      },
      /**
       * @description For new accounts, create two default demos
       */
      async addDefaultDemos() {
        // Remove signup trigger
        localStorage.removeItem('setupDemos');
        localStorage.removeItem('signupReferrer');

        // Default demos to add
        const defaultDemos = [VUE_APP_GRID_ONE, VUE_APP_GRID_TWO, VUE_APP_CHART_ONE, VUE_APP_CHART_TWO, VUE_APP_CHART_THREE]
        
        // After creating default demos, add to demolist
        defaultDemos.forEach(async(demo, index) => { 
          const data = await this.retrieveDemo(demo);
          await this.createDemo(data); 
          if (index === defaultDemos.length-1) { 
            this.$emit('fetch');
            this.loaded = true;
          };
        });
      }
    }
  }
</script>


<style scoped>
  .demos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: var(--row-padding) 0;
  }
  .demo-pagination{
    display: flex;
    width: 100%;
    padding-top: 1rem;
    border-top: 1px solid #eee;
  }
  .el-pagination {
    text-align: center;
    flex: 1;
  }

  [mode="list"] .demos {
    margin-top: 1.3rem;
  }
</style>