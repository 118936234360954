<template>
  <div class="zingnetCard--column">
    <h3 class="zingnetCard--header">{{title}}</h3>
    
    <div class="progressBar">
      <div v-if="progress" class="progressBar--completed" :style="`width: ${progress}%`"></div>
      <div v-if="progress && progress < 100" class="progressBar--divider"></div>
    </div>
    
    <div v-if="progress" class="progress">
      <span>
        <span class="progressText">{{progress}}%</span><span>completed</span>
      </span>
      <span>
        <span class="progressText progressText__left">{{left}}%</span><span>left</span>
      </span>
    </div>
    <p v-else-if="fail" class="progressText progressText__fail">Deployment failed</p>
    <p v-else-if="time == 0">Deployment is in queue</p>
    <p v-else>There are no deployments in progress right now for the {{title.split(' ').shift()}} gallery</p>
  </div>
</template>

<script>

import MixinDate from '../../../../mixins/date.js';
import { Octokit } from '@octokit/core';
const octokit = new Octokit({ auth: VUE_APP_GITHUB_API_TOKEN });

process.env.authTOKEN;

export default {
  mixins: [MixinDate],
  props: {
    title: String,
    sitename: String,
    branch: String,
  },
  data() {
    return {
      $progressBar: null,

      estimate: 100,
      fail: false,
      intervalStatus: null,
      intervalTime: null,
      lastRun: null,
      progress: 0,
      increment: 1,
      start: false,
      time: null,
      workflowIds: {
        zingchart: {
          dev: 218016,
          master: 550840,
        },
        zinggrid: {
          dev: 182535,
          master: 184183,
        },
      },
    }
  },
  computed: {
    left() {
      return 100 - this.progress;
    },
  },
  destroyed() {
    if (this.intervalStatus) clearInterval(this.intervalStatus);
    if (this.intervalTime) clearInterval(this.intervalTime);
  },
  mounted() {
    this.$progressBar = this.$el.querySelector('.progressBar');
  },
  methods: {
    trackProgress() {
      // Get estimation of how long deployment takes
      this._getEstimate();

      // Setup progress bar
      this._setupBar();

      // Check progress (set to 100% if complete, stuck at 95% if in progress, or fails)
      this._checkProgress(true);
    },
    
    _calculateProgress(increment) {
      this.time += increment;

      this.progress = Math.floor(this.time / this.estimate * 100);
    },
    _checkProgress(init) {
      this.intervalStatus = setInterval(() => {
        octokit.request(`GET https://api.github.com/repos/{owner}/{repo}/actions/workflows/${this.workflowIds[this.sitename][this.branch]}/runs?per_page=1`, {
          owner: "zingsoftinc",
          repo: `${this.sitename}-com`,
        })
          .then((result) => {
            // Check if queued, in_progress, completed (failure, success), 
            let {id, status, conclusion} = result.data.workflow_runs[0];
            if (id !== this.lastRun) {
              if (status === 'in_progress' && !this.start) {
                this.start = true;
                // Start progress bar
                this._progressTime();
              } else if (status === 'completed') {
                this._completeProgress(conclusion === 'success');
              }
            };
          });
      }, 5000);
    },
    _completeProgress(succeed) {
      // Stop intervals
      if (this.intervalStatus) clearInterval(this.intervalStatus);
      if (this.intervalTime) clearInterval(this.intervalTime);

      this.start = false;

      if (succeed) {
        // Complete progress bar
        let diff = this.estimate - this.time;
        this._calculateProgress(diff);     
      } else {
        // Show fail note
        this.fail = true;
      }
    },
    _getEstimate() {
      octokit.request(`GET https://api.github.com/repos/{owner}/{repo}/actions/workflows/${this.workflowIds[this.sitename][this.branch]}/runs?per_page=10`, {
        owner: "zingsoftinc",
        repo: `${this.sitename}-com`,
      })
        .then((result) => {
          let runs = result.data.workflow_runs

          // Get last run to not do progress tracking on wrong run
          for (let i = 0; i < runs.length; i++) {
            if (runs[i].status === 'completed') {
              this.lastRun = runs[i].id
              break;
            }
          };

          let size = 0;
          let completionTimes = runs.reduce((acc, run) => {
            if (run.status === 'completed' && run.conclusion === 'success') {
              size++;
              return acc + this.dateDiff(run.created_at, run.updated_at, null, 'seconds');
            } else {
              return acc;
            }
          }, 0);
          this.estimate = Math.floor(completionTimes / size);
        });
    },
    _progressTime() {
      this.intervalTime = setInterval(() => {
        this._calculateProgress(this.increment);
        
        // Stall in case progress not complete at 95%
        if (this.progress > 95) {
          this._completeProgress();
          clearInterval(this.intervalTime);
        }
      }, 1000);
    },
    _setupBar() {
      this.fail = false;
      this.time = 0;

      this.$progressBar.classList.add('progressBar--left');
    }
  },
}
</script>

<style scoped>
  span, p {
    color: var(--color-primary-gray);
    font-size: 0.8125rem;
    margin: 0;
  }
  p {
    text-align: center;
  }
  .progress {
    display: flex;
    justify-content: space-between;
  }
  .progressText {
    color: var(--color-secondary-blue);
    font-size: 2.5rem;
    font-weight: 200;
    margin: 0 0.3125rem 1rem 0;
  }
  .progressText__fail {
    color: var(--color-primary-danger);
  }
  .progressText__left {
    color: var(--color-secondary-blue-active);
  }
  .progressBar {
    background-color: var(--color-tertiary-gray);
    border-radius: var(--zingnet-border-radius);
    display: flex;
    height: 1.5rem;
    margin-bottom: 1rem;
    overflow: hidden;
    width: 100%;
  }
  .progressBar--completed {
    background-color: var(--color-secondary-blue);
    height: 100%;
    transition: all 0.2s;
  }
  .progressBar--divider {
    background-color: #fff;
    height: 100%;
    width: 0.125rem;
  }
  .progressBar--left {
    background-color: var(--color-secondary-blue-active);
  }
</style>