<template>
  <el-dialog
    title="Settings"
    :visible.sync="visibility"
    class="settings__dialog">
      <el-tabs :tabPosition="isMobile ? 'top' : 'left'" @tab-click="currentTab">
        <!-- GENERAL -->
        <el-tab-pane v-if="showSettings('general')" label="General">
          <el-row>
            <el-col :span="18" style="border-right:1px solid var(--border-light)">
              <section class="settings__entry">
                <label>Title</label>
                <div style="display: flex;">
                  <el-input placeholder="Untitled Demo" type="text" v-model="title" size="mini" maxlength="75"/>
                </div>
              </section>
              <section class="settings__entry">
                <label>Description</label>
                <div>
                  <el-input class="demo-description" placeholder="Demo Description" type="textarea" autosize v-model="description" rows="10" maxlength="250"/>
                </div>
              </section>

              <section class="settings__entry">
                <label>Tags</label>
                <div>
                  <el-tag
                    :key="tag.name"
                    v-for="tag in tags"
                    closable
                    :disable-transitions="false"
                    @close="removeTag(tag.id, tag.name)">
                    {{tag.name}}
                  </el-tag>
                  <template v-if="inputVisible">
                    <!-- ADMIN TAG INPUT -->
                    <el-select
                      v-if="adminTagAccess"
                      class="input-new-tag"
                      filterable
                      v-model="inputValue"
                      @change="addTag"
                      @keyup.enter.native="addTag"
                      style="max-width:150px;">
                      <el-option
                        v-for="(tag, index) in adminTagOptions"
                        :key="index"
                        :label="tag"
                        :value="tag">
                      </el-option>
                    </el-select>
                    <!-- USER TAG INPUT -->
                    <el-input
                      v-else
                      class="input-new-tag"
                      v-model="inputValue"
                      ref="saveTagInput"
                      size="mini"
                      @keyup.enter.native="addTag"
                      @blur="addTag"
                      style="max-width:150px;"
                      >
                    </el-input>
                  </template>
                  <el-button v-else class="button-new-tag el-tag" size="small" @click="showInput">+ New Tag</el-button>
                </div>
              </section>

              <section class="settings__entry">
                <el-button type="danger" @click="deleteDemo" :disabled="demo && !demo.uid" size="mini">Delete Demo</el-button>
              </section>
            </el-col>
            
            <el-col :offset="2" :span="4">
              <section v-if="adminTemplateCreate" class="settings__entry">
                <label>Public</label>
                <br/>
                <el-switch v-model="isPublic">
                </el-switch>
              </section>
              <section class="settings__entry">
                <label>Run On Click</label>
                <br/>
                <el-switch v-model="runOnClick">
                </el-switch>
              </section>
            </el-col>
          </el-row>

          <el-row>
            <section class="settings__entry" style="display:flex; justify-content: flex-end; align-items: flex-end; flex:1;">
              <el-button @click="$emit('toggle-visibility')">Close</el-button>
              <el-button type="primary" @click="saveData">Save</el-button>
            </section>
          </el-row>
        </el-tab-pane>

        <!-- UPLOAD FILES -->
        <el-tab-pane label="Files" v-if="showSettings('upload')" >
          <header><h3 class="upload-dialog__heading">Uploaded Files</h3></header>
          <ul class="upload-dialog__files">
            <li v-show="files.length === 0">No files uploaded</li>
            <li class="upload-dialog__file" v-for="(file, index) in files" :key="index">
              <div v-if="fileThumbnailStyle(file)" class="upload-dialog__file__thumbnail--image" :style="fileThumbnailStyle(file)"></div>
              <svg-icon v-else icon="file" height="28" class="upload-dialog__file__thumbnail--text"></svg-icon>
              <input type="text" :value="file" style="width: 100%"/>
              <div style="display: flex">
                <el-button size="mini" @click="copyLink(file)" plain>copy link</el-button>
                <el-button size="mini" type="danger" @click="deleteFile(file)" plain>delete</el-button>
              </div>
            </li>
          </ul>

          <section>
            <header><h3 class="upload-dialog__heading">Upload Files</h3></header>
            <el-button type="primary" @click="openFilestack">Upload</el-button>
            <p>Maximum upload size is 5MB</p>
            <p>Accepted filetypes are limited to image and text.</p>
            <p>To set image as thumbnail:</p>
            <ul>
              <li>Find an image you want to set as the demo thumbnail</li>
              <li>Rename selected image to "demo_thumbnail" (ex. demo_thumbnail.png)</li>
              <li>Upload the image (Name of file will be "DEMO_ID/RANDOM_HASH_demo_thumbnail.png")</li>
              <li>Save demo</li>
            </ul>
          </section>

          <span slot="footer" class="dialog-footer">
            <el-button @click="$emit('toggle-visibility')">Close</el-button>
          </span>
        </el-tab-pane>

        <!-- SHARE -->
        <el-tab-pane label="Share" v-if="showSettings('share')" >
            <h4 style="color:#dc1257" v-show="demo && !demo.uid">
              Save the demo before sharing!
            </h4>
            <section style="margin-bottom: 1rem;">
              <header>
                <h3>Share Link</h3>
                <div class="demo-control__item">
                  <el-select v-model="shareValue" placeholder="Select" size="mini">
                    <el-option
                      v-for="item in shareOptions"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id">
                    </el-option>
                  </el-select>
                  <svg-icon class="el-input-icon" icon="angle"></svg-icon>
                </div>
              </header>
              <el-input v-model="shareLink" :disabled="demo && !demo.uid">
                <el-button slot="append" @click="copyShareLink" :disabled="demo && !demo.uid">COPY</el-button>
              </el-input>
            </section>
          <section>
            <h3>Download the demo as an HTML file</h3>
            <el-button type="primary" @click="download" size="mini" :disabled="demo && !demo.uid"><font-awesome-icon :icon="['fas', 'download']"/> DOWNLOAD</el-button>
          </section>
        </el-tab-pane>

        <!-- EDITOR -->
        <el-tab-pane label="Editor" v-if="showSettings('editor')" >
          <section style="margin-bottom: 1rem;">
            <h3>Auto-update preview</h3>
            OFF <el-switch v-model="autoUpdate" @change="updateAutoUpdateSettings"/> ON
          </section>

          <section v-show="hide" style="margin-bottom: 1rem;">
            <h3>Documentation Hints</h3>
            OFF <el-switch v-model="docsTooltip" @change="updateDocsToolipsSettings"/> ON
          </section>

          <section style ="margin-bottom: 1rem;">
            <h3>Auto-complete</h3>
            <el-tree
              @check-change="updateAutoCompleteSettings"
              :data="autoCompleteTree"
              :default-checked-keys="autoComplete"
              default-expand-all
              node-key="id"
              show-checkbox
              :props="defaultAutoCompleteProps">
            </el-tree>
          </section>
        </el-tab-pane>

        <!-- TEMPLATE -->
        <el-tab-pane label="Template" v-if="showSettings('template')" >
          <section class="settings__entry">
            <h3>Template</h3>
            <div><el-switch v-model="isTemplate"></el-switch> Mark as template</div>

            <h3>Template Type</h3>
            <div class="demo-control__item">
              <el-select v-model="demoTemplate" placeholder="Select">
                <el-option
                  v-for="item in templateTypes"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
                </el-option>
              </el-select>
              <svg-icon class="el-input-icon" icon="angle"></svg-icon>
            </div>
          </section>

          <section v-if="premiumContentSet || adminTemplateCreate" class="settings__entry">
            <h3>Admin Settings</h3>
            <div v-if="premiumContentSet">
              <el-switch v-model="isPremium"></el-switch> Premium (Only premium users can view)
            </div>
            <div v-if="adminTemplateCreate">
              <el-switch v-model="isPublic"></el-switch> Public (Mark both "Template" and "Public" creates a template for all users)
            </div>
          </section>

          <section class="settings__entry" style="display:flex; justify-content: flex-end; align-items: flex-end; flex:1;">
            <el-button @click="$emit('toggle-visibility')">Close</el-button>
            <el-button type="primary" @click="saveData">Save</el-button>
          </section>

        </el-tab-pane>

        <!-- METADATA -->
        <el-tab-pane label="Metadata" v-if="showSettings('metadata') && adminMetadataUpdate">
          <section class="settings__entry" style="margin-bottom: 1rem;">
            <h3>Metadata</h3>
            <div v-show="adminMetadataUpdate">
              <p>To include demo in gallery, add the following</p>
              <ul>
                <li>"zc-gallery" or "zg-gallery" tag (in "General" tab)</li>
                <li>"zingchart.com-demo" or "zinggrid.com-demo" tag (in "General" tab)</li>
                <li>"vanityUrl" metadata</li>
                <li>"feature" metadata</li>
              </ul>
            </div>
            <p v-show="!metadata && !insertingMetadata">Demo has no metadata.</p>
            <div demo-settings="metadata"></div>
            <el-button demo-settings="metadata__add--gallery" @click="galleryMetadataVisible=true">Add Gallery Metadata</el-button>
          </section>

          <section class="settings__entry" style="display:flex; justify-content: flex-end; align-items: flex-end; flex:1;">
            <el-button @click="$emit('toggle-visibility')">Close</el-button>
            <el-button type="primary" @click="saveData">Save</el-button>
          </section>
        </el-tab-pane>

      </el-tabs>
    <textarea ref="copyBuffer" style="height: 0; width:0; opacity:0;"></textarea>

    <gallery-metadata
      @close="galleryMetadataVisible=false"
      @open="galleryMetadataVisible=true"
      :zgRef="zgRef"
      :demoType.sync="demo.type"
      :metadata.sync="demo.metadata"
      :visible.sync="galleryMetadataVisible"
    ></gallery-metadata>

  </el-dialog>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import MixinPermissions from './../../mixins/permissions.js';
import SvgIcon from '../../components/SvgIcon.vue';
import GalleryMetadata from './components/GalleryMetadata.vue';

export default {
  components: {FontAwesomeIcon, GalleryMetadata, SvgIcon},
  mixins: [MixinPermissions],
  props: {
    demo: Object,
    settings: Array,
    settingsVisible: Boolean,
  },
  asyncComputed: {
    adminMetadataUpdate() {
      return this.checkPermission('admin_metadata_update');
    },
    adminSaveOverride() {
      return this.checkPermission('admin_save_override');
    },
    adminTagAccess() {
      return this.checkPermission('admin_tag_access');
    },
    adminTemplateCreate() {
      return this.checkPermission('admin_template_create');
    },
    premiumContentSet() {
      return this.checkPermission('premium_content_set');
    },
  },
  computed: {
    // Demo-related
    description: { 
      get: function () { return this.demo ? this.demo.description : null; },
      set: function (val) { this.$emit('update-demo', 'description', val); },
    },
    demoTemplate: { 
      get: function () { return this.demo ? this.demo.demoTemplate : null; },
      set: function (val) { this.$emit('update-demo', 'demoTemplate', val); },
    },
    files: { 
      get: function () { return this.demo ? this.demo.files : null; },
      set: function (val) { this.$emit('update-demo', 'files', val); },
    },
    isMobile() {
      return this.$store.getters['ui/isMobile'];
    },
    isPremium: { 
      get: function () { return this.demo ? this.demo.isPremium : null; },
      set: function (val) { this.$emit('update-demo', 'isPremium', val); },
    },
    isPublic: { 
      get: function () { return this.demo ? this.demo.isPublic : null; },
      set: function (val) { this.$emit('update-demo', 'isPublic', val); },
    },
    isTemplate: { 
      get: function () { return this.demo ? this.demo.isTemplate : null; },
      set: function (val) { this.$emit('update-demo', 'isTemplate', val); },
    },
    metadata: { 
      get: function () { return this.demo ? this.demo.metadata : null; },
      set: function (val) { this.$emit('update-demo', 'metadata', val); },
    },
    publish: { 
      get: function () { return this.demo ? this.demo.publish : null; },
      set: function (val) { this.$emit('update-demo', 'publish', val); },
    },
    tags: { 
      get: function () { return this.demo ? this.demo.tags : null; },
      set: function (val) { this.$emit('update-demo', 'tags', val); },
    },
    title: { 
      get: function () { return this.demo ? this.demo.title : null; },
      set: function (val) { this.$emit('update-demo', 'title', val); },
    },
    runOnClick: { 
      get: function () { return this.demo ? this.demo.runOnClick : null; },
      set: function (val) { this.$emit('update-demo', 'runOnClick', val); },
    },
    type: { 
      get: function () { return this.demo ? this.demo.type : null; },
      set: function (val) { this.$emit('update-demo', 'type', val); },
    },

    // General
    autoComplete: { 
      get: function () { return this.demo ? this.demo.autoComplete : null; },
      set: function (val) { this.$emit('update-demo', 'autoComplete', val); },
    },
    autoUpdate: { 
      get: function () { return this.demo ? this.demo.autoUpdate : null; },
      set: function (val) { this.$emit('update-demo', 'autoUpdate', val); },
    },
    docsTooltip: { 
      get: function () { return this.demo ? this.demo.docsTooltip : null; },
      set: function (val) { this.$emit('update-demo', 'docsTooltip', val); },
    },
    /**
     * @description Sets up Filestack client with options.
     * More on Filestack options are found at https://www.filestack.com/docs/concepts/pickers/web/
     */
    filestackOptions() {
      return {
        accept: ['application/json', 'image/*', 'text/*'],
        fromSources: ['local_file_system', 'url', 'imagesearch', 'facebook', 'instagram', 'googledrive'],
        maxSize: 5 * this.MEGABYTE,
        storeTo: {
          location: 'gcs',
          container: VUE_APP_CLOUD_ASSETS_BUCKET,
          path: `/${this.demo.uid}/`,
        },
        onOpen: () => {
          this.setZIndex(true);
        },
        onUploadDone: files => {
          let curLength = this.files.length;
          let expectedLength = curLength + files.filesUploaded.length - files.filesFailed.length;
          this.getFiles(expectedLength);
        },
        onClose: () => {
          this.setZIndex(false);
        }
      }
    },
    shareLink() {
      let sharePath = this.shareValue;
      let shareUrl = `${window.location.origin}/demos/${sharePath}/${this.demo.uid}`;

      // Share link for special case: oembed
      if (sharePath === 'oembed') {
        sharePath = 'view';
        shareUrl = `${window.location.origin}/oembed?url=${window.location.origin}/demos/${sharePath}/${this.demo.uid}`
      };
      return shareUrl;
    },
    visibility: {
      get: function () {
        return this.settingsVisible;
      }, 
      set: function(val) {
        this.$emit('toggle-visibility');
      },
    },
  },
  watch: {
    demo: {
      handler(newValue, oldValue) {
        if (!this.zgRef && newValue.uid !== oldValue.uid) 
        this.setupZingGrid();
      },
      deep: true,
    },
  },
  data() {
    return {
      MEGABYTE: 1048576,

      $content: null,
      $nav: null,
      zgRef: null,
      $zgInsertBtn: null,
      zgRefContainer: null,
      acceptedFiletypes: ['.csv', '.tsv', '.xls', 'xslx', '.json', '.gif', '.png', '.jpg', '.jpeg', '.svg', '.webp'],
      adminTagOptions: ['zc-gallery', 'zinggrid.com-demos', 'zinggrid.com-preload', 'zg-gallery'],
      autoCompleteTree: [{
        label: 'All',
        children: [{
          id: 'html',
          label: "HTML",
        }, {
          id: 'css',
          label: "CSS",
        }, {
          id: 'js',
          label: "JavaScript",
        }],
        //  {
        //   id: 'js',
        //   label: "JS",
        // }]
      }],
      defaultAutoCompleteProps: {
        children: 'children',
        label: 'label'
      },
      filestackClient: null,
      hide: false,
      inputValue: '',
      inputVisible: false,
      insertingMetadata: false,
      shareValue: 'view',
      shareOptions: [{
          id: 'view',
          label: 'Embed / Preview',
        }, {
          id: 'embed',
          label: 'Embed with Code',
        }, {
          id: 'oembed',
          label: 'oEmbed',
        }, {
          id: 'create',
          label: 'Edit'
        }, 
      ],
      templateTypes: [{
        id: 'basic',
        label: 'Basic Grid',
      }, {
        id: 'blank',
        label: 'Blank Grid',
      }, {
        id: 'custom',
        label: 'Grid with Custom Rows',
      }, {
        id: 'features',
        label: 'Grid with Features',
      }, {
        id: 'headers',
        label: 'Grid with Headers',
      }, {
        id: 'headers_custom',
        label: 'Grid with Headers and Custom Rows',
      }],
      uploadForm: new FormData(),
      galleryMetadataVisible: false,
    }
  },
  created() {
    let interval = setInterval(() => {
      // Early exit if 'filestack' is not defined
      if (typeof (filestack) === 'undefined') return;

      // When defined, initialize filestack client
      this.filestackClient = filestack.init(VUE_APP_FILESTACK_API);
      clearInterval(interval);
    }, 500);
  },
  destroyed() {
    this.zgRef = null;
  },
  methods: {
    /**
     * @description Add metadata from ZingGrid into variable saved into database.
     * Before adding to the database, make sure that the demo has been created or forked.
     * If the demo has not bee created, there is no id_user or uid associated.
     * If the demo has not been forked yet, the metadata will be added to the original demo and not the forked one.
     */
    addMetadataRecord(record) {
      // Request to create metadata
      let addMetadata = function(_this) {
        _this.$api('metadata/create', {
          metadataUid: record.metadataUid,
          id_user: _this.demo.id_user,
          uid: _this.demo.uid,
          type: record.metadata,
          value: record.value,
          content: record.data,
        }).then((result) => {
          // Update value for relatedDocs
          let data = record.data;
          if (record.metadata === 'relatedDocs') {
            let relatedDocsUrl = record.content;
            data = `${relatedDocsUrl}${data}`;
          };

          // Add in `metadata` to determine if option should be disabled
          _this.metadata.push({
            id: result.data.id,
            metadata: record.metadata,
            value: record.value,
            data,
          });

          // Update dataset
          _this.zgRef.data = _this.metadata;
        }).catch((error) => {
          _this.$message({
            duration: 10000,
            message: 'Could not update metadata because value is invalid',
            showClose: true,
            type: 'error',
          });
        });
      };

      // Create/fork demo if it has not been done
      let demoExists = this.demo.id_user || this.demo.uid;
      let query = this.$route.query;
      let demoToFork = Object.keys(query).includes('fork');

      if (!demoExists || demoToFork) {
        // Save demo not created or forked, then add metadata
        this.saveData(addMetadata);
      } else {
        // Add metadata
        addMetadata(this);
      }
    },
    addTag(e) {
      let newTag = null;
      // Grab input of tag from select or input field
      if (this.adminTagAccess && typeof(e) === 'object') {
        if (e.target) newTag = e.target.value;
      } else {
        newTag = this.inputValue;
      }

      // Check if duplicate
      let duplicate = this.tags.filter((tag) => tag.name === newTag).length > 0;
      // Push tag if not duplicate
      if (newTag && !duplicate) {
        this.tags.push({
          id: null,
          name: newTag,
        });
      } else {
        this.$message({
          message: 'Tag already exists!',
          showClose: true,
          type: 'error',
        });
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    copyLink(filename) {
      this.$refs.copyBuffer.value = this.imageLink(filename);
      this.$refs.copyBuffer.select();
      document.execCommand('copy');
      this.$message({
        message: 'Link copied to clipboard!',
        showClose: true,
        type: 'success',
      });
    },
    copyShareLink(){
      this.$refs.copyBuffer.value = this.shareLink;
      this.$refs.copyBuffer.select();
      document.execCommand('copy');
      this.$message({
        message: 'Link copied to clipboard!',
        showClose: true,
        type: 'success',
      });
    },
    currentTab(tab) {
      if (tab.label === 'Metadata') {
        // Initialize ZingGrid
        this.setupZingGrid();
      }
    },
    deleteDemo() {
      this.$confirm('Are you sure you want to delete this demo?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
      .then(() => {
        this.$api('demo/delete', {
          slug: this.demo.uid,
        })
        .then(() => {
          this.$message({
            message: 'Demo successfully deleted!',
            showClose: true,
            type: 'success',
          });
          if (this.$route.path !== '/demos') this.$router.push('/demos');
        });
      })
      .catch((error) => {
        this.$message({
          duraion: 0,
          message: 'Demo could not be deleted.',
          showClose: true,
          type: 'warning',
        })
      });
    },
    deleteFile(filename) {
      this.$api('file/delete', {
        slug: filename,
      })
      .then(() => {
        this.$message({
          message: 'File deleted!',
          showClose: true,
          type: 'success',
        });
        this.getFiles();
      })
      .catch((error) => {
        this.$message({
          duration: 10000,
          message: 'File could not be deleted!',
          showClose: true,
          type: 'error',
        });
        this.getFiles();
      });
    },
    deleteMetadataRecord(data) {
      this.$api('metadata/delete', {
        id: data.id,
        id_user: this.demo.id_user,
        uid: this.demo.uid
      }).then(() => {
        // Remove in `metadata` to determine if option is disabled
        let index = this.metadata.findIndex((meta) => meta.metadata === data.metadata && meta.value === data.value);
        this.metadata.splice(index, 1);

        // Determine if "zc-gallery" tag is to be removed
        let zcGalleryTag = this.tags.find(t => t.name === 'zc-gallery');
        let chartType = this.metadata.find(m => m.metadata === 'chartType');
        let vanityUrl = this.metadata.find(m => m.metadata === 'vanityUrl');
        if (zcGalleryTag && (!chartType || !vanityUrl)) {
          // Remove "zc-gallery" tag
          this.removeTag(zcGalleryTag.id, zcGalleryTag.name);

          // Send warning message about tag removal
          this.$message({                                                                  
            duration: 0,
            showClose: true,
            message: 'The "zc-gallery" tag is removed. To add again, make sure to have both "Chart Type" and "Vanity Url" metadatas set.',
            type: 'warning',
          });
        };
      });
    },
    download() {
      window.location = `${window.location.origin}/demos/download/${this.demo.uid}`;
    },
    fetchTags() {
      this.$api('tag/read', {
        slug: this.demo.uid,
      })
      .then((result) => {
        this.tags = result.data;
      })
      .catch((error) => {
        this.$message({
          duration: 10000,
          message: 'Could not retrieve tags',
          showClose: true,
          type: 'error',
        });
      });
    },
    /**
     * @description Given the file name, returns the image link
     * @param {String} filename - file name of the image
     */
    imageLink(filename) {
      let isLocalhost = window.location.hostname === 'localhost';
      let protocol = isLocalhost ? 'http' : 'https';
      let hostname = isLocalhost ? 'localhost:8080' : window.location.hostname;
      return `${protocol}://${hostname}/api/file/${this.demo.uid}/${encodeURIComponent(filename.split(this.demo.uid+'/')[1])}`;
    },
    /**
     * @description If given file is an image, set as background-image of div, else
     * returns false so that a default thumbnail for files is used. 
     * File is determined as an image by checking through a regex
     * @param {String} file - name of file (includes file extension to determine mime-type)
     */
    fileThumbnailStyle(file) {
      // Check if image
      let isImage = file.match(/\.(jpeg|jpg|gif|png)$/) != null;
      // Set background image
      return isImage ? `background-image: url(${this.imageLink(file)});` : false;
    },
    /**
     * @description Get files associated to demo from Google Cloud Storage.
     * @param {Number} expected - Expected final files length. If files are not added, keep
     * fetching files until there.
     */
    getFiles(expected) {
      if (this.demo.uid) {
        this.$api(`file/list`, {
          slug: this.demo.uid,
        })
        .then(response => {
          // Update file list for "Uploaded Files" section
          this.files = response.data.filter((filename) => !filename.includes('_preview'));
          // Check if uploaded images added to file list. Recall until files added
          let curLength = this.files.length
          if (expected && curLength < expected) {
            setTimeout(() => {
              this.getFiles(expected);
            }, 500);
          }
        })
        .catch(error => {
          // Fails usually because demo just created and files not yet created. Retry until created.
          setTimeout(() => {
            this.getFiles();
          }, 1000);
        });
      }
    },
    /**
     * @description Opens up the Filestack client with specified options to handle file uploaders.
     */
    openFilestack() {
      this.filestackClient.picker(this.filestackOptions).open();
    },
    /**
     * @description Before save, prepare data. Then emit event to parent
     * @param {Function} cb - callbacka after saving
     */
    saveData(cb) {
      this.$emit('save', null, null, null, null, cb);
    },
    removeTag(id, name) {
      if(id === null) {
        // Just sort via filters and remove the pending tag to save, otherwise ajax to remove from server
        this.tags = this.tags.filter((tag) => tag.name !== name);
      } else {
        this.$api('tag/delete', {
          slug: id,
          data: {
            override: this.adminSaveOverride,
          }
        })
        .then((result) => {
          // Remove tag from UI
          this.tags = this.tags.filter((tag) => {
            if (tag.name !== name) return tag;
          });
        })
        .catch((error) => {
          this.$message({
            duration: 10000,
            message: 'Could not delete tag',
            showClose: true,
            type: 'error',
          })
        });
      }
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        if (this.$refs.saveTagInput) this.$refs.saveTagInput.$refs.input.focus();
        else document.querySelector('.el-select.input-new-tag input').focus();
      });
    },
    /**
     * @description Sets z-index of navbar and app content to 0 to prevent styles from
     * interrupting with Filestack's mask transition when opening Filestack client.
     * @param {Boolean} setup - Flag to determine to setup z-index to 0 or back to
     * original values
     */
    setZIndex(setup) {
      // Save references
      if (!this.$content) this.$content = document.querySelector('.app-content');
      if (!this.$nav) this.$nav = document.querySelector('.nav');

      // Set z-index
      if (setup) {
        // Set z-index to 0
        if (this.$content) this.$content.style.zIndex = 0;
        if (this.$nav) this.$nav.style.zIndex = 1;
      } else {
        // Remove z-index
        if (this.$content) this.$content.style.removeProperty('z-index');
        if (this.$nav) this.$nav.style.removeProperty('z-index');
      }
    },
    initZingGrid() {
      if (this.zgRefContainer && !this.zgRef) {
        let columns = [{
          type: 'selector',
        }, {
          index: 'id',
          hidden: true,
          editor: false,
        }, {
          index: 'metadata',
        }, {
          index: 'value',
        }, {
          index: 'data',
        }, {
          type: 'remover',
        }];

        this.zgRef = new ZingGrid({
          caption: 'Metadata',
          compact: true,
          columns,
          data: JSON.stringify((this.metadata && this.metadata.length > 0) ? this.metadata : []),
          editor: 'modal',
          cellEditor: 'disabled',
          filter: true,
          layout: 'row',
          layoutControls: 'disabled',
          nodata: 'No Metadata',
          pager: true,
          pageSize: 10,
          search: true,
          sort: true,
        });

        // Event listners
        this.zgRef.addEventListener('data:record:delete', (e) => {
          this.deleteMetadataRecord(e.detail.ZGData.data);
        });
        this.zgRef.addEventListener('data:record:insert', (e) => {
          this.addMetadataRecord(e.detail.ZGData.data);
        });
        this.zgRefContainer.appendChild(this.zgRef);
      } else {
        if (this.zgRef) this.zgRef.setData(this.metadata);
      }
    },
    setupZingGrid() {
      this.zgRefContainer = this.$el.querySelector('[demo-settings="metadata"]');

      if (this.zgRefContainer) this.zgRef = this.zgRefContainer.querySelector('zing-grid');  
      this.$api('metadata/read', {
        uid: this.demo.uid,
        format: 'grid'
      }).then((result) => {
        this.metadata = result.data;
        this.initZingGrid();
      });
    },
    showSettings(curSetting) {
      return this.settings.includes('all') || this.settings.includes(curSetting);
    },
    /**
     * @description Update autocomplete settings when user makes changes to settings
     * @param {Object} node - node whose state changed. Object contains properties 'check', 'id', and 'label' of node.
     * @param {Boolean} val - node current state
     * @param {Boolean} subtree - subtree
     */
    updateAutoCompleteSettings(node, val, subtree) {
      if (node.id && this.autoComplete) {
        if (val) this.autoComplete.push(node.id);
        else this.autoComplete.splice(this.autoComplete.indexOf(node.id), 1);
        this.$api('user/update', {
          settings_autocomplete: this.autoComplete.toString(),
        })
        .then((response) => {
          this.$store.dispatch('user/refresh', this);
        })
        .catch((result) => {
          this.$message({
            duration: 10000,
            message: 'Unable to update autoComplete settings',
            showClose: true,
            type: 'error',
          });
        })
      }
    },
    /**
     * @description Update autoupdate settings when user makes changes to settings
     * @param {Boolean} val - new value of switch
     */
    updateAutoUpdateSettings(val) {
      this.$api('user/update', {
        settings_autoupdate: val === true ? 'true' : 'false',
      })
      .then((response) => {
        this.$store.dispatch('user/refresh', this);
      })
      .catch((result) => {
        this.$message({
          duration: 10000,
          message: 'Unable to update autoupdate settings',
          showClose: true,
          type: 'error',
        });
      })
    },
    /**
     * @description Update docs tooltip settings when user makes changes to settings
     * @param {Boolean} val - new value of switch
     */
    updateDocsToolipsSettings(val) {
      this.$api('user/update', {
        settings_docs: val === true ? 'true' : 'false',
      })
      .then((response) => {
        this.$store.dispatch('user/refresh', this);
      })
      .catch((result) => {
        this.$message({
          duration: 10000,
          message: 'Unable to update documentation hints settings',
          showClose: true,
          type: 'error',
        });
      })
    },
  }
}
</script>

<style scoped>
  [demo-settings="metadata__add"] {
    margin-top: 1rem;
  }

  .demo-control__item {
    width: fit-content;
  }

  section header {
    display: flex;
    align-items: center;
  }

  section header * + * {
    margin-left: 1rem;
  }

  .upload-dialog__file__thumbnail--image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 28px;
    margin-right: 0.5rem;
    width: 35px;
  }

  .upload-dialog__file__thumbnail--text {
    height: 28px;
    margin-right: 0.5rem;
    position: relative;
    right: 2px;
    width: 27px;
  }
</style>

<style>
  [demo-settings="metadata"] zing-grid {
    border: 0;
    margin-bottom: 1rem;
  }
  [demo-settings="metadata"] zing-grid zg-status {
    display: none;
  }
</style>