<template>
  <zingnet-card :grid-body=true>
    <span slot="header">Latest Active Users</span>
    <div slot="header__right">
      <span class="input-wrapper">
        <el-input
          @change="searchGrid"
          v-model="search"
          placeholder="Search"
          search>
        </el-input>
        <svg-icon class="el-input-icon" icon="search2" fill="var(--color-primary-gray)"></svg-icon>
      </span>
    </div>
    <div slot="body">
      <zing-grid 
        id="latestUsers"
        cell-class="renderNA"
        default-display="N/A"
        height=650
        layout="row"
        layout-controls="disabled"
        no-border
        pager
        page-size=10
        search
        selector
        sort>
        <zg-data>
          <!-- REST PARAMS -->
          <zg-param name="headers" :value="headerVal"></zg-param>
          <zg-param name="src" :value="srcValue"></zg-param>
          <!-- LOADBYPAGE PARAMS -->
          <zg-param name="countPath" value="total"></zg-param>
          <zg-param name="idKey" value="id"></zg-param>
          <zg-param name="loadByPage" value="true"></zg-param>
          <zg-param name="limitToKey" value="limit"></zg-param>
          <zg-param name="limitToValue" value="10"></zg-param>
          <zg-param name="pageKey" value="page"></zg-param>
          <zg-param name="recordPath" value="result"></zg-param>
          <zg-param name="searchKey" value="search"></zg-param>
          <zg-param name="sortBy" value='email'></zg-param>
          <zg-param name="sortByKey" value='sort'></zg-param>
          <zg-param name="sortDirKey" value='dir'></zg-param>
          <zg-param name="queryString" value='?exclude=zingsoft+pint'></zg-param>
        </zg-data>
        <!-- ZGColgroup -->
        <zg-colgroup>
          <zg-column index="email, name, id" header="User" renderer="renderUser"></zg-column>
          <zg-column index="last_login" header="Last Seen" type="date" renderer="renderDate" _type-date-from-now></zg-column>
          <zg-column index="last_ip" header="Last IP"></zg-column>
          <zg-column index="logins_count" header="# of Logins"></zg-column>
          <zg-column index="demo_count" header="# of Demos"></zg-column>
        </zg-colgroup>
      </zing-grid>
    </div>
  </zingnet-card>
</template>

<script>
import MixinDate from '../../../../mixins/date.js';
import MixinRenderer from '../../mixins/renderer.js';
import MixinSearch from '../../mixins/search.js';
import SvgIcon from '../../../../components/SvgIcon.vue';
import ZingnetCard from '../ZingnetCard.vue';

export default {
  components: {
    SvgIcon,
    ZingnetCard,
  },
  mixins: [MixinDate, MixinSearch, MixinRenderer],
  computed: {
    headerVal() {
      return `{"Authorization": "Bearer ${this.$store.state.auth.idToken}"}`;
    },
    srcValue() {
      return window ? `${window.location.origin}/api/user/list` : null;
    },
  },
  mounted() {
    this.registerRenderer('renderDate');
    this.registerRenderer('renderNA');
    this.registerRenderer('renderUser');
  },
}
</script>