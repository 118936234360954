/**
 * @description Mixin used for `Create.vue` to handle the UI of the studio.
 * This deals basically with resetting UI, handling toggle and resize of the code
 * and preview handles.
 */
export default {
  data() {
    return {
      currentlyResizing: null,
      cssSize: {
        height: 0,
        width: 0,
      },
      htmlSize: {
        height: 0,
        width: 0,
      },
      jsSize: {
        height: 0,
        width: 0,
      },
      previewSize: {
        height: 400,
        width: 0,
      },
      lastX: 0,
      lastY: 0,
      resize: {
        height: 10,
        width: 10,
      },
      showControls: ['html', 'css', 'js'],
      windowSize: document.body ? document.body.getBoundingClientRect().width : 0,
    };
  },
  computed: {
    codeActive() {
      return this.showHTML + this.showCSS + this.showJS;
    },
    cssStyle() {
      let style = {
        display: (this.showCSS) ? 'initial' : 'none',
        height: this.isMobile || this.layoutRow ? '100%' : this.cssSize.height + 'px',
        width: this.isMobile || this.layoutCol ? '100%' : this.cssSize.width + 'px',
      };
      return style;
    },
    htmlStyle() {
      let style = {
        display: (this.showHTML) ? 'initial' : 'none',
        height: this.isMobile || this.layoutRow ? '100%' : this.htmlSize.height + 'px',
        width: this.isMobile || this.layoutCol ? '100%' : this.htmlSize.width + 'px',
      };
      return style;
    },
    jsStyle() {
      let style = {
        display: (this.showJS) ? 'initial' : 'none',
        height: this.isMobile || this.layoutRow ? '100%' : this.jsSize.height + 'px',
        width: this.isMobile || this.layoutCol ? '100%' : this.jsSize.width + 'px',
      };
      return style;
    },
    layoutCol() {
      return this.editorLayout.indexOf('col') > -1;
    },
    layoutRow() {
      return this.editorLayout.indexOf('row') > -1;
    },
    previewStyle() {
      return {
        'min-height': `${this.previewSize.height}px`,
        'min-width': `${this.previewSize.width}px`,
      };
    },
    showHTML() {
      return this.showControls.includes('html');
    },
    showCSS() {
      return this.showControls.includes('css');
    },
    showJS() {
      return this.showControls.includes('js');
    },
    stateHtmlTab() {
      let settings = this.$store.state.user['settings_editor'] ? JSON.parse(this.$store.state.user['settings_editor']) : {};
      if (settings.tabsHtml) {
        return settings.tabsHtml === 'true' ? 'html' : null;
      } else {
        return 'html';
      };
    },
    stateCssTab() {
      let settings = this.$store.state.user['settings_editor'] ? JSON.parse(this.$store.state.user['settings_editor']) : {};
      if (settings.tabsCss) {
        return settings.tabsCss === 'true' ? 'css' : null;
      } else {
        return 'css';
      };
    },
    stateJsTab() {
      let settings = this.$store.state.user['settings_editor'] ? JSON.parse(this.$store.state.user['settings_editor']) : {};
      if (settings.tabsJs) {
        return settings.tabsJs === 'true' ? 'js' : null;
      } else {
        return 'js';
      };
    },
  },
  watch: {
    showControls() {
      this.resetPanes();
    },
  },
  mounted() {
    // UI Reset
    this.resetPanes();

    this.$resizerPreview = this.$el.querySelector('.editor__resizer--preview');
    this.$resizerPreview.addEventListener('touchstart', (e) => this.setActiveResizer('preview', e));
    window.addEventListener('touchend', this.untrackEvent);
    window.addEventListener('touchmove', this.calculateDragHandle);
    window.addEventListener('mouseup', this.untrackEvent);
    window.addEventListener('mousemove', this.calculateDragHandle);
    window.addEventListener('resize', this.handleResize);
    setTimeout(() => {
      const templateList = document.querySelector('.template__list');
      if (templateList) {
        this.templateListBottom = templateList ? templateList.getBoundingClientRect().bottom : 0;
        templateList.addEventListener('scroll', this.updateMoreTemplates);
      }
    }, 3000);

    this.setupCodePanes();
  },
  destroyed() {
    this.$resizerPreview.removeEventListener('touchstart', (e) => this.setActiveResizer('preview', e));
    window.removeEventListener('touchend', this.untrackEvent);
    window.removeEventListener('touchmove', this.calculateDragHandle);
    window.removeEventListener('mouseup', this.untrackEvent);
    window.removeEventListener('mousemove', this.calculateDragHandle);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    calculateDragHandle(event) {
      if (this.currentlyResizing) {
        switch(this.currentlyResizing) {
          case 'html':
            this.resizeCodepane(this.htmlSize, this.cssSize, this.jsSize, event);
          break;
          case 'css':
            this.resizeCodepane(this.cssSize, null, this.jsSize, event);
          break;
          case 'preview':
            this.resizePreviewpane(event);
          break;
        }
        this.lastX = this.setClientPos(event, 'x');
        this.lastY = this.setClientPos(event, 'y');
      }
      // Needs a preventDefault, otherwise mouseup events aren't always reliable.
      // Do not apply preventDefault to inputs and textarea because it disables highlighting
      // Do not apply to pre to not block code dragging
      if (event.type !== 'touchmove' && !(event.srcElement.tagName === 'INPUT'
        || event.srcElement.tagName === 'TEXTAREA'
        || event.srcElement.tagName === 'PRE')) event.preventDefault();
    },
    /**
     * @description Handle resize by triggering resize event to preview in preview pane,
     * reseting pane size, and getting current window size.
     */
    handleResize() {
      this.resizePreview();
      this.resetPanes();
      this.trackWindowSize();
    },
    /**
     * @description Maximize/show a code pane
     * @param {String} type - code pane
     */
    maximizeWindow(type) {
      this.showControls = [type];
    },
    /**
     * @description Setups the studio to display only HTML code
     */
    setupCodePanes() {
      if (this.isMobile) {
        this.showControls = 'html';
      } else {
        let currentSettings = [];
        ['Html', 'Css', 'Js'].forEach((setting) => {
          currentSettings.push(this[`state${setting}Tab`]);
          currentSettings = currentSettings.filter((el) => el);
        });
        this.showControls = currentSettings;
      };
    },
    /**
     * @description Display code pane
     * @param {String} type - code pane to display
     * @return {Object} opacity to show/hide code pane
     */
    showControlStyling(type) {
      return (this.showControls.includes(type)) ? {opacity: 1} : {opacity: 0};
    },
    /**
     * @description Reset size of code and preview panes
     */
    resetPanes() {
      if(this.$refs.editor) {
        // Caclulate code panel size
        const editorHeight = window.getComputedStyle(this.$refs.editor).height.slice(0, -2);
        const editorWidth = window.getComputedStyle(this.$refs.editor).width.slice(0, -2);
        const resizeActive = this.codeActive - 1;
        const resizeSize = resizeActive * (this.layoutCol ? this.resize.height : this.resize.width);
        const codeHeight = (editorHeight - resizeSize)/(this.showControls.length);
        const codeWidth = (editorWidth - resizeSize)/(this.showControls.length);

        // Reset code panel height
        this.$set(this.htmlSize, 'height', codeHeight);
        this.$set(this.cssSize, 'height', codeHeight);
        this.$set(this.jsSize, 'height', codeHeight);

        // Reset code panel width
        this.$set(this.htmlSize, 'width', codeWidth);
        this.$set(this.cssSize, 'width', codeWidth);
        this.$set(this.jsSize, 'width', codeWidth);
      }
    },
    /**
     * @description Resize pane width
     * @param {Object} paneSizeObj - pane size object
     * @param {Object} ps1 - paneSize object of first affected code pane
     * @param {Object} ps2 - paneSize object of second affected code pane
     * @param {Object} event - native drag event object
     */
    resizeCodepane(paneSizeObj, ps1, ps2, event) {
      const MIN_SIZE = 50;
      let last = this.layoutCol ? this.lastY : this.lastX;
      let client = this.layoutCol ?
        this.setClientPos(event, 'y') : this.setClientPos(event, 'x');
      let size = this.layoutCol ? 'height' : 'width';
      if (client < last) {
        // Calc html
        let delta = (last - client);
        let paneSize = (paneSizeObj[size] - delta);
        if (paneSize < MIN_SIZE) {
          return;
        }
        // Remove sizing from target
        this.$set(paneSizeObj, size, paneSize);
        // Add sizing to the right neighbor
        if (this.showCSS && ps1) {
          this.$set(ps1, size, (ps1[size] + delta));
        } else {
          this.$set(ps2, size, (ps2[size] + delta));
        }
      } else {
        const delta = (client - last);
        // Remove sizing from neighbors
        if (this.showCSS && ps1) {
          this.$set(ps1, size, (parseInt(ps1[size]) - delta));
        } else {
          this.$set(ps2, size, (parseInt(ps2[size]) - delta));
        }
        // Add sizing from neighbors
        this.$set(paneSizeObj, size, (parseInt(paneSizeObj[size])+ delta));
      }
    },
    /**
     * @description Resize preview inside preview pane
     */
    resizePreview() {
      let previewIframe = document.getElementById('preview');
      if (previewIframe) {
        previewIframe.contentWindow.postMessage('resize', '*');
      };
    },
    /**
     * @description Resize pane height
     * @param {Object} event - navtive drav event object
     */
    resizePreviewpane(event) {
      if (event) {
        let previewSize = parseInt(this.layoutCol ? this.previewSize.width : this.previewSize.height);
        let last = this.layoutCol ? this.lastX : this.lastY;
        let client = this.layoutCol ?
          this.setClientPos(event, 'x') : this.setClientPos(event, 'y');
        let size = this.layoutCol ? 'width' : 'height';
        if (client < last) {
          let delta = parseInt(last - client);
          let newSize = this.editorLayout === 'col-left'
            || this.editorLayout === 'row-top' ? previewSize - delta : previewSize + delta;
          this.$set(this.previewSize, size, newSize);
        } else {
          let delta = parseInt(client - last);
          let newSize = this.editorLayout === 'col-left'
            || this.editorLayout === 'row-top' ? previewSize + delta : previewSize - delta;
          this.$set(this.previewSize, size, newSize);
        }
      }
    },
    /**
     * @description Gets the clientX and clientY based off of the type of event. For touch events,
     * the x and y coordinates are located in 'touch[0]' property
     * @param {Object} event - native event object
     * @param {String} pos - position to get ('x', 'y')
     */
    setClientPos(event, pos) {
      let mouseType = ['mousedown', 'mousemove'];

      if (pos === 'x') {
        return mouseType.includes(event.type) ? event.clientX : event.touches[0].clientX;
      } else {
        return mouseType.includes(event.type) ? event.clientY : event.touches[0].clientY;
      }
    },
    /**
     * @description Triggered on 'mousedown' or 'touchstart' event to start resizing pane.
     * `this.currentlyResizing` is set to start resize event. The current mouse/touch location
     * is saved to later determine how much to resize pane.
     * @param {String} target - pane being resized
     * @param {Object} event - navtive event object
     */
    setActiveResizer(target, event) {
      this.currentlyResizing = target;
      this.lastX = this.setClientPos(event, 'x');
      this.lastY = this.setClientPos(event, 'y');
      if(target === 'html') {
        if (this.layoutRow)
          this.$set(this.htmlSize, 'width', window.getComputedStyle(this.$refs.codeHTML.$el).width.slice(0, -2));
      } else if (target === 'css') {
        if (this.layoutRow)
          this.$set(this.cssSize, '<width', window.getComputedStyle(this.$refs.codeCSS.$el).width.slice(0, -2));
      } else if (target === 'preview') {
        if (this.layoutCol)
          this.$set(this.previewSize, 'width', window.getComputedStyle(this.$refs.previewWrap).width.slice(0, -2));
        else
          this.$set(this.previewSize, 'height', window.getComputedStyle(this.$refs.previewWrap).height.slice(0, -2));
      }
    },
    /**
     * @description Get the size of the window
     */
    trackWindowSize() {
      this.windowSize = document.body ? document.body.getBoundingClientRect().width : 0;
    },
    /**
     * @description On control change, update `this.showControls`
     * @param {Array} option - option(s) selected
     */
    updateControls(option) {
      this.showControls = option;
    },
    /**
     * @description Triggered on 'mouseup' or 'touchend' event to track when 'mousemove' or
     * 'touchmove' event ends. This determines when resize is done.
     */
    untrackEvent() {
      this.currentlyResizing = null;
    },
  },
};
