<template>
  <div class="plan__list">
    
    <h4 v-if="updating">Update your domains to the subscription. You have <span v-html="countHTML"></span> free update(s) left.</h4>
    <h4 v-else>Add your domains to the subscription. This can also be modified at a later date.</h4>

    <!-- Domains -->
    <div class="plan-domains">
      <header class="plan-domains__header">
        <h4>Domains ({{domains.length}} / {{activePlanMaxDomains && activePlanMaxDomains === 'unlimited' ? 'unlimited' : `${activePlanMaxDomains} used`}}) - {{ optional ? 'optional' : 'requires at least one'}}</h4>
      </header>

      <div class="plan-domains__container" data-domain-scroll>
        <section class="plan-domains__list" v-for="(domain, index) in domains" :key="`domain${index}`">
          <div class="plan-domains__count">{{index + 1}}</div>
          <div class="plan-domains__url">{{getDomain(domain)}}</div>
          <div @click="removeNewDomain(domain)" class="plan-domains__delete"><font-awesome-icon :icon="['fas', 'trash-alt']" size="1x"/></div>
        </section>
      </div>

      <el-form @submit.native.prevent :model="form" ref="dialogDomainForm">
        <div class="plan-domains__add" v-show="activePlanMaxDomains && (domains.length < activePlanMaxDomains || activePlanMaxDomains === 'unlimited')">
          <el-form-item prop="dialogNewDomain" :rules="validateDomain()">
            <el-input type="text" v-model="form.dialogNewDomain" v-on:keyup.enter.native="handleDialogAddDomain(false)" autofocus></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleDialogAddDomain(false)" type="primary" size="mini">Add</el-button>
          </el-form-item>
        </div>
      </el-form>

      <!-- Subdomains -->
      <header class="plan-domains__header">
        <h4>Subdomains ({{subdomains.length}} / {{activePlanMaxSubdomains && activePlanMaxSubdomains === 'unlimited' ? 'unlimited' : `${activePlanMaxSubdomains} used`}}) - {{ optional ? 'optional' : 'requires at least one'}}</h4>
      </header>
      
      <div class="plan-domains__container" data-subdomain-scroll>
        <section class="plan-domains__list" v-for="(subdomain, index) in subdomains" :key="`subdomain${index}`">
          <div class="plan-domains__count">{{index + 1}}</div>
          <div class="plan-domains__url">{{getDomain(subdomain)}}</div>
          <div @click="removeNewDomain(subdomain, true)" class="plan-domains__delete"><font-awesome-icon :icon="['fas', 'trash-alt']" size="1x"/></div>
        </section>
      </div>

      <el-form @submit.native.prevent :model="form" ref="dialogSubdomainForm">
        <div class="plan-domains__add" v-show="activePlanMaxSubdomains && (subdomains.length < activePlanMaxSubdomains || activePlanMaxSubdomains === 'unlimited')">
          <el-form-item prop="dialogNewSubdomain" :rules="validateSubdomain()">
            <el-input type="text" v-model="form.dialogNewSubdomain" v-on:keyup.enter.native="handleDialogAddDomain(true)" autofocus></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleDialogAddDomain(true)" type="primary" size="mini">Add</el-button>
          </el-form-item>
        </div>
      </el-form>

      <!-- Warning Alerts -->
      <section class="plan-alerts">
        <el-alert
          v-if="exceedDomainLimit"
          title="Number of domains exceeded"
          description="Please remove domain(s) or upgrade license."
          :closable="false"
          type="error">
        </el-alert>

        <el-alert
          v-if="exceedSubdomainLimit"
          title="Number of subdomains exceeded"
          description="Please remove subdomain(s) or upgrade license."
          :closable="false"
          type="error">
        </el-alert>

        <el-alert
          v-if="exceedDomainUpdate"
          title="Number of updated exceeded"
          description="Please contact support@zingsoft.com to request further domain updates."
          :closable="false"
          type="error">
        </el-alert>

        <el-alert
          v-if="notSubdomain"
          title="Not a subdomain"
          :description="`${subdomain} is not a subdomain of given domain(s).`"
          :closable="false"
          type="error">
        </el-alert>

        <el-alert
          v-if="missingDomains"
          title="Please register a domain"
          description="It is required to register at least one domain."
          :closable="false"
          type="warning">
        </el-alert>
      </section>
    </div>

    <footer class="plan-dialog__footer">
      <el-button @click="cancelUpdateDomains" type="success"  plain>{{cancelText}}</el-button>
      <el-button @click="updateDomains"
        type="success"
        :disabled="disable || exceedDomainLimit || exceedSubdomainLimit || exceedDomainUpdate || missingDomains || notSubdomain"
        :loading="loading">
        {{submitText}}</el-button>
    </footer>

  </div>
</template>

<script>
  import axios from 'axios';
  import Domain from '../../../plugins/domain.js';
  import MixinAutofillDomain from '../../../mixins/autofillDomain.js';

  export default {
    mixins: [MixinAutofillDomain],
    props: {
      cancelText: String,
      disable: Boolean,
      domains: Array,
      form: Object,
      activePlan: Object,
      optional: Boolean,
      subdomains: Array,
      subscription: Object,
      submitText: String,
      updating: Boolean,
      visible: Boolean,
    },
    data() {
      return {
        loading: false,
        subdomain: null,
        toAdd: [],
        toRemove: [],
      };
    },
    computed: {
      activePlanMaxDomains() {
        return this.activePlan ? this.activePlan.metadata.max_domains
          : (this.subscription ? this.subscription.maxDomains : 0);
      },
      activePlanMaxSubdomains() {
        return this.activePlan ? this.activePlan.metadata.max_subdomains
          : (this.subscription ? this.subscription.maxSubdomains : 0);
      },
      clearAfterClose() {
        this.clearState();
        return this.visible;
      },
      exceedDomainLimit() {
        return this.domains.length > parseInt(this.activePlanMaxDomains);
      },
      exceedSubdomainLimit() {
        return this.subdomains.length > parseInt(this.activePlanMaxSubdomains);
      },
      exceedDomainUpdate() {
        if (this.subscription && this.subscription.plan.type === 'ZG' && this.subscription.plan.product === 'Multi-Domain') {
          // ZingGrid allowed unlimited updates
          return false;
        } else {
          // ZingChart license capped to at most 2 updates
          return this.updateCount >= 2;
        }
      },
      missingDomains() {
        // Skip check if optional
        if (this.optional) return false;
        return this.domains.length === 0;
      },
      notSubdomain() {
        // Early exit if given subdomain with no domains or not given any subdomain
        if ((this.subdomains.length === 0)  // not given any subdomain
          || (this.domains.length === 0 && this.subdomains.length > 0)) { // given subdomain with no domains
          this.subdomain = typeof this.subdomains[0] === 'object' ? this.subdomains[0].domain : this.subdomains[0];
          return false;
        }

        // Check if each subdomain is a subdomain to at least one given domain
        for (let i = 0; i < this.subdomains.length; i++) {
          let subdomain = typeof this.subdomains[i] === 'object' ? this.subdomains[i].domain : this.subdomains[i];
          for (let j = 0; j < this.domains.length; j++) {
            let domain = typeof this.domains[j] === 'object' ? this.domains[j].domain : this.domains[j];
            if (subdomain.endsWith(`.${domain}`)) {
              this.subdomain = subdomain;
              return false;
            }
          }
        }
        return true;
      },
      updateCount() {
        if (this.subscription) {
          let updateCounts = this.subscription.domains.map((domain) => domain.update ? parseInt(domain.update) : 0);
          let max = updateCounts && updateCounts.length > 0 ? Math.max(...updateCounts) : 0;
          return max;
        }
      },
      countHTML() {
        // ZingGrid license has unlimited updates while ZingChart is capped
        console.log(this.subscription)
        let count = this.subscription && this.subscription.plan.type === 'ZG' && this.subscription.plan.product === 'Multi-Domain' ? 'unlimited' : 2 - this.updateCount;
        let color = count === 0 ? 'var(--color-secondary-1)' : 'green';
        return `<span style="color: ${color}">${count}</span>`;
      }
    },
    methods: {
      /**
       * @description Run when "cancel" button clicked. Emit cancel event, clears data,
       * and clear validation errors.
       */
      cancelUpdateDomains() {
        this.clearData();
        this.clearValidationErrors();
        this.$refs['dialogSubdomainForm'].clearValidate();
        this.$emit('cancel-action');
      },
      /**
       * @description Cleans form data
       */
      clearData() {
        this.loading = false;
        this.toAdd = [];
        this.toRemove = [];
      },
      clearState() {
        this.clearData();
        this.clearValidationErrors();
      },
      clearValidationErrors() {
        this.$refs['dialogDomainForm'].clearValidate();
        this.$refs['dialogSubdomainForm'].clearValidate();
      },
      /**
       * @description Displays domain. `domain` can either be an object or string type. If it is type string,
       * then the domain is about to add added to the license. Else if type object, the domain is already
       * associated to license.
       * @param {Object|String} domain - domain object or domain
       */
      getDomain(domain) {
        return typeof domain === 'object' ? domain.domain : domain;
      },
      _validateAndAddDomain(sub) {
        // Determine if adding domain or subdomain
        let form = sub ? 'dialogSubdomainForm' : 'dialogDomainForm';

        this.$refs[form].validate((valid) => {
          // Validate domain/subdomain
          let formField = sub === true ? 'dialogNewSubdomain' : 'dialogNewDomain';
          let arr = sub === true ? 'subdomains' : 'domains';
          if (valid && this.form[formField] !== '') {
            // Add domain/subdomain
            this[arr].push(this.form[formField]);
            this.toAdd.push({
              domain: this.form[formField], 
              sub,
            });
            // Clear field
            this.form[formField] = '';
            // Scroll to bottom of container to display added domain/subdomain
            setTimeout(() => {
              let target = this.$el.querySelector(`[data-${sub ? 'subdomain' : 'domain'}-scroll]`);
              target.scrollTop = target.scrollHeight;
            }, 0);
          }
        });
      },
      /**
       * @description Add domain in display after verify url is valid. Does not add to database yet.
       * After form submission and `update` is true, domains will be added to database.
       * Always scroll to the bottom of the container to display newly added domain/subdomain.
       * @param {Boolean} sub - adding subdomain
       */
      handleDialogAddDomain(sub) {
        // For subdomain, check if domain field filled
        let domainFieldEmpty = this.domains.length === 0;

        if (sub && domainFieldEmpty) {
          // When adding subdomain and domain is empty, automatically add domain
          this.autofillDomain({
            refDomain: 'domains',
            refForm: 'form',
            refSubdomain: 'dialogNewSubdomain'
          });
          this.$nextTick(() => {
            this._validateAndAddDomain(sub);
          });
        } else {
          // Add subdomain/domain regularly
          this._validateAndAddDomain(sub);
        } 
      },
      /**
       * @description Remove domain from display. Does not remove from database yet.
       * After form submission and `update` is true, domains will be removed from database.
       * @param {Object|String} domainToRemove - domain to remove from view
       * @param {Boolean} sub - flag indicating that domain is a subdomain
       */
      removeNewDomain(domainToRemove, sub) {
        // Save reference to remove from `license` table
        if (domainToRemove.id) {
          this.toRemove.push(domainToRemove);
        } else {
          // Remove newly added domain from `toAdd`
          this.toAdd = this.toAdd.filter((domain) => domain.domain !== domainToRemove);
        }
        // Remove from display
        this.$emit('remove-domain', domainToRemove, sub);
      },
      /**
       * @description After form submission, if `update` is true, adds and/or remove domains
       * from database. Then emits event to perform action after formm submit.
       */
      updateDomains() {
        // Updating license table
        if (this.updating) {
          // Set `loading` attribute
          this.loading = true;
          // Add to database
          axios({
            url: '/api/subscription/domain/update',
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + this.$store.state.auth.idToken },
            data: {
              toAdd: this.toAdd,
              toRemove: this.toRemove,
              quickbooks_invoice_id: this.subscription.id,
            },
            json: true,
          }).then((response) => {
            this.toAdd = [];
            this.toRemove = [];
            this.$message({
              message: 'Domains in license updated!',
              showClose: true,
              type: 'success',
            });

            // Execute action after submit
            this.$emit('submit-action');
          }).catch((error) => {
            this.loading = false;
            this.$message({
              duration: 10000,
              message: error.response.data,
              showClose: true,
              type: 'error',
            });
          });
        } else {
          this.$emit('submit-action');
        }
      },
      /**
       * @description Validates domain to check if it is a valid url or contains accepted format (*.domain.com).
       */
      validateDomain() {
        return [
          {pattern: Domain.regexDomain, trigger: 'blur', message: Domain.messageDomain},
        ];
      },
      /**
       * @description Validates subdomain to check if it is a valid url or contains accepted format (*.domain.com), and is a subdomain of provided domain.
       */
      validateSubdomain() {
        let domains = this.domains.concat(this.toAdd.filter((domain) => !domain.sub));
        return [
          {pattern: Domain.regexSubdomain, trigger: 'blur', message: Domain.messageSubdomain},
          {pattern: Domain.regexSubdomainCheck(domains), trigger: 'blur', message: Domain.messageSubdomainCheck(domains)}
        ];
      },
    },
  }
</script>

<style scoped>
  .plan-alerts {
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
  }

  .plan-alerts .el-alert {
    margin-bottom: 0.5rem;
  }

  .plan-domains {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }

  .plan-domains__container {
    max-height: 165px;
    overflow: auto;
  }

  .plan-domains__list {
    align-items: center;
    background: white;
    border-radius: 5px;
    display: flex;
    font-size: 0.9rem;
    font-weight: 500;
    height: auto;
    justify-content: start;
    margin-bottom: 5px;
    position: relative;
    width: 100%;
  }

  .plan-domains__count {
    background: var(--color-primary-1);
    border-radius: 5px 0 0 5px;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: relative;
    padding: 0.5rem;
  }
  .plan-domains__delete {
    padding: 0.5rem;
    border-radius: 0 5px 5px 0;
    color: var(--color-secondary-1);
    cursor: pointer;
  }

  .plan-domains__add {
    display: flex;
  }
  .plan-domains__add .el-form-item {
    flex: 1;
    flex-basis: 100%;
    flex-shrink: 0;
    margin-bottom: 2.5rem;
  }
  .plan-domains__add .el-button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    height: 1.75rem;
    left: -56px;
    position: absolute;
    width: 55px;
    top: 6px;
  }

  .plan-domains__header,
  .plan-dialog__footer {
    display: flex;
  }

  .plan-domains__url {
    flex: 1;
    padding: 0.5rem 0 0.5rem 1rem;
  }

  .plan__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex:1;
    padding: 1rem 0;
    width: 100%;
    word-break: break-word;
  }

  @media screen and (min-width: 512px) {
    .plan-domains {
      width: 60%;
    }
  }
</style>

<style>
  .plan-domains__add .el-form-item .el-input__inner {
    padding-right: 4rem;
  }
</style>