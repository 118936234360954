<template>
  <el-dialog
    @close="triggerClose"
    domain-modal
    license-modal
    :visible="visible"
    width="1200px">

    <div slot="title">
      <p class="title">Create License</p>
    </div>

    <el-form v-model="licenseForm">
      <div class="flex">
        <div class="column__left">
          <section>
            <p class="zingnetCard--header required">Type</p>
            <el-select v-model="licenseForm.type">
              <el-option
                v-for="type in licenseTypes"
                :key="type"
                :label="type"
                :value="type"></el-option>
            </el-select>
          </section>
          <section>
            <p class="zingnetCard--header required">Plan</p>
            <el-select v-model="licenseForm.plan" filterable large>
              <el-option
                v-for="license in licenseOptions"
                :key="license"
                :label="license"
                :value="license"></el-option>
            </el-select>
          </section>
          <section v-if="isAnnualLicense">
            <p class="zingnetCard--header required">Expires</p>
            <el-date-picker v-model="licenseForm.expires" type="date"></el-date-picker>
          </section>
          <section>
            <p class="zingnetCard--header required">User</p>
            <p>Provide user's Studio ID or email, or select "Admin Account" option.</p>
            <el-input placeholder="Please input" v-model="licenseForm.user" class="input-with-select">
              <el-select @change="updateUserInput" v-model="userInput" slot="prepend" placeholder="Select">
                <el-option key="user" label="Studio Email / ID" value="user"></el-option>
                <el-option key="admin" label="Admin Account" value="admin"></el-option>
              </el-select>
            </el-input>
          </section>
          <section v-if="isAdminAccount">
            <p class="zingnetCard--header required">Customer ID</p>
            <p>Provide user's
              <el-popover
                placement="top-start"
                trigger="hover">
                <p>Can be found in Quickbooks customer url: <br/> 
                <code>/customerdetail?nameId=CUSTOMER_ID</code></p>
                <el-button type="text" slot="reference">Quickbooks Customer ID</el-button>
              </el-popover>.
            </p>
            <el-input v-model="licenseForm.id_quickbooks" placeholder="Customer ID"></el-input>
          </section>
          <section v-if="!isAdminAccount">
            <p class="zingnetCard--header">Quickbooks ID</p>
            <p>Link Studio account by providing 
              <el-popover
                placement="top-start"
                trigger="hover">
                <p>Can be found in Quickbooks customer url: <br/> 
                <code>/customerdetail?nameId=CUSTOMER_ID</code></p>
                <el-button type="text" slot="reference">Quickbooks Customer ID</el-button>
              </el-popover>.
            </p>
            <el-input v-model="licenseForm.id_quickbooks"></el-input>
          </section>
          <section>
            <p class="zingnetCard--header required">Invoice ID</p>
            <p>Provide  
             <el-popover
              placement="top-start"
              trigger="hover">
              <p>Can be found in Quickbooks invoice url: <br/> 
              <code>/invoice?txnId=INVOICE_ID</code></p>
              <el-button type="text" slot="reference">Quickbooks invoice ID</el-button>
            </el-popover>.
            <el-input v-model="licenseForm.quickbooks_invoice_id"></el-input>.
          </p>
          </section>
        </div>

        <div class="column__right">
          <section v-if="isOemLicense">
            <p class="zingnetCard--header required">Company Name</p>
            <el-input v-model="licenseForm.companyName"></el-input>
          </section>
          <section v-if="!isOemLicense">
            <p class="zingnetCard--header required">Register Domains</p>
            <el-form-item
              v-for="(domain, index) in licenseForm.domains"
              class="flex"
              :key="domain.key"
              :prop="'domains.' + index + '.value'">
              <el-input v-model="domain.domain" class="input-with-append">
                <el-checkbox v-model="domain.sub" label="Subdomain" size="medium" slot="append"></el-checkbox>
              </el-input>
              <el-button @click.prevent="removeDomain(domain)" type="danger" icon="el-icon-delete"></el-button>
            </el-form-item>
            <div>
              <el-button @click="addDomain">Add domain</el-button>
              <el-button @click="resetDomain">Reset domain</el-button>
            </div>
          </section>
        </div>
      </div>
    </el-form>

    <div slot="footer">
      <el-button @click="createLicense" :disabled="disable">Create License</el-button>
    </div>
  </el-dialog>
</template>

<script>

import axios from 'axios';
import Callout from '../Callout.vue';

export default {
  components: {
    Callout,
  },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      disable: false,
      userInput: 'user',
      licenseForm: {
        type: null,
        id_quickbooks: null,
        companyName: null,
        domains: [{ key: 1, domain: '', sub: false }],
        expires: null,
        quickbooks_invoice_id: null,
        plan: null,
        user: null,
        update: 0,
      },
      licenses: ['New/Annual:ZC-Enterprise-A', 'New/Annual:ZC-Internal-A', 'New/Annual:ZC-OEM-A', 'New/Annual:ZC-SaaS-A', 'New/Annual:ZC-Website-A', 'New/Annual:ZG-Multi-Domain-A', 'New/Annual:ZG-OEM-A', 'New/Annual:ZG-Single-Domain-A', 'Perpetual:ZC-Enterprise-P', 'Perpetual:ZC-Internal-P', 'Perpetual:ZC-Saas-P', 'Perpetual:ZC-Website-P', 'Perpetual:ZG-Multi-Domain-P', , 'Perpetual:ZG-Single-Domain-P'],
      licenseTypes: ['ZingChart', 'ZingGrid'],
    };
  },
  computed: {
    isAdminAccount() {
      return this.licenseForm.user == 2 || this.licenseForm.user === 'admin@zingsoft.com' || this.userInput === 'admin';
    },
    isAnnualLicense() {
      if (this.licenseForm.plan) {
        return this.licenseForm.plan.includes('-A');
      } else {
        this.licenseForm.expires = null;
      };
      return false;
    },
    isOemLicense() {
      return this.licenseForm.plan && this.licenseForm.plan.includes('OEM');
    },
    licenseOptions() {
      if (this.licenseForm.type) {
        let zingchartLicenses = this.licenses.filter((license) => license.includes(':ZC-'));
        let zinggridLicenses = this.licenses.filter((license) => license.includes(':ZG-'));
        return this.licenseForm.type.toLowerCase() === 'zingchart' ? zingchartLicenses : zinggridLicenses;
      };
      return [];
    },
    showLicenseField() {
      if (this.licenseForm.type) {
        let isZingChartLicense = this.licenseForm.type.toLowerCase() === 'zingchart';
        let isNotOEM = !this.licenseForm.type.includes('-OEM-');
        return isZingChartLicense && isNotOEM;
      } else {
        this.licenseForm.license = null;
      };
      return false;
    },
  },
  methods: {
    clearUserInput() {
      this.disable = false;
      this.userInput = 'user',
      this.licenseForm.type = null;
      this.licenseForm.id_quickbooks = null;
      this.licenseForm.companyName = null;
      this.licenseForm.domains = [{ key: 1, domain: '', sub: false }],
      this.licenseForm.expires = null;
      this.licenseForm.quickbooks_invoice_id = null;
      this.licenseForm.plan = null;
      this.licenseForm.user = null;
      this.licenseForm.update = 0;
    },
    createLicense() {
      this.disable = true;
      // Validate
      if (this.validateForm()) {
        // Process license request
        this.processRequest();
      };
    },
    processRequest() {
      // Format date into form that database can process
      if (this.licenseForm.expires) {
        // Format date as YYYY-MM-DD hh:mm:ss
        let date = new Date(this.licenseForm.expires);
        this.licenseForm.expires =  date.toISOString().replace('T', ' ').replace('.000Z', '');
      };

      let promises = this.licenseForm.companyName
        ? [this.licenseForm.companyName]
        : this.licenseForm.domains;

      Promise.all(
        promises.map(async (domain) => {
          if (domain && domain.domain !== '') {
            let body = JSON.parse(JSON.stringify(this.licenseForm));

            // Associate to user
            body[body.user.includes('@') ? 'email' : 'id_user'] = body.user;
            if (body.id_user) body.id_user = parseInt(body.id_user);

            if (body.domains) {
              // Registered domain/subdomains
              body.domain = domain.domain;
              body.sub = domain.sub;
            };

            // Removed unused fields
            if (body.domains) delete body.domains;
            delete body.user;
            
            return await this.createLicenseRequest(body);
          };
        })
      ).then(() => {
        // Success message
        this.$message({
          message: 'License Created!',
          type: 'success',
        });
        this.$nextTick(() => {
          this.disable = false;
          this.triggerClose();
        });
      });
    },
    createLicenseRequest(request) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/license',
          method: 'POST',
          headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
          data: request,
        }).then(() => {
          resolve(true);
        }).catch((err) => {
          this.disable = false;
          // Error message
          let errMessage = `Error occurred while creating license: ${err}`;
          this.$message({
            message: errMessage,
            type: 'error',
          });
          reject(false);
        });
      });
    },
    triggerClose() {
      this.clearUserInput();
      this.$emit('close');
    },
    updateUserInput(val) {
      this.licenseForm.user = val === 'admin' ? 'admin@zingsoft.com' : '';
    },
    validateForm() {
      let err = null;
      let nonEmptyDomain = this.licenseForm.domains.filter((domain) => domain.domain !== '');

      if (!this.licenseForm.type) {
        err = 'Missing license type';
      } else if (!this.licenseForm.plan) {
        err = 'Missing license plan';
      } else if (!this.licenseForm.user) {
        err = 'Missing user email or ID'
      } else if (!this.licenseForm.id_quickbooks && this.isAdminAccount) {
        err = 'Missing user\'s Quickbooks ID'
      } else if (!this.licenseForm.quickbooks_invoice_id) {
        err = 'Missing Quickbooks invoice ID';
      } else if (this.isAnnualLicense && !this.licenseForm.expires) {
        err = 'Missing license expiration date';
      } else if (nonEmptyDomain.length === 0 && !this.licenseForm.companyName) {
        err = 'Missing domain or company name to regsiter';
      };

      if (err) {
        this.disable = false;
        this.$message({
          message: err,
          type: 'error',
        });
        return false;
      };
      return true;
    },
    removeDomain(item) {
      let index = this.licenseForm.domains.indexOf(item);
      if (index !== -1) {
        this.licenseForm.domains.splice(index, 1);
      }
    },
    addDomain() {
      this.licenseForm.domains.push({
        key: Date.now(),
        domain: '',
        sub: false,
      });
    },
    resetDomain() {
      this.licenseForm.domains = [{ key: 1, domain: '', sub: false }];
    },
  },
}
</script>

<style scoped>
  .flex {
    display: flex;
  }
  .flex > div {
    padding: var(--domain-modal-padding);
    width: 50%;
  }
  .flex > div + div {
    border-left: var(--zingnet-border);
  }
  section + section {
    margin-top: 1.875rem;
  }
</style>

<style>
  :root {
    --domain-modal-padding: 1.75rem 1.875rem;
  }

  [domain-modal] .el-dialog {
    border-radius: 10px;
    padding-right: 0 !important;
  }

  [domain-modal] .el-dialog__header {
    border-bottom: var(--zingnet-border);
    padding: var(--domain-modal-padding) !important;
  }
  [domain-modal] .title {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }

  [domain-modal] .el-dialog__body {
    color: var(--color-primary-gray);
    font-size: 0.9375rem;
    padding: 0;
  }

  [domain-modal] .el-dialog__footer {
    border-top: var(--zingnet-border);
    padding: var(--domain-modal-padding) !important;
  }

  [domain-modal] .zingnetCard--header {
    display: block;
  }

  [domain-modal] .el-input + .el-input {
    margin-top: 1rem;
  }
  [domain-modal] .el-select {
    width: 100%;
  }
  [license-modal] .el-button {
    margin-top: 0;
  }

  [license-modal] .el-select ~ .el-input {
    margin-top: 1rem;
  }

  .zingnetCard [license-modal] .el-date-editor {
    padding: 0;
  }

  .zingnetCard [license-modal] .el-date-editor input {
    padding: 1.375rem 2.063rem;
  }

  .zingnetCard [license-modal] .el-input__prefix {
    top: 3px;
  }
</style>