<template>
  <default-layout>
    <section class="content--narrow">
      <!-- Profile -->
      <header class="content__header">
        <h2 class="header__title">Profile</h2>
        <div class="header__items default__setting">
          <h4>{{isMobile ? 'Default' : 'Default Homepage'}}: </h4>
          <el-radio-group v-model="defaultHomepage" @change="updateReferrer" size="mini">
            <el-radio-button label="zingchart">{{isMobile ? 'ZC' : 'ZingChart'}}</el-radio-button>
            <el-radio-button label="zinggrid">{{isMobile ? 'ZG' : 'ZingGrid'}}</el-radio-button>
          </el-radio-group>
        </div>
      </header>

      <section class="form__collection form__collection--reverse">
        <div class="form__col">
          <div class="form__row">
            <name-field @change="bufferProfile" :value="name"></name-field>
          </div>
          <div class="form__row">
            <company-field @change="bufferProfile" :value="company"></company-field>
          </div>
          <div class="form__row">
            <phone-field @change="bufferProfile" :value="phone"></phone-field>
          </div>
          <div class="form__row">
            <location-field @change="bufferProfile" :value="location"></location-field>
          </div>
          <div class="form__row">
            <billing-field @change="bufferProfile" :value="billing"></billing-field>
          </div>
          <div class="form__row">
            <el-button :disabled="isProfileDirty" @click="updateUserInfo" type="primary">Save Profile</el-button>
          </div>
        </div>
        <div class="form__col">
          <!-- <avatar></avatar> -->
        </div>
      </section>

      <!-- Authentication -->
      <header class="content__header">
        <h2>Authentication</h2>
      </header>
      <section class="form__collection form__collection--reverse">
        <div class="form__col">
          <div class="form__row">
            <!-- EMAIL ADDRESS -->
            <email-form
              @verify="emailVerification"
              @change="bufferEmail"
              :value="userEmail">
            </email-form>
          </div>
          <div class="form__row">
            <el-button :disabled="isUsernameOrEmail" @click="delegateAuthCalls" type="primary">Change E-mail</el-button>
          </div>
        </div>
        <div class="form__col">
        <!-- <social-form :value="identities"></social-form> -->
        </div>
      </section>
      <header class="content__header">
        <h2>Password</h2>
      </header>
      <!-- PASSWORD -->
      <password-form @change="passwordUpdate" :email="this.userEmail"></password-form>
      <!--  DELETE ACCOUNT -->
      <!-- <div class="delete-field">
        <a href="#" @click="$message('Please email support@zingsoft.com to delete your account!')">Delete Your Account</a>
      </div> -->

      <!-- BILLING -->
      <header class="content__header">
         <h2 class="header__title">Billing</h2>
      </header>
      <credit-card></credit-card>

      <!-- API KEY -->
      <api-key :adminAccess.sync="adminAccess"></api-key>

      <account-settings :adminAccess.sync="adminAccess"></account-settings>
    </section>

  </default-layout>
</template>

<script>
  import AccountSettings from './components/AccountSettings.vue';
  import Avatar from './components/Avatar.vue';
  import DeleteForm from './components/Delete.vue';
  import EmailForm from './components/Email.vue';
  import PasswordForm from '../../components/passwordField.vue'

  import SocialForm from './components/Social.vue';

  import NameField from './components/Name.vue';
  import LocationField from './components/Location.vue';
  import PhoneField from './components/PhoneNumber.vue';
  import CompanyField from './components/Company.vue';
  import BillingField from './components/AdditionalBilling.vue';

  import ApiKey from './components/ApiKey.vue';
  import CreditCard from './components/CreditCard.vue';

  import DefaultLayout from '../layouts/Default.vue';
  import { Loading } from 'element-ui';
  import axios from 'axios';
  import MixinPermissions from '../../mixins/permissions';

  export default {
    components: {
      Avatar,
      AccountSettings,
      'name-field': NameField,
      'location-field': LocationField,
      'company-field': CompanyField,
      'phone-field': PhoneField,
      'billing-field': BillingField,

      'delete-form': DeleteForm,
      'email-form': EmailForm,
      'password-form': PasswordForm,
      'social-form': SocialForm,
      'api-key': ApiKey,
      'credit-card': CreditCard,

      'default-layout': DefaultLayout,
    },
    mixins: [MixinPermissions],
    data() {
      return {
        defaultHomepage: this.$store.state.user['referrer'].toLowerCase(),
        email: {
          old: null,
          update: null,
        },
        profile: null,
        password: null,
        
        // Prefill user data
        name: null,
        company: null,
        phone: null,
        location: null,
        billing: null,
        identities: null,
        userEmail: null,
      };
    },
    asyncComputed: {
      adminAccess() {
        return this.checkPermission('admin_dashboard_view');
      },
    },
    computed: {
      user_id() { 
        return this.$store.state.user.user_id;
      },
      isMobile() {
        return this.$store.getters['ui/isMobile'];
      },
      isPassword() {
        return (this.showPassword) ? 'text' : 'password';
      },
      isProfileDirty() {
        return !this.profile;
      },
      isUsernameOrEmail() {
        return (this.email.update === null) || (this.email.update === this.email.old) || this.email.update === '';
      }
    },
    beforeCreate() {
      // Fetch data to prefill fields
      axios({
        url: '/api/user',
        method: 'GET',
        headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
      }).then((data) => {
        let userData = data.data || '';
        this.name = userData.name || '';
        this.company = userData.company || '';
        this.phone = userData.phone_number || '';
        this.location = userData.location || '';
        this.billing  = userData.billing || '';
        this.identities = userData.identities || '';
        this.userEmail = userData.email || '';
      });
    },
    methods: {
      bufferProfile(obj) {
        this.profile = this.profile || {};
        Object.assign(this.profile, obj);
      },
      bufferEmail(value) {
        if (!this.email.old) this.email.old = value;
        this.email.update = value;
      },
      bufferPassword(value) {
        this.password = value;
      },
      delegateAuthCalls() {
        this.emailUpdate(this.email.update);
      },
      updateUserInfo() {
        this.$api('user/update', this.profile)
          .then((res) => {
            this.$store.dispatch('refresh_state');
            this.$message({
              message: 'User updated!',
              showClose: true,
              type: 'success',
            });
          })
          .catch((err) => {
            this.$message({
              duration: 10000,
              message: 'Could not update the user',
              showClose: true,
              type: 'error',
            });
          });
      },
      // EMAIL
      emailVerification() {
        this.$api('email/resend', {slug: this.user_id})
          .then((res) => {
            this.$store.dispatch('Verification email sent!');
            this.$message({
              message: 'Email verifiication sent!!',
              showClose: true,
              type: 'success',
            });
          })
          .catch((err) => {
            this.$message({
              duration: 10000,
              message: 'Could not send the verification email',
              showClose: true,
              type: 'error',
            });
          });
      },
      emailUpdate(newEmail) {
        Loading.service({
          background: 'rgba(255,255,255,0)',
        });
        this.$api('user/update', {
          email: newEmail,
        })
          .then((response) => {
            this.$message({
              message: 'Email updated. Please check your email to verify.',
              showClose: true,
              type: 'success',
            });
            this.$store.dispatch('user/refresh', this);
            this.email.old = newEmail;

            // Add timestamp to invalidate sessions before email change
            this.$api('user/update', {
              email: newEmail,
              session_expiration: new Date().getTime(),
            }).then(() => {
              Loading.service({
                background: 'rgba(255,255,255,0)',
              });
              // Refresh token to update auth init
              this.$store.dispatch('refresh_tokens');
            });
          })
          .catch((error) => {
            this.$message({
              duration: 10000,
              message: error.response.data.message ? error.response.data.message : 'Unable to update email',
              showClose: true,
              type: 'error',
            });
          });
      },
      /**
       * @description When user updates "Default Homepage", update field referrer in database. This field
       * is used to load default view (ZingChart / ZingGrid).
       * @param {String} val - update values of state 'referrer'
       */
      updateReferrer(val) {
        this.$api('user/update', {
          signup_url: val,
        })
        .then((response) => {
          this.$store.dispatch('user/refresh', this);
        })
        .catch((result) => {
          this.$message({
            duration: 10000,
            message: 'Unable to update default homepage',
            showClose: true,
            type: 'error',
          });
        });
      },
      // Add timestamp to invalidate sessions before password change
      passwordUpdate(newPassword) {
        this.$api('user/update', {
          password: newPassword,
          session_expiration: new Date().getTime(),
        })
          .then((response) => {
            this.$message({
              message: 'Password updated.',
              showClose: true,
              type: 'success',
            });
            this.$store.dispatch('user/refresh', this)
          })
          .catch((result) => {
            this.$message({
              duration: 10000,
              message: 'Password could not be updated',
              showClose: true,
              type: 'error',
            });
          });
      },
    }
  };
</script>

<style scoped>
  .content--narrow { margin-bottom: 5rem; }
  .default__setting { display: flex; align-items: center; }
  .default__setting h4 { margin: 1rem 0.5rem 0.5rem; }
  .flex__container { align-items: center; display: flex; justify-content: space-between; }
  .el-radio-group { margin: 1rem 0 0.5rem; }
</style>