import axios from 'axios';
import { query } from 'winston';

/**
 * Api takes two parameters: 
 * 1. {String} - api object + '/' action
 * 2. {Object} - has the properties slug and data
 * 
 * Use cases:
 * const demo = {
 *   html: ‘’,
 *   css: ‘’
 *   ....
 * }
 * uid = SDFDSFW23
 *
 * ----------------------------
 * 
 * POST /api/demo
 * this.$api(‘demo/create’, {
 *   data: demo,
 * })
 * 
 * ----------------------------
 * 
 * PATCH  /api/demo/:slug
 * this.$api(‘demo/update’, {
 *   slug: ‘SDFDSFW23’,
 *   data: demo
 * })
 * 
 * ----------------------------
 * 
 * GET /api/demo
 * this.$api(‘demo/list’)
 * 
 * ----------------------------
 * 
 * GET /api/demo/:slug
 * this.$api(‘demo/read’, {
 *   slug: uid
 * })
 */

const api = {
  asset: {
    list: route('get', '/api/asset/listAssets'),
    read: route('get', '/api/asset/:slug'),
  },
  blog: {
    list: route('get', '/api/blog'),
    update: route('get', '/api/blog/update'),
  },
  card: {
    retrieve: route('get', '/api/card'),
    add: route('post', '/api/card'),
    delete: route('delete', '/api/card'),
  },
  contact: {
    email: route('post', '/api/contact'),
  },
  demo: {
    add: route('post', '/api/demo'),
    batch: route('post', '/api/demo/batch', true),
    retrieve: route('get', '/api/demo/:slug'),
    update: route('patch', '/api/demo/:slug'),
    list: route('get', '/api/demo'),
    delete: route('delete', '/api/demo/:slug'),
  },
  domain: {
    add: route('post', '/api/subscription/domain'),
    delete: route('delete', '/api/subscription/domain/:slug'),
  },
  email: {
    resend: route('get', '/api/email/:slug'),
    support: route('post', '/api/email/support'),
  },
  file: {
    retrieve: route('get', '/api/file/:slug'),
    list: route('get', '/api/file/list/:slug', true),
    add: route('post', '/api/file'),
    delete: route('delete', '/api/file/:slug'),
    update: route('post', '/api/file/:slug'),
  },
  license: {
    add: route('post', '/api/license'),
    delete: route('delete', '/api/license/:slug'),
  },
  metadata: {
    get: route('get', '/api/metadata/:slug'),
    create: route('post', '/api/metadata'),
    update: route('patch', '/api/metadata'),
    delete: route('delete', '/api/metadata'),
    read: route('post', '/api/metadata/read'),
    set: route('post', '/api/metadata/set'),
  },
  metaoptions: {
    get: route('get', '/api/metaoptions/:slug'),
    list_and_group: route('get', '/api/metaoptions/listgroup/:slug'),
    create: route('post', '/api/metaoptions'),
    delete: route('delete', '/api/metaoptions'),
    update: route('patch', '/api/metaoptions'),
  },
  oauth: {
    link: route('post', '/api/user/oauth/link'),
    unlink: route('post', '/api/user/oauth/unlink'),
  },
  product: {
    list: route('get', '/api/product'),
    // purchase: route('post', '/api/product'),
    // transaction: route('get', '/api/product/transaction'),
  },
  subscription: {
    list: route('get', '/api/subscription'),
    add: route('post', '/api/subscription'),
    transaction: route('get', '/api/subscription/transaction'),
    delete: route('delete', '/api/subscription/:slug'),
    update: route('post', '/api/subscription/:slug'),
    reactivate: route('post', '/api/subscription/reactivate/:slug'),
  },
  support_request: {
    list: route('get', '/api/support/request'),
    create: route('post', '/api/support/request'),
  },
  tag: {
    list: route('get', '/api/tag'),
    read: route('get', '/api/tag/demo/:slug'),
    add: route('post', '/api/tag'),
    delete: route('delete', '/api/tag/:slug'),
  },
  template: {
    default: route('get', '/api/template/default'),
    list: route('get', '/api/template'),
  },
  tenant: {
    list: route('get', '/api/tenant'),
    retrieve: route('get', '/api/tenant/:slug'),
    url: route('get', '/api/tenant/url'),
  },
  user: {
    create: route('post', '/api/user/signup'),
    count: route('get', 'api/user/count'),
    data: route('get', '/api/user/data'),
    delete: route('delete', '/api/user'),
    list: route('get', '/api/user/list'),
    last_active: route('get', '/api/user/lastactive'),
    permissions: route('get', '/api/user/permissions'),
    profile: route('get', '/api/user/profile/:slug'),
    retrieve: route('get', '/api/user'),
    session: route('get', '/api/user/session'),
    update: route('post', '/api/user'),
    update_session: route('post', '/api/user/session'),
  },
};
// Axios wrapper to catch all errors, dispatch messages (success and failure) that area API based.
const request = function(vm, userOptions = {}, routeOptions) {
  // Merge options and userOptions
  let authInit = vm.$store.state.auth && vm.$store.state.auth.parsedToken && vm.$store.state.auth.parsedToken.iat
    ? vm.$store.state.auth.parsedToken.iat*1000 : null;
  const auth = {
    headers: {
      'Authorization': 'Bearer ' + vm.$store.state.auth.idToken,
      'AuthInit': authInit,
    },
  };
  // Add auth session created time
  // Attach the API url to each request
  if (routeOptions.method.toLowerCase() === 'delete'
    || routeOptions.method.toLowerCase() === 'post' 
    || routeOptions.method.toLowerCase() === 'patch') {
    // Flatten the data object, required by axios.post, and its not a form object
    const data = userOptions.data || userOptions;
    return axios({
      url: WP_API_URL + routeOptions.url,
      json: true,
      data,
      method: routeOptions.method.toUpperCase(),
      headers: {
        'Authorization': 'Bearer ' + vm.$store.state.auth.idToken,
        'AuthInit': authInit,
      },
    })
    .catch((e) => {
      if (e.response && e.response.status) {
        if (e.response.data.message === 'jwt expired') {
          // JWT Expired
          localStorage.setItem('startup_status', JSON.stringify({
            message: 'Session expired. Please log back in',
            type: 'success',
          }));
          localStorage.setItem('lastSeen', window.location.pathname);
          vm.$store.commit('auth/logout');
          window.location = '/login';
        } else if (e.response.status === 401 && e.response.data === 'Session Expired') {
          // Session Expired (after email change, password change/reset)
          vm.$store.commit('auth/logout');
        }
      }
      return Promise.reject(e);
    });
  } else {
    let query = Object.assign({}, userOptions);
    Object.assign(userOptions, routeOptions);
    Object.assign(userOptions, auth);
    userOptions.url = WP_API_URL + routeOptions.url;
    let keys = Object.keys(query);
    keys.forEach((key, index) => {
      userOptions.url += `${index === 0 ? '?' : '&'}${key}=${userOptions[key]}`;
    });

    return axios(userOptions).catch((e) => {
      if (e.response && e.response.status) {
        if (e.response.data.message === 'jwt expired') {
          localStorage.setItem('startup_status', JSON.stringify({
            message: 'Session expired. Please log back in',
            type: 'success',
          }));
          localStorage.setItem('lastSeen', window.location.pathname);
          vm.$store.commit('auth/logout');
          window.location = '/login';
        }
      }
      return Promise.reject(e);
    });
  }
};

// Handles hiding and showing of loading bar on the top of the app
function loader(vm, options, routeOptions, disableLoader) {
  return new Promise((resolve, reject) => {
    let requestMethod = routeOptions.method;
    let loader = null;

    // If request is to retrieve a demo files after saving demo for first time, do not display loader
    // Request is called multiple times to check if forkable, and loader becomes disruptive
    if (requestMethod === 'get' && !disableLoader) {
      loader = vm.$loading({
        background: 'rgba(255,255,255,0)',
      });
    }
    request(vm, options, routeOptions)
    .then((result) => {
      if (loader) loader.close();
      resolve(result);
    })
    .catch((error) => {
      if (loader) loader.close();
      reject(error);
    });
  });
}

// Handles slug injection in routes dynamically
function route(method, url, disableLoader) {
  return (vm, options) => {
    if (url.indexOf(':slug') > -1) {
      return loader(vm, options, {
        method,
        url: url.replace(':slug', options.slug),
      }, disableLoader);
    } else {
      return loader(vm, options, {
        method,
        url,
      }, disableLoader);
    }
  };
}

// Dispatch function to fire an api event.
/*
* path - a stringified path of {object}/{action}
* e.g. card/update
* options - axios options
*/
const dispatch = function(path, options) {
  const target = getFn(api, path);
  return target(this, options); // eslint-disable-line
};

// Retrieve the function to fire based off the path
function getFn(obj, path) {
  const parts = path.split('/');
  let target = obj;
  parts.forEach((path) => {
    target = target[path];
  });
  return target;
}

const Plugin = {
  install: function(Vue, options) {
    // Method must be one object deep to maintain reference to 'this'
    Vue.prototype.$api = dispatch;
  },
};

export default Plugin;
