/**
 * @description Adds a custom search bar outside of ZingGrid.
 * Requires adding `[search]` attribute to targeted ZingGrid.
 */
import SvgIcon from '../../../components/SvgIcon.vue';

export default {
  components: {
    SvgIcon,
  },
  data() {
    return {
      search: '',
      $zgRef: null,
    };
  },
  mounted() {
    this.$zgRef = this.$el.querySelector('zing-grid[search]');
  },
  destroyed() {
    this.$zgRef = null;
  },
  methods: {
    searchGrid() {
      this.$zgRef.searchGrid(this.search);
    },
  },
};