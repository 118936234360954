import UserModal from '../components/UserModal.vue';

export default {
  components: {
    UserModal,
  },
  data() {
    return {
      appId: VUE_APP_HELPSCOUT_BEACON_ID,
      company: null,
      count: null,
      email: null,
      headerVal: {
        Authorization: `Bearer ${this.$store.state.auth.idToken}`
      },
      quickbooksId: null,
      userId: null,
      userModalVisible: false,
    }
  },
  methods: {
    initUserModal(zgRef, eventType) {
      if (zgRef) {
        // Event listener domain text click
        zgRef.addEventListener(`${eventType || 'row'}:click`, (e) => {
          let target = e.detail.ZGEvent.oDOMTarget;
          let attrArr = Object.values(target.attributes).map((attr) => attr.name);
          if (attrArr.includes('open-user-modal')) {
            let author = eventType === 'card' ? e.detail.ZGData.data.source.author
              : e.detail.ZGData.data;
            this.openUserModal(author);
          };
        });
      };
    },
    openUserModal(author) {
      this.company = author.name,
      this.email = author.email;
      this.userId = author.id;
      this.userModalVisible = true;
    },
  },
};