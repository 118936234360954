<template>
  <section class="content--wide" group-member>

    <h2>Groups you are member of.</h2>

    <!-- General -->
    <p>Group Name: </p>
    <p>Address: </p>

    <!-- Permissions -->
    <zing-grid
      caption="Your Permissions"
      group-member="permissions"
      layout=row
      data='[
        { "feature": "read", "permission": true },
        { "feature": "write", "permission": true },
        { "feature": "billing", "permission": false },
        { "feature": "support", "permission": false },
        { "feature": "admin", "permission": false }
      ]'>
      <zg-colgroup>
        <zg-column index="feature"></zg-column>
        <zg-column index="permission" renderer="renderToggle"></zg-column>
        <zg-column index="permission" header=" " renderer="renderRequestButton" width="200"></zg-column>
      </zg-colgroup>
    </zing-grid>

  </section>
</template>
<script>
export default {
  name: 'GroupMember',
  data: function() {
    return {
      permission: null,
    }
  },
  mounted() {
    this.permissions = this.$el.querySelector('[group-member="permissions"]');

    this.addListeners();
    this.registerMethods();
  },
  methods: {
    /**
     * @description Add event listeners to register click events in zinggrid
     */
    addListeners() {
      this.permissions.executeOnLoad(() => {
        this.permissions.addEventListener('row:click', (e) => {
          // Listen for click event for "Request Permission" button
          let target = e.detail.ZGEvent.oDOMRealTarget;
          let trigger = null;
          if ((target.tagName === 'SPAN' && target.parentElement.tagName === 'BUTTON')) trigger = target.parentElement;
          else if (target.tagName === "BUTTON" ) trigger = target;

          // Register event if not disabled
          if (trigger && !trigger.getAttribute('disabled')) {
            this.$confirm('A request to enable your permission will be sent to an admin.', {
              confirmButtonText: 'Request',
              cancelButtonText: 'Cancel'
            }).then(() => {
              this.$message({
                type: 'success',
                message: 'Your request has been sent'
              });
            }).catch(() => {
              this.$message({
                type: 'info',
                message: 'You have cancelled your request'
              });          
            });
          }
        });
      });
    },
    /**
     * @description Register renderer functions
     */
    registerMethods() {
      window.renderRequestButton = (permission) => {
        return `<button 
          ${permission ? 'disabled="disabled"' : ''}
          class="el-button el-button--primary el-button--small ${permission ? 'is-disabled' : ''}"
          type="button">
          <span>Request Permission</span>
        </button>`;
      }

      window.renderToggle = (curVal) => {
        const ON = '<div role="switch" class="el-switch is-checked" aria-checked="true"><input type="checkbox" name="" true-value="true" class="el-switch__input"><!----><span class="el-switch__core" style="width: 40px; border-color: rgb(19, 206, 102); background-color: rgb(19, 206, 102);"></span><!----></div>';
        const OFF = '<div role="switch" class="el-switch"><input type="checkbox" name="" true-value="true" class="el-switch__input"><!----><span class="el-switch__core" style="width: 40px; border-color: rgb(255, 73, 73); background-color: rgb(255, 73, 73);"></span><!----></div>';
        if (curVal) return ON;
        else return OFF;
      }
    }
  },
}
</script>

<style>
  [group-member] [role="switch"] { pointer-events: none; }
</style>