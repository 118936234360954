<template>
  <div :class="`filter_wrapper${prefix ? ` filter_prefix--${prefix}`: ''}`" :scrollable="scrollable">
    <el-select
      v-show="authenticated"
      v-model="selected"
      :allow-create="allowCreate"
      :clearable="clearable"
      :collapse-tags="collapsible"
      :filterable="filterable"
      :multiple="multiple"
      :placeholder="placeholder">
      <el-option
        v-for="filter in options"
        :key="filter.id"
        :label="filter.name"
        :value="filter.value || filter.name">
        <div class="filter__option">
          <div class="filter__name">{{filter.name}}</div>
          <div v-if="filter.count" class="filter__count">{{filter.count}}</div>
        </div>
      </el-option>
      <template v-if="prefix" slot="prefix">{{prefixText}}</template>
    </el-select>
    <svg-icon icon="close--circle" height="1rem" width="1rem"></svg-icon>
    <svg-icon class="el-input-icon" icon="angle"></svg-icon>
  </div>
</template>

<script>
  import MixinFilterSelector from '../mixins/filterSelector.js';
  import SvgIcon from './SvgIcon.vue';

  export default {
    components: {SvgIcon},
    mixins: [MixinFilterSelector],
  }
</script>

<style src="../assets/styles/filterSelector.css" scoped></style>

<style>
  .filter_wrapper [icon="close--circle"] svg {
    position: relative;
    top: -6px;
  }

  [class*="filter_prefix"] .el-input__prefix {
    color: #C0C4CC;
    font-size: 0.75rem;
    left: 15px;
    line-height: 30px;
  }

  .filter_wrapper .el-input input {
    height: 30px !important;
  }

  /* metadata */
  .filter_prefix--metadata .el-input input {
    padding-left: 7.75rem;
  }

  /* metadata value */
  .filter_prefix--metadataValue .el-select__tags {
    margin-left: 9.5rem;
  }

  /* Scrollable filter */
  [scrollable] .el-select__tags {
    flex-wrap: nowrap;
    max-height: 29px;
    overflow: auto;
    width: calc(100% - 12rem) !important;
  }

  .angle--right {
    transform: rotate(-90deg);
  }
</style>
