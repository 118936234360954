<template>
  <zingnet-card>
    <span slot="header">
      Sales Summary
      <date-range @updateDateRange="updateDateRange"></date-range>
    </span>
    <el-button slot="header__right" @click="openQuickbooks">View in Quickbooks</el-button>
    <div slot="body" v-loading="loading">
      <section class="zingnetCard--row">
        <section
          v-for="item in counts"
          :key="item.title.replace(/\s/g, '')"
          class="zingnetCard--column">
          <h3 class="zingnetCard--header">{{item.title}} <bubble-count :comparison-cur="item.after.count" :comparison-prev="item.before.count"></bubble-count></h3>
          <div class="zingnetCard--row">
            <score-card-group>
              <score-card slot="card_one" :currency="item.currency" :label="label" :value="item.after.count" :title="`in last ${daysDiff} days`"></score-card>
              <score-card slot="card_two" :currency="item.currency" :label="label"  :value="item.before.count" :title="`in previous ${daysDiff} days`" :secondary="true"></score-card>
            </score-card-group>
          </div>
          <div class="zingnetCard--row no-border">
            <div :id="`chart-${item.title.replace(/\s/g, '')}`"></div>
          </div>
        </section>
      </section>
    </div>
  </zingnet-card>
</template>

<script>
import axios from 'axios';
import BubbleCount from '../BubbleCount.vue';
import DateRange from '../DateRange.vue';
import MixinDate from '../../../../mixins/date.js';
import MixinDateRange from '../../mixins/dateRange.js';
import ScoreCard from '../ScoreCard.vue';
import ScoreCardGroup from '../ScoreCardGroup.vue';
import ZingnetCard from '../ZingnetCard.vue';

export default {
  components: {
    BubbleCount,
    DateRange,
    ScoreCard,
    ScoreCardGroup,
    ZingnetCard,
  },
  mixins: [MixinDate, MixinDateRange],
  data() {
    return {
      chartConfig: {
        type: 'line',
        plot: {
          animation: {
            effect: 'ANIMATION_EXPAND_LEFT',
            method: 'ANIMATION_LINEAR',
            sequence: 'ANIMATION_NO_SEQUENCE',
            speed: 1000,
          },
          lineWidth: '1.5px',
          marker: {
            visible: false,
          }
        },
        plotarea: {
          margin: '5px 0 25px 0',
        },
        scaleX: {
          item: {
            fontColor: 'var(--color-primary-gray)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: '12px',
            rules: [{
              rule: '%v == 0',
              offsetX: 20,
            }, {
              rule: '%v == 7',
              offsetX: -20,
            }]
          },
          guide: {
            visible: false,
          },
          labels: [],
          lineColor: 'var(--zingnet-border-color)',
          showLabels: [],
          tick: {
            visible: false,
          },
        },
        scaleY: {
          guide: {
            visible: false,
          },
          visible: false,
        },
        series: [
          {
            values: [],
            lineColor: 'var(--color-secondary-blue)',
          },
          {
            values: [],
            lineColor: 'var(--color-primary-gray-visited)',
          }
        ]
      },
      counts: {
        paymentsReceived: {
          title: 'Total payments received',
          currency: true,
          before: {
            count: 0,
            series: [],
          },
          after: {
            count: 0,
            series: [],
          },
        },
        paymentsNotReceived: {
          title: 'Total payments not received',
          currency: true,
          before: {
            count: 0,
            series: [],
          },
          after: {
            count: 0,
            series: [],
          },
        },
        customers: {
          title: 'New Customers',
          currency: false,
          before: {
            count: 0,
            series: [],
          },
          after: {
            count: 0,
            series: [],
          },
        },
      },
      loading: false,

      daysDiff: 7,
      label: '$',
    };
  },
  methods: {
    calcDiff() {
      this.daysDiff = parseInt(this.dateDiff(this.start, this.end, 'YYYY-MM-DD', 'days'));
    },
    getReportData() {
      this.calcDiff();
      this.getSummary();
    },
    getSummary() {
      this.loading = true;

      axios({
        url: `/api/admin/quickbooks/sales/summary?startDate=${this.start}&endDate=${this.end}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.idToken}`,
        },
      }).then((response) => {
        this.counts.paymentsReceived.before.count = response.data.received.total;
        this.counts.paymentsReceived.before.series = response.data.received.series;
        this.counts.paymentsReceived.after.count = response.data.receivedPrev.total;
        this.counts.paymentsReceived.after.series = response.data.receivedPrev.series;

        this.counts.paymentsNotReceived.before.count = response.data.notReceived.total;
        this.counts.paymentsNotReceived.before.series = response.data.notReceived.series;
        this.counts.paymentsNotReceived.after.count = response.data.notReceivedPrev.total;
        this.counts.paymentsNotReceived.after.series = response.data.notReceivedPrev.series;

        this.counts.customers.before.count = response.data.customers.total;
        this.counts.customers.before.series = response.data.customers.series;
        this.counts.customers.after.count = response.data.customersPrev.total;
        this.counts.customers.after.series = response.data.customersPrev.series;

        this.initChart();
      });
    },
    initChart() {
      // Determine scale labels
      let labels = this.dateList({
        end: this.end,
        step: 'days',
        stepNum: 1, 
        add: this.daysDiff, 
        inputFormat: 'YYYY-MM-DD', 
        outputFormat: 'MMM D'
      });
      this.chartConfig.scaleX.labels = labels;
      this.chartConfig.scaleX.showLabels = [labels[0], labels[labels.length-1]];

      // Determine rules
      this.chartConfig.scaleX.item.rules[1].rule = `%v == ${this.daysDiff}`;

      // Render ZingChart
      for (let count in this.counts) {
        let config = Object.assign({}, this.chartConfig);

        // Add series (data)
        config.series[0].values = this.counts[count].after.series;
        config.series[1].values = this.counts[count].before.series;

        // Adjust speed for year's worth of data
        if (this.daysDiff > 300) config.plot.animation.speed = 100;

        // Render ZingChart
        zingchart.render({
          id: `chart-${this.counts[count].title.replace(/\s/g, '')}`,
          data: config,
          height: '80px',
          width: '294px'
        });

        this.loading = false;
      };
    },
    openQuickbooks() {
      this.$emit('openQuickbooks');
    },
  },
}
</script>