/**
 * @description Used with DateRange component. Tracks date range changes on all ZingNet dashboard items and
 * updates range to sync items.
 */
export default {
  data() {
    return {
      start: null,
      end: null,
      prevStart: null,
      prevEnd: null,
      period: null,

      custom: null,
    };
  },
  methods: {
    updateDateRange(type, date) {
      this[type] = date;
      if (type === 'prevEnd') this.getReportData();
      else if (type === 'custom') this.getReportDataCustom();
    },
  },
};