import axios from 'axios';

export default {
  methods: {
    /**
     * @description Get user's specified permission (and property, but default checks if enabled)
     * @param {String} permission - feature to check. List found in Admin Feature section.
     * @param {String} property - (optional) check for specific value of permission. Default, checks for 'enabled'. Options includes
     * 'active', 'editable', and 'visible'
     * @param {Number} groupId - (optional) if set, check user's specified group permissions
     * @returns {Promise} Returns list of permissions
     */
    checkPermission(permission, property, groupId) {
      return new Promise((resolve, reject) => {
        try {
          axios({
            url: `/api/feature/get/${permission}${groupId ? `/${groupId}` : ''}`,
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
          }).then((data) => {
            if (data && data.data && data.data[permission] && data.data[permission][property || 'enabled']) {
              let _permission = data.data[permission][property || 'enabled'];
              resolve(_permission === 'true' || _permission === true);
            } else {
              resolve(false);
            }
          }).catch((error) => {
            // Suppress expected 401 error when user is not logged in
            resolve(false);
          });
        } catch (err) {
          reject(`Unable to get user permissions: ${err}`);
        };
      });
    },
    /**
     * @description List user's permissions
     * @param {Number} groupId - (optional) if set, check user's specified group permissions
     * @returns {Array} Returns list of permissions
     */
    listPermissions(groupId) {
      return new Promise((resolve, reject) => {
        try {
          axios({
            url: `/api/feature/get/list${groupId ? `/${groupId}` : ''}`,
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
          }).then((data) => {
            resolve(data.permissions);
          });
        } catch (err) {
          reject('Unable to list permissions: ', err)
        };
      });
    },
    /**
     * @description Sets permissions
     * @param {Array} permissions - array of objects containing the following members to set permissions:
     *   [{permission, [property]: value, userId, groupId}]
     *   @param {String} permission - feature to set. List found in Admin Feature section.
     *   @param {String} property - set for specific value of permission. Options includes
     *   'enabled', 'active', 'editable', and 'visible'
     *   @param {Boolean} value - value to set permission
     *   @param {Number} userId - user to set permission for
     *   @param {Boolean} groupId - (optional) if true, set in specified group permissions
     */
    setPermissions(permissions) {
      return new Promise((resolve, reject) => {
        try {
          axios({
            url: '/api/feature/set/user',
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
            data: permissions,
          }).then((data) => {
            resolve(data);
          });
        } catch (err) {
          reject('Unable to set permission: ', err);
        };
      });
    },
  },
};
