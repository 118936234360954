<template>
  <div class="form__item">
    <label class="form__label" id="billing">Additional Billing Information</label>
    <el-input
      class="form__field"
      for="billing"
      maxlength="200"
      placeholder="Example: accountsToPayable@zingsoft.com"
      :rows="2"
      :show-word-limit="true"
      type="textarea"
      v-model="billing_"></el-input>
  </div>
</template>

<script>
  export default {
    props: {
      value: String,
    },
    data() {
      return {
        billing_: null,
        init: true,
        dirty: false,
      }
    },
    mounted() {
      this.billing_ = this.value;
    },
    computed: {
      billing() {
        return this.value;
      },
    },
    watch: {
      // Handles updates from the store.
      billing(value, old) {
        this.billing_ = value;
        this.dirty = false;
      },
      billing_() {
        if (this.init) {
          // Handles an init call.
          this.init = false;
        } else if (!this.dirty)  {
          // Handles changes by the store mutating the event.
          this.dirty = true;
          this.$emit('change', {billing: this.billing_});
        } else if (this.dirty) {
          // If the field was modified by user entry.
          this.$emit('change', {billing: this.billing_});
        }
      },
    },

  }
</script>
