<template>
  <div class="app">
    <app-nav></app-nav>
    <section class="app-content" :style="fixedHeightStyling" v-show="!isMenuOpen">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
  import AppNav from '../components/AppNav.vue';
  import MixinPermissions from '../mixins/permissions.js';

  export default {
    components: {
      AppNav,
    },
    mixins: [MixinPermissions],
    computed: {
      fixedHeightStyling() {
        if(this.$route && this.$route.path.includes('/demos/create')) {
          return {
            overflow: 'hidden',
          }
        } else {
          return null;
        }
      },
      socialAccount() {
        return this.$store.getters['auth/is_social'];
      },
      isMenuOpen() {
        return this.$store.state.ui.isMenuOpen;
      },
    },
    methods: {
    }
  }
</script>
