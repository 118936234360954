<template>
  <zingnet-card :gridBody="true">
    <span slot="header">
      Gallery Metadata
      <metadata-selector
        type="metadata"
        :metadataBy.sync="metadataType"
        default-value="chartType"
      ></metadata-selector>
    </span>

    <div slot="header__right">
      <span class="input-wrapper">
        <el-input
          @change="searchGrid"
          v-model="search"
          placeholder="Search"
          search>
        </el-input>
        <svg-icon class="el-input-icon" icon="search2" fill="var(--color-primary-gray)"></svg-icon>
      </span>
    </div>

    <div slot="body">
      <section class="zingnetCard--row no-border">
        <zing-grid
          :caption="gridCaption"
          :data="gridData"
          editor-controls
          cell-editor="disabled"
          filter
          height="650"
          metadata-list="grid"
          selector
          search
          sort>
          <zg-colgroup>
            <zg-column index="metadata_uid" hidden editor="disabled"></zg-column>
            <zg-column index="name"></zg-column>
            <zg-column :hidden="noContent" index="content"></zg-column>
            <zg-column index="demo_type" type="select" type-select-options="zingchart,zinggrid" type-select-multiple></zg-column>
            <zg-column type="editor"></zg-column>
            <zg-column type="remover"></zg-column>
          </zg-colgroup>
        </zing-grid>
      </section>
    </div>
  </zingnet-card>
</template>
<script>

import MetadataSelector from '../../../../components/MetadataSelector.vue';
import MixinMetadata from '../../../../mixins/metadata.js';
import MixinSearch from '../../mixins/search.js';
import ZingnetCard from '../../components/ZingnetCard.vue';

export default {
  components: {
    MetadataSelector,
    ZingnetCard,
  },
  mixins: [MixinMetadata, MixinSearch],
  data() {
    return {
      metadataType: 'chartType',
      zgRef: null,
    }
  },
  computed: {
    checkMetadata() {
      return this.galleryMetadata && this.metadataType && this.galleryMetadata[this.metadataType];
    },
    id_user() {
      return this.$store.state.user['user_id'];
    },
    gridCaption() {
      let retval = '';
      if (this.metadataType) {
        let split = this.metadataType.split('_');
        split.forEach((s) => {
          retval += `${s[0].toUpperCase()}${s.slice(1)} `;
        });
      }
      return retval;
    },
    gridData() {
      let options = [];
      if (this.checkMetadata) {
        let metadata = this.galleryMetadata[this.metadataType].options;
        for (let opt in metadata) {
          options = options.concat(metadata[opt]);
        }
      }
      return JSON.stringify(options);
    },
    noContent() {
      return this.checkMetadata
        ? this.galleryMetadata[this.metadataType].type !== 'select-url'
        : true;
    }
  },
  mounted() {
    this.setupGrid();
    this.zgRef = this.$el.querySelector('[metadata-list="grid"]');
  },
  destroyed() {
    this.zgRef = null;
  },
  methods: {
    addMetadataRecord(data) {
      let {name, content, demo_type} = data;
      let body = {
        metadata: this.metadataType,
        value: name,
        demo_type
      };
      // Add content for specified metadata type
      if (content && this.galleryMetadata[this.metadataType].type === 'select-url')  {
        body.content = content;
      }
      // Add to database
      this.$api('metaoptions/create', body)
        .then(() => {
          this.fetchOption(this.metadataType);
        });
    },
    deleteMetadataRecord(data) {
      this.$api('metaoptions/delete', {
        id: data.metadata_uid,
      }).then(() => {
          this.fetchOption(this.metadataType);
        });
    },
    editMetadataRecord(oldData, newData) {
      this.$api('metaoptions/update', {
        id: newData.metadata_uid,
        type: this.metadataType,
        value: newData.name,
        content: newData.content,
        demo_type: newData.demo_type,
      }).then(() => {
          this.fetchOption(this.metadataType);
        });
    },
    setupGrid() {
      this.zgRef = this.$el.querySelector('[metadata-list="grid"]');

      this.zgRef.addEventListener('data:record:change', (e) => {
        this.editMetadataRecord(e.detail.ZGData.oldData, e.detail.ZGData.data);
      });
      this.zgRef.addEventListener('data:record:delete', (e) => {
        this.deleteMetadataRecord(e.detail.ZGData.data);
      });
      this.zgRef.addEventListener('data:record:insert', (e) => {
        this.addMetadataRecord(e.detail.ZGData.data);
      });
    },
  }
}
</script>

<style scoped>
  .filter_wrapper {
    margin-top: 1rem;
  }
</style>