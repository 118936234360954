<template>
  <zingnet-card :gridBody="true">
    <span slot="header">
      Site Monitoring
      <date-range @updateDateRange="updateDateRange"></date-range>
    </span>
    <el-button slot="header__right" @click="openPingdom">View in Pingdom</el-button>
    <div slot="body" v-loading="loading">
      <section class="zingnetCard--row no-border">
        <zing-grid id="grid-siteUptime">
          <zg-colgroup>
            <zg-column index="status" renderer="_renderStatus"></zg-column>
            <zg-column index="name, hostname" header="Site Name">
              <a href="[[hostname]]">[[index.name]]</a>
            </zg-column>
            <zg-column index="summary" header="Uptime" renderer="_renderUptime"></zg-column>
            <zg-column index="lasterrortime" header="Up Since" type="date" type-date-from-now></zg-column>
            <zg-column index="lastresponsetime" header="Last response time">
              [[index.lastresponsetime]] ms
            </zg-column>
            <zg-column index="summary" header="Response Time / Outages" renderer="_renderSummary">
              <div class="chart-siteUptime"></div>
            </zg-column>
          </zg-colgroup>
        </zing-grid>
      </section>
    </div>
  </zingnet-card>
</template>
<script>

import axios from 'axios';
import DateRange from '../DateRange.vue';
import MixinDateRange from '../../mixins/dateRange.js';
import ZingnetCard from '../ZingnetCard.vue';

export default {
  components: {
    DateRange,
    ZingnetCard,
  },
  mixins: [MixinDateRange],
  data() {
    return {
      loading: false,
      $zgRef: null,
    };
  },
  mounted() {
    this.$zgRef = this.$el.querySelector('zing-grid');

    ZingGrid.registerMethod(this._renderSummary, '_renderSummary', this);
    ZingGrid.registerMethod(this._renderStatus, '_renderStatus', this);
    ZingGrid.registerMethod(this._renderUptime, '_renderUptime', this);
  },
  methods: {
    dateToTimestamp(date) {
      return new Date(date).getTime() / 1000;
    },
    getReportData() {
      this.loading = true;

      axios({
        method: 'GET',
        url: `/api/admin/pingdom/uptime?from=${this.dateToTimestamp(this.start)}&to=${this.dateToTimestamp(this.end)}`,
        headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` }
      }).then((response) => {
        this.$zgRef.setData(response.data);
        this.loading = false;
      });
    },
    openPingdom() {
      window.open('https://my.pingdom.com');
    },

    _renderSummary(data, cellRef) {
      let id = this._generateAndSetId(cellRef);
      this._setChartData(id, data.hours);
    },
    _renderStatus(data) {
      // return `<span style="color: ${data === 'up' ? 'green' : 'red'}">${data}</span>`;
      return `<svg
        fill="var(--color-${data === 'up' ? 'success': 'fail'}"
        ${data === 'up' ? `style="transform: rotate(180deg)"` : ''}
        width="10" height="7" viewBox="0 0 10 7" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.175 0.589966L5 4.40663L8.825 0.589966L10 1.76497L5 6.76497L0 1.76497L1.175 0.589966Z"/>
      </svg>`;
    },
    _renderUptime(data) {
      let total = 0;
      let uptime = 0;

      // Find uptime
      data.hours.forEach((d) => {
        total++;
        if (d.avgresponse > 0) uptime++;
      });

      // Calculate Percentage
      return `${(uptime/total).toFixed(2) * 100}%`;
    },

    _generateAndSetId(cellRef) {
      // Generate and set unique id to chart container
      let $zcRef = cellRef.querySelector('.chart-siteUptime');
      let id = `chart-siteUptime-${Math.floor(Math.random() * 99999)}`;
      $zcRef.setAttribute('id', id);
      return id;
    },
    _setChartData(id, data) {
      let chartConfig = {
        type: 'area',
        backgroundColor: 'transparent',
        plot: {
          marker: {
            visible: false,
          },
          tooltip: {
            text: "%vt ms <br> %kl"
          },
        },
        plotarea: {
          height: '100px',
          margin: '0',
        },
        scaleX: {
          minValue: data[0].starttime * 1000,
          step: 3600000,
          transform: {
            type: 'date',
            all: '%M %d %H:%m'
          },
          visible: false,
        },
        scaleY: {
          format: "%v ms",
          guide: {
            visible: false,
          },
          step: 500,
        },
        series: [{
          values: data.map((d) => d.avgresponse),
          backgroundColor: 'var(--color-secondary-blue) transparent',
          borderColor: 'var(--color-secondary-blue)',
        }],
      };

      zingchart.render({
        id,
        data: chartConfig,
        height: '100px',
        width: '1000px',
      });
    },
  },
}
</script>

<style>
  #grid-siteUptime zg-cell[data-field-index="status"] div {
    display: flex;
    justify-content: center;
  }
</style>