<template>
  <zingnet-card>
    <span slot="header">Deployment</span>
    <div slot="body">
      <div class="zingnetCard--row flex">
        <deploy-item
          @deploy="startDeployment"
          title="ZingChart Dev Gallery"
          sitename="zingchart"
          branch="dev">
        </deploy-item>

        <deploy-item
          @deploy="startDeployment"
          title="ZingChart Main Gallery"
          sitename="zingchart"
          branch="master">
        </deploy-item>

        <deploy-progress
          title="ZingChart Deployment Progress"
          ref="zingchartProgress"
          sitename="zingchart"
          :branch="zingchartBranch">
        </deploy-progress>
      </div>

      <div class="zingnetCard--row flex">
        <deploy-item
          @deploy="startDeployment"
          title="ZingGrid Dev Gallery"
          sitename="zinggrid"
          branch="dev">
        </deploy-item>

        <deploy-item
          @deploy="startDeployment"
          title="ZingGrid Main Gallery"
          sitename="zinggrid"
          branch="master">
        </deploy-item>

        <deploy-progress
          title="ZingGrid Deployment Progress"
          ref="zinggridProgress"
          sitename="zinggrid"
          :branch="zinggridBranch">
        </deploy-progress>
      </div>

      <portal-target name="deployment-error">
      </portal-target>
    </div>
  </zingnet-card>
</template>
<script>

import { Octokit } from '@octokit/core';
const octokit = new Octokit({ auth: VUE_APP_GITHUB_API_TOKEN });
import DeployItem from './DeployItem.vue';
import DeployProgress from './DeployProgress.vue';
import ZingnetCard from '../ZingnetCard.vue';

process.env.authTOKEN

const ACTION_TIMEOUT_TIME = 2200;

export default {
  components: {
    DeployItem,
    DeployProgress,
    ZingnetCard,
  },
  data() {
    return {
      zingchartBranch: null,
      zinggridBranch: null,
    };
  },
  methods: {
    startDeployment(lib, branch) {
      // Set `branch` attribute
      this[`${lib}Branch`] = branch;
      // Start tracking progress
      this.$nextTick(() => {
        this.$refs[`${lib}Progress`].trackProgress();
      });
    },
  },
}
</script>