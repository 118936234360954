<template>
  <section class="zingnetCardSection">
      <!-- Quickbooks Link -->
      <quickbooks-link></quickbooks-link>
    
      <!-- Licenses -->
      <licensing></licensing>

      <!-- ZingChart License Generator -->
      <zingchart-license-generator></zingchart-license-generator>

      <!-- ZingGrid License Generator -->
      <zinggrid-license-generator></zinggrid-license-generator>
  </section>
</template>
<script>

import Licensing from './Licensing.vue';
import ZingchartLicenseGenerator from './ZingchartLicenseGenerator.vue';
import ZinggridLicenseGenerator from './ZinggridLicenseGenerator.vue';
import QuickbooksLink from './QuickbooksLink.vue';

export default {
  components: {
    Licensing,
    ZingchartLicenseGenerator,
    ZinggridLicenseGenerator,
    QuickbooksLink,
  },
  methods: {
  },
}
</script>