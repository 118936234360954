<template>
  <zingnet-card :grid-body="true">
    <span slot="header">
      Recent Sales
      <date-range @updateDateRange="updateDateRange"></date-range>
    </span>
    <el-button slot="header__right" @click="openQuickbooks">View in Quickbooks</el-button>
    <div slot="body">
      <zing-grid
        cell-class="renderNA"
        default-display="N/A"
        layout="row"
        layout-controls="disabled"
        no-border
        pager
        page-size=10
        selector>
        <zg-colgroup>
          <zg-column index="transactionDate, type, id" header="Transaction Date" renderer="renderTransactionDate"></zg-column>
          <zg-column index="amount" type="currency"></zg-column>
          <zg-column index="client, clientId" header="Client" renderer="renderClient"></zg-column>
          <zg-column index="license"></zg-column>
          <zg-column index="zingBrand" renderer="renderProductType"></zg-column>
        </zg-colgroup>
        <zg-data>
          <zg-param name="headers" :value="JSON.stringify(headerVal)"></zg-param>
          <zg-param name="src" :value="srcValue"></zg-param>
        </zg-data>
      </zing-grid>
    </div>
  </zingnet-card>
</template>

<script>
import DateRange from '../DateRange.vue';
import MixinDate from '../../../../mixins/date.js';
import MixinDateRange from '../../mixins/dateRange.js';
import MixinRenderer from '../../mixins/renderer.js';
import ZingnetCard from '../ZingnetCard.vue';

export default {
  components: {
    DateRange,
    MixinDateRange,
    ZingnetCard,
  },
  mixins: [MixinDate, MixinDateRange, MixinRenderer],
  data() {
    return {
      headerVal: {
        Authorization: `Bearer ${this.$store.state.auth.idToken}`
      },
      srcValue: '',
    }
  },
  mounted() {
    this.$srcParam = document.querySelector('zing-grid zg-data zg-param[id="srcParam"]');

    this.registerRenderer('renderClient');
    this.registerRenderer('renderNA');
    this.registerRenderer('renderProductType');
    this.registerRenderer('renderTransactionDate');
  },
  methods: {
    openQuickbooks() {
      this.$emit('openQuickbooks');
    },
    renderClient(client, id) {
      return `<a href="https://c11.qbo.intuit.com/app/customerdetail?nameId=${id}" target="_blank">${client}</a>`;
    },
    renderTransactionDate(val, type, id) {
      let objType = type === 'Payment' ? 'recvpayment' : 'salesreceipt';
      let link = `https://c11.qbo.intuit.com/app/${objType}?txnId=${id}`;
      return `<a href="${link}" target="_blank">${this.dateFromNow(val)}</a> <span class="date">${this.formatDate(val, null, 'M/D/YY')}</span>`;
    },
    getReportData() {
      this.srcValue = `${window.location.origin}/api/admin/quickbooks/sales/latest?startDate=${this.start}&endDate=${this.end}`;
    },
  },
}
</script>