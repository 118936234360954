<template>
  <default-layout v-if="authenticated && hasUser" :isAuthing="localStorageAuthState">
    <warning></warning>
  </default-layout>

  <secondary-layout v-else :isAuthing="localStorageAuthState" :noJustify="true">
    <warning light></warning>
  </secondary-layout>
</template>

<script>
import DefaultLayout from './layouts/Default.vue';
import Warning from '../components/Warning.vue';
export default {
  components: {
    DefaultLayout,
    SecondaryLayout: () => import('./layouts/Secondary.vue'),
    Warning
  },
  computed: {
    localStorageAuthState() {
      return this.currentLocalStorageAuthState;
    },
    // Determines is authenticated through auth0 is successful
    authenticated() {
      return !!this.$store.state.auth.idToken;
    },
    // After successful authentication, checks if user in database to complete login/signup process
    hasUser() {
      return !!this.$store.state.user['user_id'];
    },
  },
}
</script>
