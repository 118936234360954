import axios from 'axios';
import {Loading} from 'element-ui';

// Modules
import auth from './modules/auth.js';
import billing from './modules/billing.js';
import tenant from './modules/tenant.js';
import ui from './modules/ui.js';
import user from './modules/user.js';
import demo from './modules/demo.js';

function createStore({domain, clientID}) {
  const store = {
    modules: {
      auth: auth({domain, clientID}),
      billing,
      tenant,
      ui,
      user,
      demo,
    },
    getters: {
      isAuthenticated: (state) => {
        return !!state.auth.idToken;
      },
    },
    actions: {
      // Stores idToken and accessTokens.
      install_tokens({commit, state, dispatch}, {accessToken, idToken}) {
        commit('auth/add_tokens', {idToken, accessToken});
      },
      /*
        Responsible for keeping user information up-to-date, as well as vailidating auth flow issues such
        as tenancy validation
      */
      refresh_state({commit, state, getters}, data) {
        if (state.auth.idToken) {
          axios({
            method: 'get',
            url: WP_API_URL + '/api/user?state=true',
            headers: {
              'Authorization': data && data.idToken ? data.idToken : 'Bearer true',
            },
          })
          .then((response) => {
            let user = response.data;

            let beaconObj = {
              email: user.email,
              signature: user.hash,
            };
            if (user.name) beaconObj.name = user.name;
            if (user.company) beaconObj.company = user.company;

            Beacon('identify', beaconObj);
            commit('user/add', user);
            Loading.service({
              background: 'rgba(255,255,255,0)',
            }).close();
          })
          .catch(() => {
            commit('auth/logout');
            Loading.service({
              background: 'rgba(255,255,255,0)',
            }).close();
            location.reload();
          });
        }
      },
      // Obtain new access + id tokens from auth0
      refresh_tokens({commit, state, dispatch}) {
        return new Promise((resolve, reject) => {
          // Performs silent-authentication in an iframe
          state.auth.webAuth.renewAuth({
            redirectUri: window.location.origin + '/silent-callback',
            scope: 'openid profile email',
          }, function(error, authResult) {
            if(error && error.error === 'login_required') {
              console.log('login_required should not be happening');
            }
            
            localStorage.setItem('id_token', true);

            // Save tokens
            commit('auth/add_tokens', {
              idToken: authResult.idToken,
              accessToken: authResult.accessToken,
            });
            
            axios({
              url: '/api/user/serversession',
              method: 'POST',
              headers: { 'Authorization': `Bearer ${authResult.idToken}` },
            });
            // Refresh user
            dispatch('refresh_state', { idToken: `Bearer ${authResult.idToken}` });
            resolve();
          });
        });
      },
    },
  };
  return store;
}
export default createStore;
