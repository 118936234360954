<template>
  <el-dialog
    @close="closeGalleryMetadata"
    :append-to-body="true"
    title="Add Gallery Metadata"
    :visible="visible"
    width="50%">
    <el-form :model="galleryForm" ref="metadataForm">
      <!-- metadata -->
      <el-form-item label="Metadata" :label-width="labelWidth">
        <el-select 
          @change="clearGalleryForm(true)"
          v-model="galleryForm.metadata"
            allow-create
            filterable>
          <el-option
            v-for="item in galleryMetadataOptions"
            :disabled="metadataDisabled(item.value)"
            :key="item.index"
            :label="item.name"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- value -->
      <el-form-item 
        v-if="galleryForm.metadata && galleryForm.metadata != '' && galleryForm.newOptions.length === 0"
        label="Value"
        :label-width="labelWidth">

        <!-- select -->
        <el-select
          @change="isCreateNew"
          v-if="valueType('select', true)"
          v-model="galleryForm.value"
          :allow-create="valueType('select') && galleryMetadata[galleryForm.metadata].addOption"
          filterable>
          <el-option
            v-for="item in getMetadataOptions(galleryForm.metadata)"
            :key="`${galleryForm.metadata}-${item.value}`"
            :label="getMetadataName(item)"
            :value='`{"value": "${item.value}", "metadataUid": "${item.metadata_uid}"}`'>
          </el-option>
        </el-select>

        <!-- url -->
        <el-input
          v-else-if="valueType('url')"
          v-model="galleryForm.value"
          type="url">
        </el-input>
        
        <!-- boolean -->
        <el-checkbox
          v-else-if="valueType('boolean')"
          v-model="galleryForm.value"
          checked
          true-label="1"
          false-label="0">
        </el-checkbox>
        <!-- text -->
        <el-input
          v-else
          v-model="galleryForm.value">
        </el-input>
      </el-form-item>

      <!-- additional field for url -->
      <template v-if="valueType('select-url')">
        <!-- input -->
        <el-form-item
          v-show="galleryForm.value"
          label="Pathname"
          :label-width="labelWidth">

          <el-input
            v-model="galleryForm.data"
            :name="galleryForm.data"
            type="text">
            <template slot="prepend">{{getRelatedDocsUrl(galleryForm.metadataUid)}}</template>
          </el-input>
        </el-form-item>
      </template>

        <!-- field for adding new option -->
        <el-form-item
          v-if="valueType('select') && galleryMetadata[galleryForm.metadata].addOption && createSelect"
          label="Type"
          :label-width="labelWidth">
          <el-checkbox-group v-model="galleryForm.demoType" size="mini">
            <el-checkbox-button v-for="type in demoTypeOptions" :label="type" :key="type">{{type}}</el-checkbox-button>
          </el-checkbox-group>
        </el-form-item>

      <!-- select-url -->
      <template v-if="valueType('select-url')">
        <el-divider></el-divider>
        <el-form-item
          v-for="(newOption) in galleryForm.newOptions"
          :label="`New Option`"
          :key="newOption.key"
          :label-width="labelWidth">
          <el-input
            v-model="newOption.value"
            placeholder="Label/Value">
          </el-input>
          <el-input
            v-model="newOption.content"
            placeholder="Url"
            type="url">
          </el-input>
          <el-input
            v-model="newOption.data"
            placeholder="Pathname"
            type="text">
          </el-input>
          <el-checkbox-group v-model="galleryForm.demoType" size="mini">
            <el-checkbox-button v-for="type in demoTypeOptions" :label="type" :key="type">{{type}}</el-checkbox-button>
          </el-checkbox-group>
          <el-button @click.prevent="removeNewOption(newOption)">Delete</el-button>
        </el-form-item>
        <el-button v-if="galleryForm.newOptions < 1" @click="addFormField">Add new option</el-button>
      </template>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeGalleryMetadata">Cancel</el-button>
      <el-button type="primary" :disabled="missingRequiredField" @click="addGalleryMetadata">Confirm</el-button>
    </span>
  </el-dialog>
</template>

<script>

import MixinMetadata from '../../../mixins/metadata.js';
export default {
  mixins: [MixinMetadata],
  props: [
    'visible',
    'zgRef',
    'demoType',
    'metadata',
  ],
  data() {
    return {
      createSelect: false,
      demoTypeOptions: ['ZingChart', 'ZingGrid'],
      errorDuplicate: 'New option was not added because already exists',
      galleryForm: {
        metadataUid: '',
        metadata: '',
        value: '',
        newOptions: [],
        data: {},
        demoType: [],
      },
      $galleryMetadata: null,
      labelWidth: '100px',
      // optionsToCreate: ['chartType', 'features', 'relatedDocs', 'useCase'],
      optionsToCreate: ['relatedDocs'],
    }
  },
  computed: {
    /**
     * @description Checks if any fields are empty. Enable confirm button
     * if all fields are filled.
     */
    missingRequiredField() {
      let missing = false;
      // Metadata type not selected
      if (this.galleryForm.metadata === '') missing = true;
      // Value selected or new option to be added
      if (this.galleryForm.value === ''
        && this.galleryForm.newOptions.length === 0) missing = true;
      // Required fields for adding new options
      if (this.galleryForm.newOptions.length > 0
        && (this.galleryForm.newOptions[0].value === ''
        || this.galleryForm.newOptions[0].content === ''
        || this.galleryForm.demoType.length === 0)) missing = true;
      // Demo type set when adding new option
      if (this.createSelect && this.galleryForm.demoType.length === 0) missing = true;
      return missing;
    },
  },
  watch: {
    /**
     * @description Set galleryform.metadataUid for checkbox input
     */
    'galleryForm.value': function(val) {
      if (val == 1) {
        this.galleryForm.metadataUid = this.galleryMetadata[this.galleryForm.metadata].options.metadata_uid
      } else if (val == 0) {
        this.galleryForm.metadataUid = '';
      } else if (this.galleryForm.metadata === 'relatedDocs') {
        this.galleryForm.content = this.getRelatedDocsUrl(this.galleryForm.metadataUid);
      };
    },
  },
  methods: {
    /**
     * @description Add metadata related to site gallery demos
     */
    addGalleryMetadata() {
      // If add new options, include to add as attribute value
      this.includeNewOptions();
      // Insert body
      let body = {
        metadataUid: this.galleryForm.metadataUid,
        metadata: this.galleryForm.metadata,
        value: this.galleryForm.value,
        data: this.galleryForm.data,
        content: this.galleryForm.content,
      };
      // Close gallery metadata dialog
      this.$emit('close');
      // Add new metadata option (if created)
      this.addMetadataOption(this.galleryForm, body);
      // Clear dialog form
      this.clearGalleryForm();
    },
    addFormField() {
      this.galleryForm.newOptions.push({
        key: Date.now(),
        value: '',
        content: '',
      });
    },
    /**
     * @description If new option is added, update database to include new option
     * @param {Object} galleryForm - metadata value object
     * @param {String} galleryForm.metadata - type of metadata
     * @param {Array} value - value to assign metadata (option)
     */
    addMetadataOption(galleryForm, body) {
      // Add new options to database
      if (this.galleryForm.metadata === 'relatedDocs' && this.galleryForm.value && this.galleryForm.newOptions.length > 0) {
        // Handle for select-url
        this._addMetadataOption(body, this.galleryForm.value, this.galleryForm.demoType, this.galleryForm.newOptions[0].content);
      } else {
        // Handle for select(single)
        this._addMetadataOption(body, this.galleryForm.value, this.galleryForm.demoType)
      }
    },
    /**
     * @description Check if metadata option already exists. If not, add new option
     */
    _addMetadataOption(body, option, demoType, data) {
      if (this.galleryMetadata[this.galleryForm.metadata] && this.galleryMetadata[this.galleryForm.metadata].type !== 'boolean') {
        // If metadata has options, check if options already exists
        let existingOption = [];
        let curOptions = this.galleryMetadata[this.galleryForm.metadata].options;
        for (let options in curOptions) {
          existingOption = existingOption.concat(curOptions[options].filter((curOption) => option === curOption.value));
        }
        let optionToAdd = this.valueType('select-url') ?
          this.galleryForm.newOptions.filter((opt) => opt.value === option)
          : false;
        // Determine if option exists and allowed to be added
        if (existingOption.length === 0 && this.optionsToCreate.indexOf(body.metadata) > -1) {
          // Construct new option to add
          let newOption = {
            metadata: this.galleryForm.metadata,
            value: option,
            content: optionToAdd && optionToAdd.length > 0 ? optionToAdd[0].content : '',
            metadataData: data || '', 
            demo_type: (this.galleryForm.demoType || demoType).map((type) => type.toLowerCase()),
            metadata_uid: this.galleryForm.metadataUid
          }

          // Add to database
          this.$api('metaoptions/create', newOption)
            .then((result) => {
              // Add to metadata table
              body.metadataUid = result.data.id;
              this.zgRef.insertRow(body);
              // Include new option
              if (newOption.metadata === 'relatedDocs') {
                this.fetchOption(newOption.metadata);
              };
            }).catch((err) => {
              if (err && err.response && err.response.status === 400) {
                this.$message({
                  message: this.errorDuplicate,
                  type: 'error',
                })
              }
            });
        } else if (optionToAdd.length > 0) {
          // Add to metadata table
          this.zgRef.insertRow(body);
          // Error when user attempts to add option that already exists
          this.$message({
            message: this.errorDuplicate,
            type: 'error',
          })
        } else {
          // Add to metadata table (non-select metadata)
          this.zgRef.insertRow(body);
        }
      } else {
        // Add to metadata table (custom metadata)
        this.zgRef.insertRow(body);
      }
    },
    /**
     * @description Clears form item that is inserted into ZingGrid
     * @param {Boolean} onlyValue - clears only value
     */
    clearGalleryForm(onlyValue) {
      if (onlyValue) {
        // Clear only value
        this.galleryForm.value = '';
        this.galleryForm.newOptions = [];
        this.galleryForm.data = '';
        this.galleryForm.demoType = [];
      } else {
        // Clear all
        this.galleryForm = {
          metadata: '',
          value: '',
          data: '',
          newOptions: [],
          content: '',
          demoType: [],
        }
      }
      this.createSelect = false;
    },
    /**
     * @description Clears form and sets visibility of dialog to false
     */
    closeGalleryMetadata() {
      this.$emit('close');
      this.clearGalleryForm();
    },
    /**
     * @description Get metadata option name. Append details determining if option
     * is limited to option
     */
    getMetadataName(item) {
      let name = item.name;
      if (item.demo_type) {
        if (item.demo_type === 'zinggrid') {
          name += ' - ZG';
        } else if (item.demo_type === 'zingchart') {
          name += ' - ZC';
        }
      }
      return name;
    },
    /**
     * @description Displays options based on type and demo type
     * @param {String} type - metadata type
     */
    getMetadataOptions(type) {
      let options = this.galleryMetadata[type].options;
      let retval = [];
      // Retrieve options (single demo type)
      if (options[this.demoType]) retval = retval.concat(options[this.demoType]);
      // Retrieve options (multiple demo types)
      if (this.demoType.includes(',')) {
        let demoType = this.demoType.split(',');
        demoType.forEach((dt) => {
          let trim = dt.trim();
          if (options[trim]) retval = retval.concat(options[trim]);
        });
      }
      return retval;
    },
    /**
     * @description Returns related docs url associated to label
     */
    getRelatedDocsUrl(metadataUid) {
      if (metadataUid) {
        let relatedDocsOptions = this.galleryMetadata.relatedDocs.options;
        // For each option type (ZC, ZG, both)
        for (let option in relatedDocsOptions) {
          if (this.demoType.includes(option)) {
            // Return related docs url
            let target = relatedDocsOptions[option].filter((option) => {
              if (option.metadata_uid == metadataUid) return option;
            });
            return target[0].content;
          }
        }
      }
      return;
    },
    /**
     * @description In the case of adding new option that requires an additional fields,
     * include new options to current metadata value
     */
    includeNewOptions() {
      this.galleryForm.newOptions.forEach((option) => {
        if (this.galleryForm.metadata === 'relatedDocs') {
          this.galleryForm.value = option.value;
          this.galleryForm.data = option.data;
          this.galleryForm.content = option.content;
        } else {
          option.demo_type = this.galleryForm.demoType;
        }
      });
    },
    /**
     * @description Determine if new option is being creating by checking if
     * no matching text is displayed on blur. If an option is being created,
     * display additional field to associate type.
     * @param {String} val - value of selected option in {value, metadataUid} format
     */
    isCreateNew(val) {
      val = JSON.parse(val);
      let exists = false;
      let galleryMetadataOptions = this.galleryMetadata[this.galleryForm.metadata].options;

      // Clear previous input
      this.galleryForm.demoType = [];

      // Determine options to check
      let options = [];
      if (this.demoType.includes(',')) {
        for (let option in galleryMetadataOptions) {
          options.push(option);
        };
      } else {
        for (let option in galleryMetadataOptions) {
          if (option.includes(this.demoType)) options.push(option);
        };
      };

      // Check if option exists
      options.forEach((option) => {
        let target = galleryMetadataOptions[option].filter((opt) => opt.value === val.value);
        exists = exists || target.length > 0;
      });
      this.createSelect = !exists;

      if (this.createSelect) {
        // Add new option to option list
        this.galleryForm.newOptions.push({
          value: this.galleryForm.value,
        });
      } else {
        // Set UID
        this.galleryForm.metadataUid = val.metadataUid;
        this.galleryForm.value = val.value;
      };
    },

    /**
     * @description Determines if metadata option should be disabled because max
     * number allowed already reached for demo.
     * `gallaryMetadata` contains data indicating if metadata limited to one input.
     * `metadata` contains the metadata data to demo
     * @param {String} option - metadata option to check if disabled
     * @return {Object} `galleryMetadata` with `disabled` field added
     */
    metadataDisabled(option) {
      let metadata = this.galleryMetadata[option];
      // Determine value to set `disabled`
      if (metadata.selectMultiple) {
        // If `selectMultiple` is true, set `disabled` to false
        metadata.disabled = false;
      } else {
        if (this.metadata && typeof this.metadata === 'object' && Array.isArray(this.metadata)) {
          // Check if metadata already added
          let gridMetadata = this.metadata.filter((meta) => meta.metadata === option);
          metadata.disabled = gridMetadata.length > 0;
        } else {
          // Assume no metadata
          metadata.disabled = false;
        }
      };

      return this.galleryMetadata[option].disabled;
    },
    /**
     * @description Emits event to set dialog visibility to true
     */
    openGalleryMetadata() {
      this.$emit('open');
    },
    /**
     * @description Remove new option from being added to database
     * @param {Object} newOption - new option to not add
     */
    removeNewOption(newOption) {
      let index = this.galleryForm.newOptions.indexOf(newOption);
      if (index !== -1) {
        this.galleryForm.newOptions.splice(index, 1);
      }
    },
    /**
     * @description Determines which form-item to create depending on
     * metadata value type
     * @param {String} type - metadata value type
     * @param {Boolean} includes - includes if metadata value type includes type. 
     * Else checks for equal
     */
    valueType(type, includes) {
      let retVal = this.galleryMetadata[this.galleryForm.metadata];
      if (retVal && type) {
        let checkType = includes
          ? this.galleryMetadata[this.galleryForm.metadata].type.includes(type)
          : this.galleryMetadata[this.galleryForm.metadata].type === type;
        retVal = retVal && checkType;
      }

      return retVal;
    }
  }
}
</script>