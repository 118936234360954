<template>
  <secondary-layout slim="true" resetPassword>
    <section class="content__wrap content__wrap--login">
      <div class="content content--narrow">
        <reset-modal></reset-modal>
      </div>
    </section>

  </secondary-layout>
</template>

<script>
  import EntryContainer from './components/EntryContainer.vue';
  import SecondaryLayout from '../layouts/Secondary.vue';
  import ResetModal from './components/ResetModal.vue';

  export default {
    components: {
      ResetModal,
      SecondaryLayout,
    }
  }
</script>