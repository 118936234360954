<template>
  <section>
    <!-- Create/Request Dashboard -->
    <group-create
      v-if="activeTab == 'createRequest'">
    </group-create>

    <div v-for="group in myGroups" :key="group.id" :label="group.name" :name="`${(group.id).toString()}`">
      <template v-if="activeTab == group.id">
        <!-- Link to Group Demos -->
        <header class="content__header">
          <h2>Group Demos</h2>
        </header>
        <el-button @click="routeToDemos(group.id)">View Demos</el-button>

        <!-- User's Group Permissions -->
        <!-- TODO send in group id -->
        <toggle-grid
          caption="Your Group Permissions"
          :group-id="group.id"
          hide-list
          paging
          set-all
          request-detail="/api/feature/get/list"
          request-set="/api/feature/set"
          type="permissions_group"
        ></toggle-grid>
        
        <!-- Group Members -->
        <!-- TODO: 
          - delete member
          - invite button
        -->

        <toggle-grid
          group-admin
          button-text="Edit / View"
          caption="Group Admin Dashboard"
          paging
          :group-id="group.id"
          remove-text="Remove"
          request-detail="/api/feature/list/user"
          request-delete="TODO"
          request-list="/api/grouping/list/users"
          request-set="/api/feature/set/user"
          set-all
          set-role
          type="permissions_group_admin"
        ></toggle-grid>
        
        <!-- Invitations -->
        <!-- TODO waiting for list and set (cancel) notification requests -->
        <!-- <toggle-grid
          caption="Pending Notifications"
          hide-list
          paging
          request-detail="/api/feature/get/list"
          request-set="/api/feature/set"
        ></toggle-grid> -->
        

        <header class="content__header">
          <h2>Group Settings</h2>
        </header>
        <!-- TODO admin only -->
        <el-button type="danger" @click="deleteVisible=true">Delete Group</el-button>

        <el-button type="danger" @click="leaveVisible=true">Leave Group</el-button>
      </template>
    </div>

    <el-dialog
      class="dialog--condense"
      :visible.sync="deleteVisible">
      <h2 class="dialog__icon" style="color: #f56c6c"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="3x"/><br></h2>
      <h2>Are you sure you want to delete this group?</h2>
      <p>You will not be able to revert your changes once deletion is confirmed.</p>
      <div class="dialog__controls">
        <el-button @click="deleteVisible = false">Cancel</el-button>
        <el-button type="danger" @click="confirmDelete()">Delete</el-button>
      </div>
    </el-dialog>

    <el-dialog
      class="dialog--condense"
      :visible.sync="leaveVisible">
      <h2 class="dialog__icon" style="color: #f56c6c"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="3x"/><br></h2>
      <h2>Are you sure you want to leave this group?</h2>
      <div class="dialog__controls">
        <el-button @click="leaveVisible = false">Cancel</el-button>
        <el-button type="danger" @click="confirmLeave">Delete</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import axios from "axios";
import GroupAdmin from './components/GroupAdmin.vue';
import GroupMember from './components/GroupMember.vue';
import GroupCreate from './components/GroupCreate.vue';
import DefaultLayout from '../layouts/Default.vue';
import ToggleGrid from "./../../components/ToggleGrid.vue";
import MixinGroup from './../../mixins/groups.js';

export default {
  name: 'Groups',
  components: {
    'group-admin': GroupAdmin,
    'group-member': GroupMember,
    'group-create': GroupCreate,
    'default-layout': DefaultLayout,
    ToggleGrid,
  },
  mixins: [MixinGroup],
  props: {
    activeTab: String,
  },
  data: function() {
    return {
      deleteVisible: false,
      leaveVisible: false,
    };
  },
  methods: {
    /**
     * @description After confirming deletion of group, group is deleted
     * // TODO need request to delete group
     */
    confirmDelete() {
      let groupName = null;
      this.myGroups.forEach((g) => {
        if (g.id == this.activeTab) groupName = g.name;
      });

      axios({
        url: `/api/grouping/${this.activeTab}`,
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${this.$store.state.auth.idToken}`,
        },
        data: {
          name: groupName,
        },
      })
      .then((response) => {
        // TODO refresh page to update
      }).catch((err) => {
        this.$message({
          message: `Error deleting group: ${err}`,
          type: 'error',
        });
      });
    },
    /**
     * @description After confirming leaving group, user is removed from group
     * // TODO need to wait for request to leave group
     */
    confirmLeave() {
      axios({
        url: `/api/grouping/user/leave/${this.activeTab}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.$store.state.auth.idToken}`,
        }
      })
      .then((response) => {
        location.reload();
      }).catch((err) => {
        this.$message({
          message: `Error deleting group: ${err}`,
          type: 'error',
        });
      });
    },
    /**
     * @description Pushes to router object to update view to Manage Dashboard with filtered group demos
     * @param {Number} groupId - id of group
     */
    routeToDemos(groupId) {
      if (this.$route.path !== '/' && this.$route.query && this.$route.query.groupfilter === groupId) {
        this.$router.push({
          path: '/',
          query: {groupfilter: groupId},
        });
      };
      this.$emit('update-view', 'manage', null, 'all_demos', groupId);
    },
    /**
     * @description When a tab is switched, update group permissions for that group
     */
    updateGroupPermissions(tab, event) {
      this.activeTab = tab.paneName;
    }
  },
}
</script>

<style scoped>
  section { padding-bottom: 3rem; }
</style>

<style>
  [groups] .el-tabs__item {
    max-width: 11rem;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 0.5rem;
  }

  .dialog--condense { display: flex; justify-content: center; }
  .dialog--condense .el-dialog { max-width: 30rem !important; padding-right: 0 !important; }
  .dialog--condense .el-dialog__body { padding: 0 30px 2rem !important; }
  .dialog--condense .el-dialog__body > *, .dialog__icon { text-align: center; word-break: break-word; }
  .dialog--condense .el-dialog__header { padding: 1rem 0rem !important; }
  .dialog__controls { display: flex; justify-content: center; flex-wrap: wrap; margin-top: 2rem; width: 100%; }

  .dialog--padded .el-dialog { padding-right: 3rem !important; } 
  .dialog--padded .el-dialog__body { padding: 30px 20px !important; }
  .dialog--padded .el-dialog__header { padding: 2rem 3rem !important; }
</style>