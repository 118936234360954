export default {
  data() {
    return {
      licenseKeyInfo: null,
      licenseKeyVisible: false,
    };
  },
  methods: {
    displayLicenseKey(subscription) {
      this.licenseKeyVisible=true;
      this.licenseKeyInfo = {invoiceId: subscription.id, plan: subscription.plan};
    },
  }
};