export default {
  data() {
    return {
      subscriptionInfoDialogVisible: false,
      subscriptionInfoTitle: null,
      subscriptionInfoItems: [],
    };
  },
  methods: {
    handleSubscriptionInfo(product) {
      this.subscriptionInfoTitle = product.Name;
      this.subscriptionInfoItems = product.features;
      this.subscriptionInfoDialogVisible = true;
    },
  }
};