<template>
  <secondary-layout slim="true" logout :isAuthing="isAuthing">
    <section class="content__wrap content__wrap--login">
      <div class="content content--narrow" logout="container">

        <entry-container slim=true logout="center">
          <h3 v-if="isExpired" slot="entryHeader">Session Expired</h3>
          <h3 v-else-if="emailChange" slot="entryHeader">Email Change</h3>
          <h3 v-else-if="passwordChange" slot="entryHeader">Password Change</h3>
          <h3 v-else slot="entryHeader">Log out successful</h3>

          <div v-if="isExpired" slot="entryBody" logout="container__body">
            <h1>You're now logged out of the ZingSoft Studio</h1>
            <p>Please log back in <br> to renew your session</p>
            <br>

            <div @click="redirect" class="button  button--bottom  button--center">
              <p><span>Login</span></p>
            </div>
          </div>
          <div v-else slot="entryBody" logout="container__body">
            <!-- Email change text -->
            <template v-if="emailChange">
              <h1>Email successfully changed</h1>
              <p logout="margin--bottom">Please login with new email!</p>
            </template>

            <!-- Password change text -->
            <template v-else-if="passwordChange">
              <h1>Password successfully changed</h1>
              <p logout="margin--bottom">Please login with new password!</p>
            </template>

            <!-- Default text -->
            <template v-else>
              <h1>You're now logged out of the ZingSoft Studio</h1>
              <p logout="margin--bottom">We hope to see you again soon!</p>
            </template>

            <div @click="redirect" class="button  button--bottom  button--center">
              <p><span>Log Back In</span></p>
            </div>
            
            <p logout="link--return"><a href="https://www.zinggrid.com" logout="link">Return to homepage</a></p>
          </div>

        </entry-container>

      </div>
    </section>
  </secondary-layout>
</template>

<style lang="css" scoped>
  [logout] .button--bottom { width: 11.25rem; }
  [logout] .button--bottom p { color: var(--color-reset); font-size: 0.8125rem; margin: auto; }
  [logout] .entry__actions > div { position: relative; }
  [logout] h1 { color: var(--header-color); font-size: 1.375rem; margin: 0 auto 1.5rem; max-width: var(--container-width);text-align: center; }
  [logout] p { color: var(--color-greyscale-8); font-size: 0.85rem; line-height: 1.25rem; margin: auto; }
  [logout="center"] { align-self: center; }
  [logout="center"].center--content-inline { display: flex; margin: auto; }
  [logout="container"] { text-align:center; }
  [logout="container__body"] { flex: 1; padding: 0.5rem 0 1.5rem; }
  [logout="link"] { color: var(--color-greyscale-8); font-weight: 900; text-decoration: underline; }
  [logout="link--logout" ] a { text-decoration: none; }
  [logout="link--logout" ] p { color: var(--color-reset); font-size: 0.9rem; font-weight: 500; letter-spacing: 1px; margin-right: 0.5rem; text-transform: uppercase; }
  [logout] [logout="margin--bottom"] { margin-bottom: 0.625rem; }
  [logout] [logout="link--return" ] { margin: 2.813rem auto auto auto; }

</style>

<script>
  import MixinEntry from '../../mixins/entry.js';
  import { mapState } from '@vuex';
  export default {
    mixins: [MixinEntry],
    computed: {
      isExpired: function() {
        if (this.lastSeen) return true;
        return false;
      },
    },
    data() {
      return {
        acceptedValues: ['zingchart'],
        emailChange: false,
        lastSeen: null,
        passwordChange: false,
      }
    },
    mounted() {
      this.checkSession();

      // Toggle localStorage 'auth' state
      this.$store.commit('auth/logout');

      // Update text on email change
      this.emailChange = !!this.$route.query.emailChange;

      // Update text on password change
      this.passwordChange = !!this.$route.query.passwordChange;

      // Update return link
      this.updateReturnLink();
    },
    methods: {
      redirect() {
        if (this.$route.path !== '/login') this.$router.push('/login');
      },
      checkSession() {
        this.lastSeen = localStorage.getItem('lastSeen');
      },
      /**
       * @description Check for 'from' query parameter to update return link.
       * Return link is updated only if value is in `this.acceptedValues`
       */
       updateReturnLink() {
         let from = this.$route.query.from || null;
         if (from && this.acceptedValues.indexOf(from) > -1) {
           let $returnLink = this.$el.querySelector('[logout="link--return"] a');
           $returnLink.href = `https://www.${from}.com`;
         } 
       }
    }
  }
</script>