<template>
  <div class="scorecard-group">
    <slot name="card_one"></slot>
    <p>vs</p>
    <slot name="card_two"></slot>
  </div>
</template>

<script>
  export default {}
</script>

<style scoped>
  p {
    bottom: 1.4rem;
    color: var(--color-tertiary-blue-visited);
    font-size: 0.8125rem;
    font-weight: 500;
    margin: 0 1rem;
    max-width: 25%;
    position: relative;
    text-align: center;
  }

  .scorecard-group {
    align-items: center;
    display: flex;
    width: 100%;
  }
  .scorecard-group > * {
    flex: 1;
  }
</style>