<template>
  <section zingnet-date-range>
    <div class="el-select-wrapper">
      <el-select v-model="selectVal" placeholder="Select" date-range>
        <!-- Default Items -->
        <template v-if="!groupName">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </template>

        <!-- Additional Items -->
        <template v-if="additionalItems && !groupName">
          <el-option
            v-for="item in additionalItems"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </template>

        <!-- Additional Items (Grouped) -->
        <template v-if="additionalItems && groupName">
          <el-option-group
            key="default"
            label="Default">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-option-group>
          <el-option-group
            :key="groupName"
            :label="groupName">
            <el-option
              v-for="item in additionalItems"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-option-group>
        </template>

      </el-select>
      <svg-icon class="el-select-icon" icon="angle"></svg-icon>
    </div>

    <el-date-picker
      @change="updateCustomRange"
      v-if="selectVal === 'range' || typeof selectVal === 'object'"
      v-model="customDateRange"
      type="daterange"
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
      value-format="yyyy-MM-dd">
    </el-date-picker>
    <p v-else>{{dateLabel}}</p>

    <p hidden>{{range}}</p>
  </section>
</template>

<script>
import MixenDate from '../../../mixins/date.js';
import SvgIcon from '../../../components/SvgIcon.vue';

export default {
  components: {
    SvgIcon,
  },
  mixins: [MixenDate],
  props: {
    additionalItems: Array,
    groupName: String,
  },
  computed: {
    dateLabel() {
      // Trigger update on select drop update on current or another <date-range>
      if (this.selectVal) {
        this.$nextTick(() => this.updateRange());
      };
      return this.label;
    },
    range() {
      this.$nextTick(() => this.updateRange());
      return this.$store.state.user.adminDateRange || 'week';
    },
  },
  data() {
    return {
      customDateRange: [],
      dateRangeObj: null,
      label: null,
      options: [
        {
          label: 'Past day',
          value: 'day',
        },
        {
          label: 'Past week',
          value: 'week',
        },
        {
          label: 'Past month',
          value: 'month',
        },
        {
          label: 'Past year',
          value: 'year',
        },
        {
          label: 'Custom',
          value: 'range',
        },
      ],
      optionsVal: null,
      prevRange: 'week',
      selectVal: 'week',
    };
  },
  mounted() {
    this.setRange();
  },
  methods: {
    updateCustomRange() {
      if (this.customDateRange && this.customDateRange.length == 2) {
        this.selectVal = {start: this.customDateRange[0], end: this.customDateRange[1]};

        this.prevRange = this.selectVal;
        this.$store.state.user.adminDateRange = this.selectVal;

        this.setRange();
      };
    },
    updateRange() {
      if (!this.selectVal !== 'range') {
        if (this.prevRange !== this.selectVal) {
          // Triggered by current <date-range>
          this.prevRange = this.selectVal;
          if (this._isDefaultOption(this.selectVal)) {
            this.$store.state.user.adminDateRange = this.selectVal;
          };
          this.setRange();
        } else if (this.prevRange === this.selectVal && this.prevRange !== this.range) {
          // Triggered by another <date-range>
          this.prevRange = this.range;
          this.selectVal = this.range;

          if (typeof this.selectVal === 'object') {
            this.customDateRange = [this.selectVal.start, this.selectVal.end];
          };

          this.setRange();
        };
      };
    },
    setRange() {
      if (this._isDefaultOption(this.selectVal)) {
        // Update value
        this.dateRangeObj = this.dateRange(null, 'YYYY-MM-DD', this.selectVal, 1);

        // Emit events to notify of date range update
        this.$emit('updateDateRange', 'period', this.selectVal)
        this.$emit('updateDateRange', 'start', this.dateRangeObj.start);
        this.$emit('updateDateRange', 'end', this.dateRangeObj.end);
        this.$emit('updateDateRange', 'prevStart', this.dateRangeObj.prevStart);
        this.$emit('updateDateRange', 'prevEnd', this.dateRangeObj.prevEnd);

        // Update label
        let start = this.formatDate(this.dateRangeObj.start, 'YYYY-MM-DD', 'MMMM D');
        let end = this.formatDate(this.dateRangeObj.end, 'YYYY-MM-DD', 'MMMM D');
        this.label = `Currently viewing ${start} - ${end}`;
      } else if (typeof this.selectVal === 'object') {
        // Update value
        this.dateRangeObj = this.dateRange(this.selectVal.end, 'YYYY-MM-DD', this.selectVal);

        // Emit events to notify of date range update
        this.$emit('updateDateRange', 'start', this.dateRangeObj.start);
        this.$emit('updateDateRange', 'end', this.dateRangeObj.end);
        this.$emit('updateDateRange', 'prevStart', this.dateRangeObj.prevStart);
        this.$emit('updateDateRange', 'prevEnd', this.dateRangeObj.prevEnd);
      } else {
        this.$emit('updateDateRange', 'custom', this.selectVal);
      };
    },

    _isDefaultOption(opt) {
      if (!this.optionsVal) this.optionsVal = this.options.map((o) => o.value);
      return this.optionsVal.indexOf(opt) > -1;
    },
  },
}
</script>

<style scoped>
  [zingnet-date-range] p {
    color: var(--color-primary-gray-visited);
    font-size: 0.9375rem;
    font-style: italic;
    margin: 0 0 0 1.125rem;
  }
  [zingnet-date-range] {
    align-items: center;
    display: flex;
    margin-top: 1.1875rem;
  }
</style>

<style>
  [zingnet-date-range] .el-date-editor {
    margin-left: 1.5rem;
  }
</style>