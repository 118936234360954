<template>
  <div class="nav">
    <nav class="nav__wrap nav__wrap--main">
      <div class="nav__logo nav__logo--secondary">
        <svg-icon icon="zingsoft-logomark"></svg-icon>
      </div>

      <div class="nav__sign-in">
        <span class="mobile--hide">
          <template v-if="isSignup">Already have an account?</template>
          <template v-else>Don't have an account?</template>
        </span>
        <div class="nav__sign-in__link" >
          <span v-if="isSignup" @click="launch('/')">
            <span class="text--underline">Log in</span>
            <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="M8.778 0a9.336 9.336 0 0 0-5.284 1.623A9.033 9.033 0 0 0 .164 5.78L0 6.23h2.026l.084-.217c.364-.77.86-1.466 1.486-2.077a7.372 7.372 0 0 1 5.182-2.094c1.96 0 3.8.744 5.183 2.095a7.02 7.02 0 0 1 2.146 5.058 7.027 7.027 0 0 1-2.146 5.058 7.372 7.372 0 0 1-5.183 2.094 7.388 7.388 0 0 1-5.182-2.094A7.167 7.167 0 0 1 2.11 11.98l-.084-.216H0l.16.45a9.033 9.033 0 0 0 3.33 4.158A9.288 9.288 0 0 0 8.777 18C13.864 18 18 13.963 18 9s-4.136-9-9.222-9z"/><path d="M0 9.892h7.672l-1.764 1.862L7.118 13 11 9 7.118 5 5.864 6.246l1.808 1.862H0v1.784z"/></g></svg>
          </span>
          <span v-else @click="launch('/signup')">
            <span class="text--underline">Create account</span>
            <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="M8.778 0a9.336 9.336 0 0 0-5.284 1.623A9.033 9.033 0 0 0 .164 5.78L0 6.23h2.026l.084-.217c.364-.77.86-1.466 1.486-2.077a7.372 7.372 0 0 1 5.182-2.094c1.96 0 3.8.744 5.183 2.095a7.02 7.02 0 0 1 2.146 5.058 7.027 7.027 0 0 1-2.146 5.058 7.372 7.372 0 0 1-5.183 2.094 7.388 7.388 0 0 1-5.182-2.094A7.167 7.167 0 0 1 2.11 11.98l-.084-.216H0l.16.45a9.033 9.033 0 0 0 3.33 4.158A9.288 9.288 0 0 0 8.777 18C13.864 18 18 13.963 18 9s-4.136-9-9.222-9z"/><path d="M0 9.892h7.672l-1.764 1.862L7.118 13 11 9 7.118 5 5.864 6.246l1.808 1.862H0v1.784z"/></g></svg>
          </span>
        </div>
      </div>
    </nav>
  </div>
</template>

<style scoped>
  .nav__sign-in__link svg {
    fill: var(--color-tertiary-6);
    margin-right: 0.25rem;
    position: relative;
    top: 3px;
    width: 0.8rem;
  }

  /* MOBILE STYLING  */
  .nav {
    box-shadow: var(--box-shadow-card);
  }

  .nav__content {
    display: flex;
    margin: 0;
    padding: 0 20px;
    width: 100%;
  }

  .nav__content--main {
    justify-content: space-between;
  }

  .nav__content.nav__content--wide {
    padding: 0px;
  }

  .nav__logo--secondary {
    margin-left: 0;
  }

  .nav__logo--secondary [icon] {
    display: flex;
  }

  .nav__sign-in {
    align-items: center;
    color: var(--color-greyscale-8);
    display: flex;
    font-size: 0.85rem;
    padding: 0;
    text-decoration: none;
  }

  .nav__sign-in svg {
    margin-right: 0.25rem;
  }

  .nav__sign-in__link {
    color: var(--color-primary-1);
    cursor: pointer;
    font-weight: 600;
    margin: 0 0 0 0.5rem;
  }

  .nav__sign-in__link:hover {
    color: var(--color-tertiary-1);
  }

  .nav__wrap {
    display: flex;
    justify-content: space-between;
    max-width: 875px; 
    margin: 0 auto;
    padding: 0.75rem;
    background: #fff;
    color: var(--color-greyscale-8);
  }

  .text--underline { text-decoration: underline; }
</style>

<style>
  .nav__wrap--main .el-popover.el-popper { padding: 0; }
  .nav__wrap--main .el-popover.el-popper a:first-of-type { border-bottom: 1px solid #80808026; }
  .nav__wrap--main .el-popover.el-popper a { align-items: center; display: flex; height: 65px; justify-content: center; }
  .nav__wrap--main .el-popover.el-popper a:hover { background: #f8f8f8; }
  .nav__wrap--main .el-popover.el-popper div:first-of-type { margin: 0.5rem 0; } 
</style>

<script>
// IMPORT
// ----------------------------------
import axios from 'axios';
import Lock from '../components/Lock.vue';
import routes from '../routes/main.js';
import { isRegExp } from 'util';
import { mapState } from '@vuex';
import SvgIcon from '../components/SvgIcon.vue';

// EXPORT
// ----------------------------------
export default {
  components: {
    SvgIcon,
    Lock,
  },
  data() {
    return {
      routes,
      activeRoute: '/',
      // mobileMenuActive: false,
      email: '',
      name: '',
      picture: null,
      treeProps: {
        children: 'children',
        label: 'description'
      },
      isSignup: null,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      // point URL to stage or master
      zinggridUrl: VUE_APP_ZINGGRID_ROOT_URL,
    };
  },
  mounted() {
    this.$store.commit('auth/init_lock');

    // Initial load
    // TODO: check for router load
    const widePaths = ['/demos/create'];

    if(widePaths.includes(window.location.pathname)) {
      this.$store.state.ui.width = 'wide';
    }
    // On route change
    this.$router.beforeEach((to, from, next) => {
      if(widePaths.includes(to.path)) {
        this.$store.state.ui.width = 'wide';
      } else {
        this.$store.state.ui.width = 'narrow';
      }
      next();
    });
    if (this.$route.path === '/demos/create' || this.$route.path === '/builder') {
      this.activeRoute = '/demos';
    } else if (this.$route.path === '/signup') {
      this.isSignup = true;
    } else {
      this.activeRoute = this.$route.path;
      this.isSignup = false;
    }
  },
  computed: {
    ...mapState({
      setAuthIsAuthenticatingLocalStorageObserver: state => state.auth.setAuthIsAuthenticatingLocalStorageObserver,
    }),
    
    planId() {
      return this.$store.state.billing.planId;
    },
    planInterval() {
      return this.$store.state.billing.planInterval;
    },
    lock() {
      return this.$store.state.auth.lock;
    },
    tenantName() {
      return this.$store.state.tenant.name;
    },
    tenantLogo() {
      return this.$store.state.tenant.logo;
    },
    isMobile() {
      const isMobile = this.$store.getters['ui/isMobile'];
      if(!isMobile && this.mobileMenuActive) {
        // Force close menu if the menu is open while resizing
        // this.mobileMenuActive = false;
        this.closeMenu();
      }
      return isMobile;
    },
    appWidthClass() {
      return (this.$store.state.ui.width === 'narrow') ? 'nav__content--narrow' : 'nav__content--wide';
    },
    accountRoutes() {
      const routes = [];
      this.routes.forEach((route) => {
        if(route.path ==='/account') {
          route.children.forEach((child) => {
            routes.push({
              description: child.description,
              path: route.path + '/' + child.path,
            });
          });
        }
      });
      return routes;
    },
    isStudio() {
      return (this.$route.path.includes('/demos'));
    },
    avatar() {
      return (this.$store.state.user && this.$store.state.user.picture) ? this.picture : null;
    },
    currentSection() {
      return (this.isStudio) ? 'Studio': 'Account';
    },
    displayName() {
      return this.name || this.email;
    },
    routeMap() {
      return this.flattenedRoutes.reduce((acc, val) => {
        acc[val.description] = val.path;
        return acc;
      }, {});
    },
    flattenedRoutes() {
      const routes = flatten(this.routes);
      return routes;

      function flatten(routes, currentPath = '', result = [], levels = 0) {
        routes.forEach(({path, description, children}) => {
          if(children) {
            result.concat(flatten(children, currentPath + path + '/', result, levels + 1));
          } else {
            // Strip off any route params.
            let formattedPath = currentPath + path;
            formattedPath = formattedPath.split(':')[0]
            result.push({
              path: formattedPath,
              description,
              levels,
            });
          }
        });
        return result;
      }
    },
    mobileMenuActive() {
      return this.$store.state.ui.isMenuOpen;
    }
  },
  methods: {
    launch(path) {
      let query = window.location.href.split('?')[1];
      query = query ? `?${query}` : '';
      let _path = `${path}${query}`;
      if (this.$route.path !== _path) this.$router.push(_path);
    },
    updateWindowSize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    navigate(command) {
      if(command === 'logout') {
        this.logout();
      } else {
        if (this.$route.path !== command) this.$router.push(command);
        this.closeMenu();
      }
    },
    openMenu() {
      this.$store.commit('ui/menuStatus', true);
    },
    closeMenu() {
      this.$store.commit('ui/menuStatus', false);
    },
    logout() {
      this.$store.commit('auth/logout');
      // Don't run window.location since it refreshes the app
      if (this.$route.path !== '/') this.$router.push('/');
    }
  }
}
</script>
