<template>
  <aside
    :create-sidebar="show"
    :open="sidebarOpen">
    <app-sidebar
      :active-primary="activePrimary"
      :open="sidebarOpen"
      :sidebars="sidebarPrimary">
    </app-sidebar>
  </aside>
</template>

<script>
  import AppSidebar from '../../../components/AppSidebar.vue';

  export default {
    props: ['show', 'uid'],
    components: {
      AppSidebar,
    },
    computed: {
      sidebarOpen() {
        let count = 0;
        let interval = setInterval(() => {
          this.$emit('resize');
          count++;
          if (count === 10) clearInterval(interval);
        }, 100);
        return this.$store.state.ui['isSidebarOpen'];
      },
    },
    data() {
      return {
        activePrimary:'docs',
        sidebarPrimary: {
          'docs': {
            id: 'docs',
            sidebar: [
              {
                id: 'docs',
                name: 'Related Docs'
              }
            ],
          },
        },
      };
    },
    watch: {
      uid() {
        this.fetchRelatedDocs(this.uid);
      },
    },
    methods: {
      /**
       * @description Format text to capitalize first letter of each word
       * @param {String} text - text to format
       */
      capitalize(text) {
        let retval = '';
        let words = text.split(' ');
        words.forEach((word, index) => {
          if (index !== 0) retval += ' ';
          retval += `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`;
        });
        return retval;
      },
      /**
       * @description Add class to enable sidebar transition
       */
      enableTransition() {
        this.$nextTick(() => {
          let el = document.querySelector('.content--hasSidebar--wide');
          if (el) el.classList.add('ready');
        });
      },
      /**
       * @description Fetch relatedDocs metadata to populate sidebar
       */
      fetchRelatedDocs(uid) {
        this.$api('metadata/read', {
          uid,
          format: 'sidebar',
        }).then((result) => {
          let relatedDocs = result.data.relatedDocs;
          let sidebar = this.sidebarPrimary.docs.sidebar;

          if (relatedDocs && relatedDocs.length > 0) {
            // Add related docs sidebar items
            relatedDocs.forEach((doc) => {
              sidebar.push({
                id: doc.name,
                name: this.capitalize(doc.name),
                url: doc.url,
              })
            });

            this.$emit('show');
            this.enableTransition();
          } else {
            // Placeholder for no docs
            sidebar.push({
              id: `na_${(new Date).valueOf()}`,
              name: 'N/A',
            });
          }
        });
      },
    },
  }
</script>

<style scoped>
  /* Hide Sidebar */
  [create-sidebar="hidden"] {
    max-width: 0;
    overflow: hidden;
    transform: translate3d(0,0,0);
  }
</style>