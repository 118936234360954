<template>
  <div pricing="wrap">
    <el-button pricing="btn--closeform" @click="closeView">Back to Pricing</el-button>
    <section>
      <div class="inner">
        <div pricing="intro" loaded="true">
          <h1>{{title}}</h1>
        </div>

        <!-- Step 1: Subscription Form -->
        <!-- Mobile view of price box -->
        <price-box
          v-if="view === 0"
          @toggle="toggleInterval"
          class="desktop--hide"
          :plan.sync="plan"
          :plan-interval.sync="planInterval"
          :interval.sync="interval"
          :isPerpetual.sync="isPerpetual">
        </price-box>

        <div v-if="view === 0" pricing="container--box">
          <!-- Subscription Type -->
          <!-- Tablet/Desktop view of price box -->
          <div class="price-box-wrapper">
            <price-box
              v-if="view === 0"
              @toggle="toggleInterval"
              class="mobile--hide tablet--hide"
              :plan.sync="plan"
              :plan-interval.sync="planInterval"
              :interval.sync="interval"
              :isPerpetual.sync="isPerpetual">
            </price-box>
          </div>
          <hr pricing="divider--vertical">

          <!-- Subscription Details -->
          <div>
            <h2 pricing="h2">License &amp; Payment Details</h2>
            <hr pricing="divider--horizontal">

            <subscription-form
              :form-pricing="formPricing"
              :include-domain-fields="includeDomainFields"
              :plan.sync="plan"
              :submitAction="nextStep"
            ></subscription-form>
          </div>
        </div>

        <!-- Step 2: Account Association -->
        <div v-if="view === 1" :pricing="loginOnly ? 'container--medium--box' : 'container--large'">
          <!-- Login -->
          <div pricing="container--flex-column">
            <h2 pricing="h2">{{loginOnly ? 'Login to complete account creation!' : 'Already have an account?'}}</h2>
            <hr pricing="divider--horizontal">

            <div @click="pushAccountDataLayer('existing', 'google')">
              <google-entry popup>Log in with Google</google-entry>
            </div>

            <p pricing="entry-text">or use you email to log in</p>

            <el-form @validate="formLoginValidation" pricing="formLogin" ref="formLogin" :model="formLogin" hide-required-asterisk label-position="top" :rules="formLoginValidationRules">
              <el-form-item label="Email" prop="email">
                <el-input v-model="formLogin.email" placeholder="Enter email address" type="email"></el-input>
              </el-form-item>
              <el-form-item label="Password" prop="password">
                <el-input v-model="formLogin.password" placeholder="Enter password" type="password"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="userEntry('login')" :disabled="formLoginIncomplete" type="primary">Log In</el-button>
              </el-form-item>
            </el-form>
            <el-button @click="resetModalVisible=true" type="text">Forgot your password</el-button>
          </div>

          <!-- Signup -->
          <div v-if="!loginOnly" pricing="container--box--flex-column">
            <h2 pricing="h2">Don't have an account?</h2>
            <hr pricing="divider--horizontal">

            <div @click="pushAccountDataLayer('new', 'google')">
              <google-entry popup signup>Sign Up with Google</google-entry>
            </div>

            <p pricing="entry-text">or use you email to log in</p>

            <el-form
              @validate="formSignupValidation"
              pricing="formSignup"
              ref="formSignup"
              :model="formSignup"
              hide-required-asterisk
              label-position="top"
              :rules="formSignupValidationRules">
              <el-form-item label="Email" prop="email">
                <el-input v-model="formSignup.email" placeholder="Enter email address" type="email"></el-input>
              </el-form-item>
              <el-form-item label="Choose a Password" prop="password">
                <el-input
                  v-model="formSignup.password"
                  placeholder="Enter password"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item label="Confirm Password" prop="passwordConfirm">
                <el-input
                  v-model="formSignup.passwordConfirm"
                  placeholder="Confirm password"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="userEntry('signup')" :disabled="formSignupIncomplete" type="primary">Sign Up</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <!-- Step 3: Purchase Confirmation -->
        <div v-if="view === 2" pricing="container--large--box">
          <!-- Subscription Type -->
          <div pricing="confirmation">
            <h2 pricing="h2">Account Successfully Created</h2>
            <hr pricing="divider--horizontal">
            <div pricing="text--light">
              <p pricing="text-box">
                <template v-if="entryState == 'signup'">An account associated with the email <span pricing="bold">{{formSignup.email || userEmail}}</span> has been created successfully</template>
                <template v-else-if="entryState == 'login'">You've successfully logged in to the account associated with the email <span pricing="bold">{{formLogin.email || userEmail}}</span></template>
                <template v-else>Your account has been successfully associated with this subscription.</template>
              </p>
              <p>When you complete this order, your subscription will appear in the Subscriptions section in the ZingSoft Studio.</p>
              <p>You can manage your licensed domain and subdomain in the Subscriptions section anytime.</p>
            </div>
          </div>

          <hr pricing="divider--vertical">

          <!-- Subscription Details -->
          <div pricing="confirmation--flex-1">
            <h2 pricing="h2">Confirm License Details</h2>
            <hr pricing="divider--horizontal">
            <div pricing="text--light">
              <p><span pricing="bold--primary">Subscription: </span>{{plan && plan.name}} License</p>
              <p><span pricing="bold--primary">Name: </span>{{formPricing.nameFirst}} {{formPricing.nameLast}}</p>
              <p><span pricing="bold--primary">Licensed Domain:</span> {{formPricing.domainOptional}} (you can edit this later)</p>
              <p><span pricing="bold--primary">Licensed Subdomain:</span> {{formPricing.subdomainOptional}} (you can edit this later)</p>
              <p><span pricing="bold--primary">Company: </span>{{formPricing.company}}</p>
              <p><span pricing="bold--primary">Credit Card Number: </span>**** **** **** {{formPricing.cardNumber.substring(formPricing.cardNumber.length-4)}}</p>
              <p><span pricing="bold--primary">Cread Card CVC: </span>***</p>
              <p><span pricing="bold--primary">Credit Card Expiration: </span> {{formPricing.cardExpiration}}</p>
              <!-- TODO COUPON remove when enable <p><span pricing="bold--primary">Discount Code: </span>{{formPricing.discountOptional}}</p> -->
            </div>
            <div pricing="button-flex">
              <el-button @click="view = view-2" plain type="danger">Edit Info</el-button>
              <el-button @click="placeOrder" :disabled="!hasUser" :loading="placingOrder" type="primary">Place Order</el-button>
            </div>
          </div>
        </div>

        <!-- Step 4: Purchase Complete -->
        <div v-if="view === 3" pricing="container--small--box--center--flex-column--nowrap">
          <h2 pricing="h2">Your subscription is now active!</h2>
          <hr pricing="divider--horizontal">

          <!-- Logged in user -->
          <template v-if="entryState">
            <p pricing="entry-text--bold--highlight">Payment successfully processed.</p>
            <p pricing="entry-text">Thanks for your purchase.</p>
            <p pricing="entry-text">Visit the Studio to manage your subscription.</p>
          </template>
          <!-- Signup / Logged out user -->
          <template v-else>
            <p pricing="entry-text">Thanks for your purchase.</p>
            <p pricing="entry-text--bold--highlight">Your payment has been processed successfully.</p>
            <p pricing="entry-text"><a href="/account/subscriptions" target="_blank">Visit the Studio</a> to manage your subscription.</p>
          </template>
          
          <div pricing="button-flex">
            <el-button @click="openTab('account')" type="primary">View Account</el-button>
            <el-button @click="openTab('subscriptions')" pricing="button-secondary" type="primary">Manage License</el-button>
          </div>
        </div>

      </div>
    </section>

    <!-- Reset Modal -->
    <reset-modal @close="resetModalVisible=false" embed="false" :visible.sync="resetModalVisible"></reset-modal>
  
    <!-- Privacy Settings Modal -->
    <my-dialog
      title="Please enable 3rd Party Cookies"
      type="danger"
      :visible="privacyModalVisible">
        <div slot="content">
          <p>To continue checkout process, please change your browser settings to allow third-party cookies.</p>
        </div>
        <div slot="options">
          <el-button @click="checkPrivacySettings(true)" type="primary">Reload Page</el-button>
        </div>
    </my-dialog>
  
    <!-- Support Modal -->
    <my-dialog
      title="Your Purchase Could Not Be Completed"
      type="danger"
      @close="supportModalVisible=false"
      :visible="supportModalVisible">
        <div slot="content">
          <p>A sales representative will later contact and assist you.</p>
          <p>Or contact us at <a href="mailto:sales@zingsoft.com">sales@zingsoft.com</a>.</p>
        </div>
        <div slot="options">
          <el-button @click="supportModalVisible=false" type="primary">OK</el-button>
        </div>
    </my-dialog>

    <!-- Hidden div to check user triggers signup/login -->
    <div style="display: none;">{{userPopupAuth}}{{userPopupAuthSignup}}{{setupEntry}}</div>
  </div>
</template>

<script>
import axios from 'axios';
import GoogleEntry from './components/GoogleEntry.vue';
import MyDialog from '../../components/MyDialog.vue';
import PriceBox from './components/PriceBox.vue';
import ResetModal from './components/ResetModal.vue';
import SubscriptionForm from './components/SubscriptionForm.vue';
import { mapState } from '@vuex';
export default {
  components: {
    GoogleEntry,
    MyDialog,
    PriceBox,
    ResetModal,
    SubscriptionForm,
  },
  data() {
    // CONSTANTS
    const REQUIRED = 'Field required';

    // CUSTOM VALIDATION RULES
    /**
     * @description Checks if password meets requirements (8 chars, 1 uppercase, 1 lowercase, 1 number)
     * @param {Object} rule - validation rule corresponding to field being evaluated
     * @param {String} value - value of field being validated
     * @param {Function} callback - callback function to invoke when validation completed
     */
    let validateSignupPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Required Field'));
      } else {
        // Check if password meets requirement
        let atLeast8Char = value.length >= 8;
        let hasUppercase = /[A-Z]/.test(value);
        let hasLowercase = /[a-z]/.test(value);
        let hasNumber = /[0-9]/.test(value);
        if (!(atLeast8Char && hasUppercase && hasLowercase && hasNumber)) {
          // Error when password does not reach requirements
          callback(new Error(`Password expected to have at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number`));
        } else {
          // Validates password confirmation to check if it matches (checks only if non-empty)
          let confirmField = this.formSignup.passwordConfirm;
          if (confirmField && confirmField.length !== 0) this.$refs.formSignup.validateField('passwordConfirm');
        }
        callback();
      }
    };
    /**
     * @description Checks if password confirmation matches
     * @param {Object} rule - validation rule corresponding to field being evaluated
     * @param {String} value - value of field being validated
     * @param {Function} callback - callback function to invoke when validation completed
     */
    let validatePasswordConfirm = (rule, value, callback) => {
      if (value === '') {
        // Error for when field is empty
        callback(new Error('Please confirm password'));
      } else if (value !== this.formSignup.password) {
        // Error when passwords do not match
        callback(new Error('Passwords do not match'));
      } else {
        callback();
      }
    };

    // DATA
    return {
      cardBrand: null,
      currentInterval: null,  // Interval of subscription: 'yearly, 'perpetual'
      dataLayerObject: null,
      isPerpetual: false,        // Is interval 'perpetual'
      false: false,           // Data value to feed to attribute to turn off feature
      failedAttempts: 0,      // Track failed attempts and display form to contact sales
      plan: {                 // Subscription plan details
        name: '',
        description: [],
        tags: '',
      },
      planId: null,
      planInterval: {         // Currently subscription plan interval amount
        amount: '-',
      },
      formLogin: {            // Login form
        email: '',
        password: '',
      },
      formPricing: {          // Subscription details form
        nameFirst: '',
        nameLast: '',
        domainOptional: '',
        subdomainOptional: '',
        company: '',
        cardNumber: '',
        cardExpiration: '',
        cardCvc: '',
        chargeOptional: '',
        // discountOptional: '',  // TODO COUPON remove when enable
      },
      formLoginValidated: true, // Whether login form passes validation
      formLoginValidationRules: { // Rules to validate login
        email: [
          {required: true, trigger: 'blur', message: REQUIRED},
          {type: 'email', trigger: 'blur', message: 'Invalid email'},
        ],
        password: [{required: true, trigger: 'blur', message: REQUIRED}],
      },
      formSignup: { // Signup form
        email: '',
        password: '',
        passwordConfirm: '',
      },
      formSignupValidated: true,  // Whether signup form passes validation
      formSignupValidationRules: {  // Rules to validate signup
        email: [
          {required: true, trigger: 'blur', message: REQUIRED},
          {type: 'email', trigger: 'blur', message: 'Invalid email'},
        ],
        password: [{validator: validateSignupPassword, trigger: 'blur'}],
        passwordConfirm: [{validator: validatePasswordConfirm, trigger: 'blur'}],
      },
      loadingMask: false,
      loginOnly: false, // Flag to show login form only after Google auth signup
      placingOrder: false,  // Flag to display loading state on button when processing order
      privacyModalVisible: false, // Visibility of privacy modal
      resetModalVisible: false, // Flag to display reset moddal for password reset
      supportModalVisible: false, // Visibility of support modal
      entryState: null, // How the user enters the app - 'login', 'signup'
      redirectPath: null, // One of two flag to determine if need to manually setup after entry
      transactionId: null,
      userEmail: null,
      view: 0,  // Which view/step of the subscription process user is on
    };
  },
  computed: {
    // Variable used to prevent flashing during login / signup process
    ...mapState({
      setAuthIsAuthenticatingLocalStorage: state => state.auth.setAuthIsAuthenticatingLocalStorage,
      setAuthIsNewSignupLocalStorage: state => state.auth.setAuthIsNewSignupLocalStorage,
    }),
    // Determines if user if logged in
    hasUser() {
      return !!this.$store.state.user['user_id'];
    },
    // Subscription interval currently being displayed
    interval() {
      if (this.currentInterval) {
        if (this.currentInterval === 'annual') this.currentInterval = 'yearly';
        return this.currentInterval === 'yearly' ? 'year' : 'perpetual';
      }
    },
    // Determines if login form fields non-empty and validated
    formLoginIncomplete() {
      let disable = false;
      let keys = Object.keys(this.formLogin);
      // Check if field is nonempty
      keys.forEach(field => {
        if (!this.formLogin[field] || this.formLogin[field].length === 0) disable = true;
      });
      // Check if form validated
      if (!this.formLoginValidated) disable = true;
      return disable;
    },
    // Determines if signup form fields non-empty and validated
    formSignupIncomplete() {
      let disable = false;
      let keys = Object.keys(this.formSignup);
      // Check if field is nonempty
      keys.forEach(field => {
        if (!this.formSignup[field] || this.formSignup[field].length === 0) disable = true;
      });
      // Check if form validated
      if (!this.formSignupValidated) disable = true;
      return disable;
    },
    // Determines if subscription form should includes fields for domain and subdomains
    includeDomainFields() {
      // Do not include for enterprise licenses
      return this.planId ? !this.planId.includes('-ENT') : true;
    },
    productType() {
      let planName = this.plan.Name;
      return planName.slice(0, 2) === 'ZC' ? 'zingchart' : 'zinggrid';
    },
    // Setup after login/signup and waiting to fetch session data
    setupEntry() {
      if (!!this.$store.state.user['user_id'] && this.redirectPath && this.redirectPath.includes('/subscribe')) {
        this.setupManualEntry();
      }
      return this.redirectPath && this.$store.state.user['user_id'];
    },
    // Text to display as title of current view
    title() {
      if (this.view === 0) return 'Enter Your Subscription Details';
      else if (this.view === 1) return 'Associate Your License with an Account';
      else if (this.view === 2) return 'Confirm Your Purchase';
      else return 'Purchase Complete';
    },
    userId() {
      return this.$store.state.user['user_id'];
    },
    // After Google auth login, proceeed to next view
    userPopupAuth() {
      if (this.$store.state.auth.popupAuth && this.view === 1) this.view++;
      return this.$store.state.auth.popupAuth;
    },
    // After Google auth signup, prompt user to login
    userPopupAuthSignup() {
      if (this.$store.state.auth.popupAuthSignup) {
        this.loginOnly = true;
      }
      return this.$store.state.auth.popupAuthSignup;
    },
  },
  beforeCreate() {
    // Fetch data to prefill fields
    axios({
      url: '/api/user',
      method: 'GET',
      headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
    }).then((data) => {
      let userData = data.data;
      this.userEmail = userData.email;
    });
  },
  mounted() {
    // Check if 3rd party cookies enabled
    this.checkPrivacySettings();

    // Grab subscription details based on query params that were appended when redirected from pricing page
    this.getSubscription();

    // Determine if redirected after login/signup
    if (!!localStorage.getItem('isAuthenticating')) {
      this.loadingMask = this.$loading();
    }
    this.redirectPath = localStorage.getItem('redirectPath');
    if (!this.redirectPath && this.loadingMask) {
      this.loadingMask.close();
    }
  },
  methods: {
    /**
     * @description Add card to user account if card not retrieved. Retrieved cards
     * contains '*' to mask card number.
     */
    addCard() {
      return new Promise((resolve) => {
        if (!this.formPricing.cardNumber.includes('*')) {
          let cardExpiration = this.formPricing.cardExpiration.split('/');
          let card = {
            number: this.formPricing.cardNumber,
            exp_month: parseInt(cardExpiration[0]),
            exp_year: parseInt(cardExpiration[1]),
            cvc: this.formPricing.cardCvc,
          };
          if (this.formPricing.chargeOptional) card.name = this.formPricing.chargeOptional;
          
          axios({
            url: '/api/card',
            method: 'POST',
            data: {'card': card},
            headers: {'Authorization': `Bearer ${this.$store.state.auth.idToken}`},
          }).then(result => {
            this.cardBrand = this.getCardBrand(result.data);
            resolve(true);
          }).catch((err) => {
            let message = err;
            if (err.response) {
              if (err.response.data) {
                if (err.response.data.message) message = err.response.data.message;
                else message = err.response.data;
              };
            } else {
              message = err.response;
            };

            this.failedAttempts++;
            if (this.failedAttempts > 1) this.support(message);

            this.$message({
              duration: 10000,
              message: `${message}`,
              showClose: true,
              type: 'error',
            });
            resolve(false);
          });
        } else {
          resolve(true);
        }
      });
    },
    /**
     * @description Associate user data to account
     */
    associateData() {
      let userData = {
        company: this.formPricing.company,
        name: `${this.formPricing.nameFirst} ${this.formPricing.nameLast}`,
      };

      return new Promise((resolve) => {
        axios({
          url: '/api/user',
          method: 'POST',
          data: userData,
          headers: {'Authorization': `Bearer ${this.$store.state.auth.idToken}`},
        }).then(result => {
          this.$store.dispatch('refresh_state');
          resolve(true);
        });
      });
    },
    /**
     * @description Checks for access of localStorage to determine if 3rd party cookies allowed.
     * If not allowed, display modal to inform users to enable cookies. 
     * 3rd party cookies are usually disabled when access subscription process through an iframe on
     * ZingChart or ZingGrid site.
     * @param {Boolean} retry - Retry by refreshing page
     */
    checkPrivacySettings(retry) {
      if (retry) {
        // Refresh page with new privacy settings
        window.top.postMessage('Reload Page', '*');
      } else {
        // Check for localStorage access
        try {
          localStorage.setItem('test', 'test');
          localStorage.removeItem('test');
        } catch (error) {
          this.privacyModalVisible = true;
        }
      }
    },
    closeView() {
      // Close iframe
      window.top.postMessage('Close Form', '*');
    },
    /**
     * @description Returns active plan from `this.plan`, array of objects
     * @param {Array} arr - array to filter through
     * @param {String} prop - property to filter on
     * @param {String} val - value to filter for
     */
    filterPlan(arr, prop, val) {
      return arr.filter(el => {
        if (val.toLowerCase().includes(el[prop].toLowerCase())) return el;
      })[0];
    },
    /**
     * @description Helper method that `OR` the results of form items to determine if
     * form is validated and to enable submit button
     * @param validated - whether form item is validated
     * @param form - form to determine validation
     */
    formValidation(validated, form) {
      let formValidated = `${form}Validated`;
      // If form previously validated and form item is validated => form remains validated
      if (validated && this[formValidated]) this[formValidated] = true;
      // If form item is invalidated = > form becomes invalidated
      else if (!validated) this[formValidated] = false;
      else {
        // Check if any form item is invalidated
        setTimeout(() => {
          let invalidatedFormItem = document.querySelector(`[pricing="${form}"] .is-error`);
          this[formValidated] = !invalidatedFormItem;
        }, 500);
      }
    },
    /**
     * @description Triggered when a form item is validated to return if validation passes
     * @param prop - prop name of form item being validated
     * @param validated - whether form item is validated
     */
    formLoginValidation(prop, validated) {
      this.formValidation(validated, 'formLogin')
    },
    /**
     * @description Triggered when a form item is validated to return if validation passes
     * @param prop - prop name of form item being validated
     * @param validated - whether form item is validated
     */
    formSignupValidation(prop, validated) {
      this.formValidation(validated, 'formSignup')
    },
    
    getCardBrand(card) {
      if (card && (card.brand || card.cardType)) {
        let cardBrand = (card.brand || card.cardType).toLowerCase();
        if (cardBrand === 'mc') cardBrand = 'mastercard';
        else if (cardBrand === 'disc') cardBrand = 'discover';
        return cardBrand;
      };
      return null;
    },
    /**
     * @description Grab all plan descriptions and update `plan.description` with details of
     * which features are included in plan
     */
    getPlanDescriptions(plan) {
      let features = [];
      // Gather all unique features
      plan.forEach(p => {
        p.features = p.features.map(
          feature => feature.toLowerCase() || feature.text.toLowerCase()
        );

        p.features.forEach(f => {
          let feature = f.toLowerCase();
          if (
            features.filter(fea => {
              if (fea === feature) return feature;
            }).length === 0
          ) {
            features.push(feature);
          }
        });
      });

      // Mark which features are included in plan
      plan.forEach(p => {
        p.features = features.map(fea => {
          let formatted = `${fea.slice(0, 1).toUpperCase()}${fea
            .slice(1)
            .toLowerCase()}`;
          return {
            text: formatted,
            disabled: p.features.indexOf(fea) === -1
          };
        });
      });
    },
    /**
     * @description Get subscription plans based on query params `planInterval` and `planId`
     */
    getSubscription() {
      let { planInterval, planId } = this.$route.query;
      this.planId = planId || 'ZG-S-MUL';
      this.currentInterval = planInterval || 'yearly';
      this.isPerpetual = this.currentInterval === 'perpetual' ? true : false;

      // Make sure product is purchasable via studio
      if (this.planId && !this.planId.includes('-S-')) this.planId = 'ZG-S-MUL';

      let displayPlan = (result) => {
        let plans = Object.keys(result.data).map((plan) => result.data[plan]);
        this.plan = this.filterPlan(plans, 'Sku', this.planId);

        // Retry getting plan using fallback
        if (!this.plan) this.plan = this.filterPlan(plans, 'Sku', 'ZG-S-MUL');
        
        // Get plan interval
        if (this.plan) {
          this.planInterval = this.filterPlan(
            this.plan.plans,
            'interval',
            this.currentInterval.includes('year') ? 'year' : 'perpetual',
          );
        }
      };

      axios({
        url: `/api/plan?plan=${this.planId}`,
        method: 'GET'
      }).then(result => {
        displayPlan(result);
      });
    },
    /**
     * @description Initialize datalayer object when empty.
     * Initialization occurs:
     * - After first step
     * - After second step when user logs in or signup through email
     */
    initDataLayerObject() {
      if (!this.dataLayerObject) {
        this.dataLayerObject = {
          'event': `${this.productType}Checkout`,
          'product-name': this.productType,
          'license-interval': this.currentInterval,
          'license-id': this.planId.toUpperCase(),
          'license-type': this.plan.name.toLowerCase(),
          'license-price': this.planInterval.UnitPrice,
          'license-domains-count': this.formPricing.domainOptional.length > 0 ? 1 : 0,
          'license-domains-name': [this.formPricing.domainOptional],
          'license-subdomains-count': this.formPricing.subdomainOptional.length > 0 ? 1 : 0,
          'license-subdomains-name': [this.formPricing.subdomainOptional],
          'license-company': this.formPricing.company,
        };
      }
    },
    /**
     * @description Goes to the next step from the first step (Pricing)
     */
    nextStep() {
      // Skip account association step if user already logged in
      if (this.hasUser) {
        // Skip account association step if user logged in
        this.view += 2;
      } else {
        // Go to account association step
        this.view++;
      };

      // Push data to dataLayer
      this.initDataLayerObject();
      if (this.userId) this.dataLayerObject['user-id'] = this.userId;
      window.dataLayer.push(Object.assign({
        'checkout-step': '1',
        'checkout-name': 'plan', 
      }, this.dataLayerObject));

      window.dataLayer.push(Object.assign({
        'checkout-step': '2',
        'checkout-name': 'info', 
      }, this.dataLayerObject));
    },
    /**
     * @description Open new tab to specified page
     * @param {String} page - page to open
     */
    openTab(page) {
      let url = null;
      switch (page) {
        case 'account': url = '/account'; break;
        default: url = '/account/subscriptions'; break;
      }
      window.open(url,'_blank');
    },
    /**
     * @description Places order by adding card and subscribing to product. Also increments view.
     */
    async placeOrder() {
      // Button state
      this.placingOrder = true;
      // Associate user information to account
      await this.associateData();
      // Add card
      let success = await this.addCard();
      // Subscribe to product
      if (success) success = await this.subscribe();
      else this.placingOrder = false;
      // Go to next view
      if (success) this.view++;
      else this.placingOrder = false;

      // Push data to dataLayer 
      this.initDataLayerObject();
      window.dataLayer.push(Object.assign({
        'checkout-step': '4',
        'checkout-name': 'purchase',
        'card-type': this.cardBrand,
        'transaction-id': this.transactionId,
        'user-id': this.userId,
      }, this.dataLayerObject));
    },
    /**
     * @description Push data layer info when associating purchase to account
     * @param {Enum} accountAction - Indicates whether customer created a new account
     * or associating to an existing account ('existing' || 'new')
     * @param {Enum} accountType - type of registration ('google' || 'email')
     */
    pushAccountDataLayer(accountAction, accountType) {
      // For google entry, set flag
      if (accountType === 'google') {
        this.entryState = accountAction === 'existing' ? 'login' : 'signup';
      };

      // Push data layer
      this.dataLayerObject['account-action'] = accountAction;
      this.dataLayerObject['account-type'] = accountType;
      window.dataLayer.push(Object.assign({
        'checkout-step': '3',
        'checkout-name': 'account',
      }, this.dataLayerObject));
    },
    /**
     * @description After manual user login / signup, redirects back to this page with user information which is used to login user.
     * Grab data placed in local storage to continue subscription process.
     */
    setupManualEntry() {
      // For users logging in, grab hash to log user in
      let hash = this.$route.hash;
      if (hash) {
        // Get user token => used to log user in
        let idToken = hash.slice(hash.indexOf('=')+1, hash.indexOf('&'));
        localStorage.setItem('id_token', true);
        // Log user in by placing token into state and refresh state
        axios({
          url: '/api/user/serversession',
          method: 'POST',
          headers: { 'Authorization': `Bearer ${idToken}` },
        }).then(() => {
          this.$store.commit('auth/add_tokens', { idToken });
          if (this.$store.state.auth.parsedToken && this.$store.state.auth.parsedToken.sub.indexOf('auth0|') > -1) {
            this.$store.dispatch('refresh_state', { idToken });
          }
        });
      };

      // Check if user redirected back to this page. If yes, then go to next step
      // and load data from server session
      if (this.redirectPath && this.redirectPath.includes('/subscribe')) {
        // Go to next step in subscription process
        this.view = 2;
        if (this.loadingMask) this.loadingMask.close()

        // Grab subscription data and clear local storage
        axios({
          url: '/api/user/serversession',
          method: 'POST',
          headers: {'Authorization': `Bearer ${this.$store.state.auth.idToken}`},
        }).then((session) => {
          if (session.data.entryState) {
            let dataKeys = ['currentInterval', 'dataLayerObject', 'isPerpetual', 'plan', 'planInterval', 'formPricing', 'entryState'];
            dataKeys.forEach(data => { this[data] = session.data[data]; });

            // Clear local storage and session
            localStorage.removeItem('redirectPath');
          }
        });
      };
    },
    /**
     * @description Displays after 2 failed attempts (this.failAttempts). Creates form to submit to manually contact sales.
     */
    support(err) {
      // Display support modal
      this.supportModalVisible = true;

      // Forward information to ZingSoft intercom in form of message
      axios({
        url: '/api/subscription/log',
        method: 'POST',
        headers: {'Authorization': `Bearer ${this.$store.state.auth.idToken}`},
        data: {
          type: 'log',
          email: this.formSignup.email || this.formLogin.email || this.userEmail,
          name: `${this.formPricing.nameFirst} ${this.formPricing.nameLast}`,
          company: this.formPricing.company,
          subscription: `${this.productType} ${this.plan && this.plan.name}`,
          domains: this.formPricing.domainOptional,
          subdomains: this.formPricing.subdomainOptional,
          errMessage: err
        },
      });
    },
    /**
     * @description Subscribe to product
     */
    subscribe() {
      return new Promise((resolve) => {
        let subscription = {
          // coupon: this.formPricing.discountOptional, // TODO COUPON remove when enable
          domains: [this.formPricing.domainOptional],
          subdomains: [this.formPricing.subdomainOptional],
          planSku: this.planInterval.Sku,
        };

        axios({
          url: '/api/subscription',
          method: 'POST',
          data: subscription,
          headers: {'Authorization': `Bearer ${this.$store.state.auth.idToken}`},
        }).then(result => {
          if (result.status === 202) {
            this.$message({
              duration: 1000,
              message: 'Invalid domains/subdomains were found and not added. Please contact us or try register domains/subdomains again.',
              showClose: true,
              type: 'warning',
            })
          };
          this.transactionId = result.data.Id;
          resolve(true);
        }).catch((err) => {        
          this.failedAttempts++;
          if (this.failedAttempts > 1) this.support(err.response.data);

          this.$message({
            duration: 10000,
            message: `Cannot subscribe. ${err.response.data}`,
            showClose: true,
            type: 'error',
          });
          resolve(false);
        });
      });
    },
    /**
     * @description Explicitly set to TRUE or FALSE or just toggle boolean state
     * @param {*} type - The target type to use
     */
    toggleInterval() {
      // Toggle state
      this.isPerpetual = !this.isPerpetual;
      this.currentInterval = this.currentInterval === 'yearly' ? 'perpetual' : 'yearly';
      // Update Plan
      this.planInterval = this.filterPlan(
        this.plan.plans,
        'interval',
        this.interval
      );
    },
    /**
     * @description Non-auth user login / signup. Place data in localstorage that is later grabbed in `setupManualEntry` after redirecting.
     * @param {String} entry - determines entry type: 'login', 'signup'
     */
    userEntry(entry) {
      // Push data to dataLayer
      this.pushAccountDataLayer(entry === 'login' ? 'existing' : 'new', 'email');

      // Save information for subscription process and account association
      let subscriptionData = {
        currentInterval: this.currentInterval,
        dataLayerObject: this.dataLayerObject,
        isPerpetual: this.isPerpetual,
        plan: this.plan,
        planInterval: this.planInterval,
        formPricing: this.formPricing,
        entryState: entry,
      }
      let { planInterval, planId } = this.$route.query;
      localStorage.setItem('redirectPath', `/subscribe?planId=${planId}&planInterval=${planInterval}` );

      axios({
        url: '/api/user/serversession',
        method: 'POST',
        data: { 'set': subscriptionData },
      }).then(() => {
        // For signup, update state to setup account
        if (entry === 'signup') this.setAuthIsNewSignupLocalStorage();
        
        // Toggle localStorage 'auth' state
        this.setAuthIsAuthenticatingLocalStorage();
        // Auth0 non-auth login
        let form = entry === 'login' ? this.formLogin : this.formSignup;
        this.$store.dispatch(`auth/${entry}`, {
          email: form['email'],
          password: form['password'],
          alert: this.$message
        });
      });
    },
  }
};
</script>

<style src="./styles/subscription.css" scoped></style>

<style>
/* Element Ui Overwrites */
[pricing] .el-button {
  border-radius: 6px !important;
  font-size: 0.8125rem;
  font-weight: 600;
  transition: background-color .1s,border-color .1s,color .1s !important;
}
[pricing] .el-button.el-button--default {
  border-color: #b9bfc5 !important;
  color: #b9bfc5 !important;
}
[pricing] .el-button.el-button--primary {
  background: #04a3f5 !important;
  border-color: #04a3f5 !important;
  color: #fff !important;
}
[pricing] .el-button.el-button--primary:not(.is-disabled):hover {
  background-color: var(--color-tertiary-5) !important;
  border-color: transparent !important;
}
[pricing] .el-button.is-disabled {
  background: #ebebeb !important;
  border-color: #ebebeb !important;
  color: #fff !important;
}
[pricing] .el-button--text {
  color: var(--color-tertiary-7) !important;
  font-weight: 800;
  margin-top: 1rem;
  text-decoration: underline;
}
[pricing] .el-button.el-button--danger.is-plain {
  background-color: #fff !important;
  border: 1px solid #dd1659 !important;
  color: #dd1659 !important;
}
[pricing] .el-date-editor.el-input {
  width: 100% !important;
}
[pricing] .el-date-editor input,
[pricing] .el-input input {
  border-color: #dedede !important;
  color: #357083 !important;
  height: 2.5rem !important;
  font-size: 0.875rem !important;
  letter-spacing: 0.02rem;
  padding: 0 15px !important;
}
[pricing] .el-input input::placeholder {
  color: #d2d2d2 !important;
  font-size: 0.75rem !important;
  letter-spacing: 0.02rem !important;
}
[pricing] .el-form-item {
  margin-bottom: 0.5rem;
}
[pricing] .el-form-item__content {
  line-height: inherit;
}
[pricing] .el-form-item__error {
  position: relative !important;
}
[pricing] .el-form-item__label {
  color: #808486;
  font-size: 0.6875rem;
  line-height: 1.5rem;
  padding: 0.15rem 0 0.25rem !important;
}
[pricing="formLogin"] .el-form-item__label,
[pricing="formSignup"] .el-form-item__label {
  color: var(--color-primary-1);
  font-weight: 500;
}
[pricing="button-secondary"].el-button.el-button--primary {
  background: #00394b !important;
  border-color: #00394b !important;
}
[pricing="button-secondary"].el-button.el-button--primary:hover {
  background-color: #778c92 !important;
  border-color: transparent !important;
}
[pricing="button-flex"],
[pricing*="form"] > .el-form-item:last-of-type .el-form-item__content {
  display: flex;
  margin-top: 1.25rem;
}
[pricing="button-flex"] .el-button,
[pricing*="form"] .el-button {
  font-size: 0.8125rem;
  font-weight: 600;
}
[pricing="button-flex"] .el-button + .el-button,
[pricing*="form"] .el-button + .el-button {
  margin-left: auto;
}
[pricing="button-flex"] .el-button,
[pricing="formPricing"] .el-button {
  width: 45.8333%;
}
[pricing="btn--closeform"].el-button.el-button--default {
  background-color: #fff;
  border-color: #003748 !important;
  color: #003748 !important;
  left: 1rem;
  position: fixed;
  top: 1rem;
  z-index: 999;
}
[pricing="formLogin"] .el-button,
[pricing="formSignup"] .el-button {
  width: 100%;
}
@media screen and (max-width: 400px) {
  [pricing="button-flex"] {
    flex-wrap: wrap;
  }
  [pricing="button-flex"] .el-button {
    width: 100%;
  }
  [pricing="button-flex"] .el-button + .el-button {
    margin-top: 1rem;
  }
}
</style>