<template>
  <default-layout>
    <section support class="content--narrow">
      <header class="content__header">
        <h2>Support</h2>
      </header>

      <el-form :label-position="isMobile ? 'top' : 'left'" label-width="75px" :model="form">
        <el-form-item label="Email">
          {{this.email}}
        </el-form-item>
        <el-form-item label="Subject">
          <el-select placeholder="Support Subject" v-model="form.type">
            <el-option label="Trouble Ticket" value="troubleTicket"></el-option>
            <el-option label="Sales Inquiry" value="salesInquiry"></el-option>
            <el-option label="Licensing" value="licensing"></el-option>
            <el-option label="Installation" value="installation"></el-option>
            <el-option label="Troubleshooting" value="troubleshooting"></el-option>
            <el-option label="Other" value="other"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Product">
          <el-radio-group v-model="form.product" size="medium" @change="handleRadioChange">
            <el-radio-button label="zinggrid">ZingGrid</el-radio-button>
            <el-radio-button label="zingchart">ZingChart</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Title">
          <el-input :maxlength="MAX_TITLE" placeholder="Your Title Here" v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="Comment">
          <el-input :maxlength="MAX_BODY" placeholder="Your Comment Here" rows="10" type="textarea" v-model="form.comment"></el-input>
        </el-form-item>
        <el-form-item>
          <form support="form">
            <input support="createFile" :accept="acceptedFiletypes.toString()" @change="filesChange($event.target.files)" multiple type="file">
          </form>
          <p support="uploadText">The maximum you can upload is 10 files at 5MB. You have uploaded <span style="font-weight:600">{{attachmentAmount}}/10</span> files at <span style="font-weight:600">{{attachmentSize}}MB</span>.</p>
        </el-form-item>
        <el-form-item button-align="right" size="large">
          <el-button @click="onSubmit()" :disabled="!createValidated || attachmentAmount > 10 || attachmentSize > 5" type="primary">Create</el-button>
        </el-form-item>
      </el-form>

      <my-dialog
        @close="submitVisible = false"
        title="Message Sent"
        type="success"
        :visible="submitVisible">
        <div slot="content">
          <p>Your message has been sent to support. We usually respond within 24 hours.</p>
        </div>
        <div slot="options">
          <el-button @click="submitVisible = false" type="primary">Close</el-button>
        </div>
      </my-dialog>

    </section>

  </default-layout>
</template>

<script>
import axios from 'axios';
import DefaultLayout from '../layouts/Default.vue';
import MyDialog from '../../components/MyDialog.vue';
import MixinGroup from './../../mixins/groups.js';

  export default {
    components: {
      DefaultLayout,
      MyDialog,
    },
    mixins: [MixinGroup],
    computed: {
      createValidated() {
        const type = this.form.type.length > 0;
        const title = this.form.title.length > 0;
        const body = this.form.comment.length > 0;
        return type && title && body;
      },
      isMobile() {
        return this.$store.getters['ui/isMobile'];
      },
    },
    data() {
      return {
        acceptedFiletypes: ['.gif', '.jpg', '.jpeg', '.png', '.doc', '.docx', '.pdf', '.txt', '.css', '.html', '.json', '.js', '.zip'],
        attachmentAmount: 0,
        attachmentSize: 0,
        currentTicketType: null,  // for future use to determine what to do with non-troubleTickets
        email: null,
        files: {
          name: '',
          length: 0,
        },
        form: {
          type: '',
          title: '',
          comment: '',
          product: this.$store.state.user['referrer'].toLowerCase(),
        },
        MAX_BODY: 65536,
        MAX_TITLE: 100,
        inputForm: new FormData(),
        submitVisible: false,
      };
    },
    beforeCreate() {
      axios({
        url: '/api/user',
        method: 'GET',
        headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
      }).then((data) => {
        let userData = data.data;
        this.email = userData.email;
      });
    },
    methods: {
      /**
       * @description Clear file input
       * @param { String } fileInput - Signals which file input to clear (create, update, close, all)
       */
      clearFileInput(fileInput) {
        let inputs = [fileInput];
        if (fileInput === 'all') inputs = ['create', 'update', 'close'];
        inputs.forEach(input => {
          let $fileInput = this.$el.querySelector(`[support="${input}File"]`);
          if ($fileInput) $fileInput.value = '';
        });

        this.attachmentAmount = 0;
        this.attachmentSize = 0;
      },
      /**
       * @description Triggered when choosing attachments to track amount and size
       */
      filesChange(files) {
        this.attachmentAmount = files.length;
        if (this.attachmentAmount > 10) {
          this.$message({
            duration: 10000,
            message: `Please select only 10 attachments max. Currently attempting to attach ${this.attachmentAmount} attachments.`,
            showClose: true,
            type: 'error',
          });
        }

        this.attachmentSize = 0;
        for (let file in files) {
          if(files[file].size) this.attachmentSize += files[file].size;
        }
        this.attachmentSize = parseFloat(this.attachmentSize/1000000).toFixed(2);
        if (this.attachmentSize > 5) {
          this.$message({
            duration: 10000,
            message: `Max limit is 5MB. Currently attempting to attach ${this.attachmentSize}MB.`,
            showClose: true,
            type: 'error',
          });
        }
      },
      /**
       * @description When radio button change, update the state 'referrer'
       */
      handleRadioChange(label) {
        this.$store.state.user['referrer'] = label.toLowerCase();
      },
      /**
       * @description On submision of form, check type of support ticket. If type is:
       *  - Bug: Create support ticket
       *  - Other: Send email
       * TODO: add group id and check for support bool
       */
      onSubmit() {
        if(!this.form.type || this.form.comment === '' || this.form.title === '' || this.form.title === '') {
          this.$message({
            duration: 10000,
            message: 'Please fill out all the fields.',
            showClose: true,
            type: 'error',
          });
          return;
        }
        let files = this.$el.querySelector('[support="createFile"]').files;
        if (files.length === 0) {
          this.sendIssue();
        } else {
          let attachmentArr = [];
          Array.prototype.forEach.call(files, (file) => {
            var reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = () => {
              let encoded = reader.result
              attachmentArr.push({
                content: encoded,
                filename: file.name
              });
              if (attachmentArr.length === files.length) {
                this.sendIssue(attachmentArr);
              };
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            };
          });
        }
      },
      /**
       * @description Sends support ticket with to devs or support
       * @param { Object } attachmentArr - array of attachments to include to suport ticket
       */
      sendIssue(attachmentArr) {
        let body = {
          attachments: attachmentArr,
          description: this.form.comment,
          product: this.form.product,
          referer: 'ZingSoft',
          title: this.form.title,
          type: this.form.type,
        }


        this.$api('email/support', {
          json: true,
          data: body,
        })
        .then((response) => {
          this.submitVisible = true;
          this.clearFileInput('create');
          this.form.name = this.form.email = this.form.type = this.form.title = this.form.comment = '';
        }).catch((err) => {
          this.$message({
            duration: 10000,
            message: 'Error sending message' + err,
            showClose: true,
            type: 'error',
          });
        });
      },
    },
  }
</script>

<style scoped>
  [support="uploadText"] {
    font-size: 0.8rem;
    margin: 0.25rem;
  }
</style>

<style>
  [support].el-button {
    bottom: 3px;
    margin: 0 0.5rem 0 0;
    position:relative;
  }

  [support="form"] {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
  }

  @media screen and (max-width: 400px) {
    [support] .el-form {
      margin-top: 1.5rem;
    }

    [support] .el-form-item {
      margin-bottom: 10px;
    }

    [support] .el-form--label-top .el-form-item__label {
      padding: 0;
    }
  }
</style>
