<template>
  <div v-show="hasUser" header-bar="bar">
    <div header-bar="inner">
      <p><strong>Reminder:</strong> Complete your <router-link to="/account">profile</router-link> to help us better serve you!</p>
    </div>
    <span @click="toggleBar()" class="trigger">
      <svg-icon icon="close--circle" class="spin" height="1rem" width="1rem"></svg-icon>
    </span>
  </div>
</template>

<script>
import SvgIcon from '../../components/SvgIcon.vue';
export default {
  components: {
    SvgIcon,
  },
  data() {
    return {
      $bar: null,
    };
  },
  computed: {
    allowDisplay() {
      // Determine if user previously closed
      // Do not display until state refreshed
      return this.$store.state.ui.headerBar;
    },
    displayReminder() {
      // Check if all required fields filled
      return (!this.$store.state.user.name
        || !this.$store.state.user.company
        || !this.$store.state.user.location
        || !this.$store.state.user.phone_number)
        && this.$store.state.user.quickbooks;
    },
    hasUser() {
      return !!this.$store.state.user['user_id'];
    },
  },
  watch: {
    displayReminder() {
      this.toggleBar(this.displayReminder);
    },
  },
  mounted() {
    if (this.displayReminder) {
      this.toggleBar(true);
    }
  },
  methods: {
    toggleBar(display) {
      // Save reference
      if (!this.$bar) this.$bar = document.querySelector('[header-bar="bar"]');

      // Allow animation to apply
      if (this.$bar && this.allowDisplay) {
        setTimeout(() => {
          // Determine to display or hide
          if (display) {
            // Display
            this.$bar.classList.add('show');
          } else {
            // Hide
            this.$bar.classList.remove('show');
            // Prevent from displaying in this state
            this.$store.state.ui.headerBar = false;
          }
        }, 500);
      }
    },
  },
};
</script>

<style scoped>
  [header-bar="bar"] {
    background: var(--color-secondary-3);
    left: 0;
    max-height: var(--nav-height);
    position: absolute;
    right: 0;
    top: 0;
    transition: top .4s cubic-bezier(.175,.885,.32,1.085);
    z-index: 100;
  }

  [header-bar].show {
    top: var(--nav-height);
  }

  [header-bar="inner"] {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 14px;
    justify-content: center;
    width: auto;
  }

  [header-bar] [icon="close--circle"] {
    cursor: pointer;
    fill: #fff;
    margin: 9px 1rem;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: 8px 15px;
  }

</style>

<style>
  [header-bar] a {
    color: #fff;
  }
</style>
