import './assets/styles/main.css';
import './assets/styles/elementTheme.css';
import constants from './constants.js';
import Vue from 'vue';
import AppShell from './AppShell.vue';

// ELEMENTS UI
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
Vue.use(ElementUI, { locale });

// PORTAL VUE
import PortalVue from 'portal-vue';
Vue.use(PortalVue);

// PLUGINS
import API from './plugins/api.js';
import AsyncComputed from 'vue-async-computed'
Vue.use(API); // Plugin for internal API
Vue.use(AsyncComputed);

// DETECT BACK BUTTON EVENT
window.popStateDetected = false;
window.onpopstate = (event) => {
  window.popStateDetected = true;
};

// ROUTER + GTM
import VueGtm from '@gtm-support/vue2-gtm';
import VueRouter from 'vue-router';
import routes from './routes/main.js';
Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  routes: routes.map(({path, component, children}) => {
    return {path, component, children};
  }),
});

// STORE
import Vuex from 'vuex';
import createStore from './state/main.js';
Vue.use(Vuex);
const store = new Vuex.Store(createStore({
  domain: constants.AUTH_DOMAIN,
  clientID: constants.AUTH_CLIENT_ID,
}));

// LIBS
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
  faGooglePlusSquare
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowDown,
  faArrowRight,
  faArrowUp,
  faBell,
  faBuilding,
  faCheck,
  faCheckCircle,
  faClock,
  faCodeBranch,
  faCog,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileCode,
  faInfoCircle,
  faLock,
  faMapMarkerAlt,
  faPlay,
  faPlusCircle,
  faSave,
  faSpinner,
  faSignOutAlt,
  faTags,
  faTh,
  faThList,
  faTimes,
  faTrashAlt,
  faUserCircle,
  faWindowMaximize,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
library.add(
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
  faGooglePlusSquare,
  faArrowDown,
  faArrowRight,
  faArrowUp,
  faBell,
  faBuilding,
  faCheck,
  faCheckCircle,
  faClock,
  faCodeBranch,
  faCog,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileCode,
  faInfoCircle,
  faLock,
  faMapMarkerAlt,
  faPlay,
  faPlusCircle,
  faSave,
  faSpinner,
  faSignOutAlt,
  faTags,
  faTh,
  faThList,
  faTimes,
  faTrashAlt,
  faUserCircle,
  faWindowMaximize,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);

// GTM
import axios from 'axios';
axios({
  url: '/',
  method: 'GET'
}).then((response) => {
  let headers = response.headers['content-security-policy'];
  let nonceIndex = headers ? headers.indexOf('nonce') : -1;
  let nonce = nonceIndex > -1 ? headers.slice(nonceIndex + 6, nonceIndex + 30) : null;
  let gtmConfig = {
    id: ['GTM-M6MF3TM'], // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    queryParams: {
      // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
      gtm_auth: 'BKGzPz3orSGdSdFlA41LqQ',
      gtm_preview: 'env-7',
      gtm_cookies_win: 'x:42'
    },
    defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
  };
  if (nonce) gtmConfig.nonce = nonce;
  Vue.use(VueGtm, gtmConfig);

  // Create element to pass nonce to GTM child scripts
  let metaEl = document.createElement('meta');
  metaEl.setAttribute('id', 'gtmScript');
  metaEl.setAttribute('val', nonce);
  document.head.appendChild(metaEl);

  ZingGrid.setLicense([VUE_APP_ZG_LICENSE]);

  new Vue({
    el: '#app-shell',
    store,
    router,
    render: (h) => h(AppShell),
  });
});


