<template>
  <filter-selector
    ref="metadataSelector"
    @filterBy="filter"
    :allowCreate="true"
    :clearable="true"
    :defaultValue="defaultValue"
    :filterable="true"
    :multiple="multiple"
    :options.sync="options"
    :placeholder="placeholder"
    :scrollable="scrollable">
  </filter-selector>
</template>

<script>
import FilterSelector from './FilterSelector.vue';
import MixinMetadata from '../mixins/metadata.js';

export default {
  components: {
    FilterSelector,
  },
  mixins: [MixinMetadata],
  props: {
    defaultValue: String,
    filterBy: Function, 
    metadata: String,
    scrollable: Boolean,
    type: String,
    demoType: String,
  },
  data() {
    return {
      $angle: null,
      $inputEl: null,
      collapsible: false,
      options: [],
      placeholder: 'Select Metadata',
    };
  },
  created() {
    this.setData();
  },
  watch: {
    metadata() {
      this.clearResults();
      this.setValueOptions();
    },
    demoType() {
      this.clearResults();
      this.setValueOptions();
    },
  },
  methods: {
    clearResults() {
      this.$refs.metadataSelector.clearFilter();
    },
    filter(value) {
      this.$emit('update:metadataBy', value);

      setTimeout(() => {
        this.signalScrollable();
      }, 100);
    },
    setData() {
      if (this.type) {
        switch(this.type) {
          case 'metadata': 
            this.multiple = false;
            this.options = this.galleryMetadataOptions.filter((type) => {
              // Allow only "select" type
              let skip = ['loadInIframe', 'vanityUrl'];
              return type.value.indexOf('is') !== 0 && !skip.includes(type.value);
            });
            break;
          default:
            this.collapsible = true;
            this.multiple = true;
            this.setValueOptions();
            break;
        }
      }
    },
    setValueOptions() {
      if (this.metadata && this.galleryMetadata[this.metadata]) {
        if (this.demoType === 'all_demos') {
          this.options = this.galleryMetadata[this.metadata].options['zinggrid'].concat(this.galleryMetadata[this.metadata].options['zingchart']);
        } else {
          this.options = this.galleryMetadata[this.metadata].options[this.demoType];
        }
      }
    },
    /**
     * @description Rotate arrow at end of selector to signal whether there are more options selected
     */
    signalScrollable() {
      if (this.scrollable) {
        // Get references
        if (!this.$angle) this.$angle = this.$el.querySelector('[icon="angle"]')
        if (!this.$inputEl) this.$inputEl = this.$el.querySelector('[scrollable] .el-select__input');

        // Check width to add/remove class
        let width = this.$inputEl.getBoundingClientRect().width;
        if (width > 0 && this.$angle.classList.contains('angle--right')) {
          this.$angle.classList.remove('angle--right');
        } else if (width === 0 && !this.$angle.classList.contains('angle--right')){
          this.$angle.classList.add('angle--right');
        }
      }
    }
  }
}
</script>