<template>
    <div id="builder">
      <!-- <header class="content__header">
        <h2>Builder</h2>
      </header> -->
      <builder-el 
        caption="Example Caption Text"
        v-bind:data="gridData">
      </builder-el>
    </div>
</template>

<script>
import BuilderEl from './BuilderEl.vue'
import GRID_DATA from './assets/json/sample-data.json'
import './assets/style/builder.css'

export default {
  name: 'builder',
  components: {
    BuilderEl
  },
  data: function() {
    return {
      gridData: JSON.stringify(GRID_DATA)
    }
  }
}
</script>

<style>
  * { box-sizing:border-box; }
  #builder { padding:0 1rem 1rem 1rem; }
  body { margin:0; }
  @media screen and (min-width: 992px) {
    #builder { display:flex; flex-direction:column; height:100%; }
  }
</style>
