<template>
  <div class="group_wrapper">
    <group-filter-selector
      ref="filterSelector"
      @group="filter"
      :defaultOptions="defaultOptions"
      :getCount="getCount"
      :options.sync="myGroups"
      :selected.sync="selected"
    ></group-filter-selector>
  </div>
</template>

<script>
import GroupFilterSelector from './GroupFilterSelector.vue';
import MixinGroup from '../mixins/groups.js';

export default {
  components: {
    GroupFilterSelector,
  },
  mixins: [MixinGroup],
  props: ['groupBy', 'template', 'type'],
  data() {
    return {
      defaultOptions: [
        {
          id: 'all_demos',
          name: 'All Demos',
        }, {
          id: 'personal_demos',
          name: 'Personal Demos',
        }
      ],
      selected: 'all_demos',
    };
  },
  methods: {
    /**
     * @description Determines which group count to display depending on current demotype.
     * @param {Object} group - Group object. Contains at least three different counts to choose from: 
     * zinggrid, zingchart, all
     */
    getCount(group) {
      let groupCount = '';
      switch (this.type) {
        case 'zingchart': 
          groupCount = group.count_zingchart;
          break;
        case 'zinggrid':
          groupCount = group.count_zinggrid;
          break;
        default:
          groupCount = group.count;
          break;
      }
      return groupCount || 0;
    },
    /**
     * @description Triggered on change to notify `DemoViewer.vue` that `this.group` value should
     * update to new value
     * @param {String} value - value to update to
     */
    filter(value) {
      // Update group results
      this.$emit('update:groupBy', value);
      this.updateSelected(value);
    },
    /**
     * @description When `this.groupBy` is updated, set to either 'all_demos', 'personal', or grouping_id.
     * If attempting to set to neither of the above options, default to 'all_demos'
     */
    updateSelected(value) {
      function checkSelection(groupBy) {
        let newVal = groupBy;
        if (!(newVal === 'all_demos' || newVal === 'personal_demos')) {
          if (!isNaN(groupBy)) newVal = parseInt(groupBy);
          else newVal = 'all_demos';
        }
        return newVal;
      }
      this.selected = checkSelection(value);
    },
  },
}
</script>

<style scoped>
  .group_wrapper {
    width: 100%;
  }
</style>