<template>
  <div class="form__item">
    <label class="form__label" id="company">Company</label>
    <el-input class="form__field" placeholder="Your Company" for="company" v-model="company_" maxlength="75"></el-input>
  </div>
</template>

<script>
  export default {
    props: {
      value: String,
    },
    data() {
      return {
        company_: null,
        init: true,
        dirty: false,
      }
    },
    mounted() {
      this.company_ = this.value;
    },
    computed: {
      company() {
        return this.value;
      },
    },
    watch: {
      // Handles updates from the store.
      company(value, old) {
        this.company_ = value;
        this.dirty = false;
      },
      company_() {
        // Handles an init call.
        if (this.init) {
          this.init = false;
        // Handles changes by the store mutating the event.
        } else if (!this.dirty)  {
          this.dirty = true;
          this.$emit('change', {company: this.company_});
        // If the field was modified by user entry.
        } else if (this.dirty) {
          this.$emit('change', {company: this.company_});
        }
      },
    },

  }
</script>
