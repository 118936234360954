<template>
  <zingnet-card>
    <span slot="header">Page Speed Test</span>
    <div slot="body" v-loading="loading">
      <section class="zingnetCard--row">
        <section v-for="page in pages" :key="`pageSpeed-${page.site}`" class="zingnetCard--column" v-loading="page.loading">
          <div :id="`chart-pageSpeed-${page.site}`"></div>
        </section>
      </section>

    </div>
  </zingnet-card>
</template>
<script>

import axios from 'axios';
import ZingnetCard from '../ZingnetCard.vue';

export default {
  components: {
    ZingnetCard,
  },
  data() {
    return {
      loading: false,
      pages: [{url: 'www.zingchart.com', site: 'ZingChart', loading: true}, {url: 'www.zinggrid.com', site: 'ZingGrid', loading: true}],
      chartConfig: {
        type: 'ring',
        labels: [{
          text: '',
          fontColor: 'var(--color-tertiary-blue)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '15px',
          fontWeight: 600,
          x: 0,
          y: '0',
        }],
        plot: {
          offsetY: '-20px',
          refAngle: 220,
          slice: '88%',
          valueBox: [{
            text: '90.42%',
            placement: 'center',
            fontColor: 'var(--color-secondary-blue)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: 32,
            fontWeight: '100',
            offsetY: '-10px',
          }, {
            text: 'Performance',
            placement: 'center',
            fontColor: 'var(--color-secondary-blue)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: 13,
            fontWeight: '200',
            offsetY: '17px',
          }],
        },
        plotarea: {
          marginLeft: '50%',
        },
        series: [
          {
            values: [],
            backgroundColor: 'var(--color-secondary-blue-active)',
            text: 'blank',
          },
          {
            values: [],
            backgroundColor: 'var(--color-secondary-blue)',
            text: 'Performance',
          },
        ]
      },
    };
  },
  mounted() {
    this.getReport();
  },
  methods: {
    getReport(page) {
      this.pages.forEach((page, i) => {
        axios({
          url: `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=https://${page.url}&key=${VUE_APP_GOOGLE_API}`,
        }).then((response) => {
          this.initChart(response.data, page.site, i);
        });
      });
    },

    initChart(data, site, index) {
      let config = JSON.parse(JSON.stringify(this.chartConfig));
      let props = ['first-contentful-paint', 'speed-index', 'largest-contentful-paint', 'interactive', 'total-blocking-time', 'cumulative-layout-shift'];
      let pos = 20;
 
      let lighthousePerformance = parseInt(data.lighthouseResult.categories.performance.score*100);

      config.labels[0].text = `${site} Performance`;
      props.forEach((prop) => { pos = this._lighthouseData(prop, data, config, pos); });

      config.plot.valueBox[0].text = lighthousePerformance;
      config.series[0].values = [100-lighthousePerformance];
      config.series[1].values = [lighthousePerformance];

      zingchart.render({
        id: `chart-pageSpeed-${site}`,
        data: config,
        height: '250px',
        width: '100%'
      });

      this.pages[index].loading = false;
    },

    _lighthouseData(prop, data, config, pos) {
      let title = data.lighthouseResult.audits[prop].title;
      let displayValue = data.lighthouseResult.audits[prop].displayValue;

      let template = function(text, pos, isTitle) {
        return {
          text: isTitle ? `${text}:` : text,
          fontColor: isTitle ? 'var(--color-primary-gray)' : 'var(--color-secondary-blue-active)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '14px',
          fontWeight: 500,
          x: `${isTitle ? 0 : 35}%`,
          y: `${pos}%`,
        };
      };

      config.labels.push(template(title, pos, true));
      config.labels.push(template(displayValue, pos, false));

      return pos += 12;
    },
  },
}
</script>

<style scoped>
  .zingnetCard--row {
    min-height: 296px;
  }
</style>