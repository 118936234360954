<template>
  <secondary-layout :isAuthing="isAuthing">
    <section class="content__wrap content__wrap--login">

      <!-- Display to complete Google Auth signup -->
      <template v-if="social">
        <div authVerify class="content content--narrow">
          <entry-container form=false button=false slim=true>
            <h3 slot="entryHeader">Account created</h3>

            <div entryContainer="entry__content__inner" slot="entryBody">
              <h1>Welcome to ZingSoft!</h1>
              <h3 v-if="name">Hi {{name}}!</h3>
              <h3 v-else>Hi!</h3>
              <p>To complete your account setup, please log back in.</p>
              <br>
              <div @click="loginGoogle($event, true)">
                <google-entry :regenerate-session="regenerateSession">Log In With Google</google-entry>
              </div>
            </div>
          </entry-container>
        </div>
      </template>

      <template v-else>
        <!-- Display bar if redirect from site pricing page -->
        <div class="content content--narrow">
          <div class="purchaseNotification" v-if="planId && planInterval">
            Please login or sign up to continue your purchase.
          </div>

          <!-- Login container -->
          <entry-container :slim="slim">
            <h3 slot="entryHeader">Log in to your account</h3>

            <div slot="entryBody">
              <!-- Google Auth login -->
              <div @click="loginGoogle($event, false)">
                <google-entry :regenerate-session="regenerateSession">Log in with Google</google-entry>
              </div>
              <p class="margin--small--laptop">or</p>

              <!-- Login -->
              <el-form @submit.native.prevent :model="form" ref="loginForm">
                <!-- Email input -->
                <input-email form="form" v-model="form.email"></input-email>
                <!-- Password input -->
                <el-form-item
                  prop="password"
                  :rules="[{ required: true, message: 'Please input password', trigger: 'blur' }]">
                  <el-input
                    @keyup.enter.native="login"
                    @change="validateForm('password')"
                    v-model="form.password"
                    tabindex=2
                    type="password"
                    placeholder="Enter your password">
                    <template slot="prepend">
                      <svg width="11" height="14" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg" class="auth0-lock-icon auth0-lock-icon-box"><path d="M11 15.998H2a2 2 0 0 1-2-1.999v-6c0-1.104.896-2.001 2-2.001V4.499a4.501 4.501 0 0 1 9 0v1.5a2 2 0 0 1 2 2v6a2 2 0 0 1-2 1.999zM10 4.499a3.5 3.5 0 1 0-7 0v1.499h7V4.499zm2 3.5a1 1 0 0 0-1-1.001H2a1 1 0 0 0-1 1.001v6c0 .552.447.999 1 .999h9a1 1 0 0 0 1-.999v-6zm-5.5 4.999a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 1 0v3a.5.5 0 0 1-.5.5z"/></svg>
                    </template>
                  </el-input>
                </el-form-item>
                <!-- Login + Forgot password -->
                <div class="flex-container">
                  <p><router-link class="link--blue" to="/forgot">Forgot password?</router-link></p>
                  <el-form-item>
                    <el-button @click="login" type="primary" class="button button--bottom" :disabled="!validLogin">Log In</el-button>
                  </el-form-item>
                </div>
              </el-form>

              <!-- Terms and Privacy -->
              <p class="p--terms">
                View our
                <a class="term_link" href="https://www.zinggrid.com/legal">Terms of Service</a>
                and 
                <a class="term_link" href="https://www.zinggrid.com/privacy">Privacy Policy</a>
              </p>
            </div>
          </entry-container>

          <!-- Signup text -->
          <div class="signup__container">
            <p class="margin--small--laptop">
              Don't have an account yet?
              <span @click="passQueryParam(false)">
                <span class="signup__link">Create account.</span>
              </span>
            </p>
          </div>

        </div>
      </template>
    </section>
  </secondary-layout>
</template>

<script>
  // IMPORT
  // ----------------------------------
  import MixinEntry from '../../mixins/entryMain.js';

  // EXPORT
  // ----------------------------------
  export default {
    mixins: [MixinEntry],
    data() {
      return {
        form: {
          email: '',
          password: '',
        },
        redirectPath: '',
        validated: false,
      };
    },
    props: {
      slim: Boolean,  // if true, does not display feature banner
      social: Boolean,
      name: {},
    },
    computed: {
      validLogin() {
        let fieldsFilled = this.form.email.length !== 0 && this.form.password.length !== 0;
        let validEmail = this.validated;
        return fieldsFilled && validEmail;
      }
    },
    mounted() {
      this.redirectAfterLogin();
    },
    methods: {
      /**
       * @description Logs in user who does this the normal (non-Google-Auth) process while validating login form items
       * @param {Object} e - native event object
       */
      login(e) {
        e.preventDefault();

        // Validate form
        this.validateForm();

        // Check if disabled
        if (!this.validLogin) {
          this.$message({
            duration: 10000,
            message: 'Please fill in all fields.',
            showClose: true,
            type: 'error',
          });
          return;
        }

        // Validate
        let hasError = this.$el.querySelectorAll('.el-form-item.is-error');
        if (hasError.length > 0) {
          this.$message({
            duration: 10000,
            message: 'Please fill in all fields correctly.',
            showClose: true,
            type: 'error',
          });
          return;
        };

        // Toggle localStorage 'auth' state
        this.setAuthIsAuthenticatingLocalStorage();

        // login
        this.$store.dispatch('auth/login', {
          email: this.form.email,
          password: this.form.password,
          regenerateSession: this.regenerateSession,
        });
      },

      /**
       * @description Login or signup through Google Auth
       * @param {Object} e - native event object
       */
      loginGoogle(e, signup) {
        e.preventDefault();
        // Remove auth signup trigger and add setup signup triggers
        if (signup) {
          localStorage.removeItem('GoogleAuthSignup');
          this.setAuthIsNewSignupLocalStorage();
        } else localStorage.setItem('GoogleAuthSignup', true);

        this.$store.dispatch('auth/authorize_google', {
          regenerateSession: this.regenerateSession,
        });
        // Toggle localStorage 'auth' state
        this.setAuthIsAuthenticatingLocalStorage();
      },

      update(type, val) {
        this.form[type] = val;
      },

      validateForm(type) {
        if (type && (this.form.email.length === 0 || this.form.password.length === 0)) {
          // Validate single field
          this.$refs.loginForm.validateField(type);
          this.validated = false;
        } else {
          // Validate whole form
          this.$refs.loginForm.validate((valid) => {
            this.validated = valid;
          });
        }
      },
    }
  }
</script>

<style lang="css" scoped>
  .content { text-align: center; }

  [entryContainer] .button--bottom { background: var(--color-tertiary-1); border-radius: var(--border-radius); font-size: 0.8125rem; height: 2.5rem; line-height: 1.5rem; width: 7.813rem; }
  [entryContainer] .button--bottom p { font-weight: 600; margin: auto; }
  [entryContainer] .button--bottom:hover { background: var(--color-tertiary-4); }
  [entryContainer] [disabled].button--bottom { background: var(--color-primary-5); border-color: var(--color-primary-5); cursor: not-allowed; }
  
  [entryContainer] .flex-container { display: flex; justify-content: space-between; }

  [entryContainer] .link--blue { display: flex; font-size: 0.8125rem; font-weight: 500; text-decoration: none; }
  [entryContainer] .link--blue:hover { color: #2d62bd; }

  @media (max-width: 800px) {
    .login__container { margin-top: 0; }
  }

  /* Mobile Overwrites */
  @media screen and (max-width:472px) {
    .button__text { margin-left: 0; }
  }

  @media screen and (max-height: 900px) {
    .signup__container { margin: 1rem auto; }
  }

  @media screen and (min-height: 600px) {
    [slim][entryContainer] { position: relative; top: 0; }
  }
</style>
