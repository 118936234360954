<template>
  <div callout :class="type">
    <svg-icon v-if="icon" :icon="icon" height="18" width="18"></svg-icon>
    <slot name="content"></slot>
  </div>
</template>

<script>

import SvgIcon from '../../../components/SvgIcon.vue';

export default {
  components: {
    SvgIcon,
  },
  props: {
    icon: String,
    type: String,
  },
}

</script>

<style scoped>
  div {
    align-items: center;
    border: var(--zingnet-border);
    border-radius: 10px;
    display: flex;
    font-size: 0.9375rem;
    font-weight: 400;
    padding: 0.75rem 1.375rem;
    width: fit-content;
  }

  div p {
    margin: 0;
  }

  .danger {
    background-color: var(--color-tertiary-danger);
    border-color: var(--color-secondary-danger);
    color: var(--color-primary-danger);
    fill: var(--color-secondary-danger);
  }
</style>

<style>
  [callout] svg {
    margin-right: 0.625rem;
    position: relative;
    top: 2px;
  }
</style>