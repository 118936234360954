<template>
  <div class="form__item">
    <label class="form__label" id="phoneNumber">Phone number</label>
    <el-input
      @input="format(phoneNumber_)"
      class="form__field"
      for="phoneNumber"
      placeholder="(xxx) xxx - xxxx"
      v-model="phoneNumber_"
      type="tel"></el-input>
  </div>
</template>

<script>
  export default {
    props: {
      value: String,
    },
    data() {
      return {
        phoneNumber_: null,
        init: true,
        dirty: false,
      }
    },
    mounted() {
      this.phoneNumber_ = this.value;
    },
    computed: {
      phoneNumber() {
        return this.value;
      },
    },
    watch: {
      // Handles updates from the store.
      phoneNumber(value, old) {
        this.phoneNumber_ = value;
        this.dirty = false;
      },
      phoneNumber_() {
        // Handles an init call.
        if (this.init) {
          this.init = false;
        // Handles changes by the store mutating the event.
        } else if (!this.dirty)  {
          this.dirty = true;
          this.$emit('change', {phone_number: this.phoneNumber_});
        // If the field was modified by user entry.
        } else if (this.dirty) {
          this.$emit('change', {phone_number: this.phoneNumber_});
        }
      },
    },
    methods: {
      format(value) {
        if (value && value.length > 0) {
          let phoneNumber = value.replace(/\s|\(|\)|\-/g, '');
          let formatted = phoneNumber.replace(/(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/, (_, p1, p2, p3, p4) => {
            let output = '';
            if (p1) output = `(${p1}`;
            if (p2) output += `${p2})`;
            if (p3) output += ` ${p3}`;
            if (p4) output += ` - ${p4}`;
            this.phoneNumber_ = output;
          });
        }
      },
    },
  }
</script>
