export default {
  computed: {
    activePlan() {
      return this.plans[this.activePlanIndex] || null;
    },
    zingchartEnterpriseLicense() {
      if (this.activePlan) {
        return this.activePlan.Sku === 'ZC-S-ENT';
      }
      return false;
    },
    flatTransactions() {
      if(this.transactions && this.transactions) {
        return this.transactions.map((transaction) => {
          let date = new Date(transaction.created * 1000);
          return {
            name: transaction.name,
            amount: '$' + transaction.amount,
            date: date.getMonth() + '/' + date.getDay() + '/' + date.getFullYear(),
          };
        });
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      showExpiredState: false,
      transactions: [],
    };
  },
  methods: {
    /**
     * @description Get count of domains, which is displayed in the subscription card.
     * Should only count domain objects and not domain strings. Strings are domains that are
     * about to be registered.
     * @param {Array} domains - Array of objects and strings representing domains. Objects are registered
     * domains and strings are domains to be registered
     * @param {Boolean} subdomain - Get count of subdomain instead of domains
     */
    domainCount(domains, subdomain) {
      let prop = subdomain ? 'subdomains' : 'domains';
      if (domains && domains[prop]) {
        let toCount = domains[prop].filter((domain) => typeof domain === 'object');
        return toCount.length;
      } else return 0;
    },
    isEnterprise(plan) {
      return plan.type === 'ZC' && plan.product === 'Enterprise';
    },
    isZingGridOem(plan) {
      return plan.type === 'ZG' && plan.product === 'OEM';
    },
    isPlanActive(index) {
      return (this.activePlanIndex === index) ? 'plan-tab' : 'plan-tab--inactive';
    },
    showCompany(plan) {
      return (plan.type === 'ZC' && plan.product === 'Enterprise') || (plan.type === 'ZG' && plan.product === 'OEM');
    },
    /**
     * @description Given the license key, returns the company associated to the
     * ZingChart Enterprise license
     */
    getLicenseCompany(license) {
      if (license) {
        let arr = license.split('\'');
        return arr[3];
      }
    },
    getPlanFeatures(product) {
      let currentInterval = this.pricingInterval ? 'perpetual' : 'year';
      let currentProduct = product.plans.filter(plan => plan.interval === currentInterval)[0];
      return currentProduct && currentProduct.features ? currentProduct.features : [];
    },
    getUserSubscriptions() {
      // Obtain customer's subscriptions
      this.$api('subscription/list')
        .then((result) => {
          if (result.data) {
            // Add fields in `domainField` form for each doamin
            this.domainField = [];
            for(let i = 0; i < result.data.length; i++) {
              this.domainField.push(Object.assign({}, this.domainObj));
            };
            this.subscriptions = result.data;
          }
        })
        .catch((error) => {
          this.$message({
            duration: 10000,
            message: 'Unable to locate your subscription data. Please contact us.',
            showClose: true,
            type: 'error',
          });
        });
    },
    getSubscriptionTransactions() {
      this.$api('subscription/transaction')
      .then((result) => {
        this.transactions = result.data;
      })
      .catch((error) => {
        this.$message({
          duration: 10000,
          message: 'Could not get subscription transactions',
          showClose: true,
          type: 'error',
        });
      });
    },
    openUpdateDomainDialog(subscription) {
      this.updateDomainDialogVisible = true;
      this.dialogNewDomains = subscription.domains.filter((domain) => typeof domain === 'object');
      this.dialogNewSubdomains = subscription.subdomains.filter((subdomain) => typeof subdomain === 'object');
      this.updateDomainSubscription = subscription;
    },
    showExpired(subscription){
      return !(this.subscriptionStatus(subscription) == 'Expired' || this.subscriptionStatus(subscription) == 'Unsubscribed') || this.showExpiredState;
    },
    subscriptionStatus(subscription, color) {
      let status = null;
      let statusColor = null;

      // Determine in subscription is still processing payment
      if (subscription.status === 'Unsubscribed') {
        status = 'Unsubscribed';
        statusColor = 'red';
      } else if (subscription.status) {
        status = 'Pending';
        statusColor = 'orange';
      } else {
        status = 'Active';
        statusColor = 'green';
      }

      // Determine if subscription is expired
      let now = new Date().getTime()/1000;
      if (subscription.plan.interval === 'year' && now > subscription.current_period_end) {
        status = 'Expired';
        statusColor = 'red';
      };
      return color ? statusColor : status;
    },
    toggleShowExpired(){
      this.showExpiredState = !this.showExpiredState;
      return;
    },
  }
};