<template>
  <section :class="dummy ? '' : 'demo__card'" demo-viewer>
    <!-- Dummy Card -->
    <div v-if="dummy" @click="$emit('update-view')" class="demo__card demo__card--dummy demo__card--start">
      <div class="demo__header__left demo__header--dummy demo__header__left--dummy hover-hide list-hide">
        <p class="demo__title--dummy">Looks like you haven't made any demos yet.</p>
        <p class="demo__text--dummy">Want to get started?</p>
      </div>
      <button class="button demo__button demo__button--dummy not-hover-hide list-hide">
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none"><path d="M6.5 0A6.502 6.502 0 0 0 0 6.5C0 10.088 2.912 13 6.5 13S13 10.088 13 6.5 10.088 0 6.5 0zm3.25 7.15h-2.6v2.6h-1.3v-2.6h-2.6v-1.3h2.6v-2.6h1.3v2.6h2.6v1.3z" fill="#2BBEEE"/></svg>
        Create a Demo
      </button>
      <div class="demo__card demo__header grid-hide">
        <div class="demo__header__right">
          <h3 class="demo__title">
            <svg class="demo__icon" xmlns="http://www.w3.org/2000/svg" fill="#2BBEEE" width="13" height="13"><path d="M6.5 0A6.502 6.502 0 0 0 0 6.5C0 10.088 2.912 13 6.5 13S13 10.088 13 6.5 10.088 0 6.5 0zm3.25 7.15h-2.6v2.6h-1.3v-2.6h-2.6v-1.3h2.6v-2.6h1.3v2.6h2.6v1.3z"/></svg>
            Create a Demo
          </h3>
        </div>
      </div>
    </div>

    <!-- General Card -->
    <div v-else @click="template ? delegateActions('fork', demo): goToDemo(demo, $event)">
      <div class="demo__header">
        <div class="demo__header__right">
          <h3 class="demo__title">
            <span class="demo__icon  demo__icon--open"><svg class="demo__icon__svg boxed" fill="#0D80A5" width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M10.667 10.667H1.333V1.333H6V0H1.333C.593 0 0 .6 0 1.333v9.334C0 11.4.593 12 1.333 12h9.334C11.4 12 12 11.4 12 10.667V6h-1.333v4.667zM7.333 0v1.333h2.394L3.173 7.887l.94.94 6.554-6.554v2.394H12V0H7.333z"></path></svg></span>
            <span class="demo__title__text">{{demo && demo.title ? demo.title : 'Untitled Demo'}}</span>
          </h3>
            <h4 v-if="template" class="demo__description list-hide">{{demo ? demo.description : null}}</h4>
            <h4 v-else class="demo__date mobile--hide"><span class="tablet--hide">Last edited </span>{{formatDate(demo && demo.date ? demo.date : '')}}</h4>
        </div>
        <div v-if="!template" class="demo__header__left">
          <div class="el-dropdown desktop-lg--hide" @click="toggle($event)">
            <span class="el-dropdown-link el-dropdown-selfdefine focusing" aria-haspopup="list" aria-controls="dropdown-menu" role="button" tabindex="0">
              <svg class="demo__icon" fill="#ABB2B9" width="14" height="4" xmlns="http://www.w3.org/2000/svg"><path d="M1.75 0C.787 0 0 .787 0 1.75 0 2.712.787 3.5 1.75 3.5c.962 0 1.75-.788 1.75-1.75C3.5.787 2.712 0 1.75 0zm10.5 0c-.963 0-1.75.787-1.75 1.75 0 .962.787 1.75 1.75 1.75S14 2.712 14 1.75C14 .787 13.213 0 12.25 0zM7 0c-.963 0-1.75.787-1.75 1.75 0 .962.787 1.75 1.75 1.75s1.75-.788 1.75-1.75C8.75.787 7.963 0 7 0z"/></svg>
            </span>

            <ul class="el-dropdown-menu el-popper" style="transform-origin: center top; z-index: 2008; width: 110px;">
              <li @click="delegateActions('edit', demo)" tabindex="-1" class="el-dropdown-menu__item">
                <svg @click="delegateActions('edit', demo)" width="15" height="15" fill="#fff" style="position:relative; right:2px; top:3px;" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0C3.36 0 0 3.36 0 7.5 0 11.64 3.36 15 7.5 15c4.14 0 7.5-3.36 7.5-7.5C15 3.36 11.64 0 7.5 0zm.75 11.25h-1.5v-4.5h1.5v4.5zm0-6h-1.5v-1.5h1.5v1.5z"/></svg>
                Edit Info
              </li>
              <li @click="delegateActions('fork', demo)" tabindex="-1" class="el-dropdown-menu__item">
                <svg @click="delegateActions('fork', demo)" aria-hidden="true" data-prefix="fas" data-icon="code-branch" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-code-branch fa-w-12 fa-1x" style="color: rgb(175, 175, 175);"><path d="M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 36.4 24.3 67.1 57.5 76.8-.6 16.1-4.2 28.5-11 36.9-15.4 19.2-49.3 22.4-85.2 25.7-28.2 2.6-57.4 5.4-81.3 16.9v-144c32.5-10.2 56-40.5 56-76.3 0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-34-21.2-63.1-51.2-74.6 3.1-5.2 7.8-9.8 14.9-13.4 16.2-8.2 40.4-10.4 66.1-12.8 42.2-3.9 90-8.4 118.2-43.4 14-17.4 21.1-39.8 21.6-67.9 31.6-10.8 54.4-40.7 54.4-75.9zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm224-320c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16z" class=""></path></svg>
                Fork
              </li>
              <li @click="delegateActions('delete', demo)" tabindex="-1" class="el-dropdown-menu__item">
                <svg @click="delegateActions('delete', demo)" aria-hidden="true" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-trash-alt fa-w-14 fa-1x" style="color: rgb(175, 175, 175);"><path d="M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z" class=""></path></svg>
                Delete
              </li>
              <zg-button @click="delegateActions('edit', demo)" action="removerecord" class="el-dropdown-menu__item">
                <zg-icon slot="icon" name=""><svg aria-hidden="true" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-trash-alt fa-w-14 fa-1x" style="color: rgb(175, 175, 175);"><path d="M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z" class=""></path></svg></zg-icon>
                Delete
              </zg-button>
              <div x-arrow="" class="popper__arrow"></div>
            </ul>
          </div>
        </div>
          
        <!-- List mode -->
        <div class="demo__header__left--list">
          <!-- Inline -->
          <div v-if="demo.tags" class="tag-container desktop-sm--hide tablet--hide mobile--hide">
            <template v-for="(tag, index) in demo.tags.slice(0, 4)">
              <span v-if="index < 3" class="tag" :key="tag.name">{{tag.name}}</span>
              <span v-else>
                <button @click="toggle($event)" type="button" class="tag-button el-button el-button--text el-popover__reference" tabindex="0" style="position: relative">
                  <span>{{demo.tags.length - 3}} more...</span>
                  <ul class="el-dropdown-menu el-popper" style="transform-origin: center top; z-index: 2008; text-align: center; width: fit-content;">
                    <li v-for="tag2 in demo.tags.slice(3)" :key="tag2.name" tabindex="-1" class="el-dropdown-menu__item">
                      <!-- <span class="tag tag--menu" style="padding: 0.2rem 0.5rem; width: fit-content">{{tag.name}}</span> -->
                    </li>
                    <div x-arrow="" class="popper__arrow"></div>
                  </ul>
                </button>
              </span>
            </template>
          </div>
              
          <!-- Menu -->
          <div v-if="demo.tags && demo.tags.length > 0" @click="toggle($event)" class="el-dropdown desktop-lg--hide">
            <span class="el-dropdown-link el-dropdown-selfdefine focusing" aria-haspopup="list" aria-controls="dropdown-menu" role="button" tabindex="0">                
              <font-awesome-icon :icon="['fas', 'tags']" size="1x"/><br>
            </span>
            <ul class="el-dropdown-menu el-popper" style="transform-origin: center top; z-index: 2008; text-align: center; width: fit-content;">
              <li v-for="tag in demo.tags" :key="tag.name" tabindex="-1" class="el-dropdown-menu__item">
                <span class="tag">{{tag.name}}</span>
              </li>
              <div x-arrow="" class="popper__arrow"></div>
            </ul>
          </div>

          <!-- Description and Buttons -->
          <h4 v-if="template" class="demo__description mobile--hide tablet-hide">{{ demo ? demo.description : null }}</h4>
          <template v-else>
            <div class="button-container desktop-sm--hide tablet--hide mobile--hide">
              <button @click="delegateActions('edit', demo)" class="button">
                <svg @click="delegateActions('edit', demo)" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 8 9" fill="#fff"><path d="M4 .5c-2.208 0-4 1.792-4 4s1.792 4 4 4 4-1.792 4-4-1.792-4-4-4zm.4 6h-.8V4.1h.8v2.4zm0-3.2h-.8v-.8h.8v.8z" /></svg>
                Edit Info
              </button>
              <button @click="delegateActions('fork', demo)" class="button">
                <svg @click="delegateActions('fork', demo)" width="12" height="16" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path d="M12 4.5a2.5 2.5 0 1 0-3.203 2.4c-.019.503-.131.89-.344 1.153-.481.6-1.54.7-2.662.803-.882.082-1.794.169-2.541.528v-4.5A2.499 2.499 0 0 0 2.5 0a2.5 2.5 0 0 0-.75 4.884v6.229A2.506 2.506 0 0 0 0 13.5a2.5 2.5 0 1 0 3.4-2.331 1.1 1.1 0 0 1 .466-.419c.506-.256 1.262-.325 2.065-.4 1.319-.122 2.813-.262 3.694-1.356.438-.544.66-1.244.675-2.122A2.51 2.51 0 0 0 12 4.5zM2.5 2c.275 0 .5.225.5.5s-.225.5-.5.5a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm0 12a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5s.5.225.5.5-.225.5-.5.5zm7-10c.275 0 .5.225.5.5s-.225.5-.5.5a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5z"/></svg>  
                Fork
              </button>
              <button @click="delegateActions('delete', demo)" class="button">
                <svg @click="delegateActions('delete', demo)" width="12" height="15" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path d="M0 2.362v-.787C0 1.201.287.9.643.9h3l.252-.526A.633.633 0 0 1 4.468 0h3.061a.64.64 0 0 1 .576.374L8.357.9h3c.356 0 .643.3.643.675v.787a.33.33 0 0 1-.321.338H.32A.33.33 0 0 1 0 2.362zm11.143 1.575v9.113c0 .745-.576 1.35-1.286 1.35H2.143c-.71 0-1.286-.605-1.286-1.35V3.937A.33.33 0 0 1 1.18 3.6h9.642a.33.33 0 0 1 .322.337zM3.857 5.85a.441.441 0 0 0-.428-.45.441.441 0 0 0-.429.45v6.3c0 .248.193.45.429.45a.441.441 0 0 0 .428-.45v-6.3zm2.572 0A.441.441 0 0 0 6 5.4a.441.441 0 0 0-.429.45v6.3c0 .248.193.45.429.45a.441.441 0 0 0 .429-.45v-6.3zM9 5.85a.441.441 0 0 0-.429-.45.441.441 0 0 0-.428.45v6.3c0 .248.193.45.428.45A.441.441 0 0 0 9 12.15v-6.3z"/></svg>
                Delete
              </button>
            </div>
            
            <div @click="toggle($event)" class="el-dropdown desktop-lg--hide">
              <span class="el-dropdown-link el-dropdown-selfdefine focusing" aria-haspopup="list" aria-controls="dropdown-menu" role="button" tabindex="0">
                <svg class="demo__icon" fill="#ABB2B9" width="14" height="4" xmlns="http://www.w3.org/2000/svg"><path d="M1.75 0C.787 0 0 .787 0 1.75 0 2.712.787 3.5 1.75 3.5c.962 0 1.75-.788 1.75-1.75C3.5.787 2.712 0 1.75 0zm10.5 0c-.963 0-1.75.787-1.75 1.75 0 .962.787 1.75 1.75 1.75S14 2.712 14 1.75C14 .787 13.213 0 12.25 0zM7 0c-.963 0-1.75.787-1.75 1.75 0 .962.787 1.75 1.75 1.75s1.75-.788 1.75-1.75C8.75.787 7.963 0 7 0z"/></svg>
              </span>

              <ul class="el-dropdown-menu el-popper" style="transform-origin: center top; z-index: 2008; width: 110px;">
                <li @click="delegateActions('edit', demo)" tabindex="-1" class="el-dropdown-menu__item">
                  <svg @click="delegateActions('edit', demo)" width="15" height="15" fill="#fff" style="position:relative; right:2px; top:3px;" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0C3.36 0 0 3.36 0 7.5 0 11.64 3.36 15 7.5 15c4.14 0 7.5-3.36 7.5-7.5C15 3.36 11.64 0 7.5 0zm.75 11.25h-1.5v-4.5h1.5v4.5zm0-6h-1.5v-1.5h1.5v1.5z"/></svg>
                  Edit Info
                </li>
                <li @click="delegateActions('fork', demo)" tabindex="-1" class="el-dropdown-menu__item">
                  <svg @click="delegateActions('fork', demo)" aria-hidden="true" data-prefix="fas" data-icon="code-branch" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-code-branch fa-w-12 fa-1x" style="color: rgb(175, 175, 175);"><path d="M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 36.4 24.3 67.1 57.5 76.8-.6 16.1-4.2 28.5-11 36.9-15.4 19.2-49.3 22.4-85.2 25.7-28.2 2.6-57.4 5.4-81.3 16.9v-144c32.5-10.2 56-40.5 56-76.3 0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-34-21.2-63.1-51.2-74.6 3.1-5.2 7.8-9.8 14.9-13.4 16.2-8.2 40.4-10.4 66.1-12.8 42.2-3.9 90-8.4 118.2-43.4 14-17.4 21.1-39.8 21.6-67.9 31.6-10.8 54.4-40.7 54.4-75.9zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm224-320c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16z" class=""></path></svg>
                  Fork
                </li>
                <li @click="delegateActions('delete', demo)" tabindex="-1" class="el-dropdown-menu__item">
                  <svg @click="delegateActions('delete', demo)" aria-hidden="true" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-trash-alt fa-w-14 fa-1x" style="color: rgb(175, 175, 175);"><path d="M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z" class=""></path></svg>
                  Delete
                </li>
                <zg-button @click="delegateActions('delete', demo)" action="removerecord" class="el-dropdown-menu__item">
                  <zg-icon slot="icon" name=""><svg aria-hidden="true" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-trash-alt fa-w-14 fa-1x" style="color: rgb(175, 175, 175);"><path d="M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z" class=""></path></svg></zg-icon>
                  Delete
                </zg-button>
                <div x-arrow="" class="popper__arrow"></div>
              </ul>
            </div>
          </template>
        </div>
        <!-- End of List mode -->
      </div>
      <div class="demo__body">
        <div class="demo__image__wrapper list-hide">
          <img class="demo__image" :src="template ? demoTemplate(demo && demo.template_type ? demo.template_type : null) : demo && demo.image ? demo.image : null" alt="" onerror="this.style.display = 'none'"/>
        </div>
        <div class="demo__image demo__image__default" :class="template ? ' demo__image__default--template' : ''">
          <span class="demo__image__default__text">{{template ? '' : 'Generating Screenshot'}}</span>
        </div>
        <div class="demo__action">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none"><path d="M6.5 0A6.502 6.502 0 0 0 0 6.5C0 10.088 2.912 13 6.5 13S13 10.088 13 6.5 10.088 0 6.5 0zm3.25 7.15h-2.6v2.6h-1.3v-2.6h-2.6v-1.3h2.6v-2.6h1.3v2.6h2.6v1.3z" fill="#2BBEEE"/></svg>
          <div>Create Demo</div>
        </div>
      </div>
    </div>

    <!-- Demo Settings -->
    <demo-settings
      @save="saveDemo"
      @toggle-visibility="settingsVisible = !settingsVisible"
      @update-demo="updateDemoData"
      :demo.sync="demoSettings"
      :settings="['general', 'share', 'template', 'metadata']"
      :settingsVisible.sync="settingsVisible"
    ></demo-settings>
  </section>
</template>

<script>
  import DemoSettings from '../views/demos/DemoSettings.vue';
  import MixinPermissions from '../mixins/permissions.js'
  import SvgIcon from './SvgIcon.vue';

  export default {
    components: { 
      DemoSettings,
      SvgIcon,
    },
    mixins: [MixinPermissions],
    props: ['demo', 'dummy', 'demoType', 'header', 'mode', 'template'],
    data() {
      return {
        settingsVisible: false,

        // Demo Data
        uid: null,
        demoTemplateType: null,
        title: null,
        description: null,
        tags: null,
        image: null,
        isPremium: null,
        isTemplate: null,
        isPublic: null,
      };
    },
    asyncComputed: {
      adminTemplateCreate() {
        return this.checkPermission('admin_template_create');
      },
    },
    computed: {
      demoSettings() {
        return {
          uid: this.uid,
          demoTemplate: this.demoTemplateType,
          title: this.title,
          description: this.description,
          tags: this.tags,
          image: this.image,
          isPremium: this.isPremium,
          isTemplate: this.isTemplate,
          isPublic: this.isPublic,
        }
      },
    },
    methods: {
      delegateActions(command, demo) {
        // Execute action
        const uid = demo.uid;
        const title = demo.title;
        switch(command) {
          case 'fork':
            let path = `/demos/create/${uid}?fork`;
            if (this.$route.path !== path) this.$router.push(path);
          break;
          case 'delete':
            this.$confirm(`Are you sure you want to delete demo "${title || uid}?"`, 'Delete', {
              confirmButtonText: 'Delete',
              cancelButtonText: 'Cancel',
              type: 'error',
            })
            .then(() => {
              this.$api('demo/delete', {
                slug: uid,
              })
              .then(() => {
                this.$message({
                  message: 'Demo successfully deleted!',
                  showClose: true,
                  type: 'success',
                });
                this.$emit('delete');
              });
            })
            .catch((error) => {
              this.$message({
                duration: 10000,
                message: 'Demo could not be deleted.',
                showClose: true,
                type: 'warning',
              });
            });
          break;
          case 'edit':
            this.$api(`demo/retrieve`, {
              slug: uid
            })
            .then(response => {
              this.uid = uid;
              this.demoTemplateType = response.data.template_type || '';
              this.title = response.data.title;
              this.description = response.data.description;
              this.tags = response.data.tags;
              this.image = response.data.image;
              this.isPremium = response.data.premium_template === 'true' ? true : false;
              this.isTemplate = (response.data.is_template === 1);
              this.isPublic = (response.data.public === 1);
              this.settingsVisible = true;
            })
            .catch(error => {
              switch(error && error.response && error.response.status) {
                case 401:
                  if (localStorage.getItem('startup_status')) location.reload();
                  else if (this.$route.path !== '/401') this.$router.push('/401');
                  break;
                default:
                  this.$router.replace('/404');
                  break;
              }
            });
          break;
        }
      },
      demoTemplate(template_type) {
        if (template_type) return `../assets/images/demoTemplates/${template_type}.png`;
      },
      formatDemoLink(uid) {
        return '/demos/create/' + uid;
      },
      formatDate(date) {
        if (date) {
          const months = ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
          const dateItem = date.split(' ');
          if (dateItem && dateItem[0] && dateItem[1] && dateItem[2]) {
            return `${months.indexOf(dateItem[0])+1}/${dateItem[1].slice(0, -1)}/${dateItem[2]}`;
          }
        }
        return 'N/A';
      },
      goToDemo(demo, event) {
        // Prevent if attempting to click for another action (open menu)
        let trigger = null; 
        if (event.srcElement.classList.contains('.el-dropdown')) trigger = event.srcElement.classList.contains('.el-dropdown');
        else if (event.srcElement.closest('.el-dropdown')) trigger = event.srcElement.closest('.el-dropdown');
        if (trigger) return;
        // Go to demo
        let path = `/demos/create/${demo && demo.uid ? demo.uid : (this.demoType ? '?type='+this.demoType.toLowerCase() : '')}`;
        if (this.$route.path !== path) this.$router.push(path);
      },
      /**
       * @description Saves the demo
       * @param { String } type - determines if demo is saved as personal or specified group. If none provided, saved as last specified
       * @param { Boolean } displayMessage - determines which success message to display on save
       */
      saveDemo(type, displayMessage = true) {
        // Determine type of grid
        const data = {
          title: this.title,
          description: this.description,
          public: this.isPublic,
          is_template: this.isTemplate,
          template_type: this.demoTemplateType,
          premium_template: this.isPremium,
        };
        if (this.adminTemplateCreate) data['default_template'] = this.isPublic ? 'true' : 'false';
        // Expect type to be group id (not event object triggered by saving from settings)
        if (type && typeof(type) !== 'object') data['id_grouping'] = type === 'personal' ? null : type;
        this.$api('demo/update', {
          slug: this.uid,
          data,
        })
        .then(response => {
          let messageStatus = response.data.warningCount > 0 ? 'warning' : response.data.result ;
          let messageText = messageStatus === 'success' ? 'Demo updated!' : response.data.warning;
          this.$message({
            message: messageText,
            showClose: true,
            type: messageStatus,
          });

          this.saveTags();
          this.$nextTick(() => this.settingsVisible = false);
        })
      },
      saveTags() {
        //Sort through all of the tags. If any are null, then we need to save them.
        //TODO: Batch save tags
        let tagsToSave = this.tags.filter((tag) => tag.id === null);
        tagsToSave.forEach((tag) => {
          this.$api('tag/add', {
            uid: this.uid,
            name: tag.name,
          })
          .catch((error) => {
            this.$message({
              duration: 10000,
              message: 'Could not create tag',
              showClose: true,
              type: 'error',
            })
          });
        });
      },
      toggle(event) {
        let target = event.target.closest('.el-dropdown') || event.target;
        if (target.classList.contains('active')) target.classList.remove('active');
        else target.classList.add('active');
      },
      /**
       * @description Update demo information to save
       */
      updateDemoData(prop, val) {
        switch (prop) {
          case 'demoTemplate': this.demoTemplateType = val; break;
          case 'description': this.description = val; break;
          case 'isPremium': this.isPremium = val; break;
          case 'isPublic': this.isPublic = val; break;
          case 'isTemplate': this.isTemplate = val; break;
          case 'tags': this.tags = val; break;
          case 'title': this.title = val; break;
        }
      },
    }
  }
</script>
<style scoped>
  .demo__action {
    margin-top: 1rem;
  }
  .demo__card {
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-card);
    cursor: pointer;
    display: flex;
    flex-basis: 31%;
    flex-direction: column;
    height: 9.375rem;
    margin-top: 2.25rem;
    max-height: 9.375rem;
    max-width: 22rem;
    overflow: visible;
    padding: 1rem 1.4375rem;
    transition: box-shadow 0.25s ease-out;
  }
  [mode="grid"] .demo__card--dummy {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    position: relative;
  }
  .demo__header--dummy {
    height: auto;    
    position: relative;
    width: 40%;
  }
  .demo__header__left--dummy {
    min-width: 50%;
    width: 40%;
  }
  .demo__card .button svg {
    margin-right: 0.4375rem;
  }

  /* Grid Mode : Hover */
  .demo__card:hover {
    background: var(--color-tertiary-7);
    border-color: var(--color-tertiary-7);
  }
  .demo__card:hover .button {
    background: #fff;
    border-color: #fff;
    color: var(--color-tertiary-7);
  }
  .demo__card:hover .button svg {
    fill: var(--color-tertiary-7);
  }
  .demo__card:hover .demo__card--dummy {
    display: inherit;
  }
  .demo__card:hover .demo__date,
  .demo__card:hover .demo__description,
  .demo__card:hover .demo__title {
    color: #fff;
  }
  .demo__card:hover .demo__icon {
    fill: #fff;
  }
  .demo__card:hover .demo__body {
    display: flex;
  }
  [demo-viewer] .demo__card:hover .demo__image__wrapper {
    background-color: var(--color-tertiary-7);
  }
  [mode="grid"] .grid-hide,
  [mode="list"] .list-hide,
  .demo__card:hover .hover-hide,
  .demo__card-dummy:hover .hover-hide,
  .demo__card:not(:hover) .not-hover-hide {
    height: 0 !important;
    margin: 0;
    opacity: 0 !important;
    padding: 0;
    width: 0;
  }
  [mode="grid"] .demo__card--dummy:hover {
    padding: 1rem 6.5rem;
    max-width: 22rem;
  }
  [mode="grid"] .demo__card:hover .demo__image,
  [mode="grid"] .demo__card:hover .demo__image__wrapper {
    height: 0 !important;
    overflow: hidden;
    padding: 0;
    width: 0;
  }
  /* List */
  [mode="list"] .demo__card {
    display: flex;
    flex-basis: 100%;
    height: 3.125rem;
    padding: 0 0.75rem;
    margin: 0 0 0.375rem 0;
    max-width: 100%;
  }

  /* List Mode : Hover */
  [mode="list"] .demo__card:hover .demo__button {
    opacity: 1;
    pointer-events: all;
  }
  [mode="list"] .demo__card:hover .tag {
    background: var(--color-tertiary-7);
    border: 1px solid #fff;
    color: #fff;
  }
  [mode="list"] .demo__card:hover .tag-button,
  [mode="list"] .demo__card:hover [aria-controls="dropdown-menu"][role="button"] svg {
    color: #fff;
  }
  [mode="list"] .demos .demo__card {
    padding: 0.75rem 1.4375rem;
  }

  /* Template */
  [template] .demo__card {
    background: var(--background-light);
    border: 1px solid #d8d8d8;
    border-image: url('../assets/images/dashed-border.png') 1 round;
  }
  [template] .demo__card:hover {
    background: var(--color-tertiary-7);
    border-color: var(--color-tertiary-7);
  }

  /* Element Overwrites */
  .el-dropdown:hover [aria-controls="dropdown-menu"][role="button"] svg {
    color: #fff;
  }
  [aria-controls="dropdown-menu"][role="button"] svg {
    color: var(--color-greyscale-10);
    fill: var(--color-greyscale-10);
  }

  @media screen and (max-width: 1200px) {
    .demo__card {
      flex-basis: 48%;
      max-width: initial;
    }
    .demo__card:nth-child(odd) {
      margin-left: 1rem;
    }
  }

  @media screen and (max-width: 872px) {
    .demo__card {
      flex-basis: 100%;
      max-width: initial;
    }
    .demo__card:nth-child(odd) {
      margin-left: 0;
    }
  }
</style>