<template lang="html">
  <default-layout>
    <div class="flex content__wrap--sidebar">
      <app-sidebar
        :active-primary="activePrimary"
        :active-secondary="activeSecondary"
        :open="sidebarOpen"
        :sidebars="sidebarPrimary"
        @update-secondary="updateTab"
        @update-primary="updateView"
      >
      </app-sidebar>

      <section class="content--hasSidebar">
        <!-- Forces to check until user found to load groups -->
        <div v-show="authenticated"></div>
            
        <!-- Header -->
        <header :groups="activePrimary == 'groups'">
          <!-- Header Text -->
          <h1 class="content__header">{{header}}</h1>
          <section class='header-container'>
            <!-- Tabs -->
            <div class='tab-scroller mobile--hide'>
              <template v-for="(item, index) in sidebarSecondary">
                <span v-if="index !== 0" class="tab" @click="updateTab(item.id)" :tab="item.id" :class="`${item.id == activeSecondary ? 'active' : ''}`">{{item.name}}</span>
              </template>
            </div>
            <div v-show="activePrimary != 'groups'" class="header-container__right">
              <!-- Demo View Options -->
              <div class="tab-group tab-group--view">
                <div class="tab tab--small trigger" @click="updateViewMode('list')" :class="activeViewMode('list')" name="list"><font-awesome-icon class="zoom" :icon="['fas', 'th-list']"/></div>
                <div class="tab tab--small trigger" @click="updateViewMode('grid')" :class="activeViewMode('grid')" name="grid"><font-awesome-icon class="zoom" :icon="['fas', 'th']"/></div>
              </div>
            </div>
          </section>
        </header>

        <!-- MAIN -->
        <main>
          <!-- Manage/Create Dashboard -->
          <template v-if="activePrimary != 'groups'">
            <!-- IE/Edge Compatible Viewer -->
            <demo-viewer2 v-if="activeSecondary && IEorEdge"
              @update-view="updateView"
              :demo-type="activePrimary != 'groups' ? activeSecondary : null"
              :group="activePrimary == 'groups' ? activeSecondary : false"
              :mode="viewMode"
              :pagination="true"
              :ref="activeSecondary"
              :search="true"
              :template="activePrimary == 'create'">
            </demo-viewer2>
            <!-- ZingGrid -->
            <demo-viewer v-else-if="activeSecondary"
              @update-view="updateView"
              :demo-type="!(activePrimary == 'groups' || activeSecondary == 'templates') ? activeSecondary : null"
              :mode="viewMode"
              :pagination="true"
              :ref="activeSecondary"
              :search="true"
              :template="activePrimary == 'create'"
              :user-id="userId">
            </demo-viewer>
          </template>
          <!-- Group Dashboard -->
          <my-groups
            v-else
            @update-view="updateView"
            :active-tab="activeSecondary">
          </my-groups>
        </main>

      </section>
    </div>
  </default-layout>
</template>
<script>
  import axios from 'axios';
  import AppSidebar from '../../components/AppSidebar.vue';
  import DemoViewer from '../../components/DemoViewer.vue';
  import DemoViewer2 from '../../components/DemoViewer2.vue';
  import DefaultLayout from '../layouts/Default.vue';
  import MyGroups from '../groups/Groups.vue';
  import MixinGroup from './../../mixins/groups.js';
  import MixinPermissions from './../../mixins/permissions.js';
  import SvgIcon from '../../components/SvgIcon.vue';

  export default {
    components: {
      AppSidebar,
      DemoViewer,
      DemoViewer2,
      DefaultLayout,
      MyGroups,
      SvgIcon,
    },
    mixins: [MixinGroup, MixinPermissions],
    asyncComputed: {
      groupingDashboardView() {
        return this.checkPermission('grouping_dashboard_view');
      },
    },
    computed: {
      activePrimary() {
        return this.$store.state.ui['sidebarPrimary'];
      },
      activeSecondary() {
        let sidebarSecondary = this.$store.state.ui['sidebarSecondary'];
        localStorage.setItem('sidebarSecondary', sidebarSecondary);
        if (sidebarSecondary === 'zingchart' || sidebarSecondary === 'zinggrid') sidebarSecondary = this.$store.state.user['referrer'].toLowerCase();
        return sidebarSecondary;
      },
      authenticated() {
        // Fetch groups when not attempting to log out
        if (this.$store.state.user['user_id'] && this.$route.path !== '/logout') this.fetchGroups();
        return this.$store.state.user['user_id'];
      },
      count() {
        return this.$store.state.user['demoCounts'];
      },
      IEorEdge() {
        return document.documentMode || /Edge/.test(navigator.userAgent);
      },
      referrer() {
        return this.$store.state.user['referrer'].toLowerCase();
      },
      sidebarCreate: {
        get() {
          return this.$store.state.user['sidebar']['create'] || 
          {
            id: 'create',
            transition: 'spinZoom',
            sidebar: [
              {
                id: 'create',
                name: 'Create',
                nav: [{
                  id: 'ML6T0HWY',
                  name: 'Create blank grid',
                  linkType: 'fork',
                }, {
                  id: 'ZB4HLRGI',
                  name: 'Create blank chart',
                  link: 'fork',
                }],
              }, 
              {
                count: null,
                id: 'zinggrid',
                name: 'Grids',
                nav: [],
              }, {
                count: null,
                id: 'zingchart',
                name: 'Charts',
                nav: [],
              }, {
                count: null, 
                id: 'templates',
                name: 'My Templates',
                nav: [],
              }
            ]
          };
        },
        set(newVal) {},
      },
      sidebarGroups: {
        get() {
          let createRequestTab = [{
            id: "createRequest",
            name: "Create/Request"
          }];

          return {
            id: 'groups',
            transition: 'zoom',
            sidebar: [{id: 'groups', name: 'Groups'}].concat(this.myGroups).concat(createRequestTab),
          }
        },
        set(newVal) {},
      },
      sidebarManage: {
        get() {
          return this.$store.state.user['sidebar']['manage'] || 
            {
              id: 'manage',
              transition: 'zoom',
              sidebar: [
                {
                  id: 'manage',
                  name: "Manage",
                }, {
                  count: null,
                  id: 'zinggrid',
                  name: 'Grids',
                }, {
                  count: null,
                  id: 'zingchart',
                  name: 'Charts',
                }, {
                  count: null,
                  id: 'all_demos',
                  name: 'All'
                },
              ],
            };
        },
        set(newVal) {},
      },
      sidebarOpen() {
        return this.$store.state.ui['isSidebarOpen'];
      },
      sidebarPrimary: {
        get() {
          this.$nextTick(() => { this.setupSidebar(); });
          let sidebar = {
            'manage': this.sidebarManage,
            'create': this.sidebarCreate,
          };
          // Include groups only if have permission
          if (this.groupingDashboardView) sidebar['groups'] = this.sidebarGroups;
          return sidebar;
        },
        set(newVal) {
          this.sidebarManage = newVal.sidebarManage;
          this.sidebarCreate = newVal.sidebarCreate;
          if (newVal.sidebarGroups) this.sidebarGroups = newVal.sidebarGroups;
        },
      },
      userId() {
        return this.$store.state.user.user_id;
      },
    },
    data() {
      return {
        demo: null,
        fetchData: false,
        header: null,
        sidebarSecondary: [],
        viewBtns: null,
        viewMode: this.$store.state.user['settings_dashView'] ? this.$store.state.user['settings_dashView']  : 'grid',
      }
    },
    beforeCreate() {
      this.$store.commit('user/initializeSidebar');
    },
    mounted() {
      this.checkSession();
    },
    methods: {
      /**
       * @description Appends active class on button associated to active view mode
       * @param {String} view - view button to check
       */
      activeViewMode(view) {
        if (view === this.viewMode) return 'active';
        return '';
      },
      checkSession() {
        // Redirect to last seen for expired session
        let lastSeen = localStorage.getItem('lastSeen');
        if (lastSeen) {
          localStorage.removeItem('lastSeen');
          if (this.$route.path !== lastSeen) this.$router.push(lastSeen);
        } else this.isRedirecting = false;
      },
      /**
       * @description Fetch sidebar content, such as demo counts and templates. Request body is constructed
       * to make one batch request. The response data is appended to the secondary sidebars.
       * @param {Object} primary - Primary sidebar, which is parent to secondary sidebar. This is used
       * to get the secondary sidebar and construct request body.
       */
      fetchSidebarItems(primary) {
        // Construct request body of sidebars
        let body = [];  // Request body (ex. {primary: [secondary]})
        let primarySidebar = Object.keys(this.sidebarPrimary);
        primarySidebar.forEach((primary) => {
          let secondarySidebar = this.sidebarPrimary[primary] ? this.sidebarPrimary[primary].sidebar : null;
          if (secondarySidebar) {
            secondarySidebar.forEach((secondary, index) => {
              // Make request for each secondary sidebar items
              if (index > 0) body.push(this.sidebarRequestObject(primary, secondary.id));
              // Additional requests for groups for zingchart and zinggrid demo counts
              if (primary === 'groups') {
                body.push(this.sidebarRequestObject(primary, secondary.id, 'zingchart'));
                body.push(this.sidebarRequestObject(primary, secondary.id, 'zinggrid'));
              }
            });
          }
        });

        // Check if already setup
        let localSidebar = localStorage.getItem('zs_sidebar');
        localSidebar = JSON.parse(localSidebar);
        let setup = localSidebar ? !!localSidebar.create.sidebar[1].count : false;

        if (!setup) {
          // Fetch count and templates for sidebars
          axios({
            url: '/api/demo/batch',
            method: 'POST',
            headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
            data: body,
          })
          .then((response) => {
            // Make copy of `this.sidebarPrimary` and assign after to avoid triggering computed property
            let primarySidebarCopy = Object.assign({}, this.sidebarPrimary);
            // Map out keys
            let keys = this.mapKeys();
            response.data.forEach(data => {
              // Add count
              let secondaryIndex = keys[data.primary].indexOf(data.secondary);
              let primaryRef = primarySidebarCopy[data.primary].sidebar;
              if (data.count_zingchart) primaryRef[secondaryIndex].count_zingchart = data.size;
              else if (data.count_zinggrid) primaryRef[secondaryIndex].count_zinggrid = data.size;
              else primaryRef[secondaryIndex].count = data.size;
              // Add templates (for Create sidebar only)
              if (data.primary === 'create') {
                let nav = [];
                if (data.results) {
                  data.results.forEach(template => {
                    let navObj = {
                      id: template.uid,
                      name: template.title,
                    }

                    // Move "Blank" template to top of list
                    if (template.title === 'Blank') {
                      nav.unshift(navObj);
                    } else {
                      nav.push(navObj);
                    }
                  });
                  primaryRef[secondaryIndex].nav = nav;
                }
              }
            });
            // Assign back to original
            if (JSON.stringify(this.$store.state.user.sidebar) !== JSON.stringify(primarySidebarCopy)) {
              this.sidebarPrimary = Object.assign({}, primarySidebarCopy);
              this.$store.commit('user/update', {sidebar: this.sidebarPrimary});
            }
          });
        }
      },
      /**
       * @description Creates a mapping of sidebar keys. This allows getting the
       * index of the key to prevent having to loop through object to find key.
       * @returns {Object} Mapping of secondary sidebar ids
       */
      mapKeys() {
        return {
          create: this.sidebarPrimary.create.sidebar.map((k) => k.id),
          manage: this.sidebarPrimary.manage.sidebar.map((k) => k.id),
          groups: this.sidebarPrimary.groups ? this.sidebarPrimary.groups.sidebar.map((k) => k.id) : [],
        };
      },
      /**
       * @description Make requests to get sidebar information.
       * For each primary nav item, grab the count of demos/templates for each secondary nav item.
       * Group id provided for retrieving group demos.
       * Demo type provided for personal demos and templates
       * For templates, retrieve all to list out in sidebar.
       */
      async setupSidebar() {
        // Setup sidebar
        let primarySidebar = Object.keys(this.sidebarPrimary);
        await this.fetchSidebarItems(primarySidebar);
        // Save Sidebar
        if (!this.fetchData) {
          this.fetchData = true;
          let sidebars = {
            'manage': this.sidebarPrimary['manage'],
            'create': this.sidebarPrimary['create'],
          };
          this.$store.commit('user/update', {sidebar: sidebars});
        }
      },
      /**
       * @description Creates an object containing query for
       * a single request. This is one request of a batch requests
       * called in `fetchSidebarItems`.
       * @param {String} primary - primary sidebar, parent to secondary sidebar
       * @param {String} secondary - seondary sidebar to determine queries for
       * @param {String} type - specify demotype for group demos
       * @returns {Object} object of queries for specified sidebar
       */
      sidebarRequestObject(primary, secondary, type) {
        // Based on `primary` and `secondary` to determine queries to add to object
        // Flag to determine demo type to filter by: zinggrid, zingchart, null
        let demoType = !(primary === 'groups' || secondary === 'templates') ? secondary : null;
        if (primary === 'groups' && type) demoType = type;
        // Flag to grab all, personal, or group demos
        let group = null;
        if (primary === 'manage') group = 'all_demos';
        else if (primary === 'groups') group = secondary;
        // Flag to determine how many demos to fetch (1000 - attempt to grab all, 1 - required at least one to get count)
        let limit = primary === 'create' && secondary ? 1000 : 1;
        // Flag to grab templates for 'Create' primary sidebar
        let template = primary === 'create';

        // Queries to add to request object
        let requestObject = {
          'request': 'readPage',
          'limit': limit,
          'primary': primary,
          'secondary': secondary,
          'sort_by': 'title',
          'sort_direction': 'ASC',
        };
        // Add grouping id if filtering by group
        if (group) requestObject['id_grouping'] = group;
        // Add user id to grab user's demos
        if (group === 'all_demos' && !template) requestObject['id'] = this.userId;
        // Add query to grab only templates
        if (demoType && template) requestObject['template_default'] = '';
        // Add query to identify zingchart/zinggrid count from default count
        if (type) requestObject[`count_${type}`] = true;

        // Construct filter query and push to request object
        let filter = [];
        // Add filter to get all templates (personal and public)
        if (template) filter.push({by: 'is_template', value: 1, type: 'demo'});
        // Add filter to get specific demo type (chart or grid)
        if (demoType) filter.push({by: 'type', value: demoType, type: 'demo'});
        // Stringify string and push
        if (filter && filter.length > 0) requestObject['filter'] = JSON.stringify(filter);

        // Specify list of props to return
        //if (primary === 'manage')
        requestObject['props_only'] = JSON.stringify(['id_user', 'is_template', 'last_updated', 'tags', 'uid', 'template_type', 'title']);


        return requestObject;
      },
      /**
       * @description Updates the active class on tabs
       * @param {String} id - Id of tab to append active class on
       */
      updateTab(id) {
        // Update state and active tag
        if (id) {
          let sId = id.toString();
          let referrer = sId === 'zinggrid' || sId === 'zingchart' ? sId : null;
          if (referrer) this.$store.state.user['referrer'] = referrer;
          this.$store.commit('ui/updateSidebar', {sidebarSecondary: sId});
        }
        // Update active class
        let tabRef = this.$el.querySelectorAll('.tab-scroller .tab');
        let activeTabPos = null;
        tabRef.forEach(tab => {
          if (tab.getAttribute('tab') === this.activeSecondary) {
            tab.classList.add('active');
            activeTabPos = tab.getBoundingClientRect().left;
          } else tab.classList.remove('active');
        });
        // Scroll to active tab
        let tabContainer = this.$el.querySelector('.tab-scroller');
        let tabContainerPos = tabContainer.getBoundingClientRect().left;
        let tabContainerScroll = tabContainer.scrollLeft;
        tabContainer.scrollLeft = activeTabPos - tabContainerPos + tabContainerScroll;
      

        // Clear filtered results
        this.$nextTick(() => {
        let index = id.toString();
        if (this.$refs && this.$refs[index] && this.$refs[index].clearFilterResults) this.$refs[index].clearFilterResults();
        });
      },
      /**
       * @description Update view and sidebar.
       * @param {String} id - ID of primary sidebar to set active
       * @param {Array} sidebar - Secondary sidebar items
       * @param {String} tab - ID of tab to set active
       */
      updateView(id, sidebar, tab, filter) {
        setTimeout(() => {
          // Update view and sidebar
          this.$store.commit('ui/updateSidebar', {sidebarPrimary: id});
          this.sidebarSecondary = this.sidebarPrimary[id].sidebar;
          this.header = this.sidebarPrimary[id].sidebar[0].name;
          // Set tab
          if (tab) this.updateTab(tab);
        }, 500);
      },
      /**
       * @description Update user settings for dashboard view mode
       */
      updateViewMode(val) {
        // Save reference
        if (!this.viewBtns) this.viewBtns = this.$el.querySelectorAll('.tab-group--view .tab');
        // Add active
        this.viewBtns.forEach(btn => {
          if (btn.getAttribute('name') === val) btn.classList.add('active');
          else btn.classList.remove('active');
        });
        this.viewMode = val;
        // Update state
        this.$api('user/update', {
          settings_dashView: val === 'grid' ? 'grid' : 'list',
        })
        .then((response) => {
          this.$store.state.user['settings_dashView'] = val;
        })
        .catch((result) => {
          this.$message({
            duration: 10000,
            message: 'Unable to update dashboard view settings',
            showClose: true,
            type: 'error',
          });
        })
      }
    }
  }
</script>

<style>
  /* GLOBAL */
  :root {
    --tab-border-color: #d2d2d2;
  }
</style>
<style scoped>
  .content { height: auto; padding: 2rem; }
  .content__wrap--sidebar {
    overflow-x: hidden;
  }

  .button--group svg {
    bottom: 1px;
    position: relative;
  }

  /* HEADER */
  header[groups="true"] {
    border-bottom: 1px solid #a9b9bf;
  }
  header[groups="true"] .header-container {
    border-bottom: 0;
  }
  .header-container {
    align-items: flex-end;
    border-bottom: 1px solid var(--background-light);
    display: flex;
    justify-content: space-between;
    margin-left: auto;
  }
  .header-container__right {
    align-items: center;
    display: flex;
    margin-left: auto;
    position: relative;
  }

  .append {
    display: flex;
    justify-content: flex-end;
    transition: width 0.25s ease-in-out;
  }
  .append .button__icon {
    margin: 0;
    overflow: hidden;
    width: 0;
    transform-origin: 50% 6.5px;
    transition: all 0.5s ease-in-out;
  }
  .append:hover {
    width: 9.313rem;
  }
  .append:hover .button__icon {
    margin: 0.625rem;
    min-width: 13px;
    width: auto;
    transform: rotate(135deg);
  }
  
  @media screen and (max-width:472px) {
    .header-container__right {
      margin-left: 0;
      margin-right: unset;
      width: 100%;
    }
    .tab + .tab-group--view {
      margin-left: auto;
    }
  }
  @media screen and (max-width:950px) {
    .content { height: auto; }
  }
  @media screen and (min-width: 472px) {
    .header-container__right {
      min-width: 15.5rem;
      justify-content: flex-end;
    }
    .header-container__right::after {
      background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--background-light));
      content: "";
      height: 2.5rem;
      left: -3rem;
      pointer-events: none;
      position: absolute;
      bottom: 0;
      width: 3rem;
    }
  }
</style>

<style>
  .el-pager li,
  .el-pagination button {
    background: transparent !important;
  }
</style>
