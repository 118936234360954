<template>
  <section class="wrapper">
    <section class="view__wrap">
      <section class="content__wrap">
        <header-bar></header-bar>
        <slot></slot>
      </section>
    </section>
    <app-footer></app-footer>
  </section>
</template>

<script>
import AppFooter from './Footer.vue';
import HeaderBar from './HeaderBar.vue';
export default {
  components: {
    AppFooter,
    HeaderBar,
  }
};
</script>

<style>
  .app-footer--narrow, .content--narrow {
    width: 100%;
    max-width: var(--max-width);
    padding: 0 1rem 1rem;
  }
  .content--hasSidebar {
    max-width: calc(100vw - var(--sidebar-primary-width));
    padding: 0 1rem 1rem;
    width: 100%;
  }

  .content--hasSidebar--wide {
    width: 100%;
    margin-left: auto;
    max-width: calc(100% - var(--sidebar-primary-width));
  }

  .content--hasSidebar--wide.ready {
    transition: max-width 0.25s ease-in;
  }

  .content--hasSidebar--wide.editor {
    height: calc(100vh - var(--header-height) - var(--editor-tools-height));
  }

  [secondary-sidebar="open"] + .content--hasSidebar--wide,
  [create-sidebar="show"][open="open"] + .content--hasSidebar--wide {
    max-width: calc(100% - var(--sidebar-primary-width) - var(--sidebar-secondary-width));
  }

  .content {
    height: 100%;
  }
  .content__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    min-height: 0;
  }
  .view__wrap {
    display: flex;
    height: calc(100% - var(--footer-height));
    width: 100%;
  }
  [hide-footer] .view__wrap {
    height: 100%;
  }
  .wrapper {
    width: 100%;
  }
  
  @media screen and (min-width: 800px) {
    .app-footer--narrow, .content--narrow {
      width: 100%;
      max-width: var(--max-width);
      padding: 0 2rem 2rem;
    }
    .content--hasSidebar {
      padding: 0 2rem 2rem;
    }
  }
  @media screen and (min-width: 800px) and (max-width: 1100px) {
    [mode="list"] [demo-viewer] zg-body zg-row {
      flex-basis: 100%;
      padding: 0;
      margin: 0 0 0.375rem 0;
      max-width: calc(100vw - 4rem - var(--sidebar-primary-width));
    }
  }
</style>
