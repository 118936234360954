<template>
  <div googleEntry="button" @click="executeAction" class="button">
    <div googleEntry="button__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M113.47 309.408L95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z" fill="#fbbb00"/><path d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z" fill="#518ef8"/><path d="M416.253 455.624l.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z" fill="#28b446"/><path d="M419.404 58.936l-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z" fill="#f14336"/></svg></div>
    <div googleEntry="button__text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import { mapState } from '@vuex';

  export default {
    props: {
      regenerateSession: Boolean,   // Determines if session should be regenerate on login/signup
      popup: Boolean,             // Open Google Auth in popup
      signup: Boolean,            // Signup (instead of login) on button click
    },
    computed: {
      ...mapState({
        setAuthIsAuthenticatingLocalStorage: state => state.auth.setAuthIsAuthenticatingLocalStorage,
        setAuthIsNewSignupLocalStorage: state => state.auth.setAuthIsNewSignupLocalStorage,
      }),
    },
    methods: {
      /**
       * @description Depending on `action`, login/signup through google auth
       */
      executeAction() {
        // Remove auth signup trigger and add setup signup triggers
        if (this.signup) {
          localStorage.removeItem('GoogleAuthSignup');
          this.setAuthIsNewSignupLocalStorage();
        } else localStorage.setItem('GoogleAuthSignup', true);
        
        // Google authenticate (via popup or page redirect)
        if (this.popup) this.$store.dispatch('auth/authorize_google_popup', {
          regenerateSession: this.regenerateSession,
        });
        else this.$store.dispatch('auth/authorize_google', {
          regenerateSession: this.regenerateSession,
        });

        // Toggle localStorage 'auth' state
        this.setAuthIsAuthenticatingLocalStorage();
      }
    },
  }
</script>

<style scoped>
  [googleEntry].button {
    background: #fff;
    border-radius: var(--border-rounded--small);
    box-shadow: rgba(0, 0, 0, 0.24) 0 1px 4px 0px;
    color: #707070;
    cursor: pointer;
    display: flex;
    font-size: 0.625rem;
    justify-content: center;
    text-transform: uppercase;
    transition: background 0.15s cubic-bezier(0.6, -0.28, 0.74, 0.05);
  }

  [googleEntry].button:hover { background: rgba(9, 30, 66, 0.02); }

  [googleEntry="button__icon"] svg { border-bottom-left-radius: 3px; border-top-left-radius: 3px; height: 1.125rem; padding: 0 0.45rem 0 0.75rem; position: relative; top: 0.15rem; width: auto;}
  [googleEntry="button__text"] { font-weight: 900; margin-left: 0; padding: 0.75rem 0.75rem 0.75rem 0; position: relative; top: 2px; }

  @media screen and (min-width:472px) {
    [googleEntry="button__text"] { margin-left: 3px; }
  }
</style>
