<template>
  <default-layout>
    <!-- SIDEBAR -->
    <aside v-if="adminAccess" class="zingNetSidebar sidebar--secondary">
      <nav
        v-for="item in sidebar"
        :key="`znsb_${item.text}`"
        :sidebar-id="`zingNet_${item.text.replace(' ', '').toLowerCase()}`"
        :class="`sidebar-item sidebar-item--secondaryNav${
          item.data ? ' sidebar-item--expandable' : ''
        }`">
        <div class="sidebar-item--flex">
          <div>
            <div class="sidebar-item--flex sidebar-item__inner__top">
              <!-- Display arrow if sidebar item expands -->
              <span
                v-if="item.data"
                icon="angle"
                class="container sidebar-item__angle--secondary">
                <svg
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  xmlns="http://www.w3.org/2000/svg"
                  style="fill: var(--color-tertiary-7); width: 8px">
                  <path
                    d="M1.175 0.589966L5 4.40663L8.825 0.589966L10 1.76497L5 6.76497L0 1.76497L1.175 0.589966Z"
                  ></path>
                </svg>
              </span>
              <!-- Sidebar item label -->
              <div class="sidebar-item__label">{{ item.text }}</div>
              <!-- Sidebar item link -->
              <div class="sidebar-item__link">
                <a :href="item.url" target="_blank" rel="noopener noreferrer"
                  ><font-awesome-icon
                    :icon="['fas', 'external-link-alt']"
                    size="1x"
                /></a>
              </div>
            </div>
            <!-- Sidebar expanded section -->
            <ul>
              <li
                v-for="item_lvl2 in item.data"
                :key="`znsb2_${item_lvl2.text}`">
                <!-- Sidebar with expanded section -->
                <template v-if="item_lvl2.data">
                  <div class="sidebar-item__link">
                    <a
                      :href="item_lvl2.url"
                      target="_blank"
                      rel="noopener noreferrer">
                      {{ item_lvl2.text }}
                      <template v-if="item_lvl2.id">
                        <span :id="item_lvl2.id"></span>
                      </template>
                      <font-awesome-icon
                        :icon="['fas', 'external-link-alt']"
                        size="1x"/>
                    </a>
                  </div>
                  <ul>
                    <li
                      v-for="item_lvl3 in item_lvl2.data"
                      :key="`znsb3_${item_lvl2.text}-${item_lvl3.text}`">
                      {{ item_lvl3.text }}:
                      <span :id="item_lvl3.id">{{ item_lvl3.value }}</span>
                    </li>
                  </ul>
                </template>
                <!-- Sidebar with link -->
                <template v-else-if="item_lvl2.url">
                  <div class="sidebar-item__link">
                    <a
                      :href="item_lvl2.url"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ item_lvl2.text }}
                      <font-awesome-icon
                        :icon="['fas', 'external-link-alt']"
                        size="1x"
                    /></a>
                  </div>
                </template>
                <!-- Sidebar without expanded section and not linked -->
                <template v-else>
                  {{ item_lvl2.text }}:
                  <span :id="item_lvl2.id">{{ item_lvl2.value }}</span>
                </template>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </aside>

    <!-- CONTENT -->
    <section v-if="adminAccess" class="content--narrow">
      <!-- HEADER -->
      <header class="content__header">
        <h1 class="content__header">{{ title }}</h1>

        <!-- TABS -->
        <div class="content__header content__header--justify-content__start">
          <template v-for="(item, index) in tabs">
            <span
              :key="item.id"
              :tab="item.id"
              :class="`tab${index === 0 ? ' active' : ''}`"
              @click="updateTab(item.id, item.label)"
              >{{ item.label }}</span
            >
          </template>
        </div>
      </header>

      <main>
        <!-- ANALYTICS -->
        <analytics :class="showPage('analytics')"></analytics>

        <!-- DEVELOPMENT -->
        <development :class="showPage('development')"></development>

        <!-- OPERATION -->
        <operations :class="showPage('operations')"></operations>

        <!-- SALES -->
        <sales :class="showPage('sales')"></sales>

        <!-- SUPPORT -->
        <support :class="showPage('support')"></support>

        <!-- USERS -->
        <users :class="showPage('users')"></users>
      </main>
    </section>
  </default-layout>
</template>

<script>
import axios from "axios";
import DefaultLayout from "../layouts/Default.vue";
import Analytics from "./components/Analytics/Analytics.vue";
import zingchart from "zingchart";
import Development from "./components/Development/Development.vue";
import MixinPermissions from "../../mixins/permissions";
import Operations from "./components/Operations/Operations.vue";
import Sales from "./components/Sales/Sales.vue";
import Support from "./components/Support/Support.vue";
import Users from "./components/Users/Users.vue";

export default {
  name: "Admin",
  components: {
    "default-layout": DefaultLayout,
    Analytics,
    Development,
    Operations,
    Sales,
    Support,
    Users,
  },
  mixins: [MixinPermissions],
  data: function () {
    return {
      activeTab: "analytics",
      clubhouseProjects: [
        "ZingChart Library",
        "ZingChart Site",
        "ZingGrid Library",
        "ZingGrid Site",
        "ZingSoft",
      ],
      clubhouseProjectIds: [
        VUE_APP_CLUBHOUSE_ZINGCHART_LIB_PROJECT_ID,
        VUE_APP_CLUBHOUSE_ZINGCHART_SITE_PROJECT_ID,
        VUE_APP_CLUBHOUSE_ZINGGRID_LIB_PROJECT_ID,
        VUE_APP_CLUBHOUSE_ZINGGRID_SITE_PROJECT_ID,
        VUE_APP_CLUBHOUSE_ZINGSOFT_PROJECT_ID,
      ],
      clubhouseStories: ["Bugs", "Chores", "Features"],
      tabs: [
        {
          id: "analytics",
          label: "Analytics",
        },
        {
          id: "development",
          label: "Development",
        },
        {
          id: "operations",
          label: "Operations",
        },
        {
          id: "sales",
          label: "Sales",
        },
        {
          id: "support",
          label: "Support",
        },
        {
          id: "users",
          label: "Users",
        },
      ],
      title: "ZingNet",
      sidebar: [
        {
          text: "Clubhouse",
          url: "https://clubhouse.io/",
          data: [
            {
              text: "ZingChart Library",
              url:
                "https://app.clubhouse.io/zingsoft/search#project:50%20is:story%20!state:completed",
              data: [
                {
                  text: "Bugs",
                  id: "zingNet_clubhouse_zingchart_library_bug",
                },
                {
                  text: "Chores",
                  id: "zingNet_clubhouse_zingchart_library_chore",
                },
                {
                  text: "Features",
                  id: "zingNet_clubhouse_zingchart_library_feature",
                },
              ],
            },
            {
              text: "ZingChart Site",
              url:
                "https://app.clubhouse.io/zingsoft/search#project:76%20is:story%20!state:completed",
              data: [
                {
                  text: "Bugs",
                  id: "zingNet_clubhouse_zingchart_site_bug",
                },
                {
                  text: "Chores",
                  id: "zingNet_clubhouse_zingchart_site_chore",
                },
                {
                  text: "Features",
                  id: "zingNet_clubhouse_zingchart_site_feature",
                },
              ],
            },
            {
              text: "ZingGrid Library",
              url:
                "https://app.clubhouse.io/zingsoft/search#project:6%20is:story%20!state:completed",
              data: [
                {
                  text: "Bugs",
                  id: "zingNet_clubhouse_zinggrid_library_bug",
                },
                {
                  text: "Chores",
                  id: "zingNet_clubhouse_zinggrid_library_chore",
                },
                {
                  text: "Features",
                  id: "zingNet_clubhouse_zinggrid_library_feature",
                },
              ],
            },
            {
              text: "ZingGrid Site",
              url:
                "https://app.clubhouse.io/zingsoft/search#project:26%20is:story%20!state:completed",
              data: [
                {
                  text: "Bugs",
                  id: "zingNet_clubhouse_zinggrid_site_bug",
                },
                {
                  text: "Chores",
                  id: "zingNet_clubhouse_zinggrid_site_chore",
                },
                {
                  text: "Features",
                  id: "zingNet_clubhouse_zinggrid_site_feature",
                },
              ],
            },
            {
              text: "ZingSoft",
              url:
                "https://app.clubhouse.io/zingsoft/search#project:27%20is:story%20!state:completed",
              data: [
                {
                  text: "Bugs",
                  id: "zingNet_clubhouse_zingsoft_bug",
                },
                {
                  text: "Chores",
                  id: "zingNet_clubhouse_zingsoft_chore",
                },
                {
                  text: "Features",
                  id: "zingNet_clubhouse_zingsoft_feature",
                },
              ],
            },
          ],
        },
        {
          text: "Code Climate",
          url: "https://codeclimate.com/dashboard",
          data: [
            {
              text: "Test Coverage",
              id: "zingNet_codeClimate_textCoverage",
              value: "90.42%",
            },
            {
              text: "Lines of Code",
              id: "zingNet_codeClimate_linesOfCode",
            },
            {
              text: "Files",
              id: "zingNet_codeClimate_files",
            },
            {
              text: "Complexity",
              id: "zingNet_codeClimate_complexity",
            },
            {
              text: "Duplication",
              id: "zingNet_codeClimate_duplication",
            },
          ],
        },
        {
          text: "Fullstory",
          url: "https://www.fullstory.com/",
        },
        {
          text: "Github",
          url: "https://github.com/",
          data: [
            {
              text: "ZingChart",
              url: "https://github.com/zingsoftinc/zingchart-lib",
              id: "zingNet_github_zingchart_library_version",
              data: [
                {
                  text: "Full Build",
                  id: "zingNet_github_zingchart_library_fullBuild",
                },
                {
                  text: "Full Build (gzip)",
                  id: "zingNet_github_zingchart_library_fullBuild_gzip",
                },
                {
                  text: "Core Build",
                  id: "zingNet_github_zingchart_library_coreBuild",
                },
                {
                  text: "Core Build (gzip)",
                  id: "zingNet_github_zingchart_library_coreBuild_gzip",
                },
                {
                  text: "ES6 Build",
                  id: "zingNet_github_zingchart_library_es6Build",
                },
                {
                  text: "ES6 Build (gzip)",
                  id: "zingNet_github_zingchart_library_es6Build_gzip",
                },
              ],
            },
            {
              text: "ZingGrid",
              url: "https://github.com/zingsoftinc/zingchart-lib",
              id: "zingNet_github_zinggrid_library_version",
              data: [
                {
                  text: "Full Build",
                  id: "zingNet_github_zinggrid_library_fullBuild",
                },
                {
                  text: "Full Build (gzip)",
                  id: "zingNet_github_zinggrid_library_fullBuild_gzip",
                },
                {
                  text: "Core Build",
                  id: "zingNet_github_zinggrid_library_coreBuild",
                },
                {
                  text: "Core Build (gzip)",
                  id: "zingNet_github_zinggrid_library_coreBuild_gzip",
                },
                {
                  text: "ES6 Build",
                  id: "zingNet_github_zinggrid_library_es5Build",
                },
                {
                  text: "ES6 Build (gzip)",
                  id: "zingNet_github_zinggrid_library_es5Build_gzip",
                },
              ],
            },
            {
              text: "ZingSoft",
              url: "https://github.com/zingsoftinc/zingsoft-studio",
            },
          ],
        },
        {
          text: "HelpScout",
          url: "https://helpscout.com/",
        },
        {
          text: "MaxCDN",
          url: "https://cp.maxcdn.com/",
        },
        {
          text: "Matomo",
          url: "https://tracking.zingchart.com/piwik/",
        },
        {
          text: "Miro",
          url: "https://miro.com/",
        },
        {
          text: "Notion",
          url: "https://notion.com/",
          data: [
            {
              text: "Design / Marketing",
              url:
                "https://www.notion.so/zingsoft/Design-Marketing-14fde32b306947f7a6101fa9691ddc43",
            },
            {
              text: "Developers",
              url:
                "https://www.notion.so/zingsoft/Developers-68b0a2ddd1da4894b3ca899943f7da64",
            },
            {
              text: "Product Management",
              url:
                "https://www.notion.so/zingsoft/Product-Management-cefeb0f3ba1247ee961de57eb0b0d16c",
            },
            {
              text: "Support / Sales",
              url:
                "https://www.notion.so/zingsoft/Support-Sales-1a9776e5381144018d43da140a805f7b",
            },
          ],
        },
        {
          text: "Pingdom",
          url: "https://www.pingdom.com/",
        },
        {
          text: "Status Hero",
          url: "https://statushero.com/",
        },
        {
          text: "TrackJS",
          url: "https://trackjs.com/",
        },
      ],
    };
  },
  asyncComputed: {
    adminAccess() {
      return new Promise((resolve, reject) => {
        this.checkPermission("admin_dashboard_view")
          .then((response) => {
            if (!response) this.$router.replace("/401");
            resolve(response);
          })
          .catch(() => {
            reject(false);
          });
      });
    },
  },
  computed: {
    path() {
      let path = this.$route.path;
      let id = path.slice("/admin/".length);
      if (id) {
        this.$nextTick(() => this.updateActiveTab(id));
      }
      return this.$route.path;
    },
  },
  mounted() {
    this.setupSidebar();

    ZC.LICENSE = [
      "c68af4e126f505af0def43c254621ea9",
      "b55b025e438fa8a98e32482b5f768ff5",
    ];
  },
  methods: {
    setupSidebar() {
      let $sidebarItems = document.querySelectorAll(
        ".zingNetSidebar .sidebar-item--expandable .sidebar-item__inner__top"
      );
      for (let i = 0; i < $sidebarItems.length; i++) {
        $sidebarItems[i].addEventListener("click", (e) => {
          let target = e.target.closest(
            ".zingNetSidebar .sidebar-item--expandable"
          );
          if (target) {
            if (target.classList.contains("open")) {
              target.classList.remove("open");
            } else {
              target.classList.add("open");
            }
          }
        });
      }
    },
    showPage(page) {
      let pageMatch = this.path === `/admin/${page}`;
      let defaultMatch = page === "analytics" && this.path === "/admin";
      return pageMatch || defaultMatch ? "show" : "";
    },
    storyLink(projectId) {
      return `https://app.clubhouse.io/zingsoft/search#project:${projectId}%20is:story%20!state:completed`;
    },
    updateActiveTab(id) {
      let tabRef = this.$el.querySelectorAll(".content__header .tab");
      let activeTabPos = null;
      tabRef.forEach((tab) => {
        if (tab.getAttribute("tab") === id) {
          tab.classList.add("active");
        } else tab.classList.remove("active");
      });
    },
    updateTab(id, label) {
      // Update url
      let path = `/admin/${id}`;
      if (this.$route.path !== path) this.$router.push(path);
      // Update state
      if (id) {
        this.activeTab = id;
      }
    },
  },
};
</script>

<style scoped>
h2 {
  margin: 1rem;
}
#sadSVG {
  width: 100%;
}

main > * {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

main > .show {
  opacity: 1;
  pointer-events: all;
  position: relative;
}

/* HEADER */
.content__header {
  border-bottom: 0;
  color: var(--color-primary-blue);
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  font-size: 2.5rem;
  margin-top: 0;
}
header.content__header {
  margin-top: 2.5rem;
}

/* TAB */
.tab {
  background: #fff;
  border: var(--border);
  border-radius: 0;
  box-shadow: 0px 1.67483px 3.34965px 0.837413px rgba(73, 79, 94, 0.07);
  font-family: "Nunito Sans", sans-serif;
  font-size: 1rem;
  font-weight: normal;
  padding: 0.35rem 1rem;
  margin: 1px;
}
.tab.active {
  background: var(--color-primary-gray);
  color: #fff;
}
.tab:not(.active) {
  background: #fff;
  border: var(--border);
  color: var(--color-primary-blue);
}
.tab:not(.active):hover {
  border-color: #40a6c3;
  color: var(--color-tertiary-blue-focus);
}
.tab + .tab,
.tab + .tab-group {
  margin-left: 0.1rem;
}

@media only screen and (max-width: 2200px) {
  .content__header {
    font-size: 2rem;
  }
  .content--narrow-alt {
    max-width: var(--max-width);
  }
}
</style>

<style>
.zingnetCardSection {
  display: flex;
  flex-wrap: wrap;
}

/* Sidebar */
.zingNetSidebar {
  height: calc(100vh - var(--footer-height) - 3rem);
  left: 0;
  overflow-y: scroll;
  padding: 2rem 2.25rem;
  position: fixed;
  width: 15.5rem;
}
.zingNetSidebar a {
  color: var(--color-primary-3);
  font-weight: bold;
  text-decoration: none;
}
.zingNetSidebar a:hover,
.zingNetSidebar
  .sidebar-item--expandable
  .sidebar-item__inner__top:hover
  .sidebar-item__label,
.zingNetSidebar .sidebar-item .sidebar-item__link:hover svg {
  cursor: pointer;
  color: var(--color-tertiary-6);
}
.zingNetSidebar li {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.625rem;
  list-style-type: none;
}
.zingNetSidebar li > *:not(ul) {
  display: inline;
}
.zingNetSidebar ul {
  margin: 0;
  padding-left: 1.5rem;
}
.zingNetSidebar p {
  margin: 0;
}
.zingNetSidebar .sidebar-item {
  cursor: initial;
}
.zingNetSidebar .sidebar-item--expandable ul {
  height: 0;
  overflow: hidden;
}
.zingNetSidebar .sidebar-item--expandable.open ul {
  height: auto;
}
.zingNetSidebar .sidebar-item__link {
  font-size: 0.75rem;
  margin-left: 0.75rem;
}
.zingNetSidebar ul .sidebar-item__link svg {
  bottom: 1px;
  margin-left: 0.25rem;
  position: relative;
}
.zingNetSidebar .sidebar-item__link svg {
  color: var(--color-greyscale-12);
}

@media only screen and (min-width: 1050px) {
  .zingnetCard + .zingnetCard {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 1700px) {
  .zingNetSidebar {
    pointer-events: none;
    opacity: 0;
  }
}
</style>