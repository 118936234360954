<template>
  <section class="zingnetCardSection">
    <!-- User Summary -->
    <user-summary></user-summary>

    <!-- Users -->
    <latest-users></latest-users>
  </section>
</template>

<script>
import LatestUsers from './LatestUsers.vue';
import UserSummary from './UserSummary.vue';

export default {
  components: {
    LatestUsers,
    UserSummary,
  },
}
</script>