<template>
  <section api-key v-if="adminAccess">
    <!-- Settings Section -->
    <header class="content__header">
      <h2 class="header__title">Profile</h2>
    </header>

    <zing-grid
      caption="Existing keys"
      :data="existingKeysData"
      layout="row"
      layout-controls="disabled"
      pager
      page-size="5">
      <zg-colgroup>
        <zg-column index="key" header="Key Prefix"></zg-column>
      </zg-colgroup>
    </zing-grid>
    <el-button @click="generateKey" type="primary">Generate Key</el-button>

    <!-- Generated Key -->
    <el-dialog title="Create New API Key" :visible.sync="dialogVisible">
      <el-alert
        title="API key will only be displayed now."
        type="info"
        :closable="false"
        show-icon>
      </el-alert>

      <p>
        Please store somewhere safe because this key cannot be generated or
        retrieved again.
      </p>

      <div api-key="key-container">
        <el-input v-model="apiKey" ref="apiKeyInput" readonly></el-input>
        <el-popover content="Copied!" placement="right" effect="light">
          <el-button
            @click="copyKey"
            size="mini"
            slot="reference"
            trigger="click"
            type="primary"
            >Copy</el-button
          >
        </el-popover>
      </div>
    </el-dialog>
  </section>
</template>

<script>

import axios from 'axios';
export default {
  props: ["adminAccess"],
  mounted() {
    if (this.adminAccess) {
      this.zgRef = this.$el.querySelector("zing-grid");
      this.fetchExistingKeys();
    }
  },
  data() {
    return {
      apiKey: "",
      apiKeyRef: null,
      dialogVisible: false,
      existingKeys: [],
      zgRef: null,
    };
  },
  computed: {
    existingKeysData() {
      return JSON.stringify(
        this.existingKeys.map((key) => {
          return { key: key };
        })
      );
    },
  },
  methods: {
    /**
     * @description Copies input field content container api key
     */
    copyKey() {
      if (this.$refs["apiKeyInput"]) {
        // Get reference to input field
        if (!this.apiKeyRef)
          this.apiKeyRef = this.$refs["apiKeyInput"].$el.querySelector("input");
        this.apiKeyRef.select();
        document.execCommand("copy");
      }
    },
    /**
     * @description Fetch existing keys
     */
    fetchExistingKeys() {
      axios({
        url: "/api/user/apikey",
        method: "GET",
        headers: { Authorization: `Bearer ${this.$store.state.auth.idToken}` },
      }).then((result) => {
        this.existingKeys = result.data || [];
      });
    },
    /**
     * @description Opens dialog and requests server to generate key. When received, displays API key to
     * user, which will only be available once.
     */
    generateKey() {
      this.dialogVisible = true;

      axios({
        url: "/api/user/apikey",
        method: "POST",
        headers: { Authorization: `Bearer ${this.$store.state.auth.idToken}` },
      }).then((result) => {
        this.apiKey = result.data.rawKey;
        this.existingKeys.push(result.data.prefix);
        this.zgRef.refresh();
      });
    },
  },
};
</script>

<style scoped>
[api-key="key-container"] {
  display: flex;
  margin-top: 2rem;
}

[api-key="key-container"] .el-button {
  margin-left: 1rem;
}

[api-key] zing-grid {
  margin-bottom: 1rem;
}
</style>

<style>
[api-key] .el-dialog__header {
  padding: 20px 20px 10px !important;
}
</style>