<template>
  <el-form-item
    prop="email"
    :rules="[
      { required: true, message: 'Please input email address', trigger: 'blur' },
      { type: 'email', message: 'Please input valid email address', trigger: 'blur' }
    ]">
    <el-input
      v-model="email"
      @change="handleInput"
      @blur="validate()"
      :form="form"
      placeholder="Enter your email" 
      tabindex=1 
      type="email">
      <template slot="prepend"><svg width="14" height="13" viewBox="0 0 32 26" xmlns="http://www.w3.org/2000/svg" class="auth0-lock-icon"><path d="M30 23c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h26c.55 0 1 .45 1 1v20zm0-23H2C.89 0 0 .89 0 2v22a2 2 0 0 0 2 2h28c1.1 0 2-.9 2-2V2a2 2 0 0 0-2-2zm-8 6h4v4h-4V6zm-2 6h8V4h-8v8zM4 16h14v-2H4v2zm0 4h14v-2H4v2z" fill-rule="evenodd"/></svg></template>
    </el-input>
  </el-form-item>
</template>

<script>
  export default {
    props: ['form', 'value'],
    data() {
      return {
        email: this.value || '',
      }
    },
    methods: {
      handleInput(e) {
        this.$emit('input', this.email)
      },
      validate() {
        this.$emit('validate', 'email');
      },
    }
  }
</script>