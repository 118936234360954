<template>
  <section class="zingnetCardSection">
    <!-- Sales Summary -->
    <sales-summary @openQuickbooks="openQuickbooks"></sales-summary>
    
    <!-- Sales -->
    <sale-transactions @openQuickbooks="openQuickbooks"></sale-transactions>
  </section>
</template>

<script>
import SaleTransactions from './SaleTransactions.vue';
import SalesSummary from './SalesSummary.vue';

export default {
  components: {
    SalesSummary,
    SaleTransactions,
  },
  methods: {
    openQuickbooks() {
      window.open('https://quickbooks.intuit.com/');
    },
  },
}
</script>