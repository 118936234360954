<template>
  <section>
    <pre>{{licenseKey}}</pre>
    <textarea></textarea>
    <footer>
      <el-button @click="copyCode" type="primary" size="mini">{{buttonText}}</el-button>
    </footer>
  </section>
</template>

<script>

import axios from 'axios';
export default {
  props: {
    subscription: Object,
  },
  data() {
    return {
      $pre: null,
      $textarea: null,
      fetchText: 'Fetching license key...',
      initialText: 'Copy',
      copyText: 'Copied!',
      buttonText: null,
      licenseKey: null,
    };
  },
  watch: {
    subscription() {
      this.getKey();
    },
  },
  mounted() {
    // References
    this.$pre = this.$el.querySelector('pre');
    this.$textarea = this.$el.querySelector('textarea');

    // Set text
    this.buttonText = this.initialText;
    this.licenseKey = this.fetchText;

    // Fetch license key
    this.getKey();
  },
  methods: {
    /**
     * @description Copies license key to clipboard
     */
    copyCode() {
      // Copy to clipboard
      this.$textarea.textContent = this.licenseKey;
      this.$textarea.select();
      document.execCommand('copy');

      // Update text for 1s
      this.buttonText = this.copyText;
      setTimeout(() => {
        this.buttonText = this.initialText;
      }, 1000);
    },
    /**
     * @description Fetches license key
     */
    getKey() {
      if (this.subscription) {
        let interval = this.subscription.plan.interval;
        let plan = `${interval === 'year' ? 'New/Annual' : 'Perpetual'}:${this.subscription.plan.type}-${this.subscription.plan.product}-${interval === 'year' ? 'A' : 'P'}`;
        axios({
          url: '/api/license/key',
          method: 'POST',
          headers: { 'Authorization': 'Bearer ' + this.$store.state.auth.idToken },
          data: {
            plan: plan,
            invoiceId: this.subscription.invoiceId,
            type: this.subscription.plan.type,
          },
        }).then((response) => {
          this.licenseKey = response.data;
        }).catch((err) => {
          this.$message({
            duration: 10000,
            message: `Could not fetch license key. Please contact us regarding your license key.`,
            showClose: true,
            type: 'error',
          });
        });
      } else {
        this.licenseKey = this.fetchText;
      }
    },
  }
}
</script>

<style scoped>
  footer {
    display: flex;
    justify-content: flex-end;
  }

  pre {
    background-color: #f4f9fb;
    border: 1px solid #d6e1e6;
    border-radius: 4px;
    margin-bottom: 1.25rem;
    padding: 18px 24px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  section {
    padding: 2rem;
  }

  textarea {
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }

  @media screen and (min-width: 472px) {
    section {
      padding: 2rem 3rem;
    }
  }
</style>