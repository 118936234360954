export default {
  data() {
    return {
      pricingInterval: false,
      activePlanIndex: 0,
      productSubscriptions: [],
      subscriptions: [],
      products: [],
      productTypes: ['ZC', 'ZG'],
      productType: null,
      subscriptionNames: null,
      skuQty: [],
      subscriptionDialogVisible: false,
      subscriptionUpdateDialogVisible: false,
      planDialogVisible: false,
    };
  },
  methods: {
    computeAmount(subscription){
      if (subscription.plan.amount) {
        if (subscription.discount && subscription.discount.coupon && subscription.discount.coupon.percent_off) {
          return ((subscription.plan.amount - (subscription.plan.amount * subscription.discount.coupon.percent_off * 0.01))).toFixed(2);
        } else if (subscription.discount && subscription.discount.coupon && subscription.discount.coupon.amount_off) {
          return ((subscription.plan.amount - subscription.discount.coupon.amount_off)).toFixed(2);
        } else {
          return (subscription.plan.amount).toFixed(2);
        }
      }
    },
  }
};