<template>
  <el-button lockButton class="button" :size="size" :type="type" v-on:click="login">
    <slot>Login</slot>
  </el-button>
</template>

<script>
  export default {
    props: {
      'size': String,
      'type': String,
    },
    computed: {
      lock() {
        return this.$store.state.auth.lock;
      },
      tenantName() {
        return this.$store.state.tenant.name;
      },
      tenantLogo() {
        return this.$store.state.tenant.logo;
      },
    },
    mounted() {
      this.$store.commit('auth/init_lock');
    },
    methods: {
      login(event) {
        this.$emit('beforeLogin');

        if (this.lock) {
          // Open auth0 widget if initialize
          this.lock.show({
            initialScreen: 'signUp'
          });
        } else {
          // Retry initialization and open
          this.$store.commit('auth/init_lock', true);
        }
      }
    }
  }
</script>

<style lang="css" >
</style>
