<template>
  <secondary-layout slim=true>
    <section class="content__wrap content__wrap--login">
      <div class="content content--narrow">
        <entry-container form=false slim=true verify>
          <h3 slot="entryHeader">Email Verified</h3>

          <div entryContainer="entry__content__inner" slot="entryBody">
            <h1 slot="entryHeader">Welcome to ZingSoft!</h1>
            <p>Thank you for creating an account.</p>
            <p>To continue, please verify your email.</p>
            <div @click="goHome" class="button button--bottom">
              <p>Verify Email</p>
            </div>
          </div>
          
        </entry-container>
      </div>
    </section>
  </secondary-layout>
</template>

<script>
  import MixinEntry from '../../mixins/entry.js';
  export default {
    mixins: [MixinEntry],
  }
</script>

<style>
  [entryContainer][verify] .button--bottom { margin: 2rem auto 1.5rem; width: 11rem; }
  [verify] .button--bottom p { color: var(--color-reset); font-size: 0.8125rem; margin: auto; }
  [verify="container__body"] { flex: 1; padding: 0.5rem 0; }
  [verify] h1 { color: var(--header-color); font-size: 1.375rem; margin: 2rem auto 0.5rem; max-width: var(--container-width); text-align: center; }
</style>
