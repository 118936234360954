<template>
  <default-layout>
    <!-- Profile -->
    <section v-if="adminAccess"  class="content--narrow">
      <header class="content__header content__header--expanded">
        <img v-if="profile.avatar != '#'" class="profile__avatar" :src="profile.avatar" alt="Avatar">
        <font-awesome-icon v-else class="profile__avatar" :icon="['fas', 'user-circle']" size="10x"/>
        <div class="profile__info">
          <h2>{{profile.email}}</h2>
          <h3 v-if="profile.name">{{profile.name}}</h3>
          <p v-if="profile.company"><font-awesome-icon :icon="['fas', 'building']" size="1x"/>{{profile.company}}</p>
          <p v-if="profile.location"><font-awesome-icon :icon="['fas', 'map-marker-alt']" size="1x"/>{{profile.location}}</p>
        </div>
      </header>

      <!-- Demos -->
      <demo-viewer mode="grid" create=false :user-id="id" :search="true" :pagination="true"></demo-viewer>
    </section>
  </default-layout>
</template>
<script>

import DefaultLayout from '../layouts/Default.vue';
import DemoViewer from '../../components/DemoViewer.vue';
import MixinPermissions from '../../mixins/permissions';
export default {
  components: {
    DefaultLayout,
    DemoViewer,
  },
  mixins: [MixinPermissions],
  asyncComputed: {
    adminAccess() {
      return new Promise((resolve, reject) => {
        this.checkPermission('admin_dashboard_view')
          .then((response) => {
            if (!response) this.$router.replace('/401');
            resolve(response);
          }).catch(() => {
            reject(false);
          })
      });
    },
  },
  data: function() {
    return {
      id: this.$route.params.id,
      profile: {
        avatar: null,
        email: null,
        name: null,
        userId: null,
        company: null,
        location: null,
      }
    }
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    /**
     * @Desciption Gets user profile data
     */
    getProfile() {
      this.$api('user/profile', {
        slug: this.id,
      })
      .then((response) => {
        ['avatar', 'email', 'name', 'userID', 'company', 'location'].forEach(prop => {
          if(response.data) this.profile[prop] = response.data[prop];
        });
      });
    },
  },
}
</script>

<style scoped>
  .content__header--expanded {
    align-items: center;
    justify-content: center;
    margin: 2rem;
  }
  .profile__avatar {
    border-radius: 50%;
    height: 7rem;
    margin: 2rem;
  }
  .profile__info h2 {
    font-size: 1.25rem;
  }
  .profile__info h3 {
    color: var(--color-primary-2);
    font-size: 1rem;
    font-weight: 500;
  }
  .profile__info p {
    color: var(--color-greyscale-2);
  }
  .profile__info h2,
  .profile__info h3,
  .profile__info p {
    margin: 0 0 0.25rem 0;
  }
  .profile__info svg {
    margin-right: 0.5rem;
  }
</style>
