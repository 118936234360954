<template>
  <zingnet-card>
    <span slot="header">
      User Summary
      <date-range @updateDateRange="updateDateRange"></date-range>
    </span>
    
    <div slot="body" v-loading="loading">
      <section class="zingnetCard--row">
        <section class="zingnetCard--column">
          <h3 class="zingnetCard--header">New users</h3>
          <score-card-group v-if="newUsers">
            <score-card slot="card_one" :value="newUsers.cur" :title="`in last ${daysDiff} days`"></score-card>
            <score-card slot="card_two" :value="newUsers.prev" :title="`in previous ${daysDiff} days`" :secondary="true"></score-card>
          </score-card-group>

          <div class="zingnetCard--row no-border">
            <div id="chart-newUsers"></div>
          </div>
        </section>

        <section class="zingnetCard--column">
          <div id='chart-userSubscriptions'></div>
        </section>

        <section class="zingnetCard--column">
          <h3 class="zingnetCard--header">Total users</h3>
          <score-card-group v-if="totalUsers">
            <score-card slot="card_one" :value="totalUsers.cur" :title="`in last ${daysDiff} days`"></score-card>
            <score-card slot="card_two" :value="totalUsers.prev" :title="`in previous ${daysDiff} days`" :secondary="true"></score-card>
          </score-card-group>

          <div class="zingnetCard--row no-border">
            <div id="chart-totalUsers"></div>
          </div>
        </section>
      </section>
    </div>
  </zingnet-card>
</template>

<script>
import axios from 'axios';
import DateRange from '../DateRange.vue';
import MixinDate from '../../../../mixins/date.js';
import MixinDateRange from '../../mixins/dateRange.js';
import ScoreCard from '../ScoreCard.vue';
import ScoreCardGroup from '../ScoreCardGroup.vue';
import ZingnetCard from '../ZingnetCard.vue';

export default {
  components: {
    DateRange,
    ScoreCard,
    ScoreCardGroup,
    ZingnetCard,
  },
  mixins: [MixinDate, MixinDateRange],
  data() {
    return {
      lineConfig: {
        type: 'line',
        plot: {
          animation: {
            effect: 'ANIMATION_EXPAND_LEFT',
            method: 'ANIMATION_LINEAR',
            sequence: 'ANIMATION_NO_SEQUENCE',
            speed: 1000,
          },
          lineWidth: '1.5px',
          marker: {
            visible: false,
          }
        },
        plotarea: {
          margin: '5px 0 25px 0',
        },
        scaleX: {
          item: {
            fontColor: 'var(--color-primary-gray)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: '12px',
            rules: [{
              rule: '%v == 0',
              offsetX: 20,
            }, {
              rule: '%v == 7',
              offsetX: -20,
            }]
          },
          guide: {
            visible: false,
          },
          labels: [],
          lineColor: 'var(--zingnet-border-color)',
          showLabels: [],
          tick: {
            visible: false,
          },
        },
        scaleY: {
          guide: {
            visible: false,
          },
          visible: false,
        },
        series: [
          {
            values: [],
            lineColor: 'var(--color-secondary-blue)',
          },
          {
            values: [],
            lineColor: 'var(--color-primary-gray-visited)',
          }
        ]
      },
      ringConfig: {
        type: 'ring',
        labels: [{
          text: 'User Subscriptions',
          fontColor: 'var(--color-tertiary-blue)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '15px',
          fontWeight: 500,
          x: 0,
          y: '0'
        }, {
          text: '90.42% subscribed',
          fontColor: 'var(--color-secondary-blue)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '14px',
          fontWeight: 500,
          x: 0,
          y: '32px'
        }, {
          text: '9.58% unsubscribed',
          fontColor: 'var(--color-secondary-blue-active)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '14px',
          fontWeight: 500,
          x: 0,
          y: '50px'
        }, {
          text: '_________________',
          fontColor: 'var(--zingnet-border-color)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '15px',
          fontWeight: 500,
          x: 0,
          y: '60px'
        }, {
          text: 'in last 7 days',
          fontColor: 'var(--color-primary-gray)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '13px',
          fontWeight: 400,
          x: 0,
          y: '82px'
        }, {
          text: 'vs',
          fontColor: 'var(--color-tertiary-blue-visited)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '13px',
          fontWeight: 400,
          x: 0,
          y: '115px'
        }, {
          text: '89.42% subscribed',
          fontColor: 'var(--color-primary-gray-visited)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '13px',
          fontWeight: 600,
          x: 0,
          y: '150px'
        }, {
          text: '8.58% unsubscribed',
          fontColor: 'var(--color-primary-gray-visited)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '13px',
          fontWeight: 600,
          x: 0,
          y: '168px'
        }, {
          text: '_________________',
          fontColor: 'var(--zingnet-border-color)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '15px',
          fontWeight: 600,
          x: 0,
          y: '177px'
        }, {
          text: 'in previous 7 days',
          fontColor: 'var(--color-primary-gray)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '13px',
          fontWeight: 400,
          x: 0,
          y: '200px'
        }],
        plot: {
          offsetX: '70px',
          refAngle: 200,
          slice: '88%',
          valueBox: [{
            text: '90.42%',
            placement: 'center',
            fontColor: 'var(--color-secondary-blue)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: 36,
            fontWeight: '100',
            offsetY: '-25px',
          }, {
            text: 'subscription rate',
            placement: 'center',
            fontColor: 'var(--color-secondary-blue)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: 13,
            fontWeight: '200',
            offsetY: '5px',
          }, {
            text: '    _____________',
            placement: 'center',
            fontColor: 'var(--zingnet-border-color)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: '15px',
            fontWeight: 600, 
            offsetY: '15px',
          }, {
            text: 'in last 7 days',
            placement: 'center',
            fontColor: 'var(--color-primary-gray)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: 13,
            fontWeight: '200',
            offsetY: '33px',
          }],
        },
        plotarea: {
          margin: '25px 0 0',
        },
        series: [
          {
            values: [9.58],
            backgroundColor: 'var(--color-secondary-blue-active)',
            text: 'subscribed',
          },
          {
            values: [90.42],
            backgroundColor: 'var(--color-secondary-blue)',
            text: 'unsubscribed',
          },
        ]
      },
      daysDiff: 7,
      labels: [],
      loading: false,
      newUsers: {
        cur: 0,
        prev: 0,
      },
      showLabels: [],
      totalUsers: {
        cur: 0,
        prev: 0,
      },
    };
  },
  methods: {
    calcDiff() {
      this.daysDiff = parseInt(this.dateDiff(this.start, this.end, 'YYYY-MM-DD', 'days'));
    },
    generateLabel() {
      this.labels = this.dateList({
        end: this.end,
        step: 'days',
        stepNum: 1, 
        add: this.daysDiff, 
        inputFormat: 'YYYY-MM-DD', 
        outputFormat: 'MMM D'
      });

      this.showLabels = [this.labels[0], this.labels[this.labels.length-1]];
    },
    instantiatePieChart(id, data, height, width) {
      zingchart.render({id, data, height, width});
    },
    instantiateLineChart(id, series1, series2, height, width) {
      let data = Object.assign({}, this.lineConfig);

      data.scaleX.item.rules[1].rule = `%v == ${this.daysDiff}`;
      data.scaleX.labels = this.labels;
      data.scaleX.showLabels = this.showLabels;
      data.series[0].values = series1;
      data.series[1].values = series2;

      zingchart.render({id, data, height, width});

      this.loading = false;
    },
    getReportData() {
      this.calcDiff();
      this.generateLabel();

      this.laoding = true;
      this._getSummaryNew();
      this._getSummaryTotal();
    },

    _formatSeries(data) {
      return Object.values(data);
    },
    _getSummaryNew() {
      axios({
        url: `/api/user/summary/new?startDate=${this.start}&endDate=${this.end}&prevStartDate=${this.prevStart}&prevEndDate=${this.prevEnd}`,
        json: true,
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.idToken,
        },
      }).then((response) => {
        this._setSummaryData(response.data, 'newUsers');
      }).catch((error) => {
        console.error(error);
      });
    },
    _getSummaryTotal() {
      axios({
        url: `/api/user/count?fields=quickbooks,email`,
        json: true,
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.idToken,
        },
      }).then((response) => {
        // TODO placeholder data until add created time in from auth 0 and start recording
        let total = response.data.email;
        let data = {
          cur: {
            data: new Array(this.daysDiff+1).fill(total),
            total,
          },
          prev: {
            data: new Array(this.daysDiff+1).fill(total),
            total,
          },
        };
        this._setSummaryData(data, 'totalUsers');

        this._setTotalRingChart(response.data.quickbooks, response.data.email);
      }).catch((error) => {
        console.error(error);
      });
    },
    _setSummaryData(data, type) {
      // Set scorecard data
      this[type].cur = data.cur.total;
      this[type].prev = data.prev.total;

      // Set chart data
      let series1 = this._formatSeries(data.cur.data);
      let series2 = this._formatSeries(data.prev.data)
      this.$nextTick(() => {
        this.instantiateLineChart(`chart-${type}`, series1, series2, '80px', '316px');
      });
    },
    _setTotalRingChart(subscribed, total) {
      let subscribedPercentage = (subscribed/total).toFixed(2);
      let text_numSubscribed = `${subscribed} subscribed`;
      let text_numUnsubscribed = `${total - subscribed} unsubscribed`;
      let text_timePeriod = `in last ${this.daysDiff} days`;

      // Set labels
      let labels = this.ringConfig.labels;
      labels[1].text = text_numSubscribed;
      labels[2].text = text_numUnsubscribed;
      labels[4].text = text_timePeriod;

      labels[6].text = text_numSubscribed;
      labels[7].text = text_numUnsubscribed;
      labels[9].text = text_timePeriod;

      // Set valueboxes
      let valueboxes = this.ringConfig.plot.valueBox;
      valueboxes[0].text = `${subscribedPercentage}%`;
      valueboxes[3].text = text_timePeriod;

      // Set series
      let series = this.ringConfig.series;
      series[0].values = [subscribed];
      series[1].values = [total-subscribed];

      this.instantiatePieChart('chart-userSubscriptions', this.ringConfig, '220px', '100%');
    },
  },
}
</script>