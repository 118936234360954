/**
 * @description Mixin used for /views/entry files that share common computed properties, imports, or methods
 *    - EmailVerify
 *    - ResetPassword
 *    - Unsuccessful
 */
import EntryContainer from '../views/entry/components/EntryContainer.vue';
import SecondaryLayout from '../views/layouts/Secondary.vue';

export default {
  components: {
    EntryContainer,
    SecondaryLayout,
  },
  props: {
    isAuthing: Boolean,
  },
  methods: {
    goHome() {
      window.location = '/';
    },
  },
};
