import axios from 'axios';

export default {
  computed: {
    // AUTH/USER
    authenticated() {
      return !!this.$store.state.auth.idToken;
    },
    myGroups() {
      this.updateLocalStorage(this.$store.state.user.groups);
      return this.$store.state.user.groups || [];
    },
  },
  beforeCreate() {
    this.$store.commit('user/initializeGroups');
  },
  create() {
    if (this.authenticated && this.$route.path !== '/') {
      this.fetchGroups();
    }
  },
  methods: {
    /**
     * @description Fetches list of groups user belongs to create tab that lists group demos.
     * If localstorage does not match, update groups object `zs_groups`.
     */
    fetchGroups() {
      axios({
        url: '/api/grouping/list',
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.$store.state.auth.idToken}`,
        },
      })
      .then((response) => {
        let groupCounts = [];
        // For active groups, save the count of demos for each groups
        response.data.forEach((group) => {
          if (group.status === 'active') {
            if (this.myGroups.length > 0) {
              this.myGroups.filter((myGroup) => {
                if (myGroup.id === group.id) {
                  group.count = myGroup.count || 0;
                  group.count_zingchart = myGroup.count_zingchart || 0;
                  group.count_zinggrid = myGroup.count_zinggrid || 0;
                }
              });
            } else {
              group.count = 0;
            }
            groupCounts.push(group);
          }
        });
        // Save as state
        this.$store.commit('user/update', {groups: groupCounts});
        // Update localstorage (is neccessary)
        this.updateLocalStorage(groupCounts);
        // Set active tab for Groups page
        if (this.activeTab) {
          let groupId = window.location.search.split('?')[1];
          if (groupId) this.activeTab = groupId.toString();
          else this.activeTab = this.myGroups ? this.myGroups[0].id.toString() : 'create';
        }
        if (this.myGroups.length === 0) return;
      }).catch((err) => {
        this.$message({
          message: `Error grabbing your groups: ${err}`,
          type: 'error',
        });
      });
    },
    /**
     * @description Update groups object `zs_groups` in localstorage if does not match current
     * groups object `my_groups`
     * @param {Object} groups - group object to update with if differs from current object in
     * localstorage
     */
    updateLocalStorage(groups) {
      let localStorageGroups = localStorage.getItem('zs_groups');
      let sGroups = null;
      // Compare
      if (localStorageGroups) {
        // Check if count property added to group object
        sGroups = JSON.stringify(groups);
        // Early exit if object does not need to update
        if (localStorageGroups === sGroups) return;
      }
      // Update/add
      try {
        localStorage.setItem('zs_groups', sGroups);
      } catch(e) {
        console.err('Unable to store groups: ', e);
      }
    },
  },
};
