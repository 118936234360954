import axios from 'axios';

export default {
  data() {
    return {
      disableInvoiceButton: false,
      subscriptionInvoiceDialogVisible: false,
      subscriptionInvoiceSend: null,
      subscriptionInvoiceSent: null,
      formInvoice: {
        email: '',
        invoiceId: '',
      },
    };
  },
  methods: {
    checkInvoiceFormFilled() {
      this.disableInvoiceButton = this.formInvoice.email.length === 0;
    },
    openInvoiceDialog(subID) {
      // Open dialog
      this.subscriptionInvoiceDialogVisible = true;

      this.$nextTick(() => {
        // Get references
        if (!this.subscriptionInvoiceSend) {
          this.subscriptionInvoiceSend = document.querySelector('#sendInvoiceButtonSend');
          this.subscriptionInvoiceSent = document.querySelector('#sendInvoiceButtonSent');
        };

        // Update buttons
        this.subscriptionInvoiceSend.classList.remove('hide');
        this.subscriptionInvoiceSent.classList.add('hide');
        this.disableInvoiceButton = false;

        // Set invoice id to form
        this.formInvoice.invoiceId = subID;
      });
    },
    sendInvoice() {
      // Disable button to prevent double submission
      this.disableInvoiceButton = true;

      // Send invoice
      axios({
        url: '/api/subscription/invoice',
        method: 'POST',
        headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
        data: {
          email: this.formInvoice.email,
          invoiceId: this.formInvoice.invoiceId,
        },
      }).then(() => {
        // Update buttons
        this.disableInvoiceButton = false;
        this.$nextTick(() => {
          this.subscriptionInvoiceSend.classList.add('hide');
          this.subscriptionInvoiceSent.classList.remove('hide');
        });
      }).catch((error) => {
        console.log(error)
      });
    },
    validateInvoiceForm() {
      this.$refs.invoiceForm.validate((valid) => {
        this.disableInvoiceButton = !valid;
      });
    },
  }
};