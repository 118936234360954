<template>
  <zingnet-card>
    <span slot="header">Library Releases</span>
    <div slot="body">
      <section class="zingnetCard--row">
        <div class="zingnetCard--column column-45 flex no-padding">
          <div class="zingnetCard--column">
            <h3 class="zingnetCard--header">Latest ZingChart Release</h3>
            <score-card :value="zingchartVersion" icon="zingchart-logomark" icon-size="29" :title="`Released on ${formatDate(zingchartReleaseDate, null, 'MMMM D, YYYY')}`"></score-card>
          
            <h3 class="zingnetCard--header"># of Changelog Releases</h3>
            <score-card :value="zingchartChangelog" title="since 2015"></score-card>

            <el-button @click="openChangelog('zingchart')">View Changelog</el-button>
          </div>
          <div class="zingnetCard--column column-50">
            <h3 class="zingnetCard--header">Latest ZingGrid Release</h3>
            <score-card :value="zinggridVersion" icon="zinggrid-logomark" icon-size="29" :title="`Released on ${formatDate(zinggridReleaseDate, null, 'MMMM D, YYYY')}`"></score-card>
            
            <h3 class="zingnetCard--header"># of Changelog Releases</h3>
            <score-card :value="zinggridChangelog" title="since 2018"></score-card>
            
            <el-button @click="openChangelog('zingchart')">View Changelog</el-button>
          </div>
        </div>

        <div id="chart-versionBuilds" class="zingnetCard--column column-55"></div>
      </section>
    </div>
  </zingnet-card>
</template>
<script>

import axios from 'axios';
import MixinDate from '../../../../mixins/date.js';
import { Octokit } from '@octokit/core';
const octokit = new Octokit({ auth: VUE_APP_GITHUB_API_TOKEN });
const randomatic = require('randomatic');
import ScoreCard from '../../components/ScoreCard.vue';
import ZingnetCard from '../ZingnetCard.vue';

export default {
  components: {
    ScoreCard,
    ZingnetCard,
  },
  mixins: [MixinDate],
  data() {
    return {
      // Library Releases
      chart_versionBuilds: {
        type: 'bar',
        title: {
          text: 'Build Size Comparison',
          fontColor: 'var(--color-tertiary-blue)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '15px',
          fontWeight: 600,
          textAlign: 'left',
          x: '-5px',
          y: '-5px',
        },
        legend: {
          borderWidth: 0,
          item: {
            fontColor: 'var(--color-primary-gray)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            offsetX: '-5px',
          },
          layout: 'horizontal',
          offsetX: '-5px',
          offsetY: '-16px',
          width: '270px',
        },
        scaleX: {
          item: {
            fontColor: 'var(--color-primary-gray)',
            fontFamily: 'Nunito Sans, sans-serif',
          },
          fontSize: '12px',
          label: {
            fontColor: 'var(--color-primary-gray)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: '13px',
            fontWeight: 600,
            offsetY: '5px',
            text: 'Builds'
          },
          labels: ['ZingChart', 'ZingChart (gzip)', 'ZingGrid', 'ZingGrid (gzip)'],
          lineColor: 'var(--zingnet-chart-color)',
          tick: {
            visible: false,
          }
        },
        scaleY: {
          item: {
            fontColor: 'var(--color-primary-gray)',
            fontFamily: 'Nunito Sans, sans-serif',
          },
          fontColor: 'var(--color-primary-gray)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '13px',
          guide: {
            lineColor: 'var(--zingnet-chart-color)',
          },
          label: {
            fontColor: 'var(--color-primary-gray)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: '13px',
            fontWeight: 600,
            text: 'Build Size (kB)',
          },
          lineColor: 'var(--zingnet-chart-color)',
          step: 200,
          tick: {
            fontSize: '12px',
            fontFamily: 'Nunito Sans, sans-serif',
            lineColor: 'var(--zingnet-chart-color)',
          },
        },
        plot: {
          animation: {
            effect: 'ANIMATION_EXPAND_BOTTOM',
            method: 'ANIMATION_STRONG_EASE_OUT',
            sequence: 'ANIMATION_BY_NODE',
            speed: 275,
          }
        },
        plotarea: {
          margin: '45px 0 42px 56px',
        },
        series: [{
            values: [],
            text: 'Full Build',
            backgroundColor: 'var(--color-primary-blue)'
          },
          {
            values: [],
            text: 'Core Build',
            backgroundColor: 'var(--color-secondary-blue)'
          },
          {
            values: [],
            text: 'ES5/ES6 Build',
            backgroundColor: 'var(--color-secondary-blue-visited)'
          }
        ]
      },
      
      zingchartBuilds: ['zingchart.min.js', 'zingchart-core.min.js', 'zingchart-es6.min.js'],
      zingchartChangelog: null,
      zingchartReleaseDate: null,
      zingchartVersion: null,

      zinggridBuilds: ['zinggrid.min.js', 'zinggrid.es6.min.js', 'zinggrid.es5.min.js'],
      zinggridChangelog: null,
      zinggridReleaseDate: null,
      zinggridVersion: null,
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      // Get library versions
      this._getLibraryData('zingchart');
      this._getLibraryData('zinggrid');

      // Get library changelog
      this._getLibraryChangelog('zingchart');
      this._getLibraryChangelog('zinggrid');

      // Get library build sizes
      let compressed = [this._getBuildSize('zingchart'), this._getBuildSize('zinggrid')];
      Promise.all(compressed)
        .then((response) => {
          // Add to chart data
          this._addChartData(response);
          this.instantiateChart('chart-versionBuilds', this.chart_versionBuilds, '334px', '537.5px');

          // Add to sidebar
          response.forEach((library, i) => {
            let lib = i === 0 ? 'zingchart' : 'zinggrid';
            
            library.data.builds.forEach((build) => {
              let buildName = this._getBuildName(build.name, lib);
              let id = `zingNet_github_${lib}_library_${buildName}Build`;
              this.setSidebar(id, this._bToKb(build.size, true));
              this.setSidebar(`${id}_gzip`, this._bToKb(build.size_gzip, true));
            });
          });
        });
    },
    instantiateChart(id, data, height, width) {
      zingchart.render({id, data, height, width});
    },
    openChangelog(lib) {
      window.open(`https://github.com/${lib}/${lib}/releases`);
    },
    setSidebar(id, val) {
      let ref = document.getElementById(id);
      if (ref) ref.textContent = val;
    },

    _addChartData(data) {
      // Format chart data
      let chartData = [[], [], []];
      data.forEach((lib, i) => {
        let libName = i === 0 ? 'zingchart' : 'zinggrid';

        lib.data.builds.forEach((build) => {
          let index = this[`${libName}Builds`].indexOf(build.name);
          chartData[index].push(this._bToKb(build.size));
          chartData[index].push(this._bToKb(build.size_gzip));
        });
      });

      // Add chart data
      chartData.forEach((data, i) => {
        this.chart_versionBuilds.series[i].values = data;
      });
    },
    _bToKb(num, addUnit) {
      let conversion = Math.floor(num/1024);
      return addUnit ? `${conversion} kB` : conversion;
    },
    _getLibraryChangelog(lib) {
      axios({
        url: `https://api.github.com/repos/${lib}/${lib}/releases`,
        method: 'GET',
      }).then((response) => {
        this[`${lib}Changelog`] = response.data.length;
        this[`${lib}ReleaseDate`] = response.data[0].published_at;
      });
    },
    _getLibraryData(lib) {
      octokit.request('GET https://api.github.com/repos/{owner}/{repo}/releases/latest', {
        owner: "zingsoftinc",
        repo: `${lib}-lib`,
      })
      .then((response) => {
        let version = response.data.name;
        this[`${lib}Version`] = version;
        this.setSidebar(`zingNet_github_${lib}_library_version`, version);
      })
      .catch((error) => {
        console.log(error);
      });
    },
    _getBuildName(name, lib) {
      let buildTypes = ['core', 'es5', 'es6'];
      let type = null;
      for (let i = 0; i < buildTypes.length; i++) {
        if (name.indexOf(buildTypes[i]) > -1) {
          type = buildTypes[i];
          break;
        };
      };
      // Map zinggrid es6 build to core
      if (type === 'es6' && lib === 'zinggrid') type = 'core';
      return type || 'full';
    },
    _getBuildSize(lib) {
      return new Promise((resolve, reject) => {
        // Determine to use conditional request
        let headers = {
          Authorization: `token ${VUE_APP_GITHUB_API_TOKEN}`,
          Accept: 'application/vnd.github.v3+json',
          'If-None-Match': randomatic('a0', 40)
        };

        // Make request for build files
        axios({
          url: `https://api.github.com/repos/zingsoftinc/${lib}-cdn/contents/public`,
          headers,
          method: 'GET',
        }).then((response) => {
          // Filter for current library builds
          let builds = response.data.filter((file) => {
            if (this[`${lib}Builds`].indexOf(file.name) > -1) {
              return true;
            }
          });

          // Get gzip compressed size
          this._getGzipBuildSize(builds, resolve);
        }).catch((error) => {
          console.log(error);
        });
      });
    },
    _getGzipBuildSize(arg, resolve) {
      axios({
        url: '/api/admin/buildsize/gzip',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.idToken}`,
          'X-CSRF-TOKEN': axios.defaults.headers.delete['X-CSRF-Token'] ,
        },
        data: {builds: arg},
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        console.log(error);
      });
    },
  },
}
</script>

<style scoped>
  .el-button {
    margin-top: 0;
  }
  .zingnetCard--column {
    height: 380px;
  }
</style>