<template>
  <div class="collapse--container">
    <div class="collapse--header">
      <h3>{{title}}</h3>
      <el-button @click="hide" v-if="buttonView">Hide Options</el-button>
      <el-button @click="view" v-else>View Options</el-button>
    </div>
    
    <div :class="`collapse--body${toCollapse ? ' collapse' : ''}`">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: String,
    open: Boolean
  },
  data() {
    return {
      button: null,
      collapse: null,
    };
  },
  computed: {
    buttonView() {
      if (!this.collapse && this.collapse !== false) {
        return this.open;
      } else {
        return this.button;
      };
    },
    toCollapse() {
      if (!this.collapse && this.collapse !== false) {
        return !this.open;
      } else {
        return this.collapse;
      };
    },
  },
  methods: {
    hide() {
      this.collapse = true;
      this.button = false;
    },
    view() {
      this.collapse = false;
      this.button = true;
    },
  },
}

</script>

<style scoped>
  .collapse--container {
    border: var(--border);
    border-radius: 10px;
    overflow: hidden;
  }
  .collapse--container + .collapse--container {
    margin-top: 1rem;
  }
  .collapse--header,
  .collapse--body > div {
    padding: 0.875rem 1.125rem;
  }
  .collapse--header {
    align-items: center;
    background: var(--zingnet-color-gray);
    display: flex;
    justify-content: space-between;
  }
  .collapse--header .el-button {
    margin: 0;
  }
  .collapse--body {
    max-height: 1000px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }
  .collapse--body.collapse {
    border-top: 0;
    max-height: 0;
  }
  .collapse--body > div {
    border-top: var(--border);
  }
  h3 {
    color: var(--color-primary-gray);
    font-size: 0.9375rem;
    font-weight: 600;
    margin: 0;
  }
</style>