<template>
  <section class="zingnetCardSection">
    <!-- Library Releases -->
    <library-releases></library-releases>

    <!-- Test Coverage -->
    <test-coverage></test-coverage>

    <!-- Deployment -->
    <deploy-gallery></deploy-gallery>
    
    <!-- Quickbooks Client -->
    <quickbooks-client></quickbooks-client>

    <!-- Demo Docs Metadata -->
    <metadata-list></metadata-list>

    <!-- Badge -->
    <badge-section></badge-section>

  </section>
</template>
<script>

import BadgeSection from './BadgeSection.vue';
import DeployGallery from './DeployGallery.vue';
import LibraryReleases from './LibraryReleases.vue';
import MetadataList from './MetadataList.vue';
import QuickbooksClient from './QuickbooksClient.vue';
import TestCoverage from './TestCoverage.vue';

export default {
  components: {
    BadgeSection,
    DeployGallery,
    LibraryReleases,
    MetadataList,
    QuickbooksClient,
    TestCoverage,
  },
}
</script>