/**
 * @description Mixin used main entry screens (login and signup)
 */
import AppFooter from '../views/layouts/Footer.vue';
import EntryContainer from '../views/entry/components/EntryContainer.vue';
import InputEmail from '../views/entry/components/InputEmail.vue';
import GoogleEntry from '../views/entry/components/GoogleEntry.vue';
import SecondaryLayout from '../views/layouts/Secondary.vue';
import { mapState } from '@vuex';

export default {
  components: {
    AppFooter,
    EntryContainer,
    InputEmail,
    GoogleEntry,
    SecondaryLayout,
  },
  props: {
    isAuthing: Boolean,
  },
  computed: {
    ...mapState({
      setAuthIsAuthenticatingLocalStorage: state => state.auth.setAuthIsAuthenticatingLocalStorage,
      setAuthIsNewSignupLocalStorage: state => state.auth.setAuthIsNewSignupLocalStorage,
    }),
    planId() {
      return this.$store.state.billing.planId;
    },
    planInterval() {
      return this.$store.state.billing.planInterval;
    },
    lock() {
      return this.$store.state.auth.lock;
    },
    regenerateSession() {
      // Determines if server session should be regenerated in login
      let redirectPath = localStorage.getItem('redirectPath', true);
      // Do not regenerate when logging/signing up during checkout process
      if (redirectPath) return !redirectPath.includes('/subscribe');
      return true;
    },
  },
  data() {
    return {
      acceptedRedirects: ['zingchart', 'zinggrid'],
    }
  },
  methods: {
    /**
     * @description When redirecting off this page, pass paramters that is used to redirect to
     * studio, subscription or any other page after login/signup
     * @param {Boolean} signup - if true, redirect to login page, else redirect to sign up page
     */
    passQueryParam(signup) {
      let query = window.location.href.split('?')[1];
      query = query ? `?${query}` : '';
      let path = `${signup ? 'login' : 'signup'}${query ? `${query}` : ''}`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    /**
     * @description Called on login and signup view to save query parameter `redirectSite` into localStorage.
     * The localstorage variable will later be consumed in `AppShell.vue` after user successfully logs in.
     * The user will be redirected to an external site specified by `redirectSite`.
     * 
     * `redirectSite` is formatted `site/path`, where `site` must be one of the values in `acceptedRedirects` and
     * `path` could be any page on the site.
     * Ex. https://www.zingchart.com/docs => `zingchart/docs`
     * Ex. https://www.zingchart.com/docs/events/index.html => `zingchart/docs/events/index.html`
     */
    redirectAfterLogin() {
      let redirectSite = this.$route.query.redirectSite || '';
      if (redirectSite) {
        let split = redirectSite.split('/');
        // Set redirectSite in localStorage only if it set to redirect expected sites
        if (this.acceptedRedirects.indexOf(split[0].toLowerCase()) > -1) {
          localStorage.setItem('redirectSite', `https://www.${split[0]}.com/${split[1] ? split[1] : ''}`);
        }
      }
    },
  },
};
