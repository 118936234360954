<template>
  <zingnet-card>
    <span slot="header">
      Site Error Tracker
      <date-range @updateDateRange="updateDateRange"></date-range>
    </span>
    <el-button slot="header__right" @click="openTrackJS">View in TrackJS</el-button>
    <div slot="body" v-loading="loading">
      <section class="zingnetCard--row">
        <section class="zingnetCard--column">
          <h3 class="zingnetCard--header">Highest Impact</h3>
          <ol v-if="errors" class="zingnetCard--list">
            <li v-for="li in errors.highestImpact" :key="li.message">
              <a :href="`https://${li.url}`" target="_blank" rel="noreferrer noopener"><span class="zingnetCard--list--item">{{li.message}}</span></a>
              <bubble-count :count="li.count"></bubble-count>
            </li>
          </ol>
        </section>
        <section class="zingnetCard--column">
          <h3 class="zingnetCard--header">Top Errors</h3>
          <ol v-if="errors" class="zingnetCard--list">
            <li v-for="li in errors.top" :key="li.message">
              <a :href="`https://${li.url}`" target="_blank" rel="noreferrer noopener"><span class="zingnetCard--list--item">{{li.message}}</span></a>
              <bubble-count :count="li.count"></bubble-count>
            </li>
          </ol>
        </section>
      </section>

      <section class="zingnetCard--row">
        <section class="zingnetCard--column">
          <div id="chart-errorTracking"></div>
        </section>
      </section>
    </div>
  </zingnet-card>
</template>

<script>

import axios from 'axios';
import BubbleCount from '../BubbleCount.vue';
import DateRange from '../DateRange.vue';
import MixinDateRange from '../../mixins/dateRange.js';
import moment from 'moment';
import ScoreCard from '../ScoreCard.vue';
import ZingnetCard from '../ZingnetCard.vue';

export default {
  components: {
    BubbleCount,
    DateRange,
    ScoreCard,
    ZingnetCard,
  },
  mixins: [MixinDateRange],
  data() {
    return {
      config: {
        type: 'mixed',
        title: {
          text: 'Errors per Page View',
          fontColor: 'var(--color-tertiary-blue)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '15px',
          fontWeight: 600,
          textAlign: 'left',
          x: '-5px',
          y: '-5px',
        },
        crosshairX: {
          plotLabel: {
            backgroundColor: '#fff',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: '15px',
          },
          scaleLabel: {
            backgroundColor: 'var(--color-primary-blue)',
            fontFamily: 'Nunito Sans, sans-serif',
          },
        },
        legend: {
          borderWidth: 0,
          item: {
            fontColor: 'var(--color-primary-gray)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            offsetX: '-5px',
          },
          layout: 'horizontal',
          offsetX: '-5px',
          offsetY: '-16px',
          width: '150px',
        },
        scaleX: {
          item: {
            fontColor: 'var(--color-primary-gray)',
            fontFamily: 'Nunito Sans, sans-serif',
          },
          fontSize: '12px',
          lineColor: 'var(--zingnet-chart-color)',
          minValue: null,
          step: 3600000,
          transform: {
            type: 'date',
            all: '%M %d %H:%m'
          },
          tick: {
            visible: false,
          }
        },
        scaleY: {
          item: {
            fontColor: 'var(--color-primary-gray)',
            fontFamily: 'Nunito Sans, sans-serif',
          },
          fontColor: 'var(--color-primary-gray)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '13px',
          guide: {
            lineColor: 'var(--zingnet-chart-color)',
          },
          lineColor: 'var(--zingnet-chart-color)',
          step: 20,
          tick: {
            fontSize: '12px',
            fontFamily: 'Nunito Sans, sans-serif',
            lineColor: 'var(--zingnet-chart-color)',
          },
        },
        plotarea: {
          margin: '45px 20px 25px 40px',
        },
        series: [
          {
            type: 'area',
            values: [],
            text: 'Errors',
            backgroundColor: 'var(--color-fail)',
            lineColor: 'var(--color-fail)',
            marker: {
              backgroundColor: 'var(--color-fail)',
            },
          },
          {
            type: 'line',
            values: [],
            text: 'Page Views',
            lineColor: 'var(--color-secondary-blue)',
            marker: {
              backgroundColor: 'var(--color-secondary-blue)',
            },
          },
        ]
      },
      errors: {
        highestImpact: [],
        top: [],
      },
      loading: false,
      $zgRef: null,
    };
  },
  methods: {
    dateToMillisecondas(sDate) {
      return moment(sDate, 'YYYY-MM-DD').valueOf();
    },
    getReportData() {
      this.loading = true;

      axios({
        url: `/api/admin/trackjs/report?startDate=${this.start}&endDate=${this.end}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.$store.state.auth.idToken}`,
        }
      }).then((response) => {
        // Set "Top 5" data
        this.errors.highestImpact = response.data.impactErrors.map((data) => {return {message: data.key, count: data.count, url: data.trackJsUrl}});
        this.errors.top = response.data.topErrors.map((data) => {return {message: data.key, count: data.count, url: data.trackJsUrl}});

        // Set chart data
        this.config.series[0].values = response.data.errorSeries;
        this.config.series[1].values = response.data.viewSeries;
        this.config.scaleX.minValue = this.dateToMillisecondas(this.start);
        zingchart.render({
          id: 'chart-errorTracking',
          data: this.config,
          height: '400px',
          width: '1074px',
        });

        this.loading = false;
      });
    },
    openTrackJS() {
      window.open('https://my.trackjs.com/');
    },
  },
};
</script>