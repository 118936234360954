let axios = require('axios');

export default {
  data() {
    return {
      chartTypes: {},
      chartTypesMap: {
        'area': ['timeseries'],
        'line': ['real-time-feed'],
        'maps': ['map'],
        'mixed': ['blank'],
        'tree-module': ['tree', 'org'],
        'venn-diagram': ['venn'],
      },
      svg: null,
    };
  },
  beforeMount() {
    this.fetchChartTypes();
  },
  methods: {
    fetchChartTypes() {
      axios('/assets/images/chartTypes.svg')
        .then((response) => {
          this.svg = response.data;
          this._removeSvgTag();
          this._separateSvg();
          this._setChartType();
        });
    },
    _mapName(name) {
      return this.chartTypesMap[name] || false;
    },
    _setChartType() {
      this.svg.forEach((svg) => {
        svg = svg.trim();
        let i = svg.indexOf('id="') + 4;
        let i2 = svg.indexOf('" viewBox');
        let chartType = svg.slice(i, i2);
        if (chartType) {
          svg = ('<svg chartTypeThumbnail '.concat(svg)).replace('</symbol>', '</svg>');
          this.chartTypes[chartType] = svg;
          
          // Add again mapping to another name
          let alias = this._mapName(chartType);
          if (alias) {
            alias.forEach((a) => {
              this.chartTypes[a] = svg;
            });
          };
        }
      });
    },
    _removeSvgTag() {
      let svgTagRegex = /<(\/)*svg(.)*\>/igm;
      this.svg = this.svg.replace(svgTagRegex, '');
    },
    _separateSvg() {
      this.svg = this.svg.split(/<symbol /gm);
    },
  },
};