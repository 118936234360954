<template>
  <zingnet-card :gridBody="true">
    <span slot="header">
      CDN Tracker for Library Usage
      <date-range @updateDateRange="updateDateRange" :additional-items="dateRangeItems" :group-name="dateRangeGroupName"></date-range>
    </span>
    <div slot="body" v-loading="loading">
      <section class="zingnetCard--row">
        <section class="zingnetCard--column">
          <h3 class="zingnetCard--header">ZingChart CDN Top Referer Domains</h3>
          <ol v-if="cdnData" class="zingnetCard--list">
            <li v-for="li in cdnData.zingchartDomains" :key="li.id">
              <p @click="openModal(li)" class="zingnetCard--list--item">{{li.domain}}</p>
              <bubble-count :count="li.hits"></bubble-count>
              <a :href="`https://${li.domain}`" target="_blank" rel="noreferrer noopener">
                <svg-icon domain-link icon="open" :height="outLinkSize" :width="outLinkSize"></svg-icon>
              </a>
            </li>
          </ol>
        </section>

        <section class="zingnetCard--column">
          <h3 class="zingnetCard--header">ZingGrid CDN Top Referer Domains</h3>
          <ol v-if="cdnData" class="zingnetCard--list">
            <li v-for="li in cdnData.zinggridDomains" :key="li.id">
              <p @click="openModal(li)" class="zingnetCard--list--item">{{li.domain}}</p>
              <bubble-count :count="li.hits"></bubble-count>
              <a :href="`https://${li.domain}`" target="_blank" rel="noreferrer noopener">
                <svg-icon domain-link icon="open" :height="outLinkSize" :width="outLinkSize"></svg-icon>
              </a>
            </li>
          </ol>
        </section>

        <section class="zingnetCard--column">
          <h3 class="zingnetCard--header">CDN Usage <bubble-count v-if="cdnData" :comparison-cur="cdnData.weekHits" :comparison-prev="cdnData.prevWeekHits"></bubble-count></h3>
          <div class="zingnetCard--row">
            <score-card-group v-if="cdnData">
              <score-card slot="card_one" :value="cdnData.weekHits" :title="`hits in last ${daysDiff} days`"></score-card>
              <score-card slot="card_two" :value="cdnData.prevWeekHits" :title="`hits in previous ${daysDiff} days`" :secondary="true"></score-card>
            </score-card-group>
          </div>
          <div class="zingnetCard--row no-border">
            <div id="chart-cdnUsage"></div>
          </div>
        </section>
      </section>

      <section class="zingnetCard--row no-border">
        <zing-grid
          id="zingNet_cdnTracker"
          data='[{"hits": null, "domain": null, "uris": null}]'
          filter
          height=650
          pager
          page-size=10
          selector
          sort>
          <zg-colgroup>
            <zg-column index="hits" filter="disabled" type="number" width="10%"></zg-column>
            <zg-column index="domain" renderer="renderDomain" width="30%"></zg-column>
            <zg-column index="uris" header="URI" width="60%" :hidden="hiddenUri"></zg-column>
            <zg-column index="first_seen" type="date" width="60%" :hidden="hiddenSeen"></zg-column>
          </zg-colgroup>
        </zing-grid>
      </section>

      <domain-modal
        @openUserModal="showUserDetails"
        @close="visible=false"
        :domain-obj="domainObj"
        :visible.sync="visible">
      </domain-modal>

      <user-modal
        @close="userModalVisible=false"
        :company="company"
        :email="email"
        :id="userId"
        :quickbooks-id="quickbooksId"
        :visible.sync="userModalVisible">
      </user-modal>

    </div>
  </zingnet-card>
</template>
<script>

import axios from 'axios';
import BubbleCount from '../BubbleCount.vue';
import DateRange from '../DateRange.vue';
import DomainModal from './DomainModal.vue';
import MixinDate from '../../../../mixins/date.js';
import MixinDateRange from '../../mixins/dateRange.js';
import MixinRenderer from '../../mixins/renderer.js';
import MixinUserModal from '../../mixins/userModal.js';
import ScoreCard from '../ScoreCard.vue';
import ScoreCardGroup from '../ScoreCardGroup.vue';
import SvgIcon from '../../../../components/SvgIcon.vue';

import ZingnetCard from '../ZingnetCard.vue';

export default {
  components: {
    BubbleCount,
    DateRange,
    DomainModal,
    ScoreCard,
    ScoreCardGroup,
    SvgIcon,
    ZingnetCard,
  },
  mixins: [MixinDate, MixinDateRange, MixinRenderer, MixinUserModal],
  data() {
    return {
      chartConfig: {
        type: 'line',
        plot: {
          animation: {
            effect: 'ANIMATION_EXPAND_LEFT',
            method: 'ANIMATION_LINEAR',
            sequence: 'ANIMATION_NO_SEQUENCE',
            speed: 1000,
          },
          lineWidth: '1.5px',
          marker: {
            visible: false,
          },
        },
        plotarea: {
          margin: '5px 0 25px 0',
        },
        scaleX: {
          item: {
            fontColor: 'var(--color-primary-gray)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: '12px',
            rules: [{
              rule: '%v == 0',
              offsetX: 20,
            }, {
              rule: '%v == 7',
              offsetX: -20,
            }]
          },
          guide: {
            visible: false,
          },
          labels: [],
          lineColor: 'var(--zingnet-border-color)',
          showLabels: [],
          tick: {
            visible: false,
          },
        },
        scaleY: {
          guide: {
            visible: false,
          },
          visible: false,
        },
        series: [
          {
            values: [],
            lineColor: 'var(--color-secondary-blue)',
          },
          {
            values: [],
            lineColor: 'var(--color-primary-gray-visited)',
          }
        ]
      },
      cdnData: null,
      dateRangeItems: [{label: 'Newest', value: 'first_seen-desc'}, {label: 'Oldest', value: 'first_seen-asc'}, {label: 'High Usage', value: 'hits_total-desc'}],
      dateRangeGroupName: 'All Time',
      daysDiff: null,
      domainObj: null,
      hiddenSeen: true,
      hiddenUri: false,
      loading: false,
      outLinkSize: '9',
      visible: false,
    };
  },
  mounted() {
    this.registerRenderer('renderDomain');
  },
  methods: {
    getReportData() {
        this.$nextTick(() => {
        // Get number of days
        this.daysDiff = this.dateDiff(this.start, this.end, 'YYYY-MM-DD', 'days');

        this.loading = true;

        // Get report
        axios({
          url: `/api/cdn_log?start=${this.start}&end=${this.end}&prevStart=${this.prevStart}&prevEnd=${this.prevEnd}`,
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.$store.state.auth.idToken}`,
          }
        })
        .then((response) => {
          this.hiddenSeen = true;
          this.hiddenUri = false;

          // Set report data
          this.cdnData = response.data;
          let series = this.chartConfig.series;
          series[0].values = [...new Array(this.daysDiff + 1 - this.cdnData.weekSeries.length).fill(0), ...this.cdnData.weekSeries];
          series[1].values = [...new Array(this.daysDiff + 1 - this.cdnData.prevWeekSeries.length).fill(0), ...this.cdnData.prevWeekSeries];
          this.chartConfig.scaleX.item.rules[1].rule = `%v == ${this.daysDiff}`;

          this.$nextTick(() => {
            this.initChart();
            this.initGrid(this.cdnData.logs);
          });
        });
      });
    },
    getReportDataCustom() {
      this.$nextTick(() => {
        this.loading = true;

        let [sortBy, sortDir] = this.custom.split('-');

        // Get report
        axios({
          url: `/api/cdn_domain/list?sortBy=${sortBy}&sortDir=${sortDir}`,
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.$store.state.auth.idToken}`,
          }
        })
        .then((response) => {
          this.hiddenSeen = false;
          this.hiddenUri = true;

          function mapHitsProp(arr) {
            return arr.map((entry) => {
              entry.hits = entry.hits_total;
              return entry;
            });
          };

          // Set report data
          this.cdnData.zingchartDomains = mapHitsProp(response.data.topZingChart.entries);
          this.cdnData.zinggridDomains = mapHitsProp(response.data.topZingGrid.entries);

          // Format grid data
          let gridData = mapHitsProp(response.data.records.entries);

          this.$nextTick(() => {
            this.initGrid(gridData);

            this.loading = false;
          });
        });
      });
    },
    getRecentLogs() {
      axios({
        url: '/api/admin/cdn/report',
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.$store.state.auth.idToken}`,
        }
      })
      .then((response) => {
        // Set data
        let zgRef = document.getElementById('zingNet_cdnTracker');
        zgRef.setData(response.data.records);
      });
    },
    initChart() {
      // Determine scale labels
      let labels = this.dateList({
        end: this.end,
        step: 'days',
        stepNum: 1, 
        add: this.daysDiff, 
        inputFormat: 'YYYY-MM-DD', 
        outputFormat: 'MMM D'
      });
      this.chartConfig.scaleX.labels = labels;
      this.chartConfig.scaleX.showLabels = [labels[0], labels[labels.length-1]];

      // Render ZingChart
      zingchart.render({
        id: 'chart-cdnUsage',
        data: this.chartConfig,
        height: '80px',
        width: '414px',
      });

      this.loading = false;
    },
    initGrid(data) {
      let zgRef = document.getElementById('zingNet_cdnTracker');

      if (zgRef) {
        // Event listener domain text click
        zgRef.addEventListener('row:click', (e) => {
          let target = e.detail.ZGEvent.oDOMTarget;
          let attrArr = Object.values(target.attributes).map((attr) => attr.name);
          if (attrArr.includes('[open-domain-modal]')) {
            this.openModal(e.detail.ZGData.data);
          };
        });

        // Set data
        zgRef.setData(data);
      };
    },
    openModal(domainObj) {
      this.domainObj = domainObj;
      this.visible = true;
    },
    showUserDetails(obj) {
      this.quickbooksId = obj.quickbooksId;
      this.openUserModal(obj);
    },
  },
}
</script>

<style scoped>
  [domain-link] {
    fill: var(--color-primary-gray);
    margin: 0 0.3125rem;
  }
  [domain-link]:hover {
    cursor: pointer;
    fill: var(--color-tertiary-blue-focus);
  }
  li {
    display: flex;
  }
  li > p {
    margin: 0;
    max-width: 165px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .zingnetCard--column {
    min-height: 265px;
    min-width: 330px;
  }
  .zingnetCard--column:nth-child(3) {
    min-width: 476px;
  }
</style>