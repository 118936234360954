<template>
  <filter-selector
    ref="filterSelector"
    @authenticated="getTags"
    @filterBy="filter"
    :clearable="true"
    :options.sync="possibleTags"
    placeholder="Filter By Tag..."
  ></filter-selector>
</template>

<script>
import FilterSelector from './FilterSelector.vue';

export default {
  components: {
    FilterSelector,
  },
  props: ['filterBy', 'group', 'template', 'type'],
  data() {
    return {
      possibleTags: [],
    };
  },
  watch: {
    group() {
      if (this.group) this.getTags();
    },
    type() {
      if (this.type) this.getTags();
    },
    template() {
      if (this.template) this.getTags();
    }
  },

  methods: {
    clearTagResults() {
      this.$refs.filterSelector.clearFilter();
    },
    filter(value) {
      this.$emit('update:filterBy', value)
    },
    getTags() {
      let query = {type: this.type, group: this.group};
      
      if (this.template) {
        query.template = this.template;
        if (!this.type && this.group === 'all_demos' && this.template === 'personal') delete query.type;
      }

      this.$api('tag/list', query)
      .then((result) => {
        this.possibleTags = [];
        this.possibleTags = Object.keys(result.data)
        .map((tag, index) => {
          return {
            name: tag,
            count: result.data[tag],
            id: index,
          }
        })
        .sort((tag, tag2) => {
          return tag2.count - tag.count;
        });
      })
      .catch((error) => {
        this.$message({
          duration: 10000,
          message: 'Could not fetch user tags',
          showClose: true,
          type: 'error',
        });
      });
    },
  }
}
</script>