/**
 * @description Autofill domain field for customers who leave the domain field empty
 * and attempts to only fill subdomain field. This is to aide customers who do
 * not realize that domain field needs to be filled before the subdomain field.
 * 
 * Subdomain field is validated by two rules:
 * 1. Checks if is a valid subdomain url
 * 2. Checks if is a subdomain of listed domain(s)
 * 
 * When domain field is skipped, the second validation fails because there is no
 * domain to check against.
 * 
 * Provided that the domain field is empty, studio will automatically autofill
 * domain field if the subdomain that the customer provides is valid.
 */
import Domain from '../plugins/domain.js';

export default {
  data() {
    return {
      subscribeForm: 'formPricing',
    };
  },
  methods: {
  /**
   * @description If checks are enabled, checks if requirements are fulfilled
   * before autofilling domain field.
   * @param {String} refDomain - variable name for domain array
   * @param {String} refForm - variable name for form
   * @param {String} refSubdomain - variable name to to subdomain field
     */
    autofillDomain({checkDomainEmpty, refDomain, refForm, refSubdomain}) {
      if (checkDomainEmpty) {
        let pass = false;
        // Checks differ based on form type
        if (refForm === this.subscribeForm) {
          // Subscription form from pricing page
          pass = this[refForm][refDomain] === '';
        } else {
          // Subscription page
          pass = this.domains.length === 0;
        }

        if (pass) {
          // Autofill after check for domain field is empty passes
          this._autofillDomainField({refDomain, refForm, refSubdomain});
        }
      } else {
        // Autofill without checks
        this._autofillDomainField({refDomain, refForm, refSubdomain});
      }
    },
    /**
     * @description Autofills domain field if subdomain field is validated.
     * Uses inputted subdomain to generate domain used to autofill domain field.
     * @param {String} refDomain - variable name for domain array
     * @param {String} refForm - variable name for form
     * @param {String} refSubdomain - variable name to to subdomain field
     */
    _autofillDomainField({refDomain, refForm, refSubdomain}) {
      // Validate subdomain
      let subdomain = this[refForm][refSubdomain];
      if (Domain.regexSubdomain.test(subdomain)) {
        // Automatically input domain
        let split = subdomain.split('.');
        let domain = split.slice(-2).join('.');
        if (Domain.regexDomain.test(domain)) {
          // Handle differently depending on form type
          if (refForm === this.subscribeForm) {
            // Subscription form from pricing page
            this[refForm][refDomain] = domain;
          } else {
            // Subscription page
            this[refDomain].push(domain);
            this.toAdd.push({
              domain, 
              sub: false,
            });
          }
        }
      }
    },
  },
};