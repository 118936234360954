import axios from 'axios';

export default {
  mounted() {
    window.addEventListener('error', this.forwardError);
    window.addEventListener('unhandledrejection', this.forwardUnhandledrejection);
  },
  destroyed() {
    window.addEventListener('error', this.forwardError);
    window.addEventListener('unhandledrejection', this.forwardUnhandledrejection);
  },
  methods: {
    _browser() {
      let browserName = navigator.appName;
      let nAgt = navigator.userAgent;
      let nameOffset, verOffset;

      // In Opera, the true version is after 'Opera' or after 'Version'
      if ((verOffset = nAgt.indexOf('Opera')) != -1) {
        browserName = 'Opera';
      }
      // In MSIE, the true version is after 'MSIE' in userAgent
      else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
        browserName = 'Microsoft Internet Explorer';
      }
      // In Chrome, the true version is after 'Chrome' 
      else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
        browserName = 'Chrome';
      }
      // In Safari, the true version is after 'Safari' or after 'Version' 
      else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
        browserName = 'Safari';
      }
      // In Firefox, the true version is after 'Firefox' 
      else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
        browserName = 'Firefox';
      }
      // In most other browsers, 'name/version' is at the end of userAgent 
      else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))) {
        browserName = nAgt.substring(nameOffset, verOffset);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
          browserName = navigator.appName;
        }
      }
      return browserName;
    },
    _os() {
      let OSName = 'Unknown';
      if (window.navigator.userAgent.indexOf('Windows NT 10.0') != -1) OSName = 'Windows 10';
      if (window.navigator.userAgent.indexOf('Windows NT 6.3') != -1) OSName = 'Windows 8.1';
      if (window.navigator.userAgent.indexOf('Windows NT 6.2') != -1) OSName = 'Windows 8';
      if (window.navigator.userAgent.indexOf('Windows NT 6.1') != -1) OSName = 'Windows 7';
      if (window.navigator.userAgent.indexOf('Windows NT 6.0') != -1) OSName = 'Windows Vista';
      if (window.navigator.userAgent.indexOf('Windows NT 5.1') != -1) OSName = 'Windows XP';
      if (window.navigator.userAgent.indexOf('Windows NT 5.0') != -1) OSName = 'Windows 2000';
      if (window.navigator.userAgent.indexOf('Mac') != -1) OSName = 'Mac/iOS';
      if (window.navigator.userAgent.indexOf('X11') != -1) OSName = 'UNIX';
      if (window.navigator.userAgent.indexOf('Linux') != -1) OSName = 'Linux';
      return OSName;
    },
    _viewport() {
      let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      return `${vw} x ${vh}`;
    },
    _baseData(e) {
      return {
        type: e.type,
        timeStamp: e.timeStamp,
        location: e.currentTarget.location.href,
        isTrusted: e.isTrusted,
        viewport: this._viewport(),
        connection: window.navigator.connection.effectiveType,
        language: window.navigator.language,
        userAgent: window.navigator.userAgent,
        os: this._os(),
        browser: this._browser(),
      };
    },
    /**
     * @description Forward client-side errors to GAE logging
     */
    forwardError(e) {
      let baseData = this._baseData(e);

      let data = Object.assign({}, baseData, {
        filename: e.filename,
        message: e.error.message,
        stack: e.error.stack,
        colno: e.colno,
        lineno: e.lineno,
      });

      axios({
        url: '/api/log',
        method: 'POST',
        data,
      });
    },
    /**
     * @description Forward client-side unhandledrejections to GAE logging
     */
    forwardUnhandledrejection(e) {
      let baseData = this._baseData(e);

      let data = Object.assign({}, baseData, {
        message: e.reason.message,
        stack: e.reason.stack,
      });

      axios({
        url: '/api/log',
        method: 'POST',
        data,
      });
    },
  },
};
