<template>
  <div class="form__item">
    <label class="form__label" id="location">Billing Address</label>
    <el-input
      class="form__field"
      for="location"
      maxlength="200"
      placeholder="Your Billing Address"
      :rows="2"
      :show-word-limit="true"
      type="textarea"
      v-model="location_"></el-input>
  </div>
</template>

<script>
  export default {
    props: {
      value: String,
    },
    data() {
      return {
        location_: null,
        init: true,
        dirty: false,
      }
    },
    mounted() {
      this.location_ = this.value;
    },
    computed: {
      location() {
        return this.value;
      },
    },
    watch: {
      // Handles updates from the store.
      location(value, old) {
        this.location_ = value;
        this.dirty = false;
      },
      location_() {
        // Handles an init call.
        if (this.init) {
          this.init = false;
        // Handles changes by the store mutating the event.
        } else if (!this.dirty)  {
          this.dirty = true;
          this.$emit('change', {location: this.location_});
        // If the field was modified by user entry.
        } else if (this.dirty) {
          this.$emit('change', {location: this.location_});
        }
      },
    },

  }
</script>
