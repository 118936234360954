<template>
  <div zing-theme-builder="item" :name="name" :value="value">
    <div class="form-field">
      <label data-field="checkbox">
        <form-checkbox :checked="selected"></form-checkbox> 
        <span zing-theme-builder="control-cell" v-html="tooltip"></span>
        <span zing-theme-builder="control-cell">
          <span v-if="resolvedVal" :defaultVal="defaultVal" zing-theme-builder="default" v-html="resolvedVal"></span>
          <span v-else zing-theme-builder="default" :defaultVal="defaultVal"><em>{{defaultVal}}</em></span>
          <input @keyup="_controlTextChange" type="text" zing-theme-builder="value" :value="value">
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import FormCheckbox from "./FormCheckbox.vue";
import DEFAULT_VAR from './assets/json/themeDefault.json'
import THEME_VAR from './assets/json/theme.json'
export default {
  name: "ControlItem",
  components: {
    FormCheckbox
  },
  data: function() {
    return {
      // json files
      androidJson: null,
      defaultJson: DEFAULT_VAR,
      iosJson: null,
      genThemeJson: THEME_VAR,
      //misc
      resolvedVal: false,
      $builder: null,
      regex_var: /var\(.*\)/g,
      tooltip: null,
      tooltipSelector: '[zing-theme-builder="tooltip"]'
    }
  },
  props: {
    defaultVal: String,
    name: String,
    value: String,
    selected: Boolean
  },
  mounted: function() {
    this.$builder = this.$parent.$parent.$parent;
    this.androidJson = this.$builder.themeAndroid;
    this.iosJson = this.$builder.themeIos;
    this._addValue();
    this._addTooltip();
  },
  methods: {
    /**
     * @description Display value of variable
     * @private
     */
    _addValue() {
      // Resolve value of css variable
      let cssValue = this._replaceVar(this.defaultVal);
      const template = `<em zing-theme-builder="resolved">${cssValue}</em>`;
      this.resolvedVal = template;
    },
    /**
     * @description Add tooltip containing inheritance info over css variables in Variable Column
     * @private
     */
    _addTooltip() {
      // Get list of inherited
      const inherited = this._splitArgs(this.defaultVal);
      // Remove values
      inherited.forEach(function(item, index) { if(!item.includes('var(')) { inherited.splice(index, 1); } });
      let tooltipTemplate = `<em zing-theme-builder="var"><em zing-theme-builder="name">${this.name}</em>`
      // Add tooltip if css variables inherit from another
      if(inherited.length !== 0) {
        tooltipTemplate += `<em zing-theme-builder="tooltip">Inheriting from:<ol>`;
        inherited.forEach(function(item) { tooltipTemplate += `<li>${item}</li>`; });
        tooltipTemplate += `</ol></em>`
      } else tooltipTemplate += `</em>`;
      this.tooltip = tooltipTemplate;
    },
    /**
     * @description When checkbox clicked, calls parent function
     * @private
     */
    _controlCheckboxClick(e) { 
      this.$builder._controlCheckboxClick(e); 
      // Check on close if default value change
      if (!e.closest('[data-field="checkbox"]').classList.contains('selected')) this._addValue();
      this._updateDependents();
    },
    /**
     * @description When test inputted, calls parent function and update dependent variables
     * @private
     */
    _controlTextChange(e) { 
      this.$builder._controlTextChange(e);
      this._updateDependents();
    },
    /**
     * @description Returns the value extracted from a css variable
     * @param {String} cssVar - css variable to extract value from
     * @private
     */
    _extractValue(cssVar) {
      // Regex that extracts component name (ex. zg-pager, zing-grid) 
      // LIMITATIONS: misses components consisting of two '-' (ex. zg-head-cell, zg-option-list)
      const regex_component = /--[a-z]+-[a-z]+/g;
      const output = this.$builder.outputCss;
      let componentName = null;
      let cssValue = null;
      // Find component to search for css variable
      if (cssVar.includes('--zg-head-cell')) { componentName = 'ZGHeadCell'; }
      else if (cssVar.includes('--zg-option-list')) { componentName = 'ZGOptionList'; }
      else {
        const component = cssVar.match(regex_component)[0];
        componentName = component.toLowerCase().split('-').map(n => n = `${n.charAt(0).toUpperCase()}${n.substring(1)}`).join('').replace(/zg/i, 'ZG').replace(/zing-grid/i, 'ZingGrid');
      }
      // Determine value by searching in first in output
      if (output && Object.keys(output).length && output[componentName]) { output[componentName].forEach(item => { if(item.name === cssVar) cssValue = item.value; }); }
      // Then general theme
      if (!cssValue && cssVar.includes('--theme')) { this.genThemeJson.forEach(item => { if(item.name === cssVar) cssValue = item.defaultvalue; }); }
      // Then components
      if (!cssValue) {
        const cssJson = this.$builder.cssJson.filter(n => n.memberof === componentName);
        cssJson.forEach(item => { if(item.name === cssVar) { cssValue = item.defaultvalue; } });
      }
      // Value to display when css value is empty string
      if (!cssValue) cssValue = '--- no value ---';
      return cssValue;
    },
    /**
     * @description Given a default value, replaces all css variables with its values
     * @param {String} defaultVal - string to replace all var(*) with actual values
     * @private
     */
    _replaceVar(defaultVal) {
      const regex_numVar = /var\([^/)]*\)/g;
      let retVal = defaultVal;
      // Check if contains 1+ css variable within one argument
      const numVars = defaultVal.match(regex_numVar);
      if(numVars && numVars.length > 1) {
        numVars.forEach(item => { 
          const resolved = this._resolveValue(item);
          if (resolved === '--- no value ---') return resolved;
          retVal = retVal.replace(item, resolved); 
        });
      } else if (numVars && numVars.length === 1) {
        const varMatch = defaultVal.match(this.regex_var);
        const resolved = this._resolveValue(varMatch[0]);
        if (resolved === '--- no value ---') return resolved;
        retVal = retVal.replace(varMatch[0], resolved);
      } else {
        retVal = this._resolveValue(retVal);
      }
      return retVal;
    },
    /**
     * @description Returns a value that a css variable resolves to
     * @param {String} cssValue - string to replace all var(*) with actual values
     * @private
     */
    _resolveValue(cssValue) {
      // Regex that extracts 'var(*)'
      let content = null;
      let wholeValue = null;
      let toReplace = null;
      // If does not contain css variables, return given value
      if (!cssValue.match(this.regex_var) || !cssValue.includes('--')) { return cssValue; }
      // If starts with var(), grab inner content
      if (cssValue.indexOf('var(') === 0) content = cssValue.slice(4, -1);
      else {
        wholeValue = cssValue;
        content = toReplace = cssValue.match(this.regex_var)[0];
      }
      let cssValues = this._splitArgs(content);
      // For each item, search for value and replace 'var(*)' until no more 'var(*)' in that item
      let resolved = null;
      let resolvedArr = [];
      cssValues.forEach(item => {
        // if resolve return first item that doesn't return '--- no value ---'
        const matches = item.match(this.regex_var);
        if (matches) {
          // (Case 1) Contains var(...)
          const resolve = this._resolveValue(item);
          resolvedArr.push(resolve);
        } else if (item.includes('--')) {
          // (Case 2) Contains css variable to resolve
          const extracted = this._extractValue(item);
          if (extracted === '--- no value ---') { 
            resolvedArr.push(extracted);
          } else {
            const resolve = this._resolveValue(extracted);
            resolvedArr.push(resolve);
          }
        } else {
          // (Case 3) Returns '--- no value ---'
          resolvedArr.push(item);
        }
      });
      resolvedArr.forEach(item => { if (item && item !== '--- no value ---') { if (!resolved) resolved = item; } });
      if (resolved) {
        if (wholeValue) { return wholeValue.replace(toReplace, resolved); };
        return resolved;
      }
      return '';
    },
    /**
     * @description Splits string by css variable arguments
     * @param {String} content - string to replace all var(*) with actual values
     * @returns Array of css variable arguments
     * @private
     */
    _splitArgs(content) {
      // Split by comma
      let cssValues = content.split(', ');
      // Combine indices that make up on css var argument
      for(var i = 0; i < cssValues.length; i++) {
        // Check that number of '(' matches number of ')'
        const numLeftParam = cssValues[i].match(/\(/g);
        const numRightParam = cssValues[i].match(/\)/g);
        if ((numLeftParam !== numRightParam) || (numLeftParam && numRightParam && numLeftParam.length !== numRightParam.length)) {
          if (cssValues[i+1]) {
            cssValues[i] += `, ${cssValues[i+1]}`;
            cssValues.splice(i+1, 1); 
          }
        }
      }
      return cssValues;
    },
    /** 
     * @description Updates the dependent css variables' default value
     * @param
     * @private
     */
    _updateDependents() {
      const controls = this.$parent.$children;
      controls.forEach(function(control) {
        // Change only if not opened, has tooltip, is dependent to changing variable
        const tooltip = control.$el.querySelector('[zing-theme-builder="tooltip"]');
        if (!control.$el.querySelector('[data-field="checkbox"].selected') && tooltip
          && tooltip.textContent.includes(this.name)) {
            control._addValue()
          };
      }.bind(this));
    }
  }
};
</script>