<template>
  <zingnet-card :gridBody="true">
    <span slot="header">
      {{type}} Site Analytics
      <date-range @updateDateRange="updateDateRange" value-format="yyyy-MM-dd"></date-range>

      <div class="el-select-wrapper" page-visitor-select>
        <el-select v-model="selected" @change="updateTable">
          <el-option-group
            v-for="group in selectOptions"
            :key="group.label"
            :label="group.label">
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-option-group>
        </el-select>

        <svg-icon class="el-select-icon" icon="angle"></svg-icon>
      </div>
    </span>


    <span slot="header__right"><el-button @click="openMatomo">Open Matomo</el-button></span>

    <div slot="body" v-loading="loading">
      <section class="zingnetCard--row no-border">
        <el-button @click="back" subtable-back :disabled="disableBack">Back</el-button>
        <!-- Main grid -->
        <zing-grid
          cell-break="ellipsis"
          cell-class="renderNA"
          column-controls
          filter
          height=725
          main
          no-border
          pager
          page-size=10
          selector
          sort>
          <zg-colgroup>
            <zg-column index="subtable" sort="disabled" filter="disabled" renderer="_renderSubtable" width="118px"></zg-column>
            <zg-column index="label,url" header="Label" width="200px" type="custom" renderer="_renderUrl"></zg-column>
            <zg-column index="nb_hits" header="Pageviews"></zg-column>
            <zg-column index="bounce_rate"></zg-column>
            <zg-column index="avg_time_on_page"></zg-column>
            <zg-column index="exit_rate"></zg-column>
            <zg-column index="avg_time_generation"></zg-column>
          </zg-colgroup>
        </zing-grid>
      </section>
    </div>
  </zingnet-card>
</template>
<script>

import axios from 'axios';
import DateRange from '../DateRange.vue';
import MixinDateRange from '../../mixins/dateRange.js';
import MixinRenderer from '../../mixins/renderer.js';
import SvgIcon from '../../../../components/SvgIcon.vue';
import ZingnetCard from '../ZingnetCard.vue';

export default {
  components: {
    DateRange,
    SvgIcon,
    ZingnetCard,
  },
  mixins: [MixinDateRange, MixinRenderer],
  props: {
    type: String,
  },
  data() {
    return {
      loading: false,
      $zgRef: null,
      dataHistory: [],
      pageHistory: [],
      columnsMap: {
        'Actions': ['exit_rate', 'avg_time_generation', 'avg_time_on_page', 'bounce_rate', 'nb_hits'],
        'DevicesDetection': ['bounce_count', 'nb_actions', 'nb_conversions', 'nb_visits', 'nb_visits_converted', 'sum_daily_nb_uniq_visitors', 'sum_daily_nb_users', 'sum_visit_length'],
        'Referrers': ['bounce_count', 'max_actions', 'nb_actions', 'nb_conversions', 'nb_visits', 'nb_visits_converted', 'sum_daily_nb_uniq_visitors', 'sum_daily_nb_users', 'sum_visit_length'],
        'UserCountry': ['bounce_count', 'max_actions', 'nb_actions', 'nb_conversions', 'nb_visits', 'nb_visits_converted', 'sum_daily_nb_uniq_visitors', 'sum_daily_nb_users', 'sum_visit_length'],
        'UserLanguage': ['bounce_count', 'max_actions', 'nb_actions', 'nb_conversions', 'nb_visits', 'nb_visits_converted', 'sum_daily_nb_uniq_visitors', 'sum_daily_nb_users', 'sum_visit_length'],
        'VisitorInterest': ['nb_visits'],
        'VisitorInterest.getNumberOfVisitsByVisitCount': ['nb_visits', 'nb_visits_percentage'],
        'VisitsSummary': ['avg_time_on_site', 'bounce_count', 'bounce_rate', 'max_actions', 'nb_actions', 'nb_actions_per_visit', 'nb_uniq_visitors', 'nb_users', 'nb_visits', 'nv_visits_converted', 'sum_visit_length'],
        'VisitTime': ['bounce_count', 'max_actions', 'nb_actions', 'nb_visit', 'nv_visits_converted', 'sum_daily_nb_uniq_visitors', 'sum_daily_nb_users', 'sum_visit_length']
      },
      selectOptions: [{
        label: 'Devices',
        options: [{
          label: 'Brand',
          value: 'DevicesDetection.getBrand',
        }, {
          label: 'Browsers',
          value: 'DevicesDetection.getBrowsers',
        }, {
          label: 'Browser Engines',
          value: 'DevicesDetection.getBrowserEngines',
        }, {
          label: 'Browser Versions',
          value: 'DevicesDetection.getBrowserVersions',
        }, {
          label: 'Model',
          value: 'DevicesDetection.getModel',
        }, {
          label: 'OS Families',
          value: 'DevicesDetection.getOsFamilies',
        }, {
          label: 'OS Versions',
          value: 'DevicesDetection.getOsVersions',
        }, {
          label: 'Type',
          value: 'DevicesDetection.getType',
        }],
      }, {
        label: 'Referrers',
        options: [{
          label: 'All',
          value: 'Referrers.getAll',
        }, {
          label: 'Search Engines',
          value: 'Referrers.getSearchEngines',
        }],
      }, {
        label: 'Users',
        options: [{
          label: 'Countries',
          value: 'UserCountry.getCountry',
        }, {
          label: 'Languages',
          value: 'UserLanguage.getLanguage',
        }],
      }, {
        label: 'Visitor Actions',
        options: [{
          label: 'Downloads',
          value: 'Actions.getDownloads',
        }, {
          label: 'Entry Page Titles',
          value: 'Actions.getEntryPageTitles',
        }, {
          label: 'Entry Page Urls',
          value: 'Actions.getEntryPageUrls',
        }, {
          label: 'Exit Page Titles',
          value: 'Actions.getExitPageTitles',
        }, {
          label: 'Exit Page Urls',
          value: 'Actions.getExitPageUrls',
        }, {
          label: 'Outlinks',
          value: 'Actions.getOutlinks',
        }, {
          label: 'Page Titles',
          value: 'Actions.getPageTitles',
        }, {
          label: 'Page Titles Following Site Search',
          value: 'Actions.getPageTitlesFollowingSiteSearch',
        }, {
          label: 'Page Urls',
          value: 'Actions.getPageUrls'
        }, {
          label: 'Page Urls Following Site Search',
          value: 'Actions.getPageUrlsFollowingSiteSearch',
        }, {
          label: 'Site Search Categories',
          value: 'Actions.getSiteSearchCategories',
        }, {
          label: 'Site Search Keywords',
          value: 'Actions.getSiteSearchKeywords',
        }, {
          label: 'Site Search No Result Keywords',
          value: 'Actions.getSiteSearchNoResultKeywords',
        }],
      }, {
        label: 'Visitor Interest',
        options: [{
          label: 'Visits by Days Since Last',
          value: 'VisitorInterest.getNumberOfVisitsByDaysSinceLast',
        }, {
          label: 'Visits by Visit Count',
          value: 'VisitorInterest.getNumberOfVisitsByVisitCount',
        }, {
          label: 'Visits per Page',
          value: 'VisitorInterest.getNumberOfVisitsPerPage',
        }, {
          label: 'Visits per Visit Duration',
          value: 'VisitorInterest.getNumberOfVisitsPerVisitDuration',
        }],
      }, {
        label: 'Visits Summary',
        options: [{
          label: 'Summary',
          value: 'VisitsSummary.get',
        }],
      }, {
        label: 'Visit Time',
        options: [{
          label: 'Local',
          value: 'VisitTime.getVisitInformationPerLocalTime',
        }],
      }],
      selected: 'Actions.getPageUrls',
    };
  },
  computed: {
    disableBack() {
      return this.dataHistory.length === 0; 
    },
  },
  mounted() {
    this.$zgRef = this.$el.querySelector('zing-grid[main]');
    this.setupDrilldown();

    this.registerRenderer('renderNA');
    this.registerRenderer('_renderSubtable');
    this.registerRenderer('_renderUrl');
  },
  destroyed() {
    this.$zgRef = null;
  },
  methods: {
    generateOptionValue(type, val) {
      return `${type}.get${val.replace(/\s/g, '')}`;
    },
    getReportData() {
      this.loading = true;

      axios({
        url: `/api/admin/matomo/visitors?method=${this.selected}&site=${this.type}&period=${this.period}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.$store.state.auth.idToken}`,
        }
      }).then((response) => {
        this.baseData = response.data;
        this.$zgRef.setData(this.baseData);
        this.loading = false;
      });
    },
    openMatomo() {
      window.open('https://tracking.zingchart.com/piwik/');
    },
    updateColumns() {
      // Display selected columns
      let cols = this.columnsMap[this.selected];
      if (!cols) {
        let split = this.selected.split('.').shift();
        cols = this.columnsMap[split];
      };

      // Default columns
      let colObj = [{
        index: 'subtable', sort: 'disabled', filter: 'disabled', renderer: '_renderSubtable', width: '118px',
      }, {
        index:'label,url', header: 'label', width: '200px', type: 'custom', renderer: '_renderUrl',
      }];

      // Add columns for specified data
      cols.forEach((col) => {
        colObj.push({index: col});
      });

      // Set data
      this.$zgRef.columns = JSON.stringify(colObj);
    },
    updateTable() {
      // Clear history
      this.dataHistory = [];
      this.pageHistory = [];

      // Get and update data
      this.getReportData();

      // Update columns
      this.updateColumns();
    },
    setupDrilldown() {
      this.$zgRef.addEventListener('cell:click', bindExpandEvent);
    
      let _this = this;
      function bindExpandEvent(e) {
        // Check if subtable available for row
        let attributes = e.detail.ZGTarget.attributes;
        let dataFieldIndex = attributes.getNamedItem('data-field-index').value;
        let subtable = e.detail.ZGData.value;
        if (dataFieldIndex === 'subtable' && subtable) {
          // Update table and add to history
          _this.viewSubtable(subtable, _this);
        };
      };
    },

    back() {
      // Remove from history
      let data = this.dataHistory.pop();
      let page = this.pageHistory.pop();

      // Setup grid
      this.$zgRef.setData(data);
      this.$zgRef.setPageIndex(page);
    },
    viewSubtable(data, _this) {
      // Save history
      _this.dataHistory.push(_this.$zgRef.getData());
      _this.pageHistory.push(_this.$zgRef.getPageIndex())

      // Set data
      _this.$zgRef.setData(data);
    },

    _renderSubtable(subtable) {
      return subtable
        ? `<button type="button" subtable-expand class="el-button el-button--default" data-v-2e79777e=""><span>+</span></button>`
        : '';
    },
    _renderUrl(label, url) {
      return url
        ? `<a href="${url}" target="_blank">${label}</a>`
        : label;
    },
  },
}
</script>

<style>
  .zingnetCard .el-button[subtable-back] {
    margin-left: 2rem;
    position: absolute;
    top: 2.3rem;
    z-index: 100;
  }
  .zingnetCard zg-cell .el-button[subtable-expand] {
    margin: 0;
  }
  .zingnetCard [data-field-index="subtable"] {
    display: flex;
    justify-content: center;
  }

  .zingnetCard [page-visitor-select] {
    margin-top: 1rem;
  }
  .zingnetCard [page-visitor-select],
  .zingnetCard [page-visitor-select] input {
    width: 275px
  }
</style>