<template>
  <section class="content--wide" group-none>
    <p>Create your own group or request an invitation to join an existing group.</p>
    <p v-show="groupCapped" class="note  red">You currently are a part of <b>{{myGroups.length}}</b> groups. Please delete or leave a group to create another.</p>
    <p v-show="!groupingCreate" class="note  red">You do not have permission to create groups!</p>

    <el-button @click="createVisible=true" :disabled="groupCapped || !groupingCreate" type="primary">Create</el-button>
    <el-button @click="requestVisible=true" type="primary">Request</el-button>

    <!-- Create Dialog -->
    <el-dialog group-none="createDialog" class="dialog--condense dialog--padded" title="Create a Group" :visible.sync="createVisible">
      <el-form label-width="150px" :model="createForm" ref="createForm" :rules="rules">
        <el-form-item label="Group Name" prop="name">
          <el-input v-model="createForm.name"></el-input>
        </el-form-item>
        
        <el-form-item
          group-none="inputRow"
          v-for="(member, index) in createForm.members"
          :label="'Member ' + parseInt(index+1)"
          :key="member.key"
          :prop="'members.' + index + '.email'"
          :rules="[
            { required: true, message: 'Please input email address', trigger: 'blur' },
            { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
          ]">
          <el-input type="email" v-model="member.email"></el-input><el-button @click.prevent="removeMember(member)">Remove</el-button>
        </el-form-item>

        <el-form-item button-align="right">
          <el-button @click="addMember">Add Member</el-button>
          <el-button @click="validateAndSubmit('createForm')" type="primary">Create</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- Request Dialog -->
    <el-dialog group-none="requestDialog" class="dialog--condense dialog--padded" title="Request Invitation" :visible.sync="requestVisible">
      <el-form label-width="150px" :model="requestForm" ref="requestForm" :rules="rules">
        <el-form-item label="Group Name" prop="name">
          <el-input type="name" v-model="requestForm.name"></el-input>
        </el-form-item>
        <el-form-item button-align="right">
          <el-button @click="validateAndSubmit('requestForm')" type="primary">Request</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </section>
</template>
<script>

import axios from 'axios';
import MixinGroup from './../../../mixins/groups.js';
import MixinPermissions from './../../../mixins/permissions.js';

export default {
  name: 'GroupNone',
  mixins: [MixinGroup, MixinPermissions],
  asyncComputed: {
    adminDashboardView() {
      return this.checkPermission('admin_dashboard_create');
    },
    groupingCreate() {
      return this.checkPermission('grouping_create');
    },
  },
  computed: {
    groupCapped: function() {
      if (this.adminDashboardView) return false;
      return this.myGroups.length >= this.MAX_GROUPS;
    }
  },
  data: function() {
    return {
      createForm: {
        name: '',
        members: []
      },
      createVisible: false,
      MAX_GROUPS: 10,
      requestForm: {
        name: '',
      },
      requestVisible: false,
      rules: {
        name: [
          { required: true, message: 'Please input group name', trigger: ['blur', 'change'] },
          { min: 3, max: 50, message: 'Length should be 3 to 50', trigger: ['blur', 'change'] },
        ],
      }
    }
  },
  methods: {
    /**
     * @description Adds input field for Create Group's member invite 
     */
    addMember() {
      this.createForm.members.push({
        key: Date.now(),
        value: '',
      });
    },
    /**
     * @description Removes input field for Create Group's member invite
     * @param { String } item - input field to remove
     */
    removeMember(item) {
      var index = this.createForm.members.indexOf(item);
      if (index !== -1) {
        this.createForm.members.splice(index, 1);
      }
    },
    /**
     * @description Create group based on form input
     */
    submitCreate() {
      if (this.groupingCreate && !this.groupCapped) {  // Permission check
        axios({
          url: '/api/grouping',
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.$store.state.auth.idToken}`,
          },
          data: {
            'name': this.createForm.name
          }
        })
        .then((response) => {
          this.createVisible = false;
          window.location = `/?group=${response.data.groupingId}`;
          this.$router.replace('/');
        }).catch((err) => {
          let errMessage = 'Error creating group'
          if (err.error) errMessage = err.error;
          this.$message({
            message: errMessage,
            type: 'error',
          });
        });
      } else {
        this.$message({
          message: 'You do not have permission to create groups',
          type: 'error',
        });
      }
    },

    /**
     * @description Sends invite request
     */
    submitRequest() {
      // TODO. Sends name and current users email
    },
    /**
     * @description Validates form, then sends for submission if valid
     * @param { String } formName - ref value of form
     */
    validateAndSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === 'createForm') this.submitCreate();
          else this.submitRequest();
        } else {
          return false;
        }
      });
    }
  },
}
</script>

<style scoped>
  [group-none="buttonContainer"] {
    display: flex;
  }
</style>

<style>
  [group-none] .el-form .el-button {
    margin-left: 1rem;
  }
  [group-none="inputRow"] .el-form-item__content {
    display: flex;
  }
</style>