<template>
  <el-dialog
    @open="getData"
    @close="triggerClose"
    domain-modal
    :visible="visible"
    width="1200px">

    <div slot="title">
      <p class="title">Referrer info for
        <a v-if="domainObj" class="title--link" :href="`https://${domainObj.domain}`" target="_blank" rel="noreferrer noopener">{{domainObj.domain}}</a>
      </p>
      <!-- <date-range></date-range> -->
    </div>

    <div class="flex">
      <div>
        <section>
          <p class="zingnetCard--header">Hit Frequency</p>
          <p>CDN received <b>{{domainObj ? domainObj.hits : ''}} hits</b> from this referrer in this time period.</p>
        </section>
        <section>
          <p class="zingnetCard--header">Licensing</p>
          <div v-loading="loading">
            <template v-if="licenses && licenses.size > 0">
              <ul>
                <template v-for="license in licenses">
                  <a :key="license" :href="`https://c11.qbo.intuit.com/app/customerdetail?nameId=${quickbooksId}`" target="_blank"><li>{{license}}</li></a>
                </template>
              </ul>
            </template>
            <template v-else>
              <callout type="danger" icon="info"><p slot="content">This domain is not associated with a ZingChart or ZingGrid license</p></callout>
            </template>
          </div>
        </section>
        <section v-if="userId || quickbooksId">
          <p class="zingnetCard--header">Associated User</p>
          <p @click="openUserModal(userId, quickbooksId)" class="text--link">More details</p>
        </section>
        <section>
          <p class="zingnetCard--header">Add a Note about this referrer</p>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="Add your note here."
            v-model="newNote">
          </el-input>
          <el-button @click="addNote" span>Add Note</el-button>

        </section>
      </div>

      <div>
        <section>
          <p class="zingnetCard--header">
            Notes about this referrer
            <bubble-count :count="notesCount" :after="true"></bubble-count>
          </p>
          <section class="domainNote--container">
            <div v-for="note in notes" :key="note.id" class="domainNote">
              <div class="domainNote--header">Written {{dateFromNow(note.created_at, '')}}</div>
              <div class="domainNote--body">{{note.note}}</div>
            </div>
          </section>
        </section>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import axios from 'axios';
import BubbleCount from '../BubbleCount.vue';
import Callout from '../Callout.vue';
import DateRange from '../DateRange.vue';
import MixinDate from '../../../../mixins/date.js';

export default {
  components: {
    BubbleCount,
    Callout,
    DateRange,
  },
  mixins: [MixinDate],
  props: {
    domainObj: Object,
    visible: Boolean,
  },
  data() {
    return {
      notes: null,
      newNote: null,
      licenses: {},
      loading: false,
      quickbooksId: null,
      userId: null,
    };
  },
  computed: {
    notesCount() {
      return this.notes ? this.notes.length : 0;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    addNote() {
      let newNoteData = {
        domain_id: this.domainObj.domain_id,
        note: this.newNote,
        created_at: new Date().toISOString().split('T')[0],
      };

      axios({
        url: '/api/cdn_note',
        method: 'POST',
        headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
        data: newNoteData,
      })
      .then((response) => {
        // Add to notes container
        this.notes.push(newNoteData);

        // Clear textarea
        this.newNote = null;

        // Success message
        this.$message({
          message: 'Note added!',
          type: 'success',
        });
      }).catch((err) => {
        // Error message
        let errMessage = `Error occurred while adding note: ${err}`;
        this.$message({
          message: errMessage,
          type: 'error',
        });
      });
    },
    getData() {
      this.getNotes();
      this.getLicenseData();
    },
    getLicenseData() {
      // Clear license data
      this.licenses = null;

      // Check if all required info is available to get license info
      if (this.domainObj && this.domainObj.domain) {
        this.loading = true;
        axios({
          url: `/api/license/details/${this.domainObj.domain}`,
          method: 'GET',
          headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
        })
        .then((response) => {
          let licenses = response.data.map((data) => data.plan);
          this.licenses = new Set(licenses);
          this.quickbooksId = response && response.data && response.data[0] ? response.data[0].id_quickbooks : null;
          // Don't include userId if belongs to admin
          this.userId = response && response.data && response.data[0] ? response.data[0].id_user : null;
          if (this.userId === 2) this.userId = null;
          this.loading = false;
        }).catch((err) => {
          // Error message
          let errMessage = `Error occurred while retrieving license: ${err}`;
          this.$message({
            message: errMessage,
            type: 'error',
          });
        });
      } else if (this.domainObj) {
        // Error message
        let errMessage = `Missing either cdn domain or hostname: ${err}`;
        this.$message({
          message: errMessage,
          type: 'error',
        });
      };
    },
    getNotes() {
      // Clear notes
      this.notes = [];

      if (this.domainObj) {
        axios({
          url: `/api/cdn_note/${this.domainObj.domain_id}`,
          method: 'GET',
          headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
        })
        .then((response) => {
          this.notes = response.data;
        }).catch((err) => {
          // Error message
          let errMessage = `Error occurred while retrieving notes: ${err}`;
          this.$message({
            message: errMessage,
            type: 'error',
          });
        });
      };
    },
    openUserModal(id, quickbooksId) {
      this.$emit('openUserModal', {id, quickbooksId});
    },
    triggerClose() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>
  .flex {
    display: flex;
  }
  .flex > div {
    padding: var(--domain-modal-padding);
    width: 50%;
  }
  .flex > div + div {
    border-left: var(--zingnet-border);
  }
  .domainNote {
    border: var(--zingnet-border);
    border-radius: 10px;
    overflow: hidden;
  }
  .domainNote + .domainNote {
    margin-top: 1.125rem;
  }
  .domainNote--body {
    font-size: 0.9375rem;
    padding: 1rem 1.125rem;
  }
  .domainNote--container {
    max-height: 20rem;
    overflow: scroll;
  }
  .domainNote--header {
    background-color: var(--zingnet-color-gray);
    border-bottom: var(--zingnet-border);
    color: var(--color-primary-gray);
    font-size: 0.8125rem;
    font-style: italic;
    padding: 1rem 1.125rem;
  }
  section + section {
    margin-top: 1.875rem;
  }
</style>

<style>
  :root {
    --domain-modal-padding: 1.75rem 1.875rem;
  }

  [domain-modal] .el-dialog {
    border-radius: 10px;
    padding-right: 0 !important;
  }

  [domain-modal] .el-dialog__header {
    border-bottom: var(--zingnet-border);
    padding: var(--domain-modal-padding) !important;
  }
  [domain-modal] .title {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }

  [domain-modal] .el-dialog__body {
    color: var(--color-primary-gray);
    font-size: 0.9375rem;
    padding: 0;
  }

  [domain-modal] .zingnetCard--header {
    display: block;
  }
</style>