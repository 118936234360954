export default {
  data() {
    return {
      toggleSubscriptionDialogVisible: false,
      toggleSubscriptionText: '',
    };
  },
  methods: {
    reactivateSubscription(subID) {
      this.$api('subscription/reactivate', {
        slug:subID,
      })
      .then((result) => {
        this.$message({
          message: 'Subscription reactivated!',
          showClose: true,
          type: 'success',
        });
        this.getUserSubscriptions();
      })
      .catch((error) => {
        this.$message({
          duration: 10000,
          message: 'Cannot reactivate',
          showClose: true,
          type: 'error',
        });
      });
    },
  }
};