import axios from 'axios';

const userKeys =[
  'company',
  'email_verified',
  'phone_number',
  'location',
  'email',
  'name',
  'picture',
  'quickbooks',
  'referrer',
  'role',
  'settings_autocomplete',
  'settings_autoupdate',
  'settings_dashView',
  'settings_docs',
  'settings_editor',
  'support_hours',
];

function beaconHash(email) {
  return crypto.createHmac('sha256', VUE_APP_HELPSCOUT_BEACON_ID)
    .update(email)
    .digest('hex');
};

const user = {
  namespaced: true,

  state: {
    name: '',
    // super_admin: false,
    sidebar: {
      manage: null,
      create: null,
    },
    company: '',
    email_verified: false,
    extend_token: '',
    groups: null,
    hash: null,
    location: '',
    picture: '',
    user_id: '',
    referrer: '',
    role: '',
    support_hours: '',
    trackJSUrl: '',
    adminDateRange: '',
  },

  mutations: {
    add(state, user) {
      // Sensitive data not to add to state (just inform if given)
      let sensitiveData = ['company', 'location', 'name', 'phone_number', 'picture', 'quickbooks'];

      userKeys.forEach((key) => {
        if (user && key === 'referrer') {
          state[key] = user['signup_url'].indexOf('zingchart') >= 0 ? 'ZingChart' : 'ZingGrid';
        } else if (sensitiveData.indexOf(key) > -1) {
          state[key] = !!user[key];
        } else {
          state[key] = user[key];
        }
      });

      state.user_id = user.id;
      localStorage.setItem('user', `{"id": ${user.id}}`);

      // assign trackJSUrl for future fullstory use
      state.trackJSUrl = `https://my.trackjs.com/users/filtered?userid=${encodeURIComponent(user.email)}`;

      let userMetadata = {
        email: user.email,
        signature: state.hash,
        trackJSUserLink: state.trackJSUrl,
      };

      // user opted in fields
      if (user && user.company) userMetadata.company = user.company;
      // support hours default 0
      if (user && typeof user.support_hours !== 'undefined') userMetadata.support_hours = user.support_hours;

      // update Beacon
      this.dispatch('user/addBeaconSession', user);
      // add trackJS user to beacon
      this.dispatch('user/addBeaconMetadata', userMetadata);

      // trackJs only exists in production
      this.dispatch('user/addTrackJSMetadata', {
        key: 'userId',
        value: user.email,
      });

      // identify user in fullstory
      // CANT DISPATCH HERE: NO GUARANTEE FS EXISTS YET
      // and they don't have a global _FS object
      this.dispatch('user/addFullStorySession', {email: user.email});

      // check if it was a signup redirect
      if (localStorage.getItem('setupBeacon')) {
        // if !Beacon something went wrong loading Beacon in the browser...
        if (typeof Beacon !== 'undefined') {
          Beacon('identify', {
            email: user.email,
            signature: user.hash,
            'signup-type': 'zingsoft-signup'
          });
  
          localStorage.removeItem('setupBeacon'); // clear that local storage signup item
          const signupReferrer = localStorage.getItem('signupReferrer');
          if (signupReferrer) {
            Beacon('identify', {
              email: user.email,
              signature: user.hash,
              'signup-type': `${signupReferrer}-referrer`
            });

            localStorage.removeItem('signupReferrer'); // clear that local storage signup item
          }
        }
      };

      // try to log the user in piwik
      this.dispatch('user/addPiwikUserId', {value: user.email});

      // add trackJS user to piwik
      this.dispatch('user/addPiwikMetadata', {
        index: 2, // each custom variable has its own index
        key: 'trackJSUserLink',
        value: state.trackJSUrl,
      });
    },
    clear(state, data) {
      userKeys.forEach((key) => {
        state[key] = '';
      });
      state.user_id = '';
      state.trackJSUrl = '';
    },

    initializeGroups(state, data) {
      if (localStorage.getItem('zs_groups')) {
        state.groups = JSON.parse(localStorage.getItem('zs_groups'));
      }
    },

    initializeSidebar(state, data) {
      function clearSidebar() {
        // If expired or does not exist, clear sidebar-related items
        localStorage.removeItem('zs-sidebar');
        localStorage.removeItem('zs_sidebar_expire');
      }

      let expire = localStorage.getItem('zs_sidebar_expire');
      if (expire) {
        let numExpire = parseInt(expire);
        let now = new Date().getTime();

        // Set sidebar if not expired and has sidebar stored in localstorage
        if (numExpire > now && localStorage.getItem('zs_sidebar')) {
          try {
            state.sidebar = JSON.parse(localStorage.getItem('zs_sidebar'));
          } catch (err) {
            // Remove invalid JSON object
            localStorage.removeItem('zs_sidebar');
          }
        } else {
          clearSidebar();
        }
      } else {
        clearSidebar();
      }
    },

    update(state, data) {
      let keys = Object.keys(this.state.user);
      keys.forEach((key) => {
        if (key === 'sidebar' && typeof(data[key]) === 'object') {
          // Add sidebar and expiration time (1 day)
          let expire = new Date().getTime() + 1000 * 60 * 60 * 24;
          localStorage.setItem('zs_sidebar', JSON.stringify(data[key]));
          localStorage.setItem('zs_sidebar_expire', expire);
        }
        if (data[key] != null) state[key] = data[key];
      });
    },
  },

  getters: {
    retrieveSocialID: (state) => (provider) => {
      let id = null;
      state.identities.forEach((identity) => {
        if(identity.provider === provider) {
          id = identity.user_id;
        }
      });
      return id;
    },
  },

  actions: {

    refresh({commit, state, getters}, vm) {
      vm.$api('user/retrieve')
      .then((response) => {
        commit('add', response.data);
      })
      .catch((error) => {
        vm.$toast('Could not refresh user data');
      });
    },

    /**
     * @description Try to add beacon session
     */
    addBeaconSession({commit, state}, opts) {
      try {
        window.beaconSettings = { app_id: VUE_APP_HELPSCOUT_BEACON_ID };
        // adjust global window settings to reflect changes for email
        if (opts && opts.email) window.beaconSettings.email = opts.email || 'N/A';
        if (opts && opts.name) window.beaconSettings.name = opts.name || 'N/A';

        // adjust global window settings to reflect changes for picture (if available)
        if (opts && opts.picture) {
          window.beaconSettings.avatar = {
            type: 'avatar',
            image_url: opts.picture,
          };
        }
      } catch(e) {
        console.error('Beacon session error');
      };
    },

    /**
     * @description Update the users fullstory metadata
     * @param {Object} opts - object with key/value pairs
     */
    addBeaconMetadata({commit, state}, opts) {
      try {
        // pass object to fullstory for tracking
        if (typeof Beacon !== 'undefined') {
          // Beacon requires email and name
          Beacon('identify', opts);
        } else {
          // we have time to send variables to Beacon window object
          // lets copy over the object passed
          // Note: OPTS OVERRIDES values in window.beaconSettings
          Object.assign(window.beaconSettings, opts);
        }
      } catch(e) {
        console.error('Beacon metadata tracking error');
      }
    },

    /**
     * @description update the users fullstory metadata
     */
    addFullStorySession({commit, state}, opts) {
      try {
        // identify user in fullstory
        if (typeof FS !== 'undefined' && opts && opts.email) {
          // identify the user in full story
          FS.identify(opts.email, {
            // display name or email depending on whats available
            displayName: opts.email,
            email: opts.email,
            // TODO: Add your own custom user variables here, details at
            // http://help.fullstory.com/develop-js/setuservars
            emailVerified: this.state.user.email_verified,
          });
        }
      } catch(e) {
        console.error('fullstory session tracking error: ', e);
      }
    },

    /**
     * @description update the users fullstory metadata
     * @param {Object} opts - object with key/value pairs
     * must suffix var name with type e.g)  trackjslink_str
     * https://help.fullstory.com/develop-js/setuservars
     */
    addFullstoryMetadata({commit, state}, data) {
      try {
        // pass object to fullstory for tracking
        FS.setUserVars(data);
      } catch(e) {
        console.error('fullstory metadata tracking error');
      }
    },

    /**
     * @description update piwik userid
     * actions defined here: https://developer.matomo.org/guides/tracking-javascript-guide
     */
    addPiwikUserId({commit, state}, opts) {
      try {
        _paq.push(['setUserId', opts.value]);
      } catch(e) {
        console.error('piwik userid tracking error');
      };
    },

    /**
     * @description update piwik metadata
     * @param {String} opts.index - index of custom piwik value
     * @param {String} opts.key - object identifier
     * @param {Any} opts.value - any data to be assigned to key
     * actions defined here: https://developer.matomo.org/api-reference/tracking-javascript
     */
    addPiwikMetadata({commit, state}, opts) {
      try {
        _paq.push(['setCustomVariable', opts.index, opts.key, opts.value]);
      } catch(e) {
        console.error('piwik metadata tracking error');
      }
    },

    /**
     * @description update trackJs metadata
     * @param {String} opts.key - object identifier
     * @param {Any} opts.value - any data to be assigned to key
     */
    addTrackJSMetadata({commit, state}, opts) {
      try {
        // if trackJS exists use API
        if (window.trackJs) {
          // add userID identifier
          trackJs.addMetadata(opts.key, opts.value);
        } else {
          // trackJS hasn't been loaded and we can add it to the window object
          // directly defined in index.html
          window._trackJs[opts.key] = opts.value;
        }
      } catch(e) {
        console.error('trackJS metadata tracking error');
      }
    },

    /**
     * @description event broadcast when fullstory is ready for use.
     * This should ONLY be fired onces full story has been
     * instantiated on the page in APP.vue
     */
    fullStoryInstantiated() {
      try {
        // update fullstory session with userdata (if exists)
        this.dispatch('user/addFullStorySession');
        // update fullstory session url to associate tracking information
        this.dispatch('user/updateFullStorySessionUrl', FS.getCurrentSessionURL());

        // Only attempt to get user info if user logged in
        if (this.state.auth.idToken) {
          axios({
            url: '/api/user',
            method: 'GET',
            headers: { 'Authorization': `Bearer ${this.state.auth.idToken}` },
          }).then((data) => {
            // track metadata in fullstory here
            let userState = this.state.user;
            let user = Object.assign({}, data.data, userState.extend_token,
              userState.user_id, userState.referrer);
            let fullStoryMetadata = {
              trackJSUserLink_str: this.state.user.trackJSUrl,
            };
            // user opted in fields
            if (user && user.company) fullStoryMetadata.company_str = user.company;
            // support hours default is 0
            if (user && typeof user.support_hours !== 'undefined') fullStoryMetadata.support_hours_int = user.support_hours;
            // add trackJS info to fullstory
            // must suffix with _str
            // https://help.fullstory.com/develop-js/setuservars
            this.dispatch('user/addFullstoryMetadata', fullStoryMetadata);
          });
        };
      } catch(e) {
        console.error('Fullstory session url error: ', e);
      }
    },

    /**
     * @description update the current fullstory session
     */
    updateFullStorySessionUrl({commit, state}, fsSessionUrl) {
      // add trackJS fullstory session link
      this.dispatch('user/addTrackJSMetadata', {
        key: 'fullstorySessionApp',
        value: fsSessionUrl,
      });
      // add piwik fullstory session link
      this.dispatch('user/addPiwikMetadata', {
        index: 3, // each custom variable has its own index
        key: 'fullstorySessionApp',
        value: fsSessionUrl,
      });
      // add beacon fullstory session link
      this.dispatch('user/addBeaconMetadata', {
        email: state.email,
        signature: state.hash,
        fullstorySessionApp: fsSessionUrl,
      });
    },
  },
};

export default user;
