<template>
  <default-layout>
    <section notifications class="content--narrow">
      <header class="content__header content__header--expanded">
        <h2>Notifications</h2>
      </header>

      <div notifications="gridContainer">
        <zing-grid
          caption="Notifications"
          layout=row
          notifications="list"
          row-class="markRead"
          data='[
            { "id": "1", "id_from": "123", "from": "john@zingsoft.com", "date": "May 15", "read": true, "needs_response": true, "type": "companyInvite", "company": "Company X", "permission": null },
            { "id": "2", "id_from": "123", "from": "jane@zingsoft.com", "date": "May 14", "read": false, "needs_response": false, "type": "companyInvite", "company": "Company X", "permission": null },
            { "id": "3", "id_from": "123", "from": "joe@zingsoft.com", "date": "May 13", "read": false, "needs_response": true, "type": "requestPermission", "company": "Company Y", "permission": "read" },
            { "id": "4", "id_from": "123", "from": "jill@zingsoft.com", "date": "May 12", "read": true, "needs_response": false, "type": "requestPermission", "company": "Company Y", "permission": "write" },
            { "id": "5", "id_from": "123", "from": "jack@zingsoft.com", "date": "May 11", "read": true, "needs_response": true, "type": "requestCompany", "company": "Company X", "permission": null },
            { "id": "6", "id_from": "123", "from": "jim@zingsoft.com", "date": "May 10", "read": false, "needs_response": false, "type": "requestCompany", "company": "Company X", "permission": null }
          ]'>
          <zg-colgroup>
            <zg-column index="id" hidden></zg-column>
            <zg-column index="date" width="125"></zg-column>
            <zg-column index="from, type, company, permission" header="Subject" renderer="renderSubject"></zg-column>
            <zg-column index="needs_response" header="Responded" renderer="renderResponded"></zg-column>
            <zg-column index="btn" header=" " renderer="renderViewBtn" width="180"> </zg-column>
          </zg-colgroup>
        </zing-grid>

        <zing-grid
          class="hide"
          layout=row
          notifications="detail">
          <zg-caption>
            Notifications
            <el-button @click="hideDetails" size="small" support="openTicketBtn" type="primary" >Back</el-button>
          </zg-caption>
          <zg-colgroup>
            <zg-column index="id, id_from, date, from, needs_response, type, company, permission" header="Notification Details" renderer="renderDetails"></zg-column>
          </zg-colgroup>
        </zing-grid>
      </div>

    </section>
  </default-layout>
</template>
<script>
import DefaultLayout from '../layouts/Default.vue';

export default {
  components: {
    DefaultLayout,
  },
  data: function() {
    return {
      notificationsDetails: null,
      notificationsList: null,
    }
  },
  mounted() {
     this.notificationsDetails = this.$el.querySelector('[notifications="detail"]');
     this.notificationsList = this.$el.querySelector('[notifications="list"]');

    this.addListeners();
    this.registerMethods();
  },
  methods: {
    /**
     * @description Add event listeners to register click events in zinggrid
     */
    addListeners() {
      this.notificationsList.executeOnLoad(() => {
        this.notificationsList.addEventListener('row:click', (e) => {
          // Listen for click event for "View / Respond" button
          let target = e.detail.ZGEvent.oDOMRealTarget;
          let trigger = null;
          if ((target.tagName === 'SPAN' && target.parentElement.tagName === 'BUTTON') || target.tagName === "BUTTON") this.showDetails();

          // Set ticket details
          this.notificationsDetails.setData([e.detail.ZGData.data]);
        });
      });

      this.notificationsDetails.executeOnLoad(() => {
        this.notificationsDetails.addEventListener('row:click', (e) => {
          // Listen for click event for "View / Respond" button
          let target = e.detail.ZGEvent.oDOMRealTarget;
          let trigger = null;
          if ((target.tagName === 'SPAN' && target.parentElement.tagName === 'BUTTON') || target.tagName === "BUTTON") {
            if (target.textContent === 'Accept' || target.textContent === 'Approve' || target.getAttribute('notifications') === 'button--accept') {
              console.log("ACCEPT")
            } else {
              console.log("REJECT")
            }
          }
        });
      });
    },
    /**
     * @description Hides notification details to display notification list
     */
    hideDetails() {
      this.notificationsDetails.classList.add('hide');
      this.notificationsList.classList.remove('hide');
    },
    /**
     * @description Register renderer functions
     */
    registerMethods() {
      window.markRead = function(record) {
        if (record.read) return 'read';
      }

      window.renderDetails = function(id, id_from, date, from, needs_response, type, company, permission) {
        let retval = `<h3>${renderSubject(from, type, company, permission)}</h3>`;
        if (needs_response) {
          let verb = '';
          if (type === 'companyInvite') {
            retval += `<p>Do you accept the invitation to join ${company}?</p>`;
            verb = 'Accept';
          } else if (type === 'requestCompany') {
            retval += `<p>Do you accept ${from} into your company, ${company}?</p>`;
            verb = 'Accept';
          } else if (type === 'requestPermission') {
            retval += `<p>Do you approve giving ${permission} permission to ${from} from company, ${company}?</p>`;
            verb = 'Approve';
          }
          retval += `<div notifications="buttonContainter">
            <button notifications="button--accept" class="el-button el-button--primary el-button--small" type="button"><span>${verb}</span></button>
            <button notifications="button--reject" class="el-button el-button--danger el-button--small" type="button"><span>Reject</span></button>
          </div>`;
        }
        return retval;
      }

      window.renderSubject = function(from, type, company, permission) {
        if (type === 'companyInvite') return `${from} inviting you to ${company}`;
        else if (type === 'requestCompany') return `${from} requesting invitation to your company, ${company}`;
        else if (type === 'requestPermission') return `${from} requesting ${permission} permission in ${company}`;
      }

      window.renderResponded = function(needs_response) {
        const RESPONDED = `<svg aria-hidden="true" style="color:rgb(19, 206, 102);" data-prefix="far" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check-circle fa-w-16 fa-3x"><path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" class=""></path></svg>`;
        const NEEDS_RESPONSE = `<svg aria-hidden="true" style="color:rgb(255, 73, 73);" data-prefix="far" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16 fa-3x"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z" class=""></path></svg>`;
        if (needs_response) return NEEDS_RESPONSE;
        else return RESPONDED;
      }
      
      window.renderViewBtn = function() {
        return `<button
          class="el-button el-button--primary el-button--small"
          type="button">
          <span>View / Respond</span>
        </button>`;
      }
    },
    /**
     * @description Shows notification details to display notification list
     */
    showDetails() {
      this.notificationsDetails.classList.remove('hide');
      this.notificationsList.classList.add('hide');
    },
  },
}
</script>

<style>
  [notifications].content--narrow { height: 100%; }
  [notifications] [data-icon="check-circle"], [notifications] [data-icon="times-circle"] { height: 1.25rem; }
  [notifications] .hide { opacity: 0; pointer-events:none; position: absolute; top: -1000rem; }
  [notifications="detail"] { position: absolute; top: 0; --zg-row-body-background_hover: transparent; }
  [notifications="gridContainer"] { position: relative; }
  [notifications="list"] { position: absolute; top: 0; }
  [notifications="list"] [data-field-index="needs_response"] { text-align: center; }
  [notifications="list"] .read { background-color: #f4f4f5; color: #5f5f5f; }
</style>