<template>
  <el-popover
    editorSetting
    placement="bottom"
    @after-leave="updateEditorSetting"
    title="View"
    trigger="click">
    <!-- LAYOUT -->
    <template v-if="!isMobile">
      <div editorSetting="setting">
        <p editorSetting="setting__title">Layout:</p>
        <el-radio-group v-model="curLayout" @change="handleUpdate" :size="size">
          <el-radio-button
            v-for="option in layoutOptions" 
            :key="option"
            :label="option">
            {{renderLabel(option)}}
          </el-radio-button>
        </el-radio-group>
      </div>
      <el-divider></el-divider>
    </template>
    <!-- FONT -->
    <div editorSetting="setting">
      <p editorSetting="setting__title">Font Family:</p>
      <el-select v-model="curFontFamily" @change="handleUpdate" placeholder="Select">
        <el-option
          v-for="option in fontOptions" 
          :key="option"
          :label="renderLabel(option)"
          :value="option">
        </el-option>
      </el-select>
      <p>Font Size:</p>
      <el-input-number v-model="curFontSize" @change="handleUpdate" :min="1" :size="size"></el-input-number>
    </div>
    <el-divider></el-divider>
    <!-- THEME -->
    <div editorSetting="setting">
      <p editorSetting="setting__title">Theme:</p>
      <el-select v-model="curTheme" @change="handleUpdate" placeholder="Select">
        <el-option
          v-for="option in themeOptions" 
          :key="option"
          :label="renderLabel(option)"
          :value="option">
        </el-option>
      </el-select>
    </div>
    <el-divider></el-divider>
    <!-- Previews -->
    <div editorSetting="setting">
      <p editorSetting="setting__title">Previews:</p>
      <template v-for="option in tabOptions">
        <el-button
          @click="handleUpdate(option)"
          :key="option"
          :size="size">
          {{renderLabel(option)}}<font-awesome-icon :icon="['fas', 'external-link-alt']" layout-dropdown="externalLink" size="sm"></font-awesome-icon>
        </el-button>
      </template>
    </div>
    <!-- TRIGGER -->
    <el-button :type="type" :size="size" slot="reference">View<i class="el-icon-arrow-down el-icon--right"></i></el-button>
  </el-popover>
</template>

<script>
export default {
  props: {
    uid: {
      type: String,
    },
    size: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      // Setup dropdown options
      defaultOptions: ['bottom', 'default'],  // Default editor options
      layoutOptions: ['left', 'bottom', 'right', 'top'], //, 'grid', 'columns'], // Editor layout options
      fontOptions: ['cursive', 'fantasy', 'monospace', 'sans-serif', 'serif'],
      tabOptions: ['view', 'embed'],  // Dropdown options that open to new tab
      themeOptions: ['default', 'dark', 'cobalt', 'github', 'monokai'], // Theme options
      viewOptions: ['view', 'embed'],  // View layout options

      // Current state
      curFontFamily: 'monospace',
      curFontSize: '13',
      curLayout: 'bottom',
      curTheme: 'default',

      // Settings
      editorSettings: ['FontFamily', 'FontSize', 'Layout', 'Theme'],
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters['ui/isMobile'];
    },
    // Current state
    stateFontFamily () {
      return this.$store.state.user['settings_editor'] ? JSON.parse(this.$store.state.user['settings_editor']).fontFamily : this.curFontFamily;
    },
    stateFontSize() {
      return this.$store.state.user['settings_editor'] ? JSON.parse(this.$store.state.user['settings_editor']).fontSize : this.curFontSize;
    },
    stateLayout() {
      return this.$store.state.user['settings_editor'] ? JSON.parse(this.$store.state.user['settings_editor']).layout : this.curLayout;
    },
    stateTheme() {
      return this.$store.state.user['settings_editor'] ? JSON.parse(this.$store.state.user['settings_editor']).theme : this.curTheme;
    },
  },
  mounted() {
    // Load in user's editor settings
    this.loadEditorSettings();
  },
  methods: {
    /**
     * @description Load in user's editor settings
     */
    loadEditorSettings() {
      this.editorSettings.forEach(setting => {
        let stateSetting = `state${setting}`;
        this[`cur${setting}`] = this[stateSetting];
        this.handleUpdate(this[stateSetting], false);
      });
    },
    /**
     * @description Renders labels for dropdown items. When dropdown command contains '_', the words after
     * are wrapped in parenthesis.
     * @param {String} text - text to render
     * Ex. tabs => Tabs
     * Ex. tabs_row => Tabs (row)
     * Ex. tabs_row_mode => Tabs (row mode)
     */
    renderLabel(text) {
      // Capitalize first word
      let label = text[0].toUpperCase() + text.slice(1);
      let words = label.split('_');
      // Place word(s) in parenthesis
      if (words.length > 1) {
        label = '';
        words.forEach((word, index) => {
          // Add parenthesis to second word
          if (index === 1) label += `(`;
          // If last word, add closing parenthesis
          if (index === words.length-1) label += `${word})`;
          else label += `${word} `;
        });
      }
      return label;
    },
    /**
     * @description When dropdown item selected:
     * 1. Open tab option: open view in new tab
     * 2. Theme option: update theme by emitting event to parent to handle
     * 3. Editor layout option: update view by emitting event to parent to handle
     * After update both the state and send settings to database
     * @param {String} view - Selected dropdown item's `[command]` attribute value
     * @param {Boolean} update - Update state and database
     */
    handleUpdate(option, update=true) {
      if (option) {
        // Font-Family
        if (this.fontOptions.indexOf(option) > -1) this.$emit('update', option, 'fontFamily');
        // Layout
        else if (this.layoutOptions.indexOf(option) > -1) this.$emit('update', option, 'layout');
        // Tabs
        else if (this.tabOptions.indexOf(option) > -1) window.open(`/demos/${option}/${this.uid}`);
        // Theme
        else if (this.themeOptions.indexOf(option) > -1) this.$emit('update', option, 'theme');
        // Font-Size
        else this.$emit('update', option.toString(), 'fontSize');
      }
    },
    /**
     * @description Update editor settings when user makes changes to settings
     * @param {Boolean} val - new value of switch
     */
    updateEditorSetting() {
      // Update state
      let state = this.$store.state.user['settings_editor'] ? JSON.parse(this.$store.state.user['settings_editor']) : {};
      state.fontFamily = this.curFontFamily,
      state.fontSize = this.curFontSize,
      state.layout = this.curLayout,
      state.theme = this.curTheme,
      this.$store.state.user['settings_editor'] = JSON.stringify(state);

      // Null check user and settings
      if (this.$store.state.user.user_id && this.$store.state.user['settings_editor']) {
        // Update database
        this.$api('user/update', {
          'settings_editor': state,
        })
        .then((response) => {
          this.$store.dispatch('user/refresh', this);
        })
        .catch((result) => {
          this.$message({
            duration: 10000,
            message: 'Unable to update autoupdate settings',
            showClose: true,
            type: 'error',
          });
        })
      };
    },
  },
};
</script>


<style>
  .editor__resizer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #989898;
    z-index: 1;
  }

  [editorLayout] {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
    height: 100%;
  }

  [editorSetting="setting"] {
    margin-top: 20px;
  }

  [editorSetting="setting__title"] {
    font-weight: 600;
  }

  /* COL / ROW */
  [editorLayout|="col"] {
    flex-direction: row;
  }

  [editorLayout|="col"] .editor__code-container{
    flex-direction: column;
  }

  [editorLayout|="col"] .editor__resizer--code {
    cursor: row-resize;
    height: 10px;
    width: 100%;
  }
  [editorLayout|="col"] .editor__resizer--preview {
    cursor: col-resize;
    height: 100%;
    width: 10px;
  }

 [editorLayout|="row"] .editor__resizer--code {
    cursor: col-resize;
    height: 100%;
    width: 10px;
  }
  [editorLayout|="row"] .editor__resizer--preview {
    cursor: row-resize;
    height: 10px;
    width: 100%;
  }

  [editorLayout|="col"] .editor__resizer--code .fa-ellipsis-vertical,
  [editorLayout|="col"] .editor__resizer--preview .fa-ellipsis,
  [editorLayout|="row"] .editor__resizer--code .fa-ellipsis,
  [editorLayout|="row"] .editor__resizer--preview .fa-ellipsis-vertical {
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }

  /* LEFT */
  [editorLayout$="left"] {
    flex-direction: row-reverse;
  }

  /* RIGHT */
  [editorLayout$="right"] {
    flex-direction: row;
  }

  /* TOP */
  [editorlayout$="top"] {
    flex-direction: column-reverse;
  }

  /* LAYOUT DROPDOWN */
  [layout-dropdown="externalLink"] {
    margin-left: 0.75rem;
    position: relative;
    bottom: 1px;
  }

  /* THEMES: DEFAULT */
  [editorTheme="default"] {
    --editorTheme-editorPrimary: var(--background-mid);
    --editorTheme-editorSecondary: #fff;
    --editorTheme-editorTertiary: var(--color-primary-4);
    --editorTheme-primary: #1EBAED;
    --editorTheme-secondary: #78d6f4;
    --editorTheme-tertiary: #e9f8fd;
  }

  /* THEMES: DARK */
  [editorTheme="dark"] {
    --editorTheme-editorPrimary: #383838;
    --editorTheme-editorSecondary: #1e1e1e;
    --editorTheme-editorTertiary: #6d7698;
    --editorTheme-primary: #94aedf;
    --editorTheme-secondary: #6d7c99;
    --editorTheme-tertiary: #e5edfd;
  }

  /* THEMES: COBALT */
  [editorTheme="cobalt"] {
    --editorTheme-editorPrimary: #1b364e;
    --editorTheme-editorSecondary: #002240;
    --editorTheme-editorTertiary: #7b937f;
    --editorTheme-primary: #da8b0a;
    --editorTheme-secondary: #b67a13;
    --editorTheme-tertiary: #ffe8c5;
  }

  /* THEMES: GITHUB */
  [editorTheme="github"] {
    --editorTheme-editorPrimary: var(--background-mid);
    --editorTheme-editorSecondary: ghostwhite;
    --editorTheme-editorTertiary: #09885a;
    --editorTheme-primary: #0000ff;
    --editorTheme-secondary: #67a7f0;
    --editorTheme-tertiary: #f2f7ff;
  }

  /* THEMES: MONOKAI */
  [editorTheme="monokai"] {
    --editorTheme-editorPrimary: #4c4b3e;
    --editorTheme-editorSecondary: #272822;
    --editorTheme-editorTertiary: #ab5772;
    --editorTheme-primary: #cdc369;
    --editorTheme-secondary: #928c50;
    --editorTheme-tertiary: #f7f3d1;
  }

  /* THEME */
  [editorTheme] .editor {
    background: var(--editorTheme-editorSecondary);
  }
  [editorTheme] .editor__code-header {
    background: var(--editorTheme-editorTertiary);
  }
  [editorTheme] .editor__controls,
  [editorTheme] .editor__resizer {
    background: var(--editorTheme-editorPrimary);
  }
  [editorTheme] .editor__controls .el-input__inner {
    background-color: var(--editorTheme-editorSecondary);
  }
  [editorTheme] .el-button--primary,
  [editorTheme] .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: var(--editorTheme-primary);
    border-color: var(--editorTheme-primary);
  }
  [editorTheme] > .el-button--primary,
  [editorTheme] .el-checkbox-button.is-checked .el-checkbox-button__inner {
    box-shadow: -1px 0 0 0 var(--editorTheme-secondary);
  }
  [editorTheme] .el-button:hover,
  [editorTheme] .el-checkbox-button__inner:hover {
    background-color: var(--editorTheme-tertiary);;
    border-color: var(--editorTheme-primary);
    color: var(--editorTheme-secondary);
  }
  [editorTheme] .el-button-group .el-button--primary:first-child {
    border-right-color: var(--editorTheme-secondary);
  }
  [editorTheme] .el-button-group .el-button--primary:last-child {
    border-left-color: var(--editorTheme-secondary);
  }
  [editorTheme] .el-button--text {
    color: var(--editorTheme-editorTertiary)
  }
  [editorTheme] .el-input input {
    border-color: var(--editorTheme-editorPrimary);
  }

  .wrapper[editorTheme] {
    height: 100%;
  }

  /* MEDIA QUERY: ABOVE MOBILE */
  @media (max-width: 420px) {
    .editor__resizer {
      display: none;
    }
  }
</style>