<template>
  <section class="zingnetCardSection">
    <div class="zingnetCardRow">
      <!-- TrackJS -->
      <error-tracking></error-tracking>
      
      <!-- Pingdom -->
      <uptime-monitoring></uptime-monitoring>
      
    </div>
  </section>
</template>
<script>

import UptimeMonitoring from './UptimeMonitoring.vue';
import ErrorTracking from './ErrorTracking.vue';

export default {
  components: {
    ErrorTracking,
    UptimeMonitoring,
  },
}
</script>