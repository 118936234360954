<template>
  <div class="zingnetCard--column">
    <h3 class="zingnetCard--header">{{title}}</h3>
    <el-button 
      @click="_triggerSiteDeploy"
      :disabled="uiState">
      Click to Deploy<svg-icon icon="run"></svg-icon>
    </el-button>
    <hr>
    <p>Last deployed {{formatDate(lastDeploymentDate, null, 'MMMM D, YYYY')}}</p>
    <p>at {{formatDate(lastDeploymentDate, null, 'hh:mm A')}}.</p>

    <portal to="deployment-error">
      <el-alert v-if="userFeedackDisplay"
        title="Status of Github Action Request"
        :type="userFeedbackStatus"
        :description="userFeedback"
        :closable="false">
      </el-alert>
    </portal>
  </div>
</template>

<script>

import MixinDate from '../../../../mixins/date.js';
import { Octokit } from '@octokit/core';
const octokit = new Octokit({ auth: VUE_APP_GITHUB_API_TOKEN });
import SvgIcon from '../../../../components/SvgIcon.vue';

process.env.authTOKEN;
const ACTION_TIMEOUT_TIME = 2200;

export default {
  components: {
    SvgIcon,
  },
  mixins: [MixinDate],
  props: {
    branch: String,
    sitename: String,
    title: String,
  },
  data() {
    return {
      lastDeploymentDate: null,
      userFeedackDisplay: false,
      userFeedbackStatus: 'info',
      userFeedback: '',
      actionTimeout: null,
      uiState: false,
      workflowIds: {
        zingchart: {
          dev: 218016,
          master: 550840,
        },
        zinggrid: {
          dev: 182535,
          master: 184183,
        },
      }
    };
  },
  mounted() {
    this.getLastDeployment();
  },
  beforeDestroy() {
    window.clearTimeout(this.actionTimeout);
  },
  methods: {
    getLastDeployment() {
      octokit.request(`GET https://api.github.com/repos/{owner}/{repo}/actions/workflows/${this.workflowIds[this.sitename][this.branch]}/runs?per_page=1`, {
        owner: "zingsoftinc",
        repo: `${this.sitename}-com`,
      })
      .then((result) => {
        this.lastDeploymentDate = result.data.workflow_runs[0].updated_at;
      });
    },
    /**
     * @description To fire off github dispatch event to the site repository
     */
    _triggerSiteDeploy() {
      this.uiState = true;
      this.userFeedbackStatus = 'info';
      this.userFeedackDisplay = true;
      this.userFeedback = 'Sending request to dispatch action...';
      // https://github.com/octokit/core.js#readme
      octokit.request('POST https://api.github.com/repos/{owner}/{repo}/dispatches', {
        owner: "zingsoftinc",
        repo: `${this.sitename}-com`,
        event_type: `gallery-updated-${this.branch}`
      })
      .then((result) => {
        if (result.status != 204) throw new Error('Error Requesting dispatch from repository. Check network tab...');
        
        // Show success message
        this.userFeedbackStatus = 'success';
        this.userFeedackDisplay = true;
        this.uiState = true;
        this.userFeedback = 'Successfully sent action to repository! Opening action tab...';
        this.actionTimeout = setTimeout(() => {
          // Clear message
          this.userFeedackDisplay = false;
          this.uiState = false;
        }, ACTION_TIMEOUT_TIME);

        // Start progress tracking
        this.$emit('deploy', this.sitename, this.branch)
      })
      .catch((error) => {
        console.log(error);
        this.userFeedbackStatus = 'error';
        this.userFeedackDisplay = true;
        this.userFeedback = error;
        this.uiState = false;
      });
    },
  },
}
</script>

<style scoped>
  .el-button {
    margin-top: 0;
  }
  hr {
    border: 0;
    border-top: var(--zingnet-border);
    margin: 0.9375rem 0 0.625rem;
  }
  [icon="run"] {
    margin-left: 0.3125rem;
  }
  p {
    color: var(--color-primary-gray);
    font-size: 0.8125rem;
    margin: 0;
  }
  .zingnetCard--column {
    max-width: 24%;
  }
</style>