import Main from '../views/Main.vue';
import Account from '../views/account/Account.vue';
import AccountGeneral from '../views/account/General.vue';
import AccountSupport from '../views/account/Support.vue';
import AccountSubscriptions from '../views/account/Subscriptions.vue';
import AccountNotifications from '../views/account/Notifications.vue';
import Profile from '../views/account/Profile.vue';

import Demos from '../views/demos/Demos.vue';
import Create from '../views/demos/Create.vue';
import Manage from '../views/demos/Manage.vue';

import Builder from '../views/builder/Builder.vue';
import Admin from '../views/admin/Admin.vue';

import PageWarning from '../views/PageWarning.vue';

const routes = [
  {
    path: '/',
    component: Manage,
    description: 'Main',
  },
  {
    path: '/admin',
    description: 'Admin',
    component: Admin,
  },
  {
    path: '/admin/:page',
    description: 'Admin',
    component: Admin,
  },
  {
    path: '/account',
    description: 'Account',
    component: Account,
    children: [
      {
        path: '',
        component: AccountGeneral,
        description: 'General',
      },
      {
        path: 'subscriptions',
        component: AccountSubscriptions,
      },
      {
        path: 'support',
        component: AccountSupport,
        description: 'Support',
      }, {
        path: 'notifications',
        component: AccountNotifications,
        description: 'Notifications',
      },
    ],
  },
  {
    path: '/builder',
    component: Builder,
    description: 'Builder',
  },
  {
    path: '/demos',
    description: 'Demos',
    component: Demos,
    children: [
      {
        path: '',
        component: Manage,
        description: 'Manage Demos',
      },
      {
        path: 'create/:uid?',
        component: Create,
        description: 'Create Demos',
      },
    ],
  },
  {
    path: '/profile/:id',
    component: Profile,
    description: 'User Profile',
  },
  {
    path: '/forgot',
    component: Manage,
    description: 'Forgot Password',
  },
  {
    path: '/login',
    component: Manage,
    description: 'Login',
  },
  {
    path: '/logout',
    component: Manage,
    description: 'Logout',
  },
  {
    path: '/signup',
    component: Manage,
    description: 'Signup',
  },
  {
    path: '/unsuccessful',
    component: Manage,
    description: 'Unsuccessful',
  },
  {
    path: '/verify',
    component: Manage,
    description: 'Verify Email',
  },
  {
    path: '/404',
    component: PageWarning,
    description: '404 Not Found',
  },
  {
    path: '/401',
    component: PageWarning,
    description: '401 No Access',
    alias: '*',
  },
  {
    path: '/429',
    component: PageWarning,
    description: '429 Too Many Requests',
  },
  {
    path: '*',
    component: PageWarning,
    description: '404 Not Found',
    alias: '*',
  },
];

export default routes;
