<template>
    <div class="form__item">
      <div style="display: flex;">
        <label class="form__label" style="margin-right: 0.5rem">E-mail</label>
        <el-tooltip v-if="!email_verified" class="item" effect="dark" content="Resend Verification E-mail" placement="top">
          <span class="text-secondary text-secondary--warning" style="text-decoration: underline; cursor: pointer" @click="resendVerification()">( Unverified )</span>
        </el-tooltip>
      </div>
      <el-input class="form__field" v-model="email_" label="New E-mail"></el-input>
    </div>
    <!-- <el-button @click="updateEmail" type="primary" :disabled="!validEmail">Update</el-button> -->
</template>

<script>
export default {
  props: {
    value: String,
  },
  data() {
    return {
      email_: null,
      email_verified_: null,
      init: true,
      dirty: false,
    }
  },
  mounted() {
    this.email_verified_ = this.$store.state.user.email_verified;
    this.email_ = this.value;
  },
  watch: {
    email_verified(value, old) {
      this.email_verified_ = value;
    },
    email(value) {
      this.email_ = value;
      this.dirty = false;
    },
    email_(value) {
      // Handles an init call.
      if (this.init) {
        this.init = false;
      // Handles changes by the store mutating the event.
      } else if (!this.dirty)  {
        this.dirty = true;
      }
      // If the field was modified by user entry.
      this.$emit('change', this.email_);
    }
  },
  computed: {
    validEmail() {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email_);
    },
    email() {
      return this.value;
    },
    email_verified() {
      return this.$store.state.user.email_verified;
    }
  },
  methods: {
    resendVerification() {
      this.$emit('verify');
    },
    updateEmail() {
      this.$emit('update', this.email_);
    },
  }
}
</script>

<style scoped>
  .text-secondary {
    color: var(--color-greyscale-2);
    font-size: 0.8rem;
    position: relative;
    top: 0.2rem;
  }
  .text-secondary--warning { color: var(--color-fail); }
</style>