<template>
  <zingnet-card>
    <span slot="header">Test Coverage</span>
    <span slot="header__right"><el-button @click="openTestCoverage">View in CodeClimate</el-button></span>
    <div slot="body">
      <section class="zingnetCard--row">
        <section class="zingnetCard--column">
          <div id='chart-testCoverage'></div>
        </section>

        <section class="zingnetCard--column">
          <zing-grid
            rounded-borders
            :data='JSON.stringify(grid_codeClimate)'
            layout='row'
            layout-controls='disabled'
            selector
            sort>
          </zing-grid>
        </section>
      </section>
    </div>
  </zingnet-card>
</template>

<script>

import axios from 'axios';
import ZingnetCard from '../ZingnetCard.vue';

export default {
  components: {
    ZingnetCard,
  },
  data() {
    return {
      // Test Coverage
      chart_testCoverage: {
        type: 'ring',
        labels: [{
          text: 'Proportion of Code Tested',
          fontColor: 'var(--color-tertiary-blue)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '15px',
          fontWeight: 600,
          x: 0,
          y: '0'
        }, {
          text: '90.42% tested',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '14px',
          fontWeight: 500,
          x: 0,
          y: '12%'
        }, {
          text: '9.58% tested',
          fontColor: 'var(--color-secondary-blue-active)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '14px',
          fontWeight: 500,
          x: 0,
          y: '18%'
        }, {
          text: '_________________',
          fontColor: 'var(--zingnet-border-color)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '15px',
          fontWeight: 500,
          x: 0,
          y: '21%'
        }, {
          text: 'in last 7 days',
          fontColor: 'var(--color-primary-gray)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '13px',
          fontWeight: 400,
          x: 0,
          y: '28%'
        }, {
          text: 'vs',
          fontColor: 'var(--color-tertiary-blue-visited)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '13px',
          fontWeight: 400,
          x: 0,
          y: '40%'
        }, {
          text: '89.42% tested',
          fontColor: 'var(--color-primary-gray-visited)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '13px',
          fontWeight: 600,
          x: 0,
          y: '52%'
        }, {
          text: '8.58% untested',
          fontColor: 'var(--color-primary-gray-visited)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '13px',
          fontWeight: 600,
          x: 0,
          y: '58%'
        }, {
          text: '_________________',
          fontColor: 'var(--zingnet-border-color)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '15px',
          fontWeight: 600,
          x: 0,
          y: '61%'
        }, {
          text: 'in previous 7 days',
          fontColor: 'var(--color-primary-gray)',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSize: '13px',
          fontWeight: 400,
          x: 0,
          y: '68%'
        }],
        plot: {
          offsetY: '-20px',
          refAngle: 50,
          slice: '88%',
          valueBox: [{
            text: '90.42%',
            placement: 'center',
            fontColor: 'var(--color-secondary-blue)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: 28,
            fontWeight: '100',
            offsetY: '-5px',
          }, {
            text: 'code tested',
            placement: 'center',
            fontColor: 'var(--color-secondary-blue)',
            fontFamily: 'Nunito Sans, sans-serif',
            fontSize: 13,
            fontWeight: '200',
            offsetY: '17px',
          }],
        },
        plotarea: {
          marginLeft: '30%',
        },
        series: [
          {
            values: [9.58],
            backgroundColor: 'var(--color-secondary-blue-active)',
            text: 'untested',
          },
          {
            values: [90.42],
            backgroundColor: 'var(--color-secondary-blue)',
            text: 'tested',
          },
        ]
      },
      grid_codeClimate: [{
        metric: 'Lines of Code',
        value: 'n/a'
      }, {
        metric: 'Tested Files',
        value: 'n/a'
      }, {
        metric: 'Complexity',
        value: 'n/a'
      }, {
        metric: 'Duplication',
        value: 'n/a'
      }],
    }
  },

  mounted() {
    this.instantiateChart('chart-testCoverage', this.chart_testCoverage, '300px', '100%');
    this.getCodeClimateData();
  },

  methods: {
    async getCodeClimateData() {
      // Get test report id and lines of code
      // TODO Code climate for library removed => no data returned from endpoint
      let testReportId = null // await this._getTestReportId();

      if (testReportId) {
        // Get screenshot id
        let snapshotId = await this._getSnapshotId(testReportId);

        // Number of tested files
        await this._getNumTestFiles(testReportId);

        // Complexity
        await this._getComplexity(snapshotId);

        // Duplication
        await this._getDuplication(snapshotId);
      };
    },
    instantiateChart(id, data, height, width) {
      zingchart.render({id, data, height, width});
    },
    openTestCoverage() {
      window.open('https://codeclimate.com/dashboard');
    },
    setSidebar(id, val) {
      document.getElementById(id).textContent = val;
    },

    _codeClimateRequest(url, cb) {
      return new Promise((resolve, reject) => {
        axios({
          url: `https://api.codeclimate.com/v1/repos/${VUE_APP_CODE_CLIMATE_REPO_ID}${url}`,
          method: 'GET',
          headers: {
            'Authorization': `Token token=${VUE_APP_CODE_CLIMATE_API}`,
            'Accept': 'application/vnd.api+json',
          },
        }).then((response) => {
          cb(response, resolve, reject);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    _getComplexity(snapshotId) {
      let url = `/snapshots/${snapshotId}/issues?filter[categories]=Complexity&page[size]=1`;
      let fn = (response, resolve) => {
        let complexity = response.data.meta.total_count;
        this.grid_codeClimate[2].value = complexity;
        this.setSidebar('zingNet_codeClimate_complexity', complexity);
        resolve();
      };
      return this._codeClimateRequest(url, fn);
    },
    _getDuplication(snapshotId) {
      let url = `/snapshots/${snapshotId}/issues?filter[categories]=Duplication&page[size]=1`;
      let fn = (response, resolve) => {
        let duplication = response.data.meta.total_count;
        this.grid_codeClimate[3].value = duplication;
        this.setSidebar('zingNet_codeClimate_duplication', duplication);
        resolve();
      };
      return this._codeClimateRequest(url, fn);
    },
    _getSnapshotId(testReportId) {
      let url = '';
      let fn = (response, resolve) => {
        resolve(response.data.data.relationships.latest_default_branch_snapshot.data.id);
      };
      return this._codeClimateRequest(url, fn);
    },
    _getNumTestFiles(testReportId) {
      let url = `/test_reports/${testReportId}/test_file_reports?page[size]=1`;
      let fn = (response, resolve) => {
        // Get number of files
        let lastLink = response.data.links.last;
        let numFiles = lastLink.split('&')[0].split('=')[1];
        // Set to data object
        this.grid_codeClimate[1].value = numFiles;
        this.setSidebar('zingNet_codeClimate_files', numFiles);
        resolve(numFiles);
      };
      return this._codeClimateRequest(url, fn);
    },
    _getTestReportId() {
      let url = `/test_reports?page[size]=1`;
      let fn = (response, resolve, reject) => {
        // Lines of code
        if (response.data && response.data.data && response.data.data[0]) {
          let linesOfCode = response.data.data[0].attributes.lines_of_code;
          this.grid_codeClimate[0].value = linesOfCode;
          this.setSidebar('zingNet_codeClimate_linesOfCode', linesOfCode);
          // Test report id
          resolve(response.data.data[0].id);
        } else {
          reject('Unable to to retrieve test coverage report');
        }
      };
      return this._codeClimateRequest(url, fn);
    },
  },
}
</script>