<template>
  <secondary-layout :isAuthing="isAuthing" >
    <section class="content__wrap content__wrap--login">
      <div class="content content--narrow">

        <!-- Display bar if redirect from site pricing page -->
        <div class="purchaseNotification" v-if="planId && planInterval">
          Please login or sign up to continue your purchase.
        </div>

        <!-- Signup container -->
        <entry-container>
          <h3 slot="entryHeader">Create An Account</h3>

          <div slot="entryBody">
            <div>
              <google-entry :regenerate-session="regenerateSession">Continue with Google</google-entry>
            </div>
            <p class="margin--small--laptop">or</p>

            <el-form @submit.native.prevent :model="form" ref="signupForm" signup-form>
              <input-email form="form" v-model="form.email" @validate="validateForm('email')"></input-email>
              <el-popover ref="popover" trigger="focus"
                class="tooltip_validate" effect="dark" :placement="validationPlacement">
                <div>
                  Password must:
                  <ul class="password-validation">
                    <li class="password" v-bind:class="validClass('atLeast8Chars')"><font-awesome-icon :icon="validIcon('atLeast8Chars')"/> Be a minimum of 8 characters</li>
                    <li class="password" v-bind:class="validClass('atLeast1Lower')"><font-awesome-icon :icon="validIcon('atLeast1Lower')"/> Contain at least lower case letter</li>
                    <li class="password" v-bind:class="validClass('atLeast1Upper')"><font-awesome-icon :icon="validIcon('atLeast1Upper')"/> Contain at least 1 upper case letter</li>
                    <li class="password" v-bind:class="validClass('atLeast1Number')"><font-awesome-icon :icon="validIcon('atLeast1Number')"/> Contain at least 1 number</li>
                    <li class="password" v-bind:class="validClass('notEmail')"><font-awesome-icon :icon="validIcon('notEmail')"/> Not match email</li>
                  </ul>
                </div> 
              </el-popover>
              <div :error="passwordError">
                <el-form-item
                  prop="password"
                  :rules="[
                    { required: true, message: 'Please input password', trigger: 'blur' },
                    { validator: validateLength, trigger: 'blur' },
                    { validator: validateUpper, trigger: 'blur' },
                    { validator: validateLower, trigger: 'blur' },
                    { validator: validateNumber, trigger: 'blur' },
                    { validator: validateNotEmail, trigger: 'blur' },
                  ]">
                  <el-input @blur="validateForm('password')" v-model="form.password" tabindex=2 type="password" placeholder="Create a password" v-popover:popover>
                    <template slot="prepend">
                      <svg width="11" height="14" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg" class="auth0-lock-icon auth0-lock-icon-box"><path d="M11 15.998H2a2 2 0 0 1-2-1.999v-6c0-1.104.896-2.001 2-2.001V4.499a4.501 4.501 0 0 1 9 0v1.5a2 2 0 0 1 2 2v6a2 2 0 0 1-2 1.999zM10 4.499a3.5 3.5 0 1 0-7 0v1.499h7V4.499zm2 3.5a1 1 0 0 0-1-1.001H2a1 1 0 0 0-1 1.001v6c0 .552.447.999 1 .999h9a1 1 0 0 0 1-.999v-6zm-5.5 4.999a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 1 0v3a.5.5 0 0 1-.5.5z" fill-rule="evenodd"/></svg>
                    </template>
                  </el-input>
                </el-form-item>
              </div>
              <div :error="passwordConfirmError">
                <el-form-item
                  prop="confirm"
                  :rules="[
                    { required: true, message: 'Please input password', trigger: 'blur' },
                    { validator: validateConfirm, trigger: 'blur' }
                  ]">
                  <el-input v-model="form.confirm" @blur="validateForm('confirm')" @keyup.enter.native="signup" tabindex=3 type="password" placeholder="Confirm your password">
                    <template slot="prepend">
                      <svg width="11" height="14" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg" class="auth0-lock-icon auth0-lock-icon-box"><path d="M11 15.998H2a2 2 0 0 1-2-1.999v-6c0-1.104.896-2.001 2-2.001V4.499a4.501 4.501 0 0 1 9 0v1.5a2 2 0 0 1 2 2v6a2 2 0 0 1-2 1.999zM10 4.499a3.5 3.5 0 1 0-7 0v1.499h7V4.499zm2 3.5a1 1 0 0 0-1-1.001H2a1 1 0 0 0-1 1.001v6c0 .552.447.999 1 .999h9a1 1 0 0 0 1-.999v-6zm-5.5 4.999a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 1 0v3a.5.5 0 0 1-.5.5z" fill-rule="evenodd"/></svg>
                    </template>
                  </el-input>
                </el-form-item>
              </div>


              <el-form-item>
                <el-button
                  @click="signup"
                  type="primary"
                  class="button button--bottom button--center"
                  :disabled="!validSignup"
                  :loading="creatingAccount">
                  Sign Up
                </el-button>
              </el-form-item>
            </el-form>

            <p class="margin--small--laptop  p--terms">
              By creating an account you agree to the
              <a class="term_link" href="https://www.zinggrid.com/legal">Terms of Service</a>
              and 
              <a class="term_link" href="https://www.zinggrid.com/privacy">Privacy Policy</a>
            </p>

          </div>
        </entry-container>

        <!-- Signup text -->
        <div class="signup__container">
          <p class="margin--small--laptop">
            Already have an account?
            <span @click="passQueryParam(true)">
              <span class="signup__link">Log In.</span>
            </span>
          </p>
        </div>

      </div>
    </section>
  </secondary-layout>
</template>

<script>
  // IMPORT
  // ----------------------------------
  import MixinEntry from '../../mixins/entryMain.js';

  // EXPORT
  // ----------------------------------
  export default {
    mixins: [MixinEntry],
    data() {
      return {
        creatingAccount: false,
        form: {
          email: '',
          password: '',
          confirm: '',
        },
        passwordError: false,
        passwordConfirmError: false,
        // display tooltip left on desktop and top on mobile
        validationPlacement: window.innerWidth < 1024 ? 'top-start' : 'left-start',
        validated: false,
      };
    },
    computed: {
      atLeast8Chars() {
        return this.form.password && this.form.password.length >= 8;
      },
      atLeast1Upper() {
        return /[A-Z]/.test(this.form.password);
      },
      atLeast1Lower() {
        return /[a-z]/.test(this.form.password);
      },
      atLeast1Number() {
        return /[0-9]/.test(this.form.password);
      },
      passwordDontMatch() {
        return this.form.password !== this.form.confirm;
      },
      validPassword() {
        return this.atLeast8Chars && this.atLeast1Upper && this.atLeast1Lower && this.atLeast1Number;
      },
      notEmail() {
        return this.form.password !== this.form.email && this.form.password.toLowerCase() !== this.form.email.toLowerCase();
      },
      validSignup() {
        return this.validated;
      }
    },
    mounted() {
      this.redirectAfterLogin();
    },
    methods: {
      /**
       * @description Signs up user who does this the normal (non-Google-Auth) process. Before account creation,
       * validates email and password fields.
       * @param {Object} e - native event object
       */
      signup(e) {
        // Update button state
        this.creatingAccount = true;

        // stop form default and perform our own validation
        e.preventDefault();

        // Check if disabled
        if (!this.validSignup) {
          this.$message({
            duration: 10000,
            message: 'Please fill in all fields.',
            showClose: true,
            type: 'error',
          });
          this.creatingAccount = false;
          return;
        }

        // Validate array from form
        let hasError = this.$el.querySelectorAll('.el-form-item.is-error');

        // invalid form field
        if (hasError.length > 0) {
          this.$message({
            duration: 10000,
            message: 'Please fill in all fields correctly.',
            showClose: true,
            type: 'error',
          });
          this.creatingAccount = false;
          // stop submission
          return;
        };

        // invalid password
        if (!this.validPassword) {
          this.passwordError = true;
          this.$message({
            duration: 10000,
            message: 'Invalid Password',
            showClose: true,
            type: 'error',
          });
          this.creatingAccount = false;
          // stop submission
          return;
        }

        // passwords don't match
        if (this.passwordDontMatch) {
          this.passwordConfirmError = true;
          this.$message({
            duration: 10000,
            message: 'Passwords do not match!',
            showClose: true,
            type: 'error',
          });
          this.creatingAccount = false;
          // stop submission
          return;
        };

        // reset error states if we reached this far
        this.passwordError = false;
        this.passwordConfirmError = false;

        // Toggle localStorage 'auth' state
        this.setAuthIsAuthenticatingLocalStorage();
        this.setAuthIsNewSignupLocalStorage();
        
        // Signup
        this.$store.dispatch('auth/signup', {
          email: this.form.email,
          password: this.form.password,
        });
      },

      update(type, val) {
        this.form[type] = val;
      },

      validateConfirm(rule, value, callback) {
        if (this.passwordDontMatch) {
          callback(new Error('Passwords must match'));
        } else {
          callback();
        }
      },

      validateLength(rule, value, callback) {
        if (this.atLeast8Chars) {
          callback();
        } else {
          callback(new Error('Password must be a minimum of 8 characters'));
        }
      },

      validateUpper(rule, value, callback) {
        if (this.atLeast1Upper) {
          callback();
        } else {
          callback(new Error('Password must contain at least 1 upper case letter'));
        }
      },

      validateLower(rule, value, callback) {
        if (this.atLeast1Lower) {
          callback();
        } else {
          callback(new Error('Password must contain at least 1 lower case letter'));
        }
      },

      validateNumber(rule, value, callback) {
        if (this.atLeast1Number) {
          callback();
        } else {
          callback(new Error('Password must contain at least 1 number'));
        }
      },


      validateNotEmail(rule, value, callback) {
        if (this.notEmail) {
          callback();
        } else {
          callback(new Error('Password must not be the same as email'));
        }
      },

      validateForm(type) {
        if (type && (this.form.email.length === 0 || this.form.password.length === 0 || this.form.confirm.length === 0)) {
          this.$refs.signupForm.validateField(type);
          this.validated = false;
        } else {
          this.$refs.signupForm.validate((valid) => {
            this.validated = valid;
          });
        }
      },

      /**
       * @description Checks if password entered is valid and return which password validity check passes
       * @param {String} target - which type of password validity is checked
       */
      validClass(target) {
        if (this.form.password && this.form.password.length === 0) return '';
        // else
        return (this[target]) ? 'password--valid' : 'password--invalid';
      },

      /**
       * @description Returns icon representing if password entered passes validity check
       * @param {String} target - which type of password validity is checked
       */
      validIcon(target) {
        if (this.newPassword && this.newPassword.length === 0) {
          return ['fas', 'check'];
        } else {
          return ['fas', (this[target])? 'check' : 'times'];
        }
      },
    }
  }
</script>

<style>
  /* element ui imported globally and needs access to global CSS */
  #app-shell > section > div.background--grid_wrap > div > div.content_wrapper > section > div > div:nth-child(1) > div:nth-child(3) > section > div > form > div.el-form-item.is-required > div > div
  div[error] input { border:1px solid red; }

  @media (max-width: 800px) {
    .signup__container { margin-top: 0; width: var(--container-width--slim) !important; }
  }
</style>

<style lang="css" scoped>
  [entryContainer] .button--bottom { background: var(--color-tertiary-1); border-radius: var(--border-radius); font-size: 0.8125rem; height: 2.5rem; line-height: 1.5rem; width: 7.813rem; }
  [entryContainer] .button--bottom.button--center { margin: 0 auto; }
  [entryContainer] .button--bottom:hover { background: var(--color-tertiary-4); }
  [entryContainer] [disabled].button--bottom { background: var(--color-primary-5); border-color: var(--color-primary-5); cursor: not-allowed; }

  .content { text-align: center; }

  .el-form svg { position: relative; top: 2px; width: 1rem; }

  .el-form-item, [entryContainer="entry__actions"] .el-input:last-of-type { margin-bottom: 1rem; }
  .el-input-group__prepend { background: var(--color-greyscale-9); }

  [entryContainer="entry__actions"] div div:last-of-type .el-input { margin-bottom: 0.5rem; }

  .text--gray { color: var(--color-greyscale-8); }

  .link { font-size: 0.85rem; }
  .link:hover { color: var(--color-greyscale-7); }

  .text--gray { text-decoration: none; }
  .underline { text-decoration: underline; }
  /* Mobile Overwrites */

  @media screen and (max-height: 900px) {
    .signup__container { margin: 1rem auto; }
  }
</style>