<template>
  <div studio-editor="container">
    <div studio-editor="controls">
      <!-- Copy option -->
      <el-popover
        placement="bottom"
        trigger="click"
        content="Copied!"
        popper-class="studioEditor_popoverCopy">
        <el-button @click="codeCopy" type="text" size="mini" slot="reference">Copy</el-button>
      </el-popover>
      <!-- Fold option -->
      <el-button @click="codeFold" type="text" size="mini">{{toggleFold}}</el-button>
    </div>
    <!-- Temporary copy -->
    <textarea studio-editor="clipboard"></textarea>
    <!-- Monaco editor container -->
    <div ref="editor" class="editor"></div>
    <input type="hidden" :customAttribute="options"/>
  </div>
</template>

<script>
import axios from 'axios';
import * as monaco from 'monaco-editor';
import beautify from 'js-beautify';

export default {
  props: {
    id: String,
    autocomplete: Boolean,
    code: [String, Boolean],
    language: String,
    fontFamily: String,
    fontSize: String,
    theme: String,
  },
  data() {
    return {
      // REFERENCES
      clipboard: null,
      monaco: null, // Reference to monaco editor
      proxy: null,

      // MAPPINGS
      // Maps theme label to theme file name
      assetMap: {
        cobalt: 'Cobalt',
        monokai: 'Monokai',
        github: 'GitHub',
      },
      // Maps theme label to theme name
      themeMap: {
        default: 'vs',
        dark: 'vs-dark',
      },

      // THEMES
      default: 'default',
      dark: 'default',
      monokai: null,
      github: null,

      // CURRENT
      curFold: false,
      toggleFold: 'Collapse',

      // FLAGS
      loadCode: false,
    }
  },
  computed: {
    /**
     * @description Monaco editor options
     */
    options() {
      let editorOptions = {
        automaticLayout: true,
        contenteditable: true,
        fontSize: `${this.fontSize}px`,
        fontFamily: this.fontFamily,
        fixedOverflowWidgets: true,
        language: this.language,
        lineNumbers: 'on',
        lineNumbersMinChars: 4,
        minimap: {
          enabled: false
        },
        selectOnLineNumbers: true,
        tabSize: 2,
        theme: this.themeMap[this.theme] || this.theme,
        value: typeof this.code === 'boolean' ? '' : this.code,
        wordWrap: 'off',

        // autocomplete-related
        acceptSuggestionOnEnter: this.autocomplete,
        acceptSuggestionOnCommitCharacter: this.autocomplete,
        quickSuggestions: this.autocomplete,
        roundedSelection: this.autocomplete,
        snippetSuggestions: this.autocomplete,
        suggestOnTriggerCharacters: this.autocomplete,
        wordBasedSuggestions: this.autocomplete,
      };

      // Requires delay because `this.monaco` changes alot on render and triggers infinite loop
      this.$nextTick(() => {
        if (this.monaco) this.monaco.updateOptions(editorOptions);
      });
      return editorOptions;
    },
  },
  watch: {
    /**
     * @description Update editor content on first time load. Set flag to prevent from triggerring again.
     * This only triggers if `code !== ''`. In this case, this code is manually triggered in the setup.
     * @param {String} val - code
     */
    code(val) {
      if (!this.loadCode && typeof val === 'string') {
        this.loadCode = true;
        this.monaco.setValue(val);
      };
    },
    /**
     * @description Fetch themes and define in editor.
     */
    theme(val) {
      if (!this[val]) {
        // Fetch themes
        axios(`/assets/themes/${this.assetMap[val]}.json`)
          .then((data) => {
            // Define and set theme in editor
            this[val] = data.data;
            monaco.editor.defineTheme(val, this[val]);
            monaco.editor.setTheme(val);
          })
      } else if (this[val] !== 'default') {
        // Sets defined theme. For default themes, 'setTheme()' does not need to be called
        monaco.editor.setTheme(val);
      }
    }
  },
  mounted() {
    // References
    this.clipboard = this.$el.querySelector('[studio-editor="clipboard"]');
    
    // Add library TDF
    this.addTDFs();

    // Add autocomplete
    this.addAutocomplete();

    // Create and setup monaco editor
    this.monaco = monaco.editor.create(this.$refs.editor, this.options);

    // Get reference to proxy (JavaScript only)
    this.getProxy();
    
    // Setup the rest of the editor
    this.setup();
  },
  destroyed() {
    monaco.editor.getModels().forEach(model => model.dispose());
  },
  methods: {
    /**
     * @description Helper function to add autocomplete data for ZingGrid HTML and CSS
     */
    _addAutocomplete(language) {
      // Fetch docs
      axios({
        url: language === 'css' ? 'https://cdn.zinggrid.com/docs/elements/css/css.json' : 'https://cdn.zinggrid.com/docs/elements/members/members.json',
        method: 'GET'
      }).then((res) => {
        // Format data provider
        let dataProvider = language === 'css' ? this.formatCssDataProvider(res.data) : this.formatHtmlDataProvider(res.data);

        // Add data provider
        monaco.languages[language][`${language}Defaults`].setOptions({
          data: {
            useDefaultDataProvider: true,
            dataProviders: [dataProvider]
          },
        });
      });
    },
    /**
     * @description Add autocomplete data for ZingGrid HTML and CSS
     */
    addAutocomplete() {
      let language = this.options.language;
      if (language === 'css' || language === 'html') {
        this._addAutocomplete(language);
      };
    },
    /**
     * @description Add TDFs for ZingChart and ZingGrid library
     */
    addTDFs() {
      let isJavascript = this.options.language === 'javascript';
      let tdfAdded = Object.keys(monaco.languages.typescript.javascriptDefaults._extraLibs).length;

      // Add TDF once
      if (isJavascript && tdfAdded === 0) {
        // Enable validation
        monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
          noSemanticValidation: false,
          noSyntaxValidation: false,
        });

        // Set compiler options
        monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
          target: monaco.languages.typescript.ScriptTarget.ES2015,
          allowNonTsExtensions: true,
          allowJS: true,
          checkJS: true
        });
        
        // Fetch TDFs
        let zingchartTDF = null;
        axios({
          url: '/api/asset/tdf',
          method: 'GET',
          headers: { 'Authorization': `Bearer ${this.$store.state.auth.idToken}` },
        }).then((res) => {
          zingchartTDF = res.data;
          axios({
            url: 'https://cdn.zinggrid.com/index.d.ts',
            method: 'GET'
          }).then((response) => {
            // Add TDFs
            let libSource = response.data + zingchartTDF;
            let libUri = 'ts:filename/tdf.d.ts';
            monaco.languages.typescript.javascriptDefaults.addExtraLib(libSource, libUri);

            // Create model
            monaco.editor.createModel(libSource, 'typescript', monaco.Uri.parse(libUri));
          });
        });
      }
    },

    /**
     * @description Copies code editor content
     */
    codeCopy() {
      this.clipboard.value = this.monaco.getValue();
      this.clipboard.select();
      document.execCommand('copy');
    },
    /**
     * @description Toggles code folding
     */
    codeFold() {
      // Toggle code fold
      if (this.curFold) this.monaco.trigger('fold', 'editor.unfoldAll');
      else this.monaco.trigger('fold', 'editor.foldAll');
      // Update state
      this.curFold = !this.curFold;
      // Update text
      this.toggleFold = this.curFold ? 'Expand' : 'Collapse';
    },
    /**
     * @description Format API docs to CSS data provider
     */
    formatCssDataProvider(docs) {
      let dataProvider = { version: '1.1', properties: [] };
      for (let i = 0; i < docs.length; i++) {
        dataProvider.properties.push({
          name: docs[i].name,
          description: docs[i].description,
          references: [{
            name: 'Demo Reference',
            url: docs[i].demoLink,
          }, {
            name: 'CSS Reference',
            url: docs[i].cssRefLink,
          }]
        });
      };
      return dataProvider;
    },
    /**
     * @description Converts ZingGrid name to kebab-case
     */
    convertToZGKebab(tagName) {
      // edge case for main zing-grid tag
      if (tagName === 'ZingGrid') return 'zing-grid';
      let tmpStr = tagName.split('ZG'); // split after zg e.g) ZGColum
      tmpStr = tmpStr[1].replace(/([A-Z])/g, '-$1').toLowerCase();
      return `zg${tmpStr}`;
    },
    /**
     * @description Format API docs to HTML data provider
     */
    formatHtmlDataProvider(docs) {
      let dataProvider = { version: '1.1', tags: [] };
      for (let el in docs) {
        let kebabFormat = this.convertToZGKebab(el);
        let tag = {
          name: kebabFormat,
          description: docs[el].elementDescription,
          references: [{
            name: 'Docs Reference',
            url: `https://www.zinggrid.com/docs/api/tags/${kebabFormat}`,
          }],
          attributes: [],
        };

        for (let i = 0; i < docs[el].members.length; i++) {
          let _docs = docs[el].members[i];
          tag.attributes.push({
            name: _docs.name,
            description: _docs.description,
            values: (_docs.displayValues || []).map((val) => {
              return {
                name: val.replace(/\"/g, ''),
              }
            }),
            references: [{
              name: 'Docs Reference',
              url: `https://www.zinggrid.com/docs/api/tags/${kebabFormat}#${_docs.name}`,
            }, {
              name: 'Demo Reference',
              url: _docs.demoLink,
            }],
          });
        };

        dataProvider.tags.push(tag);
      };
      return dataProvider;
    },
    /**
     * @description Get reference to editor proxy.
     * This is for the case where a user uses Typescript and the code needs to be
     * compiled down to JavaScript to preview the demo.
     */
    getProxy() {
      if (this.monaco && this.monaco.getModel() && this.options.language === 'javascript') {
        monaco.languages.typescript.getJavaScriptWorker()
          .then((worker) => {
            // Check again b/c Studio reloads with user is logged in
            // The original load will attempt to get `uri`, but `this.monaco` would be null
            // The second load will properly get reference to the proxy
            if (this.monaco && this.monaco.getModel()) {
              worker(this.monaco.getModel().uri)
                .then((proxy) => {
                  this.proxy = proxy;
                });
            };
          });
      }
    },
    /**
     * @description Setup editor by listening for "change" event to update "code" value
     */
    setup() {
      this.monaco.onDidChangeModelContent((e) => {
        this.updateCode();
      });
    },
    /**
     * @description Triggered on editor code change to emit event to allow parent component to
     * get updated editor value (content)
     */
    updateCode() {
      // Get code from editor
      let changes = this.monaco.getValue();
      this.$nextTick(() => {
        // For JavaScript (make sure TS compiled to JS)
        if (this.proxy && this.monaco && this.monaco.getModel()) {
          this.proxy.getEmitOutput(this.monaco.getModel().uri.toString())
            .then((r) => { 
              changes = beautify(r.outputFiles[0].text, { indent_size: 2 });
              this.$emit('update', this.language, changes);
            });
        } else {
          // Update code
          this.$emit('update', this.language, changes);
        };
      });
    },
    /**
     * @description Update code in editor pane. Call this method when code change occurs
     * programatically (ex. library asset update) and not by a user typing in editor.
     * @param {String} val - code
     */
    updateEditorCode(val) {
      this.monaco.setValue(val);
    },
  }
}
</script>

<style scoped>
.editor,
[studio-editor="container"] {
  height: 100%;
  width: 100%;
}

[studio-editor="clipboard"] {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
[studio-editor="container"] {
  position: relative;
}
[studio-editor="controls"] {
  opacity: 0.5;
  position: absolute;
  top: -3px;
  right: 15px;
  z-index: 10;
}
[studio-editor="controls"]:hover {
  opacity: 1;
}
[studio-editor="controls"] > * {
  margin-left: 5px;
}
[studio-editor="controls"] .el-button {
  padding: 2px 7px;
}
</style>

<style>
.studioEditor_popoverCopy {
  min-width: 90px;
  padding: 10px 0;
  text-align: center;
}
</style>
