<template>
  <div class="filter_wrapper">
    <el-select :show="authenticated" :value="selected" @change="updateSelected">
      <el-option v-for="option in defaultOptions" :key="option.id" :label="option.name" :value="option.id">
        <div class="filter__option">
          <div class="filter__name">{{option.name}}</div>
          <!-- <div class="filter__count">{{getCount(option)}}</div> -->
          <!-- <div class="filter__count">10</div> -->
        </div>
      </el-option>
      <el-option-group v-if="options.length > 0" label="Groups">
        <el-option
          v-for="group in options"
          :key="group.id"
          :label="group.name"
          :value="group.id">
          <div class="filter__option">
            <div class="filter__name">{{group.name}}</div>
            <div class="filter__count">{{getCount(group)}}</div>
          </div>
        </el-option>
      </el-option-group>
    </el-select>
    <svg-icon icon="close--circle" height="1rem" width="1rem"></svg-icon>
    <svg-icon class="el-input-icon" icon="angle"></svg-icon>
  </div>
</template>

<script>
  import SvgIcon from './SvgIcon.vue';

  export default {
    components: {SvgIcon},
    props: ['defaultOptions', 'getCount', 'groupBy', 'options', 'selected'],
    computed: {
      authenticated() {
        if (!this.authSetup) {
          this.authSetup = true;
          const auth = this.$store.state.user['user_id'];
          return this.$store.state.user['user_id'];
        }
      },
    },
    methods: {
      /**
       * @description Triggered on change to notify `DemoViewer.vue` that `this.group` value should
       * update to new value
       * @param {String} value - value to update to
       */
      updateSelected(value) {
        // Update group results
        this.$emit('group', value);
      },
    },
  }
</script>

<style src="../assets/styles/filterSelector.css" scoped></style>

<style>
  .filter_wrapper [icon="close--circle"] svg {
    position: relative;
    top: -6px;
  }
</style>
