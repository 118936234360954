export default {
  props: ['allowCreate', 'clearable', 'collapsible', 'defaultOptions', 'defaultValue', 'filterable', 'getCount', 'multiple', 'options', 'placeholder', 'prefix', 'scrollable'],
  data() {
    return {
      authSetup: false,
      clearableIcon: null,
      prefixText: null,
      selected: null,
    };
  },
  computed: {
    authenticated() {
      if (!this.authSetup) {
        this.authSetup = true;
        const auth = this.$store.state.user['user_id'];
        if (auth) this.$emit('authenticated');
        return this.$store.state.user['user_id'];
      }
      return true;
    },
  },
  watch: {
    selected(value) {
      // Update filter results
      this.$emit('filterBy', value);
      // Display 'clearable' icon if value is non-null
      if (value) this.clearableIcon.classList.add('active');
      else this.clearableIcon.classList.remove('active');
    },
  },
  mounted() {
    // Save reference
    this.clearableIcon = this.$el.querySelector('[icon="close--circle"]');

    this.setPrefixText();
    
    this.setDefaultValue();
  },
  methods: {
    clearFilter() {
      this.selected = null;
    },
    setDefaultValue() {
      if (this.defaultValue) this.selected = this.defaultValue;
    },
    setPrefixText() {
      if (this.prefix) {
        switch(this.prefix) {
          case 'metadata': this.prefixText = 'Filter By Metadata: '; break;
          case 'metadataValue': this.prefixText = 'Filter By Metadata Value: '; break;
        }
      }
    },
  }
}