<template>
  <!-- Scroll Checkbox -->
  <label v-if="type === 'scroll'" id="toggleCheck" data-field="checkbox" zing-theme-builder="toggle-check">
    <el-checkbox @change="_inputChecked" v-model="checkVal" zing-theme-builder="check"></el-checkbox>
    <span zing-theme-builder="toggle-label">{{checkVal ? "Scroll Enabled" : "Scroll Disabled"}}</span>
  </label>
  <!-- Guide Checkbox -->
  <label v-else-if="type === 'guide'" id="guideCheck" data-field="checkbox" zing-theme-builder="toggle-check">
    <el-checkbox @change="_inputChecked" v-model="checkVal" zing-theme-builder="check"></el-checkbox>
    <span zing-theme-builder="toggle-label">{{checkVal ? "Guide Enabled" : "Guide Disabled"}}</span>
  </label>
  <!-- Feature Checkall Checkbox -->
  <label v-else-if="type === 'checkall'" id="featureCheck" data-field="checkbox" zing-theme-builder="toggle-check">
    <el-checkbox @change="_inputChecked" v-model="checkVal" zing-theme-builder="check"></el-checkbox>
    <span zing-theme-builder="toggle-label">{{checkVal ? "Check All" : "Uncheck All"}}</span>
  </label>
  <!-- Control Checkbox -->
  <el-checkbox v-else @change="_inputChecked" v-model="checkVal" zing-theme-builder="check"></el-checkbox>
</template>

<script>
export default {
  name: 'FormCheckbox',
  props: {
    checked: Boolean,
    type: String
  },
  data: function() {
    return {
      classCheck: 'is-checked',
      classSelected: 'selected',
      $builder: null,
      $checkbox: null,
      checkVal: null
    }
  },
  mounted: function() {
    // Set $checkbox to checkbox
    switch (this.type) {
      case 'scroll': this.$checkbox = document.querySelector('#toggleCheck [role="checkbox"]'); break;
      case 'guide': this.$checkbox = document.querySelector('#guideCheck [role="checkbox"]'); break;
      default: this.$checkbox = this; this.$input = this.$checkbox._vnode.elm; break;
    }
    // Set data variable to prop
    this.checkVal = this.checked;
    this.$builder = this.$parent.$parent.$parent;
    this.init();
  },
  methods: {
    /**
     * @description Initializes input by setting default value and checking if type is checkbox
     */
    init() {
      if (!this.$input) return;
      const checkbox = this.$input.querySelector('[aria-checked="mixed"]');
      if (this.checkVal) {
        checkbox.classList.add(this.classCheck);
        checkbox.closest('[data-field="checkbox"]').classList.add(this.classSelected);
      } else {
        checkbox.classList.remove(this.classCheck);
        checkbox.closest('[data-field="checkbox"]').classList.remove(this.classSelected);
      }
    },
    /**
     * @description Checkbox change event that adds/removes class based on if checkmark checked.
     *     For type 'guide', hides/shows guide
     * @private
     */
    _inputChecked() {
      const checkbox = this.$el;
      const checkboxInput = checkbox.querySelector('input');
      const label = checkbox.closest('[data-field="checkbox"]');
      const controlItem = checkbox.closest('[zing-theme-builder="item"]');
      if (this.type === 'guide') {
        if (this.checkVal) this.$parent.$clicked.classList.remove('hidden');
        else this.$parent.$clicked.classList.add('hidden');
      } else if (this.type === 'checkall') {
        this.$builder.features.forEach(function(feature) {
          feature.value = this.checkVal;
        }.bind(this));
        this.$builder._updateHTML();
      }
      if (this.checkVal) label.classList.add(this.classSelected);
      else label.classList.remove(this.classSelected);
      if(controlItem && controlItem.getAttribute('zing-theme-builder') === 'item') this.$parent._controlCheckboxClick(checkboxInput);
    }
  }
}
</script>